import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn, Tooltip } from '@keboola/design';
import { ControlButton } from '@xyflow/react';

const LegendButton = (props: { show: boolean; onClick: () => void }) => {
  return (
    <Tooltip placement="left" tooltip={`${props.show ? 'Hide' : 'Show'} Legend`} type="action">
      <ControlButton
        onClick={props.onClick}
        className={cn('tw-rounded-t', {
          'tw-shadow-[inset_0_0_0_1px_theme(colors.secondary.500)]': props.show,
        })}
      >
        <FontAwesomeIcon icon={['far', 'map']} />
      </ControlButton>
    </Tooltip>
  );
};

export default LegendButton;
