import React from 'react';
import type { ReactNode } from 'react';

import type { Props as CollapsibleBoxProps } from '@/react/common/CollapsibleBox';
import CollapsibleBox from '@/react/common/CollapsibleBox';

type Props = {
  children: ReactNode;
  isEmpty: boolean;
  header: ReactNode;
  actions: ReactNode;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
} & Omit<CollapsibleBoxProps, 'additionalActions'>;

const CollapsibleMapping = ({
  children,
  isEmpty,
  header,
  actions,
  isCollapsed,
  onToggleCollapse,
  ...collapsibleBoxProps
}: Props) => {
  if (isEmpty) {
    return (
      <div className="box no-mapping bottom-radius">
        <div className="box-header above-table-with-buttons tw-block">
          <div className="tw-flex tw-items-center tw-justify-between">
            {collapsibleBoxProps.title}
            {actions}
          </div>
        </div>
      </div>
    );
  }

  return (
    <CollapsibleBox
      {...collapsibleBoxProps}
      childrenClassName="!tw-p-0"
      isOpen={!isCollapsed}
      onToggle={onToggleCollapse}
      additionalActions={() => actions}
    >
      <div className="tw-flex tw-items-center tw-px-6 tw-pb-4 tw-shadow-table">{header}</div>
      {children}
    </CollapsibleBox>
  );
};

export default CollapsibleMapping;
