import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

const DocumentationButton = () => {
  return (
    <Link className="btn btn-default" href={`${URLS.USER_DOCUMENTATION}/templates/`}>
      <FontAwesomeIcon icon="book-blank" className="icon-addon-right" />
      Documentation
    </Link>
  );
};

export default DocumentationButton;
