import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonLink } from '@keboola/design';

import { routeNames } from '@/modules/components-directory/constants';
import RouterLink from '@/react/common/RouterLink';

const ComponentsListLink = () => {
  return (
    <ButtonLink>
      <RouterLink to={routeNames.ROOT} onlyActiveOnIndex>
        <FontAwesomeIcon icon="plus" />
        Add Component
      </RouterLink>
    </ButtonLink>
  );
};

export default ComponentsListLink;
