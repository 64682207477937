import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@keboola/design';
import createReactClass from 'create-react-class';

import Link from '@/react/common/RouterLink';

const QueryNavRow = createReactClass({
  propTypes: {
    query: PropTypes.object.isRequired,
    configurationId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
  },
  render() {
    return (
      <Link
        className="list-group-item"
        to={`ex-db-generic-${this.props.componentId}-query`}
        params={this.linkParams()}
      >
        {this.renderName()}
      </Link>
    );
  },

  renderName() {
    if (this.props.query.get('name') === '') {
      return (
        <strong>
          [Untitled]
          {this.props.isEditing && ' *'}
        </strong>
      );
    }
    return (
      <span>
        <strong>
          {this.props.query.get('name')}
          {this.props.isEditing && ' *'}
        </strong>
        <br />
        {this.props.query.get('table') ? (
          <span>
            {`${this.props.query.getIn(['table', 'schema'])}.${this.props.query.getIn([
              'table',
              'tableName',
            ])}`}
          </span>
        ) : (
          <Badge text="SQL" />
        )}
      </span>
    );
  },

  linkParams() {
    return {
      config: this.props.configurationId,
      query: this.props.query.get('id'),
    };
  },
});

export default QueryNavRow;
