import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import GooglePicker from '@/modules/google-utils/react/GooglePicker';
import ViewTemplates from '@/modules/google-utils/react/PickerViewTemplates';

const SpreadsheetTab = createReactClass({
  propTypes: {
    onSelectExisting: PropTypes.func.isRequired,
    onSelectFolder: PropTypes.func.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    onSwitchType: PropTypes.func.isRequired,
    valueTitle: PropTypes.string.isRequired,
    valueFolder: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['new', 'existing']),
  },

  render() {
    const spreadsheet =
      this.props.type === 'new' ? this.renderFolderPicker() : this.renderSpreadsheetPicker();
    return (
      <div className="form-horizontal">
        {this.renderTypeRadio()}
        {spreadsheet}
      </div>
    );
  },

  renderTypeRadio() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Upload data to</ControlLabel>
        </div>
        <div className="col-xs-10">
          <FormGroup>
            <Radio
              value="new"
              checked={this.props.type === 'new'}
              onChange={(event) => this.props.onSwitchType(event.target.value)}
            >
              New spreadsheet
            </Radio>
            <HelpBlock>Create new Spreadsheet</HelpBlock>
          </FormGroup>
          <FormGroup>
            <Radio
              value="existing"
              checked={this.props.type === 'existing'}
              onChange={(event) => this.props.onSwitchType(event.target.value)}
            >
              Existing spreadsheet
            </Radio>
            <HelpBlock>Use existing Spreadsheet previously created by this editor.</HelpBlock>
          </FormGroup>
        </div>
      </FormGroup>
    );
  },

  renderSpreadsheetPicker() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Spreadsheet</ControlLabel>
        </div>
        <div className="col-xs-10">
          <GooglePicker
            dialogTitle="Select Spreadsheet"
            buttonLabel={this.props.valueTitle ? this.props.valueTitle : 'Select Spreadsheet'}
            onPickedFn={this.props.onSelectExisting}
            buttonProps={{ bsStyle: 'default' }}
            views={[
              ViewTemplates.sheets,
              ViewTemplates.teamDriveSheets,
              ViewTemplates.sharedSheets,
              ViewTemplates.starredSheets,
              ViewTemplates.recentSheets,
            ]}
            multiselectEnabled={false}
          />
          <HelpBlock>Choose the spreadsheet into which you want to upload the data</HelpBlock>
        </div>
      </FormGroup>
    );
  },

  renderFolderPicker() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Spreadsheet</ControlLabel>
        </div>
        <div className="col-xs-10">
          <div className="input-group">
            <div className="input-group-btn">
              <GooglePicker
                dialogTitle="Select Folder"
                buttonLabel={this.props.valueFolder}
                onPickedFn={this.props.onSelectFolder}
                buttonProps={{ bsStyle: 'default' }}
                views={[
                  ViewTemplates.rootFolder,
                  ViewTemplates.teamDriveFolders,
                  ViewTemplates.sharedFolders,
                  ViewTemplates.starredFolders,
                ]}
                multiselectEnabled={false}
              />
            </div>
            <input
              placeholder="New Spreadsheet"
              type="text"
              value={this.props.valueTitle ? this.props.valueTitle : ''}
              onChange={this.props.onChangeTitle}
              className="form-control"
            />
          </div>
          <HelpBlock>
            Select the spreadsheet parent <strong>folder</strong> and enter the spreadsheet{' '}
            <strong>title</strong>.<br />
            The spreadsheet will be created upon saving.
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },
});

export default SpreadsheetTab;
