import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, Map } from 'immutable';

import { DEFAULT_DATE_RANGE, LAST_RUN_DATE } from '@/modules/ex-google-analytics-v4/constants';
import DateRangeModal from './DateRangeModal';

const DateRangesSelector = createReactClass({
  propTypes: {
    ranges: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    maxRanges: PropTypes.number.isRequired,
    supportsIncrementalFetch: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      supportsIncrementalFetch: true,
    };
  },

  getInitialState() {
    return {
      showModal: false,
      detail: Map(),
    };
  },

  render() {
    return (
      <div className="form-group">
        {this.renderRangeModal()}
        <label className="control-label col-md-2">Date Ranges</label>
        <div className="col-md-10">
          <div className="table">
            <div className="thead">
              <div className="tr">
                <div className="th">Since</div>
                <div className="th">Until</div>
                <div className="th" />
              </div>
            </div>
            <div className="tbody">
              {this.props.ranges.map((r, idx) => this.renderRange(r, idx))}
              {this.renderAddButton()}
            </div>
          </div>
        </div>
      </div>
    );
  },

  renderRangeModal() {
    return (
      <DateRangeModal
        show={this.state.showModal}
        startDate={this.state.detail.getIn(['range', 'startDate'])}
        endDate={this.state.detail.getIn(['range', 'endDate'])}
        allSavedRanges={this.props.ranges}
        onCancel={() => this.setState({ showModal: false, detail: Map() })}
        onSet={(startDate, endDate) =>
          this.updateRange(startDate, endDate, this.state.detail.get('idx'))
        }
        supportsIncrementalFetch={this.props.supportsIncrementalFetch}
        isIncrementalFetchingActive={this.isIncrementalFetchingActive()}
      />
    );
  },

  renderRange(range, idx) {
    return (
      <div className="tr" key={idx}>
        <div className="td">
          {range.get('startDate') === LAST_RUN_DATE ? 'Last run' : range.get('startDate')}
        </div>
        <div className="td">{range.get('endDate')}</div>
        {!this.props.isEditing ? (
          <div className="td" />
        ) : (
          <div className="td">
            <Tooltip tooltip="Edit" placement="top">
              <Button
                bsStyle="link"
                onClick={() => this.setState({ showModal: true, detail: fromJS({ range, idx }) })}
              >
                <FontAwesomeIcon icon="pen" fixedWidth />
              </Button>
            </Tooltip>
            <Tooltip tooltip="Remove" placement="top">
              <Button
                bsStyle="link"
                onClick={() => this.deleteRange(idx)}
                disabled={this.props.ranges.count() < 2}
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon="trash"
                  className={classnames({ 'text-muted': this.props.ranges.count() < 2 })}
                />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  },

  renderAddButton() {
    if (
      !this.props.isEditing ||
      this.props.ranges.count() === this.props.maxRanges ||
      this.isIncrementalFetchingActive()
    ) {
      return null;
    }

    return (
      <div className="tr">
        <Button bsStyle="link" onClick={this.addRange}>
          Add Date Range
        </Button>
      </div>
    );
  },

  addRange() {
    this.props.onChange(this.props.ranges.push(DEFAULT_DATE_RANGE));
  },

  deleteRange(idx) {
    this.props.onChange(this.props.ranges.delete(idx));
  },

  updateRange(startDate, endDate, idx) {
    const newRanges = this.props.ranges.map((range, index) => {
      if (idx === index) {
        return fromJS({ startDate, endDate });
      }

      return range;
    });
    this.props.onChange(newRanges);
  },

  isIncrementalFetchingActive() {
    return this.props.ranges.some((range) => range.get('startDate') === LAST_RUN_DATE);
  },
});

export default DateRangesSelector;
