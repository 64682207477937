import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';
import type { Map } from 'immutable';

import Truncated from '@/react/common/Truncated';
import PhaseActions from './PhaseActions';

const PhaseLabel = (props: {
  phase: Map<string, any>;
  shouldMergeInto: boolean;
  readOnly: boolean;
  behaviorOnError: string;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  onEdit: (phase: Map<string, any>) => void;
  onDelete: (phase: Map<string, any>) => void;
  toggleBehaviorOnErrorChange: (phase: Map<string, any>) => void;
}) => {
  if (props.shouldMergeInto) {
    return <div className="merge-into-label">Run in parallel</div>;
  }

  return (
    <>
      <Tooltip
        tooltip={
          <>
            <div className="phase-label-name">{props.phase.get('name')}</div>
            {props.phase.get('description') && (
              <span className="phase-label-description">{props.phase.get('description')}</span>
            )}
          </>
        }
        placement="top"
        type="explanatory"
        className="phase-label-tooltip"
        triggerClassName={classNames('flex-container justify-center phase-label', {
          clickable: !props.readOnly,
        })}
        triggerOnClick={() => !props.readOnly && props.onEdit(props.phase)}
      >
        <Truncated text={props.phase.get('name')} noTooltip />
        {!!props.phase.get('description') && (
          <FontAwesomeIcon icon="note-sticky" className="color-primary icon-addon-left" />
        )}
      </Tooltip>
      {!props.readOnly && (
        <PhaseActions
          phase={props.phase}
          onDelete={props.onDelete}
          behaviorOnError={props.behaviorOnError}
          toggleBehaviorOnErrorChange={props.toggleBehaviorOnErrorChange}
          newTaskPosition={props.newTaskPosition}
          handleShowAddTaskModal={props.handleShowAddTaskModal}
        />
      )}
    </>
  );
};

export default PhaseLabel;
