import { fromJS, List, Map } from 'immutable';

import type { AutomationDetail } from '@/api/routes/aiService';
import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

export type Store = { automations: AutomationDetail[] };

let _store = initStore('AutomationsStore', Map({ automations: List<any>([]) }));

const AutomationsStore = StoreUtils.createStore({ getStore: () => _store.toJS() as Store });

dispatcher.register(
  ({
    action,
  }: {
    action:
      | {
          type: typeof actionTypes.CREATE_AUTOMATION;
          automation: AutomationDetail;
        }
      | {
          type: typeof actionTypes.LOAD_AUTOMATIONS;
          automations: AutomationDetail[];
        }
      | {
          type: typeof actionTypes.LOAD_AUTOMATION;
          automation: AutomationDetail;
        }
      | {
          type: typeof actionTypes.DELETE_AUTOMATION;
          id: string;
        };
  }) => {
    switch (action.type) {
      case actionTypes.CREATE_AUTOMATION: {
        const automations = _store.get('automations').toJS() as Store['automations'];
        _store = _store.set(
          'automations',
          fromJS([...new Set(automations.concat(action.automation))]),
        );

        return AutomationsStore.emitChange();
      }

      case actionTypes.LOAD_AUTOMATIONS: {
        _store = _store.set('automations', fromJS(action.automations));

        return AutomationsStore.emitChange();
      }

      case actionTypes.LOAD_AUTOMATION: {
        let automations = _store.get('automations').toJS() as Store['automations'];
        automations = !automations.some(({ id }) => id === action.automation.id)
          ? automations.concat(action.automation)
          : automations.map((automation) => {
              return automation.id === action.automation.id
                ? fromJS(action.automation)
                : automation;
            });
        _store = _store.set('automations', fromJS(automations));

        return AutomationsStore.emitChange();
      }

      case actionTypes.DELETE_AUTOMATION: {
        let automations = _store.get('automations').toJS() as Store['automations'];
        automations = automations.filter(({ id }) => id !== action.id);
        _store = _store.set('automations', fromJS(automations));

        return AutomationsStore.emitChange();
      }

      default:
        break;
    }
  },
);

export default AutomationsStore;
