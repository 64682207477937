export const SCHEDULE_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const BRANCH_TOOLTIP_MESSAGE = 'Schedule can be set up only in production.';

export const SOX_BRANCH_TOOLTIP_MESSAGE =
  'Changes to the schedule will only be materialized when the branch is merged into production';

export const scheduleInvokeType = {
  TIME: 'time',
  EVENT: 'event',
};
