import React from 'react';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { features as componentFeatures } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { prepareBlocks } from '@/modules/components/react/components/generic/code-blocks/helpers';
import { prepareRuntimesForTransformation } from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import { routeNames } from '@/modules/transformations-v2/constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  config: Map<string, any>;
  sandbox: Map<string, any>;
  show: boolean;
  onHide: () => void;
  transformationComponent: Map<string, any>;
};

const CreateTransformationModal = (props: Props) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [imageTag, setImageTag] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);

  const runtimes = RuntimesStore.getRuntimes(props.transformationComponent.get('id'));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name,
      description,
      configuration: JSON.stringify({
        storage: props.config.getIn(['configuration', 'storage'], Map()),
        parameters: {
          blocks: prepareBlocks(),
        },
        ...(imageTag && {
          runtime: { tag: imageTag },
        }),
      }),
    };

    setIsCreating(true);
    return InstalledComponentsActionCreators.createConfiguration(
      props.transformationComponent.get('id'),
      data,
    )
      .then((response) => {
        // save from which workspace the transformation was created
        return InstalledComponentsActionCreators.setConfigurationMetadata(
          props.transformationComponent.get('id'),
          response.id,
          [
            {
              key: MetadataKeys.CONFIGURATION_CREATED_FROM,
              value: JSON.stringify({
                componentId: KEBOOLA_SANDBOXES,
                configurationId: props.config.get('id'),
              }),
            },
          ],
        ).then(() => {
          RoutesStore.getRouter().transitionTo(routeNames.GENERIC_TRANSFORMATION_CONFIG, {
            component: props.transformationComponent.get('id'),
            config: response.id,
          });
          return null;
        });
      })
      .catch((error) => {
        setIsCreating(false);
        throw error;
      });
  };

  return (
    <Modal
      show={props.show}
      onEnter={() => {
        setName(props.config.get('name'));
        setDescription('');
      }}
      onHide={props.onHide}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Transformation</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to create a new transformation from the workspace{' '}
            <strong>{props.config.get('name')}</strong>.
          </p>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Name your transformation"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder="Describe your transformation"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
          </FormGroup>
          {props.transformationComponent
            .get('features', List())
            .includes(componentFeatures.ALLOW_TAG_OVERRIDE) &&
            runtimes.length !== 0 && (
              <FormGroup>
                <ControlLabel>Backend Version</ControlLabel>
                <Select
                  clearable={false}
                  options={prepareRuntimesForTransformation(runtimes)}
                  value={imageTag}
                  onChange={(selected: string) => setImageTag(selected)}
                />
              </FormGroup>
            )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={isCreating ? 'Creating Transformation...' : 'Create Transformation'}
            saveButtonType="submit"
            isSaving={isCreating}
            isDisabled={!name.trim()}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateTransformationModal;
