import React from 'react';
import type { EdgeProps } from '@xyflow/react';
import { BaseEdge, BezierEdge, useStore } from '@xyflow/react';

import { highlightedEdgeStyles } from '@/modules/lineage/columns/columnsHelpers';
import { isEdgeCalculatedWithLlm, zoomOutSelector } from '@/modules/lineage/helpers';
import type { CustomEdge } from '@/modules/lineage/rfTypes';
import { LLM_EDGE_COLOR } from './constants';

export default function CommonEdge(props: EdgeProps<CustomEdge>) {
  const simpleView = useStore(zoomOutSelector);

  if (simpleView) {
    return null;
  }

  const llmStyles =
    props.data && isEdgeCalculatedWithLlm(props.data)
      ? {
          ...props.style,
          strokeDasharray: '5,4',
          stroke: LLM_EDGE_COLOR,
        }
      : {};

  const highlightedStyles =
    props.data && props.data.isHighlighted ? { ...props.style, ...highlightedEdgeStyles } : {};

  const style = { ...props.style, ...llmStyles, ...highlightedStyles };

  const { sourceX, sourceY, targetX, targetY, markerEnd } = props;

  // self connected node
  if (props.source === props.target) {
    const radiusX = (sourceX - targetX) * 0.6;
    const radiusY = 80;
    const path = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
      targetX + 2
    } ${targetY}`;
    return <BaseEdge {...props} path={path} markerEnd={markerEnd} style={style} />;
  }

  const radiusX = (sourceX - targetX) * 0.6;
  const radiusY = 80;

  // If we find that source is more right to target on x axis, then we make eliptic line to not show
  // it behind the nodes.
  const newRadius = targetY > sourceY ? targetY - sourceY : radiusY;

  const edgePath =
    sourceX > targetX
      ? `M ${sourceX - 5} ${sourceY} A ${radiusX} ${newRadius < 200 ? 200 : newRadius} 0 1 0 ${
          targetX + 2
        } ${targetY}`
      : null;

  return edgePath ? (
    <BaseEdge {...props} path={edgePath} markerEnd={markerEnd} style={style} />
  ) : (
    <BezierEdge {...props} style={style} />
  );
}
