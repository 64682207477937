import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import Checkbox from '@/react/common/Checkbox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import Tree from '@/react/common/Tree';

const TaskSelectTableRow = (props: {
  component: Map<string, any>;
  task: Map<string, any>;
  onTaskUpdate: (task: Map<string, any>) => void;
}) => {
  const renderConfiguration = () => {
    const parameters = props.task.get('actionParameters');

    if (parameters.count() === 1 && parameters.has('config') && props.component) {
      const config = InstalledComponentsStore.getConfig(
        props.component.get('id'),
        parameters.get('config').toString(),
      );
      return config.get('name', parameters.get('config'));
    }

    return (
      <Tooltip placement="top" tooltip={<Tree data={props.task.get('actionParameters')} />}>
        <FontAwesomeIcon
          className="text-muted tw-ml-2 tw-cursor-pointer hover:tw-text-secondary-500"
          icon="eye"
          fixedWidth
        />
      </Tooltip>
    );
  };

  const handleActiveChange = () => {
    return props.onTaskUpdate(props.task.set('active', !props.task.get('active')));
  };

  return (
    <tr onClick={handleActiveChange} className="clickable">
      <td>
        <span>
          {props.component && (
            <ComponentIcon component={props.component} className="icon-addon-right" size="32" />
          )}
          {props.component ? (
            <ComponentName component={props.component} />
          ) : (
            props.task.get('componentUrl')
          )}
        </span>
      </td>
      <td>
        <Badge text={props.task.get('action')} variant="blue" />
      </td>
      <td className="overflow-break-anywhere">{renderConfiguration()}</td>
      <td>
        <Checkbox checked={props.task.get('active')} onChange={handleActiveChange} />
      </td>
    </tr>
  );
};

export default TaskSelectTableRow;
