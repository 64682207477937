import Promise from 'bluebird';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { removeSchedulers } from '@/modules/scheduler/actions';
import RoutesStore from '@/stores/RoutesStore';
import { routeNames } from './constants';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadOrchestration = (configId) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_ORCHESTRATOR, configId);
};

const createOrchestration = (name, description = '') => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_ORCHESTRATOR, {
    name,
    description,
  }).then((response) => {
    RoutesStore.getRouter().transitionTo(routeNames.DETAIL, { config: response.id });
    return null;
  });
};

const deleteOrchestration = (orchestration, options = {}) => {
  return InstalledComponentsActionCreators.deleteConfiguration(
    KEBOOLA_ORCHESTRATOR,
    orchestration.get('id'),
    { transition: false, notification: true, ...options },
  ).then(() => removeSchedulers(orchestration.get('schedulers')));
};

const updateOrchestration = (configurationId, configuration, changeDescription) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    configurationId,
    configuration,
    changeDescription,
  );
};

const updateOrchestrationConfiguration = (
  configurationId,
  configurationData,
  changeDescription,
) => {
  return updateOrchestration(
    configurationId,
    {
      configuration: JSON.stringify(configurationData.toJS()),
    },
    changeDescription,
  );
};

export {
  loadAll,
  loadOrchestration,
  createOrchestration,
  deleteOrchestration,
  updateOrchestration,
  updateOrchestrationConfiguration,
};
