import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Clipboard } from '@keboola/design';
import type Bluebird from 'bluebird';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import {
  getDatabricksParametersFromCluster,
  getPredefinedCluster,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationsActionCreators from '@/modules/configurations/ConfigurationsActionCreators';
import Actions from '@/modules/sandboxes/Actions';
import Confirm from '@/react/common/Confirm';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import DatabricksParametersFormContent from './DatabricksParametersFormContent';

type DummyMap = Map<string, any>;
type Props = {
  savedValues: DummyMap;
  defaultValues: DummyMap;
  availableClusters?: List<DummyMap>;
  saveParameters?: (currentValues: DummyMap) => Bluebird<any>;
  loadClusters: () => void;
  isLoadingDatabricksClusters: boolean;
  isPredefinedCluster: boolean | undefined;
  isActiveWorkspace: boolean;
};

// HOC wrapping provided component with Databricks (Spark) related props
const withDatabricksClusters =
  (InnerComponent: React.ComponentClass<Props> | React.FunctionComponent<Props>) =>
  ({
    component,
    configId,
    configData,
    ...props
  }: {
    component: DummyMap;
    configId?: string;
    configData?: DummyMap;
  }) => {
    const isTransformation = component.get('type') === componentTypes.TRANSFORMATION;
    const componentId = component.get('id');
    const [isLoadingDatabricksClusters, setIsLoadingClusters] = useState(false);
    const { availableClusters }: { availableClusters?: Props['availableClusters'] } = useStores(
      () => ({
        availableClusters: InstalledComponentsStore.getLocalState(componentId, null).get(
          'clusters',
        ),
      }),
      [componentId],
      [InstalledComponentsStore],
    );
    let isPredefinedCluster: Props['isPredefinedCluster'],
      predefinedCluster,
      savedValues,
      saveParameters;

    if (configData) {
      savedValues = configData.get(isTransformation ? 'parameters' : 'databricks', Map());
      isPredefinedCluster = !!savedValues.get('clusterId');
      predefinedCluster = getPredefinedCluster(availableClusters, savedValues.get('clusterId'));

      if (isPredefinedCluster) {
        savedValues = savedValues.merge(getDatabricksParametersFromCluster(predefinedCluster));
      }

      // If `configData` exists HOC will pass `saveParameters` change handler
      saveParameters = (currentValues: DummyMap) => {
        let cleanedValues = !currentValues.get('clusterId')
          ? currentValues
          : currentValues.delete('nodeType').delete('numberOfNodes').delete('sparkVersion');

        if (isTransformation) {
          return InstalledComponentsActionCreators.updateComponentConfiguration(
            component.get('id'),
            configId,
            {
              configuration: JSON.stringify(configData.set('parameters', cleanedValues)),
            },
            'Change cluster parameters',
          );
        }

        cleanedValues = currentValues.get('clusterId')
          ? currentValues
          : currentValues.set('clusterId', null);

        return Actions.updateSandboxParameters(
          configData.get('id'),
          configData.getIn(['storage', 'input'], Map()),
          {
            databricks: cleanedValues.toJS(),
          },
        );
      };
    }

    const loadClusters = React.useCallback(() => {
      setIsLoadingClusters(true);

      return ConfigurationsActionCreators.loadDatabricksClusters(component.get('id')).finally(() =>
        setIsLoadingClusters(false),
      );
    }, [component]);

    useEffect(() => {
      if (!availableClusters && isPredefinedCluster && !isLoadingDatabricksClusters) {
        loadClusters();
      }
    }, [availableClusters, isPredefinedCluster, isLoadingDatabricksClusters, loadClusters]);

    return (
      <InnerComponent
        savedValues={savedValues}
        defaultValues={component.getIn(
          isTransformation
            ? ['data', 'image_parameters']
            : ['data', 'image_parameters', 'databricks'],
          Map(),
        )}
        availableClusters={availableClusters}
        loadClusters={loadClusters}
        isLoadingDatabricksClusters={isLoadingDatabricksClusters}
        isPredefinedCluster={isPredefinedCluster}
        isActiveWorkspace={!isTransformation && configData?.get('active')}
        {...(saveParameters && { saveParameters })}
        {...props}
      />
    );
  };

const Parameters = (props: Props) => {
  const { savedValues, defaultValues, availableClusters, isPredefinedCluster } = props;

  return (
    <div className="box">
      <div className="box-header big-padding with-border">
        <h2 className="box-title">Parameters</h2>
        <ModalForm {...props} />
      </div>
      {(!isPredefinedCluster || !!availableClusters) && (
        <div className="box-content value-list">
          <div className="flex-container">
            <strong className="font-medium">Node Type</strong>
            <span className="text-muted text-right">
              <Clipboard
                text={`${savedValues.get('nodeType', defaultValues.get('defaultNodeType'))}`}
                label={savedValues.get('nodeType', defaultValues.get('defaultNodeType'))}
                tooltipPlacement="top"
                showIcon={false}
              />
            </span>
          </div>
          <div className="flex-container">
            <strong className="font-medium">Number of Nodes</strong>
            <span className="text-muted text-right">
              <Clipboard
                text={`${savedValues.get(
                  'numberOfNodes',
                  defaultValues.get('defaultNumberOfNodes'),
                )}`}
                label={savedValues.get('numberOfNodes', defaultValues.get('defaultNumberOfNodes'))}
                tooltipPlacement="top"
                showIcon={false}
              />
            </span>
          </div>
          <div className="flex-container">
            <strong className="font-medium">Runtime Version</strong>
            <span className="text-muted text-right">
              <Clipboard
                text={`${savedValues.get(
                  'sparkVersion',
                  defaultValues.get('defaultSparkVersion'),
                )}`}
                label={savedValues.get('sparkVersion', defaultValues.get('defaultSparkVersion'))}
                tooltipPlacement="top"
                showIcon={false}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const ModalForm = (props: Props) => {
  const {
    savedValues,
    defaultValues,
    availableClusters,
    saveParameters,
    loadClusters,
    isLoadingDatabricksClusters,
    isPredefinedCluster,
    isActiveWorkspace,
  } = props;
  const [currentValues, setCurrentValues] = useState(savedValues);
  const [isSaving, setIsSaving] = useState(false);

  if (!saveParameters) {
    return null;
  }

  return (
    <Confirm
      closeAfterResolve
      icon="pen"
      title="Update parameters"
      onConfirm={() => {
        setIsSaving(true);
        return saveParameters(currentValues)?.finally(() => setIsSaving(false));
      }}
      text={
        <>
          {isActiveWorkspace && (
            <Alert variant="warning" className="tw-mb-5">
              After any changes workspace is automatically restarted.
            </Alert>
          )}
          <DatabricksParametersFormContent
            savedValues={savedValues}
            defaultValues={defaultValues}
            availableClusters={availableClusters}
            isLoading={isLoadingDatabricksClusters}
            loadClustersHandler={loadClusters}
            onChange={setCurrentValues}
          />
        </>
      }
      buttonType="success"
      buttonLabel="Save Changes"
      childrenRootElement="button"
      childrenRootElementClass="btn btn-link btn-link-inline btn-link-muted ml-auto"
      isDisabled={isPredefinedCluster && !availableClusters && isLoadingDatabricksClusters}
      isLoading={isSaving}
    >
      {isPredefinedCluster && !availableClusters && isLoadingDatabricksClusters ? (
        <Loader />
      ) : (
        <FontAwesomeIcon icon="pen" />
      )}
    </Confirm>
  );
};

const DatabricksParameters = withDatabricksClusters(Parameters);
const DatabricksParametersModal = withDatabricksClusters(ModalForm);

export { DatabricksParameters, DatabricksParametersModal };
