import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonGroup, IconButton } from '@keboola/design';
import { Promise } from 'bluebird';
import classnames from 'classnames';
import type { Iterable } from 'immutable';
import { List, Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import InstalledActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { loadAll } from '@/modules/dev-branches/actions';
import DevBranchDiffModal from '@/modules/dev-branches/components/DevBranchDiffModal';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { findUpdatedMetadata } from '@/modules/dev-branches/helpers';
import ConfirmModal from '@/react/common/ConfirmModal';
import FilterPanel from '@/react/common/FilterPanel';
import FullScreenModal, { FullScreenModalHeader } from '@/react/common/FullScreenModal';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import matchByWords from '@/utils/matchByWords';
import { CONFIGURATION_STATUS } from './constants';
import { getConfigurationStatus } from './helpers';
import PullAllFromProductionButton from './PullAllFromProductionButton';
import PullFromProductionComponentRow, { type ActionType } from './PullFromProductionComponentRow';

const FILTER_TYPES = {
  all: 'all',
  missing: 'missing',
  components: 'components',
  flows: 'flows',
  transformations: 'transformations',
} as const;

const modalLabels = {
  [CONFIGURATION_STATUS.noChange]: {
    description: 'Do you want to pull this configuration to the current branch?',
    buttonType: 'success',
    iconColor: 'blue',
  },
  [CONFIGURATION_STATUS.missing]: {
    description: 'Do you want to pull this configuration to the current branch?',
    buttonType: 'success',
    iconColor: 'blue',
  },
  [CONFIGURATION_STATUS.updatedInProduction]: {
    description: 'Do you want to pull this configuration to the current branch?',
    buttonType: 'success',
    iconColor: 'blue',
  },
  [CONFIGURATION_STATUS.updatedInBranch]: {
    description:
      'This action will replace your branch configuration with the latest production version. Any changes made to this configuration will be permanently lost and cannot be undone.',
    buttonType: 'danger',
    iconColor: 'red',
  },
  [CONFIGURATION_STATUS.conflict]: {
    description:
      'This action will replace your branch configuration with the latest production version. Any changes made to this configuration will be permanently lost and cannot be undone.',
    buttonType: 'danger',
    iconColor: 'red',
  },
} as const;

type FilterType = (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];

const isTransformation = (component: Map<string, any>) => {
  return component.get('type') === componentTypes.TRANSFORMATION;
};

const isFlow = (component: Map<string, any>) => {
  return component.get('type') === 'other' && component.get('id') === KEBOOLA_ORCHESTRATOR;
};

const isComponent = (component: Map<string, any>) => {
  const type = component.get('type');

  return (
    type === componentTypes.WRITER ||
    type === componentTypes.EXTRACTOR ||
    type === componentTypes.APPLICATION
  );
};

type Store = {
  devComponents: Map<string, any>;
  productionComponents: Map<string, any>;
  admins: Map<string, any>;
  updatedMetadata: Map<string, any>;
  sharedCodes: List<any>;
  readOnly: boolean;
  hasFlows: boolean;
};

type ActionModal = {
  type: null | ActionType;
  detail: Map<string, any>;
};

type Props = {
  onClose: () => void;
};

const PullFromProductionModal = ({ onClose }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState<FilterType>(FILTER_TYPES.all);
  const [actionModal, setActionModal] = useState<null | ActionModal>(null);
  const [loadingDiff, setLoadingDiff] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const store = useStores(
    () => {
      return {
        devComponents: InstalledComponentsStore.getAll(),
        productionComponents: DevBranchesStore.getProductionComponents(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
        sharedCodes: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE),
        hasFlows: ApplicationStore.hasFlows(),
        updatedMetadata: findUpdatedMetadata(
          InstalledComponentsStore.getAllMetadata(),
          DevBranchesStore.getProductionComponentsMetadata(),
        ),
      } as Store;
    },
    [],
    [InstalledComponentsStore, ApplicationStore, DevBranchesStore],
  );

  useEffect(() => {
    setIsLoading(true);
    loadAll().finally(() => setIsLoading(false));
  }, []);

  const getPlaceholder = () => {
    const components = getFilteredComponents();

    return `Search components (${components.count()}) and configurations (${components
      .flatMap((component) => getFilteredConfigurations(component))
      .count()})`;
  };

  const getFilteredComponents = () => {
    let components = store.productionComponents.filter(
      (component) => getFilteredConfigurations(component)?.size > 0,
    );

    if (filterType === FILTER_TYPES.transformations) {
      components = components.filter(isTransformation) as Map<string, any>;
    }

    if (filterType === FILTER_TYPES.flows) {
      components = components.filter(isFlow) as Map<string, any>;
    }

    if (filterType === FILTER_TYPES.components) {
      components = components.filter(isComponent) as Map<string, any>;
    }

    if (searchQuery) {
      components = components.filter((component) => {
        if (matchByWords([component.get('id'), component.get('name')], searchQuery)) {
          return true;
        }

        // Has any config matching search query
        return getFilteredConfigurations(component)?.size > 0;
      }) as Map<string, any>;
    }

    return components.sortBy((component) => component.get('name').toLowerCase());
  };

  const getFilteredConfigurations = (component: Map<string, any>) => {
    let configurations = component.get('configurations', Map());

    if (filterType === FILTER_TYPES.missing) {
      const devComponent = store.devComponents.get(component.get('id'), Map());

      configurations = configurations.filter((configuration: Map<string, any>) => {
        return !devComponent.hasIn(['configurations', configuration.get('id')]);
      });
    }

    if (searchQuery) {
      configurations = configurations.filter((configuration: Map<string, any>) => {
        return matchByWords(
          [configuration.get('id'), configuration.get('name'), configuration.get('description')],
          searchQuery,
        );
      });
    }

    return configurations;
  };

  const handleSetActionModal = (type: ActionType, detail: Map<string, any>) => {
    if (type === 'compare') {
      setLoadingDiff(true);
    }

    setActionModal({ type, detail });
  };

  const handleActionModalClose = () => {
    setActionModal(null);
  };

  const renderAdditionalActions = () => {
    return (
      <div className="predefined-search-list">
        {renderAdditionalActionsButton(FILTER_TYPES.all, 'All')}
        {renderAdditionalActionsButton(FILTER_TYPES.missing, 'Missing in branch')}
        <span className="group-separator" />
        {renderAdditionalActionsButton(FILTER_TYPES.components, 'Components')}
        {renderAdditionalActionsButton(
          FILTER_TYPES.flows,
          store.hasFlows ? 'Flows' : 'Orchestrations',
        )}
        {renderAdditionalActionsButton(FILTER_TYPES.transformations, 'Transformations')}
      </div>
    );
  };

  const renderAdditionalActionsButton = (type: FilterType, label: string) => {
    const active = filterType === type;

    return (
      <button
        className={classnames('btn predefined-search-link', { active })}
        onClick={() => setFilterType(type)}
      >
        {label}
      </button>
    );
  };

  const renderRows = (components: Iterable<string, any>) => {
    if (store.productionComponents.isEmpty() && isLoading) {
      return (
        <p>
          <Loader className="icon-addon-right" />
          Loading data...
        </p>
      );
    }

    if (components.isEmpty()) {
      return <p>No configurations found</p>;
    }

    return components
      .map((component) => {
        return (
          <PullFromProductionComponentRow
            key={component.get('id')}
            component={component}
            productionComponents={store.productionComponents}
            devComponents={store.devComponents}
            configurations={getFilteredConfigurations(component)}
            readOnly={store.readOnly}
            hasFlows={store.hasFlows}
            admins={store.admins}
            onClose={onClose}
            onAction={handleSetActionModal}
            isLoadingDiff={loadingDiff}
          />
        );
      })
      .toArray();
  };

  const configStatus = getConfigurationStatus(
    actionModal?.detail.get('componentId', Map()),
    actionModal?.detail.get('configId', Map()),
    store.productionComponents,
    store.devComponents,
  );
  const pullModalLabels = modalLabels[configStatus];

  const sharedCodes = store.sharedCodes
    .find(
      (config: Map<string, any>) =>
        config.getIn(['configuration', 'componentId']) === actionModal?.detail.get('componentId'),
      null,
      Map(),
    )
    .get('rows', List());

  return (
    <>
      <FullScreenModal className="full-screen-generic-overview" onHide={onClose}>
        <FullScreenModalHeader onClose={onClose} title="Pull from Production">
          <ButtonGroup>
            {!store.readOnly && (
              <>
                <PullAllFromProductionButton
                  devComponents={store.devComponents}
                  productionComponents={store.productionComponents}
                />
                <span className="btn-separator tw-m-1" />
              </>
            )}
            <IconButton onClick={onClose} icon="xmark" variant="outline" />
          </ButtonGroup>
        </FullScreenModalHeader>

        <Modal.Body className="tw-pb-16">
          <FilterPanel
            className="mtp-2"
            placeholder={getPlaceholder}
            query={searchQuery}
            onChange={setSearchQuery}
            additionalActions={renderAdditionalActions()}
          />

          <LazyList
            limit={10}
            items={getFilteredComponents()}
            render={renderRows}
            useWindow={false}
            getScrollParent={() => {
              return document.querySelector('.full-screen-modal .modal-dialog') as HTMLElement;
            }}
          />
        </Modal.Body>
      </FullScreenModal>

      {actionModal && (
        <DevBranchDiffModal
          show={actionModal.type === 'compare'}
          updatedMetadata={store.updatedMetadata}
          detail={actionModal.detail || Map()}
          admins={store.admins}
          sharedCodes={sharedCodes}
          component={store.productionComponents.get(actionModal.detail.get('componentId')) || Map()}
          onClose={handleActionModalClose}
          onDiffLoaded={() => setLoadingDiff(false)}
        />
      )}

      <ConfirmModal
        show={actionModal?.type === 'reset'}
        onHide={handleActionModalClose}
        title="Pull from Production"
        text={pullModalLabels.description}
        buttonLabel="Pull configuration"
        buttonType={pullModalLabels.buttonType}
        icon="code-pull-request"
        iconColor={pullModalLabels.iconColor}
        onConfirm={() => {
          return InstalledActionsCreators.resetConfigurationToProductionVersion(
            actionModal?.detail.get('componentId'),
            actionModal?.detail.get('configId'),
          ).then(() => {
            ApplicationActionCreators.sendNotification({
              type: 'success',
              message: () => (
                <>
                  Configuration <b>{actionModal?.detail.get('name')}</b> has been pulled from
                  production.
                </>
              ),
            });

            return Promise.all([
              InstalledActionsCreators.loadInstalledComponentsForce(),
              InstalledActionsCreators.loadDeletedComponentsForce(),
            ]);
          });
        }}
      />
    </>
  );
};

export default PullFromProductionModal;
