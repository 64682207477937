import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import string from '@/utils/string';

class TableOutput extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Save Settings</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Storage Table Name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.tableName}
                onChange={(e) => {
                  this.props.onChange({
                    tableName: string.sanitizeKbcTableIdString(e.target.value),
                  });
                }}
                placeholder="mytable"
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the table stored in Storage. {nameWarning}</HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

TableOutput.propTypes = {
  value: PropTypes.shape({
    tableName: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TableOutput;
