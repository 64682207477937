import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { ReactFlowState } from '@xyflow/react';
import { ControlButton, useReactFlow, useStore } from '@xyflow/react';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';

const zoomInSelector = (state: ReactFlowState) => state.transform[2] >= state.maxZoom;

const ZoomInButton = () => {
  const { zoomIn } = useReactFlow<CustomNode, CustomEdge>();
  const maxZoomReached = useStore(zoomInSelector);

  return (
    <Tooltip placement="left" tooltip="Zoom In" type="action">
      <ControlButton onClick={() => zoomIn()} disabled={maxZoomReached}>
        <FontAwesomeIcon icon="plus" />
      </ControlButton>
    </Tooltip>
  );
};

export default ZoomInButton;
