import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { canManageBucket, canUnlinkBucket, canWriteBucket } from '@/modules/admin/privileges';
import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { DISABLED_WORKSPACE_ACTIONS_MESSAGE, FORM_STEPS } from '@/modules/sandboxes/Constants';
import { workspaceActionsDisabled } from '@/modules/sandboxes/helpers';
import { toggleExpandedBucket, toggleShowAllBuckets } from '@/modules/storage/actions';
import { routeNames, sortEntities, storageInitialLimit } from '@/modules/storage/constants';
import {
  getDescriptionValue,
  getFilteredData,
  getLoadingActionName,
} from '@/modules/storage/helpers';
import { useSorter } from '@/modules/storage/hooks';
import BlockButton from '@/react/common/BlockButton';
import BucketLabels from '@/react/common/BucketLabels';
import Checkbox from '@/react/common/Checkbox';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import CreatedDate from '@/react/common/CreatedDate';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import Link from '@/react/common/RouterLink';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import SortIcon from '@/react/common/SortIcon';
import TableActionLoader from '@/react/common/TableActionLoader';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import CopyToWorkspace from '@/react/common/TableIconActions/CopyToWorkspace';
import CreateSnapshot from '@/react/common/TableIconActions/CreateSnapshot';
import CreateWorkspace from '@/react/common/TableIconActions/CreateWorkspace';
import ExportSelected from '@/react/common/TableIconActions/ExportSelected';
import TableUpdatedBy from '@/react/common/TableUpdatedBy';
import Truncated from '@/react/common/Truncated';
import useMatchMedia from '@/react/hooks/useMatchMedia';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import string from '@/utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import DeleteUnlinkBucketMenuItem from './DeleteUnlinkBucketMenuItem';
import DescriptionButton from './DescriptionButton';
import NativeTypesLabel from './NativeTypesLabel';
import RefreshExternalBucketMenuItem from './RefreshExternalBucketMenuItem';

const HIDDEN_COLUMNS_WIDTH_LIMIT = 1281;
const HIDDEN_COLUMNS_FOR_SMALL_SCREEN = ['last_updated_by'];

const Description = ({ row, entity, readOnly }) => {
  return (
    <div className="f-13 text-muted">
      <InlineDescriptionEditInput
        entity={entity}
        description={getDescriptionValue(row.original.item.get('metadata'))}
        onSave={(newDescription) => {
          return MetadataActionCreators.saveMetadata(
            entity,
            row.original.item.get('id'),
            MetadataKeys.DESCRIPTION,
            newDescription.trim(),
          );
        }}
        readOnly={readOnly}
      />
    </div>
  );
};

const BucketCell = ({ row, canWrite }) => {
  return (
    <span className="with-expand flex-container flex-start overflow-break-anywhere">
      {row.getCanExpand() && (
        <TableCollapseButton entity="bucket" isCollapsed={!row.getIsExpanded()} />
      )}
      <FontAwesomeIcon
        fixedWidth
        icon={
          row.getCanExpand() ? (row.getIsExpanded() ? 'folder-open' : 'folder') : ['far', 'folder']
        }
        className="text-muted"
      />
      <div>
        <div className="flex-container flex-start">
          <Link
            to={routeNames.BUCKET}
            params={{ bucketId: row.original.item.get('id') }}
            className="link-inherit"
          >
            <Truncated text={row.original.item.get('displayName')} />
          </Link>
          <BucketLabels bucket={row.original.item} />
        </div>
        <Description row={row} entity={ObjectTypes.BUCKET} readOnly={!canWrite} />
      </div>
    </span>
  );
};

const TableCell = ({ row, canWrite }) => {
  return (
    <span className="flex-container flex-start overflow-break-anywhere">
      <FontAwesomeIcon icon="table" className="text-muted" />
      <div>
        <div className="flex-container flex-start">
          <Link
            to={routeNames.TABLE}
            params={{
              bucketId: row.original.item.getIn(['bucket', 'id']),
              tableName: row.original.item.get('name'),
            }}
            className={classnames('link-inherit', {
              'dotted-underline': row.original.item.get('isAlias', false),
            })}
          >
            <Truncated text={row.original.item.get('displayName')} />
          </Link>
          <NativeTypesLabel isTyped={row.original.item.get('isTyped', false)} />
          <ExternalTableLabel
            tableType={row.original.item.get('tableType')}
            hasExternalSchema={row.original.item.getIn(['bucket', 'hasExternalSchema'])}
          />
          <BucketLabels
            bucket={row.original.item.get('bucket')}
            withStageLabel={false}
            withExternalLabel={false}
            withLinkedLabel={false}
          />
        </div>
        <Description row={row} entity={ObjectTypes.TABLE} readOnly={!canWrite} />
      </div>
    </span>
  );
};

export const HeaderSelection = ({ table, sapiToken }) => {
  const allAllowedRows = table.getRowModel().flatRows.filter((row) => {
    if (!row.original.item.has('stage')) {
      return canWriteBucket(sapiToken, row.original.item.get('bucket'));
    }
    if (row.original.item.get('linkedBy', List()).count() !== 0) {
      return false;
    }
    if (row.original.item.has('sourceBucket')) {
      return canUnlinkBucket(sapiToken, row.original.item);
    }
    return canManageBucket(sapiToken, row.original.item);
  });

  if (allAllowedRows.length === 0) {
    return null;
  }

  const isAllSelected = allAllowedRows.every((row) => row.getIsSelected());
  const isSomeSelected = allAllowedRows.some((row) => row.getIsSelected());

  return (
    <Checkbox
      checked={isAllSelected}
      indeterminate={!isAllSelected && isSomeSelected}
      onChange={() => {
        const toggleState = !(isAllSelected || isSomeSelected);
        allAllowedRows.forEach((row) => row.toggleSelected(toggleState));
      }}
      tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
    />
  );
};

export const CellSelection = ({ row, sapiToken }) => {
  if (!row.original.item.has('stage')) {
    if (!canWriteBucket(sapiToken, row.original.item.get('bucket'))) {
      return <Checkbox disabled tooltip="You don't have permission to delete this table" />;
    }

    return (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={() => {
          const parent = row.getParentRow();

          if (row.getIsSelected() && parent && parent.getIsSelected()) {
            parent.toggleSelected(false, { selectChildren: false });
          }

          return row.toggleSelected();
        }}
        tooltip={row.getIsSelected() ? 'Deselect table' : 'Select table'}
      />
    );
  }

  if (row.original.item.get('linkedBy', List()).count() !== 0) {
    return <Checkbox disabled tooltip="Please unlink the linked buckets first" />;
  }

  if (row.original.item.has('sourceBucket') && !canUnlinkBucket(sapiToken, row.original.item)) {
    return <Checkbox disabled tooltip="You don't have permission to unlink this bucket" />;
  }

  if (!row.original.item.has('sourceBucket') && !canManageBucket(sapiToken, row.original.item)) {
    return <Checkbox disabled tooltip="You don't have permission to delete this bucket" />;
  }

  const isChecked = row.getIsSelected() || row.getIsAllSubRowsSelected();
  const isSomeSelected = row.getIsSomeSelected();

  return (
    <Checkbox
      checked={isChecked}
      indeterminate={!isChecked && isSomeSelected}
      onChange={() => {
        row.toggleSelected(!(isChecked || isSomeSelected));
        row.subRows.forEach((subRow) => {
          subRow.toggleSelected(!(isChecked || isSomeSelected));
        });
      }}
      tooltip={`${isChecked || isSomeSelected ? 'Deselect' : 'Select'} ${
        isSomeSelected ? 'tables in bucket' : 'bucket'
      }`}
    />
  );
};

export const HeaderActions = ({
  allBuckets = Map(),
  sort,
  setSort,
  table,
  canExportTable,
  openDeleteModal,
  openCreateWorkpaceModal,
  openExportTablesModal,
  openSnapshotModal,
  hasSnowflakePartnerConnectLimited,
  isCreatingSnapshot,
  isExportingTables,
}) => {
  const selectedRows = table.getSelectedRowModel().flatRows.map((row) => row.original.item);
  const { buckets, tables } = selectedRows.reduce(
    (bucketsAndTables, row) => {
      const key = row.has('stage') ? 'buckets' : 'tables';
      return { ...bucketsAndTables, [key]: bucketsAndTables[key] + 1 };
    },
    {
      buckets: 0,
      tables: 0,
    },
  );
  const areWorkspacesDisabled = selectedRows.some((row) =>
    workspaceActionsDisabled(row.has('stage') ? row : row.get('bucket')),
  );

  const isSortedByName = sort.get('entity') === sortEntities.NAME;

  const selectedTables = selectedRows.filter((row) => !row.has('stage'));

  // As this component is used in IndexTableResults which does not contain tables inside buckets,
  // we get those tables from allBuckets if sent and merge it with selected tables. So when user selects a bucket
  // we find all the tables inside it.
  const uniqueSelectedTables =
    allBuckets.count() === 0
      ? selectedTables
      : _.uniq(
          [
            ...selectedTables,
            ...selectedRows
              .filter((row) => row.has('stage'))
              .flatMap((row) => {
                return allBuckets.getIn([row.get('id'), 'bucketTables'], Map()).toArray();
              }),
          ],
          (table) => table.get('id'),
        );

  return (
    <div className="flex-container flex-start">
      {buckets + tables === 0 ? (
        <span
          className="clickable"
          title="Sort by name"
          onClick={() => {
            setSort({
              entity: sortEntities.NAME,
              order: isSortedByName ? sort.get('order') * -1 : 1,
            });
          }}
        >
          Name
          <SortIcon
            className="icon-addon-left"
            isSorted={isSortedByName}
            isSortedDesc={sort.get('order') === -1}
          />
        </span>
      ) : (
        <strong>
          {!!buckets && `${buckets} ${string.pluralize(buckets, 'bucket')} `}
          {buckets && tables ? 'and ' : ''}
          {!!tables && `${tables} ${string.pluralize(tables, 'table')} `}
          selected
        </strong>
      )}
      {buckets + tables > 0 && (
        <div className="table-action-buttons no-wrap">
          <Tooltip placement="top" tooltip="Delete selected">
            <Button
              bsStyle="link"
              className="btn-link-inline btn-link-muted"
              onClick={() => openDeleteModal(selectedRows)}
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
          {canExportTable && (
            <ExportSelected
              disabled={isExportingTables}
              onClick={() => openExportTablesModal(uniqueSelectedTables)}
            />
          )}
          {!hasSnowflakePartnerConnectLimited && (
            <>
              <CreateWorkspace
                tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
                onClick={() => !areWorkspacesDisabled && openCreateWorkpaceModal(selectedRows)}
                disabled={areWorkspacesDisabled}
              />
              <CopyToWorkspace
                tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
                onClick={() => {
                  if (!areWorkspacesDisabled) {
                    openCreateWorkpaceModal(selectedRows, FORM_STEPS.SANDBOX_UPDATE);
                  }
                }}
                disabled={areWorkspacesDisabled}
              />
            </>
          )}
          <CreateSnapshot
            disabled={isCreatingSnapshot || uniqueSelectedTables.length === 0}
            multiple={uniqueSelectedTables.length > 1}
            onClick={() => openSnapshotModal(uniqueSelectedTables)}
          />
        </div>
      )}
    </div>
  );
};

export const BucketActions = ({
  bucket,
  sapiToken,
  isDeleting,
  isExporting,
  isCreatingSnapshot,
  openModal,
  openExportTablesModal,
  openSnapshotModal,
  canExportTable,
  canManageBucket,
}) => {
  const bucketTables = bucket.get('bucketTables', List());
  const areBucketTablesEmpty = bucketTables.isEmpty();
  const isLoading = isDeleting || isExporting || isCreatingSnapshot;

  return isLoading ? (
    <TableActionLoader
      loadingActionName={getLoadingActionName({ isCreatingSnapshot, isExporting, isDeleting })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem
          disabled={areBucketTablesEmpty}
          onSelect={() => openExportTablesModal(bucketTables.toArray())}
        >
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export bucket
        </RowActionMenuItem>
      )}
      <RowActionMenuItem
        disabled={areBucketTablesEmpty}
        onSelect={() => openSnapshotModal(bucketTables.toArray())}
      >
        <FontAwesomeIcon fixedWidth icon="camera-viewfinder" />
        Create snapshot
      </RowActionMenuItem>
      <DescriptionButton data={bucket} entity={ObjectTypes.BUCKET} readOnly={!canManageBucket} />
      {canManageBucket && (
        <>
          <RefreshExternalBucketMenuItem bucket={bucket} />
          <RowActionMenuItem divider />
          <DeleteUnlinkBucketMenuItem
            bucket={bucket}
            token={sapiToken}
            onSelect={() => openModal(bucket)}
            linkedBucketsCount={bucket.get('linkedBy', Map()).count()}
            isLinked={bucket.has('sourceBucket')}
          />
        </>
      )}
    </RowActionDropdown>
  );
};

export const TableActions = ({
  table,
  isDeleting,
  isExporting,
  isCreatingSnapshot,
  openDeleteTableModal,
  openExportTablesModal,
  openSnapshotModal,
  canExportTable,
  canWriteTable,
}) => {
  const isLoading = isCreatingSnapshot || isExporting || isDeleting;

  return isLoading ? (
    <TableActionLoader
      loadingActionName={getLoadingActionName({ isCreatingSnapshot, isExporting, isDeleting })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem onSelect={() => openExportTablesModal([table])}>
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export table
        </RowActionMenuItem>
      )}
      <RowActionMenuItem onSelect={() => openSnapshotModal([table])}>
        <FontAwesomeIcon fixedWidth icon="camera-viewfinder" />
        Create snapshot
      </RowActionMenuItem>
      <DescriptionButton data={table} entity={ObjectTypes.TABLE} readOnly={!canWriteTable} />
      {canWriteTable && (
        <>
          <RowActionMenuItem divider />
          <RowActionMenuItem onSelect={() => openDeleteTableModal(table)}>
            <FontAwesomeIcon icon="trash" fixedWidth />
            Delete table
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};

export const LastChangeColumn = ({ sort, setSort }) => {
  const isSorted = sort.get('entity') === sortEntities.LAST_CHANGE;

  return (
    <span
      className="clickable"
      title="Sort by last change"
      onClick={() => {
        setSort({
          entity: sortEntities.LAST_CHANGE,
          order: isSorted ? sort.get('order') * -1 : 1,
        });
      }}
    >
      <SortIcon isSorted={isSorted} isSortedDesc={sort.get('order') === -1} />
      Last change
    </span>
  );
};

const getTableInitialState = (data, expandedBuckets) => {
  let expanded = {};

  if (!expandedBuckets.isEmpty()) {
    expanded = data.reduce((all, bucket, bucketIndex) => {
      return { ...all, [bucketIndex]: expandedBuckets.has(bucket.item.get('id')) };
    }, {});
  }

  return {
    expanded,
    ...(window.innerWidth < HIDDEN_COLUMNS_WIDTH_LIMIT && {
      columnVisibility: HIDDEN_COLUMNS_FOR_SMALL_SCREEN.reduce((visibility, column) => {
        return { ...visibility, [column]: false };
      }, {}),
    }),
  };
};

const Table = ({ columns, data, expandedBuckets, showAll }) => {
  const initialState = React.useMemo(
    () => getTableInitialState(data, expandedBuckets),
    [data, expandedBuckets],
  );

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getRowId: (row) => row.item.get('id'),
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  let rows = tableInstance.getRowModel().rows;

  const matchMediaHandler = React.useCallback(
    ({ matches }) => {
      return tableInstance
        .getAllColumns()
        .filter((column) => HIDDEN_COLUMNS_FOR_SMALL_SCREEN.includes(column.id))
        .forEach((column) => column.toggleVisibility(!!matches));
    },
    [tableInstance],
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div className="table table-hover react-table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className={classnames('tr with-action-button is-sticky bg-color-white')}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.column.id}
                    className={classnames('th', {
                      'w-280 text-right': header.column.id === 'last_updated_by',
                      'w-200 text-right': header.column.id === 'last_modified',
                    })}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row) => {
            const rowAction = (e) => {
              if (hasSelections()) {
                return;
              }

              if (row.depth === 0) {
                if (shouldUseNewWindow(e)) {
                  return windowOpen(
                    RoutesStore.getRouter().createHref(routeNames.BUCKET, {
                      bucketId: row.original.item.get('id'),
                    }),
                  );
                }

                toggleExpandedBucket(row.original.item.get('id'), !row.getIsExpanded());
                return row.toggleExpanded(!row.getIsExpanded());
              }

              if (shouldUseNewWindow(e)) {
                return windowOpen(
                  RoutesStore.getRouter().createHref(routeNames.TABLE, {
                    bucketId: row.original.item.getIn(['bucket', 'id']),
                    tableName: row.original.item.get('name'),
                  }),
                );
              }

              return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                bucketId: row.original.item.getIn(['bucket', 'id']),
                tableName: row.original.item.get('name'),
              });
            };

            return (
              <div
                key={row.id}
                {...(row.depth === 0 && row.subRows.length === 0
                  ? {
                      className: 'tr hoverable-actions-with-replacement',
                      title: 'No tables in bucket',
                    }
                  : {
                      tabIndex: 0,
                      role: 'button',
                      className: 'tr clickable hoverable-actions-with-replacement',
                      onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                      onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                      onClick: rowAction,
                      onKeyDown: onTableRowKeyDown(rowAction),
                    })}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <div
                      key={cell.id}
                      className={classnames('td', {
                        'text-right': ['last_updated_by', 'last_modified'].includes(cell.column.id),
                        'bg-selected': row.getIsSelected(),
                        'dev-bucket': isCreatedInDevBranch(
                          row.original.item.has('stage')
                            ? row.original.item
                            : row.original.item.get('bucket'),
                        ),
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.getRowModel().rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => toggleShowAllBuckets(true)} />
      )}
    </>
  );
};

const IndexTable = ({
  buckets,
  expandedBuckets,
  showAllBuckets,
  bucketsSort,
  searchFilters,
  components,
  configurations,
  sapiToken,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openSnapshotModal,
  openCreateWorkpaceModal,
  deletingTables,
  exportingTables,
  createSnapshotsTables,
  deletingBuckets,
  canExportTable,
  hasFlows,
  hasSnowflakePartnerConnectLimited,
}) => {
  const { sort, sorter, setSort } = useSorter(bucketsSort);

  const columns = React.useMemo(
    () => [
      {
        header: ({ table }) => {
          return (
            <div className="tw-inline-flex tw-items-center">
              <div
                onClick={onClickSelectionCell}
                className="-tw-ml-6 tw-inline-flex tw-items-center tw-p-1 tw-pl-6 tw-pr-3"
              >
                <HeaderSelection sapiToken={sapiToken} table={table} />
              </div>
              <HeaderActions
                openDeleteModal={openDeleteModal}
                openCreateWorkpaceModal={openCreateWorkpaceModal}
                canExportTable={canExportTable}
                openExportTablesModal={openExportTablesModal}
                openSnapshotModal={openSnapshotModal}
                table={table}
                sort={sort}
                setSort={setSort}
                hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
                isCreatingSnapshot={!createSnapshotsTables.isEmpty()}
                isExportingTables={!exportingTables.isEmpty()}
              />
            </div>
          );
        },
        cell: ({ row }) => {
          const inFolder = row.depth === 1;

          return (
            <div
              className={classnames('tw-inline-flex tw-items-center', {
                'tw-pl-7': inFolder,
              })}
            >
              <div
                onClick={onClickSelectionCell}
                className={classnames(
                  'tw-inline-flex tw-items-center tw-p-3',
                  inFolder ? '-tw-ml-12 tw-pl-12' : '-tw-ml-6 tw-pl-6',
                )}
              >
                <CellSelection sapiToken={sapiToken} row={row} />
              </div>
              {row.depth === 0 ? (
                <BucketCell row={row} canWrite={canWriteBucket(sapiToken, row.original.item)} />
              ) : (
                <TableCell
                  row={row}
                  canWrite={canWriteBucket(sapiToken, row.original.item.get('bucket'))}
                />
              )}
            </div>
          );
        },
        accessorKey: 'item',
      },
      {
        id: 'last_updated_by',
        header: 'Recently updated by',
        cell: ({ row }) => {
          if (row.original.item.has('stage')) {
            return (
              <span className="text-muted">
                {row.original.item.get('bucketTables').isEmpty() ? (
                  'N/A'
                ) : (
                  <TableUpdatedBy
                    table={row.original.item
                      .get('bucketTables')
                      .sortBy((table) => {
                        const lastChange = table.get('lastChangeDate', table.get('created'));
                        return -1 * new Date(lastChange).getTime();
                      })
                      .first()}
                    components={components}
                    configurations={configurations}
                    hasFlows={hasFlows}
                  />
                )}
              </span>
            );
          }

          return (
            <span className="text-muted">
              <TableUpdatedBy
                table={row.original.item}
                components={components}
                configurations={configurations}
                hasFlows={hasFlows}
              />
            </span>
          );
        },
      },
      {
        id: 'last_modified',
        header: () => <LastChangeColumn sort={sort} setSort={setSort} />,
        cell: ({ row }) => {
          if (row.original.item.has('stage')) {
            const bucket = row.original.item;
            const isDeleting = deletingBuckets.get(bucket.get('id'), false);
            const isExportingBucket =
              !bucket.get('bucketTables').isEmpty() &&
              bucket.get('bucketTables').every((table) => {
                return exportingTables.get(table.get('id'), false);
              });

            const isCreatingSnapshotBucket =
              !bucket.get('bucketTables').isEmpty() &&
              bucket.get('bucketTables').every((table) => {
                return createSnapshotsTables.get(table.get('id'), false);
              });

            return (
              <div
                className={classnames('actions-container', {
                  'force-actions': isDeleting || isExportingBucket || isCreatingSnapshotBucket,
                })}
              >
                <div className="not-actions">
                  <CreatedDate
                    createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                  />
                </div>
                <div className="actions">
                  <BucketActions
                    bucket={bucket}
                    sapiToken={sapiToken}
                    isDeleting={isDeleting}
                    isExporting={isExportingBucket}
                    isCreatingSnapshot={isCreatingSnapshotBucket}
                    openModal={openDeleteBucketModal}
                    canExportTable={canExportTable}
                    openExportTablesModal={openExportTablesModal}
                    openSnapshotModal={openSnapshotModal}
                    canManageBucket={canManageBucket(sapiToken, bucket)}
                  />
                </div>
              </div>
            );
          }

          const table = row.original.item;
          const isDeleting = deletingTables.get(table.get('id'), false);
          const isExporting = exportingTables.get(table.get('id'), false);
          const isCreatingSnapshots = createSnapshotsTables.get(table.get('id'), false);

          return (
            <div
              className={classnames('actions-container', {
                'force-actions': isDeleting || isExporting || isCreatingSnapshots,
              })}
            >
              <div className="not-actions">
                <CreatedDate createdTime={table.get('lastChangeDate') || table.get('created')} />
              </div>
              <div className="actions">
                <TableActions
                  table={table}
                  isDeleting={isDeleting}
                  isExporting={isExporting}
                  isCreatingSnapshot={isCreatingSnapshots}
                  openDeleteTableModal={openDeleteTableModal}
                  openExportTablesModal={openExportTablesModal}
                  openSnapshotModal={openSnapshotModal}
                  canExportTable={canExportTable}
                  canWriteTable={canWriteBucket(sapiToken, table.get('bucket'))}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [
      sort,
      setSort,
      openDeleteModal,
      openCreateWorkpaceModal,
      canExportTable,
      openExportTablesModal,
      openSnapshotModal,
      hasSnowflakePartnerConnectLimited,
      createSnapshotsTables,
      exportingTables,
      sapiToken,
      components,
      configurations,
      hasFlows,
      deletingTables,
      deletingBuckets,
      openDeleteBucketModal,
      openDeleteTableModal,
    ],
  );

  const memoizedRows = React.useMemo(() => {
    return getFilteredData(buckets, '', searchFilters.toJS())
      .sort(sorter)
      .map((bucket) => {
        return {
          item: bucket,
          subRows: bucket
            .get('bucketTables')
            .sort(sorter)
            .map((table) => ({ item: table }))
            .toArray(),
        };
      })
      .toArray();
  }, [buckets, searchFilters, sorter]);

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets created yet.</div>
      </div>
    );
  }

  return (
    <div className="box">
      <Table
        columns={columns}
        data={memoizedRows}
        expandedBuckets={expandedBuckets}
        showAll={showAllBuckets}
      />
    </div>
  );
};

IndexTable.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  expandedBuckets: PropTypes.instanceOf(Map).isRequired,
  bucketsSort: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  deletingBuckets: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  createSnapshotsTables: PropTypes.instanceOf(Map).isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openDeleteBucketModal: PropTypes.func.isRequired,
  openDeleteTableModal: PropTypes.func.isRequired,
  openExportTablesModal: PropTypes.func.isRequired,
  openCreateWorkpaceModal: PropTypes.func.isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  showAllBuckets: PropTypes.bool.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
};

export default IndexTable;
