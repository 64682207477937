import React from 'react';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';

const MenuItem = (props: {
  onClick: () => void;
  children: React.ReactNode;
  isActive: boolean;
  disabledReason?: string;
  additionalActions?: React.ReactNode;
}) => {
  const body = (
    <span
      tabIndex={0}
      role="button"
      onClick={() => !props.disabledReason && props.onClick()}
      className={classNames(
        'tw-group tw-flex tw-h-9 tw-justify-between tw-rounded tw-p-2 tw-text-sm',
        !props.additionalActions ? 'tw-capitalize' : 'tw-normal-case',
        !props.disabledReason
          ? 'hover:tw-bg-secondary-100'
          : 'tw-cursor-not-allowed tw-text-neutral-400 hover:tw-bg-neutral-100',
        { 'tw-bg-secondary-100 tw-text-secondary-600': props.isActive },
      )}
    >
      <div className="tw-flex tw-items-center">{props.children}</div>
      {!!props.additionalActions && (
        <div className="tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100">
          {props.additionalActions}
        </div>
      )}
    </span>
  );

  if (props.disabledReason) {
    return (
      <Tooltip placement="top" type="explanatory" tooltip={props.disabledReason}>
        {body}
      </Tooltip>
    );
  }

  return body;
};

export default MenuItem;
