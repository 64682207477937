import React, { useContext, useState } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { domToBlob } from 'modern-screenshot';

import { canAccessProject } from '@/modules/admin/privileges';
import NoAccessIcon from '@/modules/lineage/components/NoAccessIcon';
import { prepareHref } from '@/modules/lineage/components/Sidebar/helpers';
import { GraphContext } from '@/modules/lineage/contexts/GraphContext';
import { parseFQID } from '@/modules/lineage/fqid';
import { getExportOptions } from '@/modules/lineage/helpers';
import Loader from '@/react/common/Loader';
import contactSupport from '@/utils/contactSupport';

const NodeDropdown = ({
  onHighlight,
  graphId = null,
  nodeId,
}: {
  onHighlight?: (nodeId: string) => void;
  graphId?: string | null;
  nodeId: string;
}) => {
  const dropdownId = React.useId();
  const { focusedNodeId } = useContext(GraphContext);
  const [isProcessing, setIsProcessing] = useState(false);

  const params = parseFQID(nodeId);
  const detailHref = prepareHref(params);

  const handleReportBug = () => {
    if (!graphId) {
      return;
    }

    const { flowPane, ...options } = getExportOptions(graphId);

    if (!flowPane) {
      console.error('cannot export, viewport is missing');
      return;
    }

    setIsProcessing(true);
    domToBlob(flowPane, { ...options })
      .then((result) =>
        contactSupport({
          ticketContext: { screenshot: result },
        }),
      )
      .finally(() => setIsProcessing(false));
  };

  if (!detailHref && !onHighlight && !graphId) {
    return null;
  }

  return (
    <Dropdown id={dropdownId} className="row-dropdown">
      <Dropdown.Toggle
        noCaret
        bsStyle="link"
        disabled={isProcessing}
        className={classNames(
          { 'tw-invisible': !isProcessing },
          '!tw-py-0 !tw-pl-3 !tw-pr-2 group-hover/node:tw-visible',
        )}
      >
        {isProcessing ? (
          <Loader />
        ) : (
          <FontAwesomeIcon icon="ellipsis-vertical" className="!tw-text-2xl" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-icon">
        {detailHref && (
          <MenuItem
            target="_blank"
            rel="noopener noreferrer"
            href={detailHref}
            disabled={!canAccessProject(params.projectId)}
          >
            Explore detail
            {canAccessProject(params.projectId) ? (
              <FontAwesomeIcon className="right" icon="arrow-up-right-from-square" />
            ) : (
              <NoAccessIcon classNames="right" />
            )}
          </MenuItem>
        )}
        {onHighlight && (
          <MenuItem disabled={nodeId === focusedNodeId} onClick={() => onHighlight(nodeId)}>
            Focus on Node
          </MenuItem>
        )}
        {graphId && <MenuItem divider className="!tw-m-2 !tw-bg-neutral-200" />}
        {graphId && <MenuItem onClick={handleReportBug}>Report Lineage Bug</MenuItem>}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NodeDropdown;
