import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';
import type { List, Map } from 'immutable';

import { KEBOOLA_DBT_TRANSFORMATION } from '@/constants/componentIds';
import { features } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import {
  getRuntimeShortLabel,
  prepareRuntimeLabel,
  prepareRuntimesForTransformation,
} from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import BackendVersionModal from '@/modules/transformations/react/components/backend-version/Modal';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  isReadOnly: boolean;
  configData: Map<string, any>;
  componentId: string;
  configId: string;
  componentFeatures: List<string>;
};

const TagOverride = ({
  isReadOnly,
  configData,
  componentId,
  configId,
  componentFeatures,
}: Props) => {
  const { runtimes } = useStores(
    () => {
      return {
        runtimes: RuntimesStore.getRuntimes(
          DBT_COMPONENTS.includes(componentId) ? KEBOOLA_DBT_TRANSFORMATION : componentId,
        ),
      };
    },
    [componentId],
    [RuntimesStore],
  );

  const [showTagOverrideModal, setShowTagOverrideModal] = useState(false);

  const imageTag = configData.getIn(
    ['runtime', 'tag'],
    configData.getIn(['runtime', 'image_tag'], ''),
  );

  const hasRuntimes =
    componentFeatures.includes(features.ALLOW_TAG_OVERRIDE) && runtimes.length !== 0;

  if (!imageTag && !hasRuntimes) {
    return null;
  }

  const runtime = imageTag
    ? runtimes.find((runtime) => runtime.transformationComponentTag === imageTag)
    : runtimes.find((runtime) => runtime.isTypeDefault);

  const overrideImageTag = (imageTag: string) => {
    const configDataWithImageTag = configData.withMutations((data) => {
      if (!imageTag) {
        return data.deleteIn(['runtime', 'image_tag']).deleteIn(['runtime', 'tag']);
      }

      // keep the old image tag if it was set
      data.hasIn(['runtime', 'image_tag'])
        ? data.setIn(['runtime', 'image_tag'], imageTag)
        : data.setIn(['runtime', 'tag'], imageTag);
    });

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      componentId,
      configId,
      { configuration: JSON.stringify(configDataWithImageTag.toJS()) },
      `${imageTag ? 'Set' : 'Remove'} image override`,
    );
  };

  return (
    <li>
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          isReadOnly
            ? READ_ONLY_TOOLTIP_MESSAGE
            : !runtime
              ? 'Configuration has a custom image tag set.'
              : `${prepareRuntimeLabel(runtime)}`
        }
      >
        <Button
          block
          bsStyle="link"
          className={classnames({ disabled: isReadOnly })}
          onClick={() => {
            if (isReadOnly) {
              return;
            }

            if (!hasRuntimes) {
              RoutesStore.getRouter().transitionTo(componentsRoutes.GENERIC_CONFIG_RAW, {
                component: componentId,
                config: configId,
              });
              return;
            }

            setShowTagOverrideModal(true);
          }}
        >
          <FontAwesomeIcon icon="right-left" fixedWidth />
          Backend version:{' '}
          <span className={classnames({ 'color-warning': !runtime })}>
            {getRuntimeShortLabel(runtime, imageTag)}
          </span>
        </Button>
      </Tooltip>
      <BackendVersionModal
        show={showTagOverrideModal}
        availableVersions={prepareRuntimesForTransformation(runtimes)}
        onClose={() => setShowTagOverrideModal(false)}
        onSave={overrideImageTag}
        imageTag={imageTag}
      />
    </li>
  );
};

export default TagOverride;
