import React from 'react';
import { ButtonGroup } from '@keboola/design';
import type { Map } from 'immutable';

import { KEBOOLA_DATABRICKS_TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  getAllowedPatternComponents,
  getAllowedTransformations,
} from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import CreateFolderButton from '@/react/common/ConfigurationsTable/CreateFolderButton';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import NewTransformationButton from './components/NewTransformationButton';

const HeaderButtons = () => {
  const state = useStores(
    () => {
      const isDevModeActive = DevBranchesStore.isDevModeActive();

      return {
        isDevModeActive,
        readOnly: ApplicationStore.isReadOnly(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        allowedPatternComponents: getAllowedPatternComponents(
          ComponentsStore.getAllForType(componentTypes.CODE_PATTERN),
          isDevModeActive,
        ),
        allConfigurations: InstalledComponentsStore.getAllForType(
          componentTypes.TRANSFORMATION,
        ).flatMap((component: Map<string, any>) => {
          return component
            .get('configurations')
            .map((config: Map<string, any>) => config.set('component', component));
        }),
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        availableDatabricksClusters: InstalledComponentsStore.getLocalState(
          KEBOOLA_DATABRICKS_TRANSFORMATION,
          null,
        ).get('clusters'),
      };
    },
    [],
    [
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore,
    ],
  );

  if (state.readOnly) {
    return null;
  }

  return (
    <ButtonGroup>
      <CreateFolderButton
        entity="transformation"
        configurations={state.allConfigurations}
        componentsMetadata={state.componentsMetadata}
      />
      <NewTransformationButton
        hasPayAsYouGo={state.hasPayAsYouGo}
        patternComponents={state.allowedPatternComponents}
        allowedComponents={state.allowedTransformationComponents}
        availableDatabricksClusters={state.availableDatabricksClusters}
        componentsMetadata={state.componentsMetadata}
        isDevModeActive={state.isDevModeActive}
      />
    </ButtonGroup>
  );
};

export default HeaderButtons;
