import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import { Map } from 'immutable';

import { unlinkConfiguration } from '@/modules/model-services/Actions';
import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';

class UnlinkConfigurationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Tooltip placement="top" tooltip="Unlink transformation">
          <Button
            bsStyle="link"
            className="text-muted pt-0 pb-0"
            onClick={() => this.setState({ showModal: true })}
            disabled={this.state.isLoading}
          >
            {this.state.isLoading ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          </Button>
        </Tooltip>
        <ConfirmModal
          closeAfterResolve
          buttonType="danger"
          icon="trash"
          title="Unlink Transformation"
          text={
            <p>
              Are you sure you want to unlink <b>{this.props.config}</b> from the model{' '}
              <b>{this.props.model.get('name')}</b>.
            </p>
          }
          buttonLabel="Unlink transformation"
          show={this.state.showModal}
          isLoading={this.state.isLoading}
          onConfirm={this.handleSubmit}
          onHide={() => this.setState({ showModal: false })}
        />
      </>
    );
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    return unlinkConfiguration(
      this.props.model.getIn(['deployment', 'id']),
      this.props.link.get('componentId'),
      this.props.link.get('configurationId'),
    ).catch((error) => {
      this.setState({ isLoading: false });
      throw error;
    });
  }
}

UnlinkConfigurationButton.propTypes = {
  model: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.string.isRequired,
  link: PropTypes.instanceOf(Map).isRequired,
};

export default UnlinkConfigurationButton;
