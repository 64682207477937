import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard, Tooltip } from '@keboola/design';
import classnames from 'classnames';
import { List, Map } from 'immutable';
import { capitalize } from 'underscore.string';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { startDeployment, stopDeployment, updateModel } from '@/modules/model-services/Actions';
import { HELP_LINK } from '@/modules/model-services/Constants';
import { getModelIdentifier } from '@/modules/model-services/helper';
import { routeNames as sandboxesRouteNames } from '@/modules/sandboxes/Constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import ConnectorIcon from '@/react/common/ConnectorIcon';
import CreatedDate from '@/react/common/CreatedDate';
import Loader from '@/react/common/Loader';
import Link from '@/react/common/RouterLink';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import User from '@/react/common/User';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import DescriptionModal from './DescriptionModal';
import LinkConfigurationButton from './LinkConfigurationButton';
import TestModelButton from './TestModelButton';
import UnlinkConfigurationButton from './UnlinkConfigurationButton';

class ModelsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDescriptionModal: false,
      modelDetail: Map(),
    };

    this.renderModel = this.renderModel.bind(this);
  }

  render() {
    return (
      <>
        <div className="box mb-2 mt-1">
          <Table hover>
            <thead>
              <tr>
                <th>Name</th>
                {(this.props.models.count() > 0 || this.props.deployingModels.count() > 0) && (
                  <>
                    <th className="w-150">Stage</th>
                    <th className="w-200">Last Change/Owner</th>
                    <th className="w-50">URL</th>
                    <th className="w-100">Status</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </Table>
        </div>
        <DescriptionModal
          show={this.state.showDescriptionModal}
          model={this.state.modelDetail}
          onHide={() => this.setState({ showDescriptionModal: false, modelDetail: Map() })}
          onSubmit={(name, version, description) => updateModel(name, version, description)}
        />
      </>
    );
  }

  renderRows() {
    if (!this.props.hasAnyModels && this.props.isLoading) {
      return (
        <tr className="no-hover">
          <td>
            <Loader className="icon-addon-right" />
            Loading models...
          </td>
        </tr>
      );
    }

    if (!this.props.hasAnyModels) {
      return (
        <tr className="no-hover">
          <td>
            No models registered yet. You can register the model to your MLflow server manually or
            via a <Link to={sandboxesRouteNames.WORKSPACES}>workspace</Link>.
          </td>
        </tr>
      );
    }

    if (
      !this.props.isSearching &&
      this.props.models.isEmpty() &&
      this.props.deployingModels.isEmpty()
    ) {
      return (
        <tr className="no-hover">
          <td>No models deployed yet.</td>
        </tr>
      );
    }

    return (
      <>
        {this.props.deployingModels.map(this.renderModel).toArray()}
        {this.props.models.map(this.renderModel).toArray()}
      </>
    );
  }

  renderModel(model, index) {
    const description = model.get('description') || '';
    const isCreating = model.getIn(['deployment', 'isCreating']);
    const isActionPending =
      isCreating ||
      this.props.pendingActions.hasIn(['stop', model.getIn(['deployment', 'id'])]) ||
      this.props.pendingActions.hasIn(['deploy', getModelIdentifier(model)]);

    return (
      <React.Fragment key={index}>
        <tr className="hoverable-actions-with-replacement">
          <td>
            <span className="component-name">{model.get('name')}</span>
            {!isCreating && (
              <>
                <br />
                <span
                  className="f-13 text-muted"
                  title={description.length > 300 ? description : ''}
                >
                  {!!description ? descriptionExcerpt(description, 300) : 'No description'}
                </span>
                <span className="f-13 text-muted">
                  <Tooltip
                    tooltip={`${!!description ? 'Update' : 'Add'} description`}
                    placement="top"
                  >
                    <Button
                      bsStyle="link"
                      className="btn-link-inline icon-addon-left"
                      onClick={() =>
                        this.setState({ showDescriptionModal: true, modelDetail: model })
                      }
                    >
                      <FontAwesomeIcon icon="pen" className="font-size-inherit" />
                    </Button>
                  </Tooltip>
                </span>
              </>
            )}
          </td>
          <td>{this.renderStage(model)}</td>
          <td className="td f-13">
            {this.renderLastEdit(model)}
            {this.renderOwner(model)}
          </td>
          <td>{this.renderUrl(model)}</td>
          <td>
            {isActionPending ? (
              this.renderStatus(model)
            ) : (
              <div className="actions-container">
                <div className="not-actions">{this.renderStatus(model)}</div>
                <div className="actions">
                  <RowActionDropdown>
                    <LinkConfigurationButton
                      model={model}
                      transformations={this.props.transformations}
                      allowedTransformationComponents={this.props.allowedTransformationComponents}
                    />
                    <TestModelButton model={model} />
                    {this.renderStartDeploymentButton(model)}
                    {this.renderStopDeploymentButton(model)}
                    {this.renderHowToUseLink()}
                  </RowActionDropdown>
                </div>
              </div>
            )}
          </td>
        </tr>
        {model.getIn(['deployment', 'linkedConfigurations'], List()).map((link) => {
          const component = this.props.allowedTransformationComponents.get(link.get('componentId'));
          const config = this.props.transformations.getIn(
            [link.get('componentId'), 'configurations', link.get('configurationId')],
            Map(),
          );

          return (
            <tr
              className={classnames('hoverable-actions', { 'row-disabled': config.isEmpty() })}
              key={`${link.get('componentId')}.${link.get('configurationId')}`}
            >
              <td>
                <div className="flex-container flex-start">
                  <ConnectorIcon className="icon-iddon-right" />
                  <ComponentIcon component={component} size="20" className="icon-addon-right" />
                  {!config.isEmpty() ? (
                    <ComponentConfigurationLink
                      className="color-main"
                      componentId={link.get('componentId')}
                      configId={link.get('configurationId')}
                      hasFlows={this.props.hasFlows}
                    >
                      {config.get('name')}
                    </ComponentConfigurationLink>
                  ) : (
                    <>{link.get('configurationId')} (deleted)</>
                  )}
                </div>
              </td>
              <td></td>
              <td className="f-13">
                {!config.isEmpty() && (
                  <CreatedDate createdTime={config.getIn(['currentVersion', 'created'])} />
                )}
              </td>
              <td></td>
              <td className="w-50 pl-0 pr-1">
                <UnlinkConfigurationButton
                  model={model}
                  config={config.get('name', link.get('configurationId'))}
                  link={link}
                />
              </td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  }

  renderStatus(model) {
    return (
      <div>
        {this.renderStatusLabel(model)}
        <span className="f-13 text-muted">Version {model.get('version')}</span>
      </div>
    );
  }

  renderUrl(model) {
    if (!model.hasIn(['deployment', 'url'])) {
      return null;
    }
    return (
      <Clipboard
        tooltipText="Copy URL to clipboard"
        tooltipPlacement="right"
        text={model.getIn(['deployment', 'url'])}
      />
    );
  }

  renderLastEdit(model) {
    return (
      <CreatedDate
        createdTime={
          model.getIn(['deployment', 'updatedTimestamp']) ||
          model.getIn(['deployment', 'createdTimestamp'])
        }
      />
    );
  }

  renderOwner(model) {
    // user should be email address
    if (!/\S+@\S+\.\S+/.test(model.get('user'))) {
      return null;
    }

    return (
      <>
        <br />
        {this.props.admins.has(model.get('user')) ? (
          <User user={this.props.admins.get(model.get('user'))} className="text-muted" size={18} />
        ) : (
          model.get('user')
        )}
      </>
    );
  }

  renderStage(model) {
    const stage = model.get('stage').toLowerCase();

    return (
      <span
        className={classnames('font-medium', {
          'text-success': stage === 'production',
          'text-muted': stage !== 'production',
        })}
      >
        {capitalize(stage)}
      </span>
    );
  }

  renderStatusLabel(model) {
    if (!model.hasIn(['deployment', 'id']) && !model.getIn(['deployment', 'isCreating'])) {
      return <div className="font-medium text-muted">Not deployed</div>;
    }

    if (model.getIn(['deployment', 'error'])) {
      return (
        <div className="font-medium text-danger">
          Failed{' '}
          <Tooltip
            placement="top"
            tooltip={model.getIn(['deployment', 'error'])}
            type="explanatory"
          >
            <FontAwesomeIcon icon="circle-info" className="text-muted" />
          </Tooltip>
        </div>
      );
    }

    const isStopPending = this.props.pendingActions.hasIn([
      'stop',
      model.getIn(['deployment', 'id']),
    ]);

    const isDeployPending =
      !isStopPending &&
      (model.getIn(['deployment', 'isCreating']) ||
        this.props.pendingActions.hasIn(['deploy', getModelIdentifier(model)]));

    if (isStopPending || isDeployPending) {
      return (
        <div className="font-medium text-warning">
          {isStopPending ? 'Stopping...' : 'Deploying...'}
        </div>
      );
    }

    return <div className="font-medium text-success">Deployed</div>;
  }

  renderStopDeploymentButton(model) {
    if (!model.hasIn(['deployment', 'id'])) {
      return null;
    }

    return (
      <ConfirmMenuItem
        buttonType="success"
        icon="circle-stop"
        title={`Stop ${model.get('name')} model`}
        text="Are you sure you want to stop the model deployment?"
        buttonLabel="Stop model deployment"
        onConfirm={() => stopDeployment(model.getIn(['deployment', 'id']))}
      >
        <FontAwesomeIcon icon="circle-stop" fixedWidth />
        Stop deployment
      </ConfirmMenuItem>
    );
  }

  renderHowToUseLink() {
    return (
      <RowActionMenuItem href={HELP_LINK}>
        <FontAwesomeIcon icon="circle-info" fixedWidth />
        How to use model
      </RowActionMenuItem>
    );
  }

  renderStartDeploymentButton(model) {
    return (
      <ConfirmMenuItem
        buttonType="success"
        icon="arrow-rotate-left"
        title={`Redeploy ${model.get('name')} model`}
        text={`Are you sure you want to redeploy the model?`}
        buttonLabel={`Redeploy model`}
        onConfirm={() => startDeployment(model)}
      >
        <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
        Redeploy model
      </ConfirmMenuItem>
    );
  }
}

ModelsTable.propTypes = {
  models: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  hasAnyModels: PropTypes.bool.isRequired,
  pendingActions: PropTypes.instanceOf(Map).isRequired,
  transformations: PropTypes.instanceOf(Map).isRequired,
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  deployingModels: PropTypes.instanceOf(List).isRequired,
  isLoading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
};

export default ModelsTable;
