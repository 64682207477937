import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import Promise from 'bluebird';
import { Map, Set } from 'immutable';

import { defaultOptions } from '@/constants/sortable';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import { sortRowsByName } from '@/modules/configurations/utils/helpers';
import { getStorageInputTablesWithSourceSearch } from '@/modules/wr-db/helpers';
import { createRow, orderRows } from '@/modules/wr-db/rowsActions';
import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';
import CreateRowModal from '@/react/common/CreateRowModal';
import FilterPanel from '@/react/common/FilterPanel';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import NoResultsFound from '@/react/common/NoResultsFound';
import SortByName from '@/react/common/SortByName';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import string from '@/utils/string';
import ConfigRowTableRow from './ConfigRowTableRow';

class ConfigRowTables extends React.PureComponent {
  state = {
    selected: Set(),
    searchQuery: '',
    showNewTableModal: false,
    showDeleteModal: false,
    isChangingOrder: false,
    sort: null,
  };

  render() {
    if (!this.props.rows.count()) {
      return this.renderMissingTables();
    }

    return (
      <>
        <div className="box-separator">
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base">Tables</h2>
            {this.renderNewTableButton()}
          </div>
          <FilterPanel
            query={this.state.searchQuery}
            onChange={(searchQuery) => this.setState({ searchQuery })}
          />
          {this.renderTables()}
        </div>
        <ConfirmModal
          closeAfterResolve
          show={this.state.showDeleteModal}
          icon="trash"
          title="Remove Selected"
          text={`Are you sure you want to remove the ${
            this.state.selected.count() > 1 ? 'selected rows' : 'row'
          }?`}
          buttonLabel="Remove"
          buttonType="danger"
          onConfirm={() => {
            return Promise.each(this.state.selected.toArray(), (rowId) => {
              return ConfigurationRowsActionCreators.deleteSimple(
                this.props.component.get('id'),
                this.props.config.get('id'),
                rowId,
                `Table ${this.props.rows.getIn([rowId, 'name'], rowId)} deleted`,
              );
            }).finally(() => this.setState({ selected: Set() }));
          }}
          onHide={() => this.setState({ showDeleteModal: false })}
        />
      </>
    );
  }

  renderTables = () => {
    const filteredRows = this.filteredTables();

    if (!filteredRows.count()) {
      return <NoResultsFound entityName="tables" />;
    }

    return (
      <LazyList
        rootElement="div"
        className="box"
        items={filteredRows}
        render={(rows) => this.renderTable(rows, filteredRows)}
      />
    );
  };

  renderTable = (rows, filteredRows) => {
    const selectedCount = this.state.selected.count();
    const isAllSelected = selectedCount === filteredRows.count();
    const isSomeSelected = selectedCount > 0 && selectedCount !== filteredRows.count();

    return (
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            {!this.props.readOnly ? (
              <>
                <span className="th with-row-sort-handle w-52 pr-0">
                  <Checkbox
                    tooltip={`${isAllSelected || isSomeSelected ? 'Deselect' : 'Select'} all rows`}
                    checked={isAllSelected}
                    onChange={(checked) =>
                      this.setState(() => ({
                        selected: !checked
                          ? Set()
                          : this.props.rows.map((row) => row.get('id')).toSet(),
                      }))
                    }
                    indeterminate={isSomeSelected && !isAllSelected}
                  />
                </span>
                <span className="th pl-0 w-250">
                  <div className="flex-container flex-start">
                    {!selectedCount ? (
                      <SortByName
                        allowReset
                        sortBy={this.state.sort}
                        onClick={(sort) => this.setState({ sort })}
                      />
                    ) : (
                      <strong>
                        {selectedCount} {string.pluralize(selectedCount, 'row')} selected
                      </strong>
                    )}
                    {selectedCount > 0 && (
                      <div className="table-action-buttons">
                        <Tooltip placement="top" tooltip="Delete Selected">
                          <Button
                            bsStyle="link"
                            className="text-muted"
                            onClick={() => this.setState({ showDeleteModal: true })}
                            disabled={this.state.isDeleting}
                          >
                            {this.state.isDeleting ? (
                              <Loader />
                            ) : (
                              <FontAwesomeIcon icon="trash" fixedWidth />
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </span>
              </>
            ) : (
              <span className="th w-250">Name</span>
            )}
            <span className="th">Source</span>
            <span className="th">Destination</span>
            <span className="th text-center">Incremental</span>
            <span className="th" />
          </div>
        </div>
        <Sortable
          className="tbody"
          options={{ ...defaultOptions, disabled: this.props.readOnly }}
          onChange={(order, sortable, event) => {
            const allRowsOrder = [
              ...order,
              ...this.props.rows.keySeq().toArray().slice(order.length),
            ];

            this.setState({ isChangingOrder: true });
            return orderRows(
              this.props.component.get('id'),
              this.props.config.get('id'),
              this.props.config.get('name'),
              allRowsOrder,
              event.newIndex,
            ).finally(() => {
              this.setState({ isChangingOrder: false });
            });
          }}
        >
          {this.renderTableRows(rows)}
        </Sortable>
      </div>
    );
  };

  renderTableRows = (rows) => {
    return rows
      .sort(sortRowsByName(this.state.sort))
      .map((row) => {
        const isSelected = this.state.selected.has(row.get('id'));

        return (
          <ConfigRowTableRow
            key={row.get('id')}
            readOnly={this.props.readOnly}
            row={row}
            config={this.props.config}
            component={this.props.component}
            isChangingOrder={this.state.isChangingOrder}
            isFiltered={!!this.state.searchQuery}
            isSorted={!!this.state.sort}
            tablesWithSourceSearchInputMapping={getStorageInputTablesWithSourceSearch(
              row.get('configuration'),
            )}
            isSelected={isSelected}
            toggleSelected={() => {
              this.setState({
                selected: isSelected
                  ? this.state.selected.delete(row.get('id'))
                  : this.state.selected.add(row.get('id')),
              });
            }}
          />
        );
      })
      .toList();
  };

  renderMissingTables = () => {
    return (
      <div className="box-separator">
        <h2 className="tw-m-0 tw-mb-4 tw-text-base">Tables</h2>
        <div className="box">
          <div className="box-content text-center">
            <p>No tables assigned yet.</p>
            {this.renderNewTableButton()}
          </div>
        </div>
      </div>
    );
  };

  renderNewTableButton = () => {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle="success"
          bsSize="sm"
          onClick={() => this.setState({ showNewTableModal: true })}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Add Table
        </Button>
        <CreateRowModal
          component={this.props.component}
          configId={this.props.config.get('id')}
          buckets={this.props.buckets}
          tables={this.props.tables}
          show={this.state.showNewTableModal}
          onHide={() => this.setState({ showNewTableModal: false })}
          onCreate={(tableId) => {
            return createRow(
              this.props.component.get('id'),
              this.props.config.get('id'),
              this.props.tables.get(tableId),
            );
          }}
          onRowRedirect={(rowId) => {
            RoutesStore.getRouter().transitionTo(this.props.component.get('id') + '-row', {
              config: this.props.config.get('id'),
              row: rowId,
            });
          }}
        />
      </>
    );
  };

  filteredTables = () => {
    if (!this.state.searchQuery) {
      return this.props.rows.toList();
    }

    return this.props.rows
      .filter((row) => {
        return matchByWords(
          [
            row.getIn(['configuration', 'parameters', 'tableId']),
            row.getIn(['configuration', 'parameters', 'dbName']),
          ],
          this.state.searchQuery,
        );
      })
      .toList();
  };
}

ConfigRowTables.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  rows: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
};

export default ConfigRowTables;
