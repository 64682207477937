import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@keboola/design';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { FEATURE_SAML_ROLES } from '@/constants/features';
import { canManageUsers } from '@/modules/admin/privileges';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import CreatedDate from '@/react/common/CreatedDate';
import Gravatar from '@/react/common/Gravatar';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import Truncated from '@/react/common/Truncated';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicatonStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import SettingsTabs from './components/SettingsTabs';
import { approveUser, cancelInvitation, rejectUser, removeUser } from './actions';
import { routeNames } from './constants';
import SettingsStore from './SettingsStore';

const Users = createReactClass({
  mixins: [createStoreMixin(ApplicatonStore, StackFeaturesStore, SettingsStore)],

  getStateFromStores() {
    const sapiToken = ApplicatonStore.getSapiToken();
    return {
      sapiToken,
      currentAdmin: sapiToken.get('admin', Map()),
      users: SettingsStore.getUsers(),
      joinRequests: SettingsStore.getJoinRequests(),
      invitations: SettingsStore.getInvitations(),
      hasSamlRoles: StackFeaturesStore.hasStackFeature(FEATURE_SAML_ROLES),
    };
  },

  getInitialState() {
    return {
      deleting: Map(),
      canceling: Map(),
      approving: Map(),
      rejecting: Map(),
    };
  },

  render() {
    return <SettingsTabs activeTab={routeNames.USERS}>{this.renderBody()}</SettingsTabs>;
  },

  renderBody() {
    if (
      this.state.joinRequests.isEmpty() &&
      this.state.users.isEmpty() &&
      this.state.invitations.isEmpty()
    ) {
      return (
        <div className="box">
          <div className="box-content">No users.</div>
        </div>
      );
    }

    return (
      <div className="box">
        <Table hover>
          <thead>
            <tr>
              <th>User</th>
              <th>Role</th>
              <th>MFA</th>
              <th>Joined</th>
              <th>Expires</th>
              <th>Invited/Approved by</th>
            </tr>
          </thead>
          <tbody>
            {this.renderJoinRequests()}
            {this.renderUsers()}
            {this.renderInvitations()}
          </tbody>
        </Table>
      </div>
    );
  },

  renderUsers() {
    return this.state.users
      .map((user) => {
        const isCurrentAdmin = this.state.currentAdmin.get('id') === user.get('id');
        const noActions =
          this.state.hasSamlRoles || (!isCurrentAdmin && !canManageUsers(this.state.sapiToken));

        const approver =
          user.get('approver') &&
          user.get('id') !== user.getIn(['approver', 'id']) &&
          this.renderInvitedOrApprover(user.get('approver'), 'approver');

        const invitor =
          user.get('invitor') &&
          user.get('id') !== user.getIn(['invitor', 'id']) &&
          this.renderInvitedOrApprover(user.get('invitor', 'invitor'));

        return (
          <tr key={user.get('id')} className="hoverable-actions-with-replacement">
            <td>{this.renderUser(user)}</td>
            <td>
              <Badge text={string.splitAndCapitalize(user.get('role'))} />
            </td>
            <td>{this.renderMfa(user)}</td>
            <td>{this.renderJoined(user)}</td>
            <td>{this.renderExpires(user)}</td>
            {noActions ? (
              <td>
                <div className="flex-container flex-column align-bottom">
                  {approver}
                  {invitor}
                  {this.renderReason(user)}
                </div>
              </td>
            ) : (
              <td>
                <div className="actions-container">
                  <div className="not-actions">
                    <div className="flex-container flex-column align-bottom">
                      {approver}
                      {invitor}
                      {this.renderReason(user)}
                    </div>
                  </div>
                  <div className="actions">
                    <RowActionDropdown showLoading={this.state.deleting.has(user.get('id'))}>
                      <ConfirmMenuItem
                        buttonType="danger"
                        icon="trash"
                        title="Remove User from Project"
                        text={
                          <>
                            Are you sure you want to remove <strong>{user.get('email')}</strong>{' '}
                            from this project?
                          </>
                        }
                        buttonLabel={isCurrentAdmin ? 'Leave project' : 'Remove from project'}
                        isDisabled={this.state.deleting.has(user.get('id'))}
                        onConfirm={() => this.handleRemoveUser(user.get('id'))}
                      >
                        <FontAwesomeIcon icon="trash" fixedWidth />
                        {isCurrentAdmin ? 'Leave project' : 'Remove from project'}
                      </ConfirmMenuItem>
                    </RowActionDropdown>
                  </div>
                </div>
              </td>
            )}
          </tr>
        );
      })
      .toArray();
  },

  renderInvitations() {
    return this.state.invitations
      .map((invite) => {
        return (
          <tr key={invite.get('id')} className="hoverable-actions-with-replacement">
            <td>{this.renderUser(invite.get('user'))}</td>
            <td />
            <td />
            <td>Invited...</td>
            <td>{this.renderExpires(invite)}</td>
            {!canManageUsers(this.state.sapiToken) || this.state.hasSamlRoles ? (
              <td>
                <div className="flex-container flex-column align-bottom">
                  {this.renderInvitedOrApprover(invite.get('creator'))}
                  {this.renderReason(invite)}
                </div>
              </td>
            ) : (
              <td>
                <div className="actions-container">
                  <div className="not-actions">
                    <div className="flex-container flex-column align-bottom">
                      {this.renderInvitedOrApprover(invite.get('creator'))}
                      {this.renderReason(invite)}
                    </div>
                  </div>
                  <div className="actions">
                    <RowActionDropdown showLoading={this.state.canceling.has(invite.get('id'))}>
                      <ConfirmMenuItem
                        buttonType="danger"
                        icon="xmark"
                        title="Cancel User Invitation"
                        text={
                          <>
                            Are you sure you want to cancel the invitation to this project for the
                            user <strong>{invite.getIn(['user', 'email'])}</strong>?
                          </>
                        }
                        buttonLabel="Cancel Invitation"
                        isDisabled={this.state.canceling.has(invite.get('id'))}
                        onConfirm={() => this.handleCancelInvitation(invite.get('id'))}
                      >
                        <FontAwesomeIcon icon="xmark" fixedWidth />
                        Cancel Invitation
                      </ConfirmMenuItem>
                    </RowActionDropdown>
                  </div>
                </div>
              </td>
            )}
          </tr>
        );
      })
      .toArray();
  },

  renderJoinRequests() {
    return this.state.joinRequests
      .map((joinRequest) => {
        return (
          <tr key={joinRequest.get('id')} className="hoverable-actions-with-replacement">
            <td>{this.renderUser(joinRequest.get('user'))}</td>
            <td />
            <td />
            <td>Pending...</td>
            <td>{this.renderExpires(joinRequest)}</td>
            {!canManageUsers(this.state.sapiToken) || this.state.hasSamlRoles ? (
              <td>{this.renderReason(joinRequest)}</td>
            ) : (
              <td>
                <div className="actions-container">
                  <div className="not-actions">{this.renderReason(joinRequest)}</div>
                  <div className="actions">
                    <RowActionDropdown
                      showLoading={
                        this.state.approving.has(joinRequest.get('id')) ||
                        this.state.rejecting.has(joinRequest.get('id'))
                      }
                    >
                      <ConfirmMenuItem
                        buttonType="success"
                        icon="circle-check"
                        title="Approve Access for User"
                        text={
                          <>
                            Are you sure you want to approve access to this project for{' '}
                            <strong>{joinRequest.getIn(['user', 'email'])}</strong>?
                          </>
                        }
                        buttonLabel="Approve User"
                        isDisabled={this.state.approving.has(joinRequest.get('id'))}
                        onConfirm={() => this.handleApproveUser(joinRequest.get('id'))}
                      >
                        <FontAwesomeIcon icon="circle-check" fixedWidth />
                        Approve User
                      </ConfirmMenuItem>
                      <ConfirmMenuItem
                        buttonType="danger"
                        icon="xmark"
                        title="Reject Access Request"
                        text={
                          <>
                            Are you sure you want to reject access to this project for{' '}
                            <strong>{joinRequest.getIn(['user', 'email'])}</strong>?
                          </>
                        }
                        buttonLabel="Reject User"
                        isDisabled={this.state.rejecting.has(joinRequest.get('id'))}
                        onConfirm={() => this.handleRejectUser(joinRequest.get('id'))}
                      >
                        <FontAwesomeIcon icon="xmark" fixedWidth />
                        Reject User
                      </ConfirmMenuItem>
                    </RowActionDropdown>
                  </div>
                </div>
              </td>
            )}
          </tr>
        );
      })
      .toArray();
  },

  renderUser(user) {
    return (
      <div className="flex-container flex-start">
        <Gravatar user={user} className="icon-addon-right" />
        <div className="tw-flex tw-flex-col">
          <strong>
            <Truncated text={user.get('name')} />
          </strong>
          <Truncated text={user.get('email')} />
        </div>
      </div>
    );
  },

  renderMfa(user) {
    return user.get('mfaEnabled', false) ? 'Enabled' : 'Not enabled';
  },

  renderJoined(user) {
    return <CreatedDate createdTime={user.get('created')} />;
  },

  renderExpires(user) {
    return <CreatedDate createdTime={user.get('expires')} fallback="Never" />;
  },

  renderInvitedOrApprover(user) {
    return (
      <div className="tw-flex tw-flex-col tw-items-end">
        <Truncated text={user.get('name')} />
        <Truncated text={user.get('email')} />
      </div>
    );
  },

  renderReason(user) {
    if (!user.get('reason')) {
      return null;
    }

    return <div className="text-muted overflow-break-anywhere">{user.get('reason')}</div>;
  },

  handleCancelInvitation(invitationId) {
    this.setState({ canceling: this.state.canceling.set(invitationId, true) });
    cancelInvitation(invitationId).finally(() =>
      this.setState({ canceling: this.state.canceling.delete(invitationId) }),
    );
  },

  handleApproveUser(joinRequestId) {
    this.setState({ approving: this.state.approving.set(joinRequestId, true) });
    approveUser(joinRequestId).finally(() =>
      this.setState({ approving: this.state.approving.delete(joinRequestId) }),
    );
  },

  handleRejectUser(joinRequestId) {
    this.setState({ rejecting: this.state.rejecting.set(joinRequestId, true) });
    rejectUser(joinRequestId).finally(() =>
      this.setState({ rejecting: this.state.rejecting.delete(joinRequestId) }),
    );
  },

  handleRemoveUser(userId) {
    this.setState({ deleting: this.state.deleting.set(userId, true) });
    removeUser(userId, this.state.currentAdmin.get('id') === userId).finally(() =>
      this.setState({ deleting: this.state.deleting.delete(userId) }),
    );
  },
});

export default Users;
