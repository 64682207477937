import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import SandboxesActions from '@/modules/sandboxes/Actions';
import { CONTAINER_BASED } from '@/modules/sandboxes/Constants';
import Confirm from '@/react/common/Confirm';
import Loader from '@/react/common/Loader';
import LoadOptions from './LoadOptions';

type Props = {
  configId: string;
  sandbox: Map<string, any>;
  configData: Map<string, any>;
  pendingActions: Map<string, any>;
  readOnly: boolean;
};

const LoadDataButton = ({ configId, sandbox, configData, pendingActions, readOnly }: Props) => {
  const [preserve, setPreserve] = useState(false);

  const isLoadingData = pendingActions.hasIn(['load-data', sandbox.get('id')]);

  const handleLoadData = () => {
    return SandboxesActions.loadData(
      sandbox.get('id'),
      configId,
      configData.getIn(['storage', 'input'], Map()),
      {
        preserve: CONTAINER_BASED.includes(sandbox.get('type')) || preserve,
      },
    );
  };

  return (
    <Confirm
      icon="database"
      buttonType="success"
      title="Load Data to Workspace"
      text={
        CONTAINER_BASED.includes(sandbox.get('type')) ? (
          <p>
            You are about to load data based on the input mapping. The existing tables will be
            overwritten.
          </p>
        ) : (
          <>
            <p>You are about to load data based on the input mapping.</p>
            <LoadOptions preserve={preserve} onChange={(newValue) => setPreserve(newValue)} />
          </>
        )
      }
      buttonLabel="Load Data"
      onConfirm={handleLoadData}
      isDisabled={
        isLoadingData ||
        !sandbox.get('active') ||
        configData.getIn(['storage', 'input'], Map()).count() === 0 ||
        readOnly
      }
      disabledReason={
        readOnly
          ? ''
          : !sandbox.get('active')
            ? 'Workspace is inactive'
            : isLoadingData
              ? ''
              : 'No input mapping defined'
      }
      childrenRootElement="a"
      childrenRootElementClass="tw-flex"
    >
      {isLoadingData ? <Loader /> : <FontAwesomeIcon icon="database" fixedWidth />}
      Load data
    </Confirm>
  );
};

export default LoadDataButton;
