import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames } from '@/modules/templates/constants';
import DocumentationButton from '@/modules/templates/DocumentationButton';
import TemplatesStore from '@/modules/templates/store';
import RouterLink from '@/react/common/RouterLink';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const CloseTemplatesDirectoryHeader = () => {
  const hasInstances = useStores(
    () => !!TemplatesStore.getStore().instances?.length,
    [],
    [TemplatesStore],
  );
  const flowOrigin = RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']);

  return (
    <ButtonToolbar>
      <DocumentationButton />
      {hasInstances && !flowOrigin && (
        <Tooltip tooltip="Back to templates" placement="top">
          <RouterLink onlyActiveOnIndex to={routeNames.ROOT} className="btn btn-default">
            <FontAwesomeIcon icon="xmark" />
          </RouterLink>
        </Tooltip>
      )}
      {!!flowOrigin && (
        <Tooltip tooltip="Back to flows" placement="top">
          <RouterLink
            onlyActiveOnIndex
            className="btn btn-default"
            {...(flowOrigin === 'new'
              ? { to: flowsRouteNames.ROOT }
              : { to: flowsRouteNames.DETAIL, params: { config: flowOrigin } })}
          >
            <FontAwesomeIcon icon="xmark" />
          </RouterLink>
        </Tooltip>
      )}
    </ButtonToolbar>
  );
};

export default CloseTemplatesDirectoryHeader;
