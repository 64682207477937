import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';

import PasswordControl from '@/react/common/PasswordControl';

class Credentials extends React.Component {
  render() {
    const { onChange, value } = this.props;
    return (
      <div className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Host</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={value.host}
              onChange={(e) => onChange({ host: e.target.value })}
              placeholder="example.snowflakecomputing.com"
              disabled={this.props.disabled}
            />
            <HelpBlock>Snowflake instance hostname</HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>User</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={value.user}
              onChange={(e) => onChange({ user: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Password</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              value={value.password}
              onChange={(e) => onChange({ password: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Database</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={value.database}
              onChange={(e) => onChange({ database: e.target.value })}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              You need to have privileges to create new schemas in this database
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Warehouse</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={value.warehouse}
              onChange={(e) => onChange({ warehouse: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>
        </FormGroup>
      </div>
    );
  }
}

Credentials.propTypes = {
  value: PropTypes.shape({
    host: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    database: PropTypes.string.isRequired,
    warehouse: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Credentials;
