import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

const ChangeOrderHandle = ({ isPending, disabled }) => {
  if (disabled || isPending) {
    return (
      <Tooltip
        placement="top"
        type={isPending ? 'action' : 'explanatory'}
        tooltip={
          isPending
            ? 'Ordering in progress'
            : 'Clear search query and reset sort to allow changing order.'
        }
      >
        <FontAwesomeIcon className="not-allowed" icon="bars" />
      </Tooltip>
    );
  }

  return <FontAwesomeIcon className="dragable drag-handle" icon="bars" />;
};

ChangeOrderHandle.propTypes = {
  isPending: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ChangeOrderHandle;
