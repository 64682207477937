import React from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import lineage from '../../../images/lineage.svg';

import BetaTitle from '@/modules/lineage/BetaTitle';
import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import RoutesStore from '@/stores/RoutesStore';

const BUTTON_DEFAULTS = {
  bsStyle: 'link',
  className: 'btn-link-inline flex-container inline-flex flex-start no-wrap',
};

const ShowLineage = ({
  allComponents,
  startingPointFqid,
  isLocked,
  isDisabled,
  projectName,
  projectId,
  configurationName,
}: {
  allComponents: Map<string, any>;
  startingPointFqid: string;
  isLocked: boolean;
  isDisabled: boolean;
  projectName: string;
  projectId: string;
  configurationName: string;
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const label = (
    <>
      <img src={lineage} alt="lineage" className="btn-icon text-muted tw-w-6" />
      Data Lineage
    </>
  );

  return (
    <>
      {isDisabled ? (
        <Tooltip
          placement="top"
          type="explanatory"
          tooltip="We currently do not support Data lineage for this type of configuration."
        >
          <BetaTitle>
            <Button disabled {...BUTTON_DEFAULTS}>
              {label}
            </Button>
          </BetaTitle>
        </Tooltip>
      ) : (
        <BetaTitle>
          <Button
            {...BUTTON_DEFAULTS}
            onClick={(event: React.SyntheticEvent) => {
              event.stopPropagation();

              setShowModal(true);
            }}
          >
            {label}
          </Button>
        </BetaTitle>
      )}
      {showModal && (
        <TablesLineage
          isLocked={isLocked}
          allComponents={allComponents}
          startingPointFqid={startingPointFqid}
          onCloseModal={() => {
            setShowModal(false);
            RoutesStore.getRouter().updateQuery({ node: null });
          }}
          projectName={projectName}
          projectId={projectId}
          nodeName={configurationName}
        />
      )}
    </>
  );
};

export default ShowLineage;
