import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';

import { isValidUrl } from '@/utils/validation';

const prepareUrlPreview = (baseUrl: string, path: string) => {
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  if (path.startsWith('/') && isValidUrl(baseUrl)) {
    return `${new URL(baseUrl).origin}${path}`;
  }

  return `${baseUrl}${path}`;
};

const UrlPreview = (props: { baseUrl: string; path: string }) => {
  return (
    <FormGroup>
      <ControlLabel>URL Preview</ControlLabel>
      <FormControl
        disabled
        type="text"
        value={prepareUrlPreview(props.baseUrl, props.path)}
        className="!tw-bg-neutral-150"
      />
      {!isValidUrl(props.baseUrl) && (
        <HelpBlock variant="danger">Base url is not valid URL.</HelpBlock>
      )}
    </FormGroup>
  );
};

export default UrlPreview;
