import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Alert, HelpBlock, Tooltip } from '@keboola/design';
import classNames from 'classnames';
import { List, Map } from 'immutable';

import DataCatalogActions from '@/modules/data-catalog/actions';
import { NO_PERMISSION_TO_SHARE_TOOLTIP, SHARED_TYPES } from '@/modules/data-catalog/constants';
import { getSharedBucketDescription } from '@/modules/data-catalog/helpers';
import ShareWithSelect from '@/modules/data-catalog/react/ShareWithSelect';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const FORM_STATE = {
  error: null,
  sharedDescription: '',
  sharedType: SHARED_TYPES.ORGANIZATION_MEMBER,
  targetUsers: List(),
  targetProjects: List(),
};

class ShareBucketSimple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isSharing: false,
      ...FORM_STATE,
    };

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Tooltip
          tooltip={NO_PERMISSION_TO_SHARE_TOOLTIP}
          placement="top"
          type="explanatory"
          forceHide={this.props.canShare}
        >
          <Button
            bsStyle="link"
            className={classNames('btn-link-inline partly-underlined', {
              disabled: !this.props.canShare,
            })}
            onClick={() => this.props.canShare && this.setState({ showModal: true })}
          >
            <span className="underline">Disabled</span>{' '}
            <span className="text-muted no-underline">(click to enable)</span>
          </Button>
        </Tooltip>
        {this.renderModal()}
      </>
    );
  }

  renderModal() {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.onHide}
        onEnter={() =>
          this.setState({
            ...FORM_STATE,
            sharedDescription: getSharedBucketDescription(this.props.bucket) || '',
          })
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Share Bucket {this.props.bucket.get('displayName')}</Modal.Title>
            <ModalIcon icon="folder" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <FormGroup controlId="formDescriptionConrol">
              <ControlLabel>Description</ControlLabel>
              <FormControl
                rows={3}
                autoFocus
                componentClass="textarea"
                value={this.state.sharedDescription}
                onChange={(e) => this.setState({ sharedDescription: e.target.value })}
              />
              <HelpBlock>New description for the shared bucket</HelpBlock>
            </FormGroup>
            <ShareWithSelect
              value={this.state.sharedType}
              onChange={(value) => this.setState({ sharedType: value })}
              hasUsersOptions={this.props.availableUsersOptions.length > 0}
              hasProjectsOptions={this.props.availableProjectsOptions.length > 0}
            />
            {this.renderAdditionalControls()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.state.isSharing ? 'Sharing bucket...' : 'Share a bucket'}
              saveButtonType="submit"
              isSaving={this.state.isSharing}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  renderAdditionalControls() {
    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        <Select
          multi
          placeholder="Select users"
          options={this.props.availableUsersOptions}
          value={this.state.targetUsers}
          onChange={(targetUsers) => this.setState({ targetUsers })}
        />
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        <Select
          multi
          placeholder="Select projects"
          options={this.props.availableProjectsOptions}
          value={this.state.targetProjects}
          onChange={(targetProjects) => this.setState({ targetProjects })}
        />
      );
    }

    return null;
  }

  handleSubmit(e) {
    e.preventDefault();

    const params = {
      targetProjectIds: this.state.targetProjects.toJS(),
      targetUsers: this.state.targetUsers.toJS(),
    };

    this.setState({ isSharing: true });
    DataCatalogActions.shareBucketSimple(
      this.props.bucket.get('id'),
      this.state.sharedType,
      params,
      this.state.sharedDescription,
    )
      .then(this.onHide)
      .catch((error) => {
        this.setState({ error, isSharing: false });
        return null;
      });
  }

  isDisabled() {
    if (this.state.isSharing) {
      return true;
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return !this.state.targetUsers.count();
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return !this.state.targetProjects.count();
    }

    return false;
  }

  onHide() {
    this.setState({ showModal: false });
  }
}

ShareBucketSimple.propTypes = {
  canShare: PropTypes.bool.isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
};

export default ShareBucketSimple;
