import React from 'react';
import { Button } from '@keboola/design';
import type { List } from 'immutable';

import {
  convertCreditsToMinutes,
  convertCreditsToPrice,
  getTopUpPurchasesFromCurrentMonth,
} from '@/modules/billing/helpers';
import CreatedDate from '@/react/common/CreatedDate';
import type { TopUpSetting } from './TopUpForm';

const TopUpInfo = (props: {
  setting: TopUpSetting;
  creditPrice: number;
  purchases: List<any>;
  onEdit: () => void;
}) => {
  const topUps = getTopUpPurchasesFromCurrentMonth(props.purchases);

  return (
    <>
      <div className="flex-container f-14 line-height-24">
        <div>
          <p className="text-muted mb-0">Automatically Recharge</p>
          <strong className="font-medium">
            {convertCreditsToMinutes(props.setting.amount)} minutes (
            {convertCreditsToPrice(props.setting.amount, props.creditPrice)})
          </strong>
        </div>
        <div>
          <p className="text-muted mb-0">Remaining Recharges</p>
          <strong className="font-medium">
            {Math.max(0, props.setting.limit - topUps.count())} of {props.setting.limit}
          </strong>
        </div>
        <div>
          <p className="text-muted mb-0">Last Payment</p>
          <strong className="font-medium">
            {topUps.isEmpty() ? (
              'None'
            ) : (
              <CreatedDate createdTime={topUps.first().get('created')} />
            )}
          </strong>
        </div>
      </div>
      <Button variant="outline" className="tw-mt-3 tw-w-full" onClick={props.onEdit}>
        Edit top-up
      </Button>
    </>
  );
};

export default TopUpInfo;
