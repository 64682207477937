import React, { useEffect } from 'react';

import { loadAllAndInitialize } from '@/modules/dev-branches/actions';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareProductionHref } from '@/modules/dev-branches/helpers';
import SimpleMergeHeaderButton from '@/modules/dev-branches/SimpleMergeHeaderButton';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Merge = (props: { flowConfigId?: string }) => {
  useEffect(() => {
    loadAllAndInitialize();
  }, []);

  if (!props.flowConfigId) return null;

  return (
    <SimpleMergeHeaderButton
      skipConfirmation
      keepBranch
      onMerge={() => {
        window.location.href = prepareProductionHref(
          ApplicationStore.getProjectBaseUrl(),
          RoutesStore.getRouter().createHref(flowsRouteNames.DETAIL, {
            config: props.flowConfigId,
          }),
          DevBranchesStore.getCurrentId(),
        );
      }}
      className="tw-ml-7"
    />
  );
};

export default Merge;
