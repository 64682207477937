import React from 'react';
import _ from 'underscore';

import TextDivider from '@/react/common/TextDivider';

type Props = {
  onSelect: (schedule: string) => void;
};

const SchedulePredefinedButtons = (props: Props) => {
  return (
    <div className="schedule-predefined mb-1">
      <p className="text-muted">Select Predefined</p>
      <div className="flex-container flex-start mb-1">
        <button className="btn predefined-button" onClick={() => props.onSelect('*/15 * * * *')}>
          Every 15 minutes
        </button>
        <button
          className="btn predefined-button"
          onClick={() => props.onSelect(`${_.random(0, 59)} * * * *`)}
        >
          Every hour
        </button>
        <button
          className="btn predefined-button"
          onClick={() =>
            props.onSelect(`${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * *`)
          }
        >
          Once a day
        </button>
        <button
          className="btn predefined-button"
          onClick={() =>
            props.onSelect(
              `${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * ${_.random(0, 6)}`,
            )
          }
        >
          Once a week
        </button>
      </div>
      <TextDivider className="tw-mb-3" />
      <p className="text-muted">Set Your Own</p>
    </div>
  );
};

export default SchedulePredefinedButtons;
