import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Tabs, TabsContent } from '@keboola/design';
import createReactClass from 'create-react-class';

import dayjs from '@/date';
import { DEFAULT_DATE_RANGE, LAST_RUN_DATE } from '@/modules/ex-google-analytics-v4/constants';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Select from '@/react/common/Select';

const DATE_FORMAT = 'YYYY-MM-DD';
const SUGGESTIONS = {
  Today: {
    start: 'today',
    end: 'tomorrow -1 second',
  },
  'Last 7 days': {
    start: '-7 days',
    end: 'today',
  },
  'Last 2 Months': {
    start: '-2 months',
    end: 'today',
  },
  'Last 2 Weeks': {
    start: '-2 weeks',
    end: 'today',
  },
  'Last Month': {
    start: 'midnight first day of last month',
    end: 'midnight last day of last month',
  },
  'This Month': {
    start: 'midnight first day of this month',
    end: 'midnight last day of this month',
  },
  'Last Week': {
    start: 'monday last week',
    end: 'sunday last week',
  },
  'This Week': {
    start: 'monday this week',
    end: 'today',
  },
};

const DateRangeModal = createReactClass({
  propTypes: {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    allSavedRanges: PropTypes.object.isRequired,
    onSet: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    supportsIncrementalFetch: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return this.getStateFromProps(this.props);
  },

  getStateFromProps(props) {
    const rangeType = this.getDatePropsType(props);

    return {
      rangeType: rangeType,
      absoluteStart: rangeType === 'absolute' ? props.startDate : '',
      absoluteEnd: rangeType === 'absolute' ? props.endDate : '',
      relativeStart: rangeType === 'relative' ? props.startDate : '',
      relativeEnd: rangeType === 'relative' ? props.endDate : '',
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(this.getStateFromProps(nextProps));
    }
  },

  getDatePropsType(props) {
    const startValid = dayjs(props.startDate).isValid();
    const endValid = dayjs(props.endDate).isValid();
    return startValid && endValid ? 'absolute' : 'relative';
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCancel}>
        <Modal.Header closeButton className="tw-border-0 !tw-pb-0">
          <Modal.Title>Change Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            inModal
            value={this.state.rangeType}
            onValueChange={this.onSelectEndpoint}
            triggers={[
              {
                value: 'relative',
                title: 'Relative date range',
              },
              {
                value: 'absolute',
                title: 'Absolute date range',
              },
            ]}
          >
            <TabsContent value="relative">{this.renderRelative()}</TabsContent>
            <TabsContent value="absolute">{this.renderAbsolute()}</TabsContent>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Change"
            isDisabled={!this.isValid()}
            onSave={this.setAndClose}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  renderAbsolute() {
    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Since</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="date"
              max={this.state.absoluteEnd || ''}
              placeholder={DATE_FORMAT}
              value={this.state.absoluteStart || ''}
              onChange={(e) => this.setState({ absoluteStart: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Until</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="date"
              min={this.state.absoluteStart || ''}
              placeholder={DATE_FORMAT}
              value={this.state.absoluteEnd || ''}
              onChange={(e) => this.setState({ absoluteEnd: e.target.value })}
            />
          </div>
        </FormGroup>
      </Form>
    );
  },

  selectSuggestion(value) {
    const { start, end } = SUGGESTIONS[value];
    this.setState({ relativeStart: start, relativeEnd: end });
  },

  renderRelative() {
    const isIncrementalFetchingActive = this.state.relativeStart === LAST_RUN_DATE;

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Suggestions</ControlLabel>
          </div>
          <div className="col-xs-9">
            <Select
              clearable={false}
              placeholder="Choose..."
              onChange={this.selectSuggestion}
              disabled={isIncrementalFetchingActive}
              options={Object.keys(SUGGESTIONS).map((value) => ({ value, label: value }))}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Since</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="text"
              onChange={(e) => this.setState({ relativeStart: e.target.value })}
              value={this.state.relativeStart === LAST_RUN_DATE ? '' : this.state.relativeStart}
              disabled={isIncrementalFetchingActive}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">{this.renderIncrementalFetchCheckbox()}</div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Until</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="text"
              onChange={(e) => this.setState({ relativeEnd: e.target.value })}
              value={this.state.relativeEnd}
            />
          </div>
        </FormGroup>
      </Form>
    );
  },

  renderIncrementalFetchCheckbox() {
    if (!this.props.supportsIncrementalFetch) {
      return null;
    }

    if (this.props.allSavedRanges.count() > 1) {
      return (
        <Checkbox disabled tooltip="Incremental load cannot be combined with other date ranges.">
          Incremental load
        </Checkbox>
      );
    }

    return (
      <Checkbox
        checked={this.state.relativeStart === LAST_RUN_DATE}
        onChange={(checked) => {
          this.setState({
            relativeStart: checked ? LAST_RUN_DATE : DEFAULT_DATE_RANGE.get('startDate'),
          });
        }}
      >
        Incremental load
      </Checkbox>
    );
  },

  onSelectEndpoint(selectedTab) {
    this.setState({ rangeType: selectedTab });
  },

  setAndClose() {
    const { rangeType, absoluteStart, absoluteEnd, relativeStart, relativeEnd } = this.state;
    const start = rangeType === 'relative' ? relativeStart : absoluteStart;
    const end = rangeType === 'relative' ? relativeEnd : absoluteEnd;
    this.props.onSet(start, end);
    this.props.onCancel();
  },

  isValid() {
    if (this.state.rangeType === 'absolute') {
      return dayjs(this.state.absoluteEnd).isAfter(dayjs(this.state.absoluteStart));
    }

    return this.state.relativeStart.trim() !== '' && this.state.relativeEnd.trim() !== '';
  },
});

export default DateRangeModal;
