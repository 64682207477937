import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import defaultCopyVersionName from '@/utils/defaultCopyVersionName';
import CopyVersionModal from './CopyVersionModal';
import Loader from './Loader';
import RowActionMenuItem from './RowActionMenuItem';

type Props = {
  configId: string;
  componentId: string;
  componentType: string;
  version: Map<string, any>;
  onCopy: (name: string, excludeRows: boolean) => Promise<void>;
  isPending?: boolean;
  isDisabled?: boolean;
  isLast?: boolean;
  isRow?: boolean;
  hasFlows?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  mode?: string;
};
const CopyVersionButton = ({
  configId,
  componentId,
  componentType,
  version,
  onCopy,
  isPending,
  isDisabled,
  isLast,
  isRow,
  hasFlows,
  onKeyDown,
  mode,
}: Props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [newVersionName, setNewVersionName] = React.useState('');

  const renderEntity = () => {
    if (componentId === KEBOOLA_ORCHESTRATOR) {
      return hasFlows ? 'flow' : 'orchestration';
    }

    if (componentId === KEBOOLA_DATA_APPS) {
      return 'data app';
    }

    if (componentType === componentTypes.TRANSFORMATION) {
      return 'transformation';
    }

    if (isRow) {
      return 'row';
    }

    return 'configuration';
  };

  const entity = renderEntity();

  const closeModal = () => {
    setShowModal(false);
    setNewVersionName('');
  };

  const openModal = () => {
    setShowModal(true);
    setNewVersionName(defaultCopyVersionName(version));
  };

  const handleCopy = (excludeRows: boolean) => {
    setIsSaving(true);
    onCopy(newVersionName.trim(), excludeRows)
      .then(closeModal)
      .finally(() => setIsSaving(false));
  };

  const renderButton = () => {
    const isActionDisabled = isDisabled || isPending;

    if (mode === 'menuitem') {
      return (
        <RowActionMenuItem onSelect={openModal} disabled={isActionDisabled} onKeyDown={onKeyDown}>
          {isPending ? <Loader /> : <FontAwesomeIcon fixedWidth icon="clone" />}
          Copy {entity}
        </RowActionMenuItem>
      );
    }

    if (mode === 'sidebar') {
      return (
        <Button
          block
          bsStyle="link"
          className="btn-link-inline"
          onClick={openModal}
          disabled={isActionDisabled}
        >
          {isPending ? <Loader /> : <FontAwesomeIcon icon="clone" fixedWidth />}
          Copy {entity}
        </Button>
      );
    }

    return (
      <Tooltip tooltip={`Copy to new ${entity}`} placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={openModal}
          disabled={isActionDisabled}
        >
          {isPending ? <Loader /> : <FontAwesomeIcon icon="clone" fixedWidth />}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {renderButton()}
      <CopyVersionModal
        entity={entity}
        isRow={isRow}
        configId={configId}
        componentId={componentId}
        componentType={componentType}
        version={version}
        show={showModal}
        onClose={closeModal}
        onCopy={handleCopy}
        onChangeName={(name) => setNewVersionName(name)}
        newVersionName={newVersionName}
        isLast={isLast}
        isSaving={isSaving}
      />
    </>
  );
};

export default CopyVersionButton;
