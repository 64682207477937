import { fromJS, List, Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import { ActionTypes } from '@/modules/components/Constants';
import {
  convertComponentsToByKeyStructure,
  renameOrchestratorToFlow,
} from '@/modules/components/helpers';
import {
  ActionTypes as MetadataActionTypes,
  MetadataKeys,
  ObjectTypes,
} from '@/modules/components/MetadataConstants';
import ApplicationStore from '@/stores/ApplicationStore';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes as devBranchesActionTypes } from './constants';

let _store = initStore(
  'DevBranchesStore',
  Map({
    all: Map(),
    mergeRequests: Map(),
    productionComponents: Map(),
    productionDeletedComponents: Map(),
    productionComponentsMetadata: Map(),
    selectedVariables: List(),
    currentId: null,
    defaultBranchId: null,
    isLoadingData: false,
    isLoaded: false,
    metadata: List(),
  }),
);

const DevBranchesStore = StoreUtils.createStore({
  getAll() {
    return _store.get('all', Map());
  },

  getAllMetadata() {
    return _store.get('metadata', List());
  },

  getProjectDescription(defaultValue = '') {
    return this.getAllMetadata()
      .find(
        (metadataObj) => metadataObj.get('key') === MetadataKeys.PROJECT_DESCRIPTION,
        null,
        Map(),
      )
      .get('value', defaultValue);
  },
  getProductionComponents() {
    return _store.get('productionComponents', Map());
  },

  getProductionDeletedComponents() {
    return _store.get('productionDeletedComponents', Map());
  },

  getProductionComponentsMetadata() {
    return _store.get('productionComponentsMetadata', Map());
  },

  getSelectedConfigurationChanges() {
    return _store.get('selectedConfigurationChanges', Map());
  },

  getSelectedVariables() {
    return _store.get('selectedVariables', List());
  },

  getCurrentId() {
    return _store.get('currentId');
  },

  getDefaultBranchId() {
    return _store.get('defaultBranchId');
  },

  getCurrentDevBranch() {
    return _store.getIn(['all', this.getCurrentId()], Map());
  },

  getIsLoadingData() {
    return _store.get('isLoadingData', false);
  },

  getIsLoaded() {
    return _store.get('isLoaded');
  },

  isDevModeActive() {
    return this.getCurrentId() !== null;
  },

  getMergeRequests() {
    return _store.get('mergeRequests', Map());
  },

  getCurrentDevBranchMergeRequest() {
    return _store.getIn(['mergeRequests', this.getCurrentId()], Map());
  },
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case ActionTypes.STORAGE_DEV_BRANCHES_LOAD_SUCCESS: {
      const devBranches = fromJS(action.devBranches)
        .toMap()
        .mapKeys((key, branch) => branch.get('id'));
      _store = _store
        .set('all', devBranches)
        .set('defaultBranchId', devBranches.find((branch) => branch.get('isDefault')).get('id'))
        .set('isLoaded', true);
      return DevBranchesStore.emitChange();
    }

    case ActionTypes.STORAGE_DEV_BRANCH_UPDATE_SUCCESS:
      _store = _store.setIn(['all', action.devBranchId], fromJS(action.devBranch));
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.SET_CURRENT_DEV_BRANCH_ID:
      _store = _store.set('currentId', action.branchId);
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_DATA_START:
      _store = _store.set('isLoadingData', true);
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_DATA_FINISH:
      _store = _store.set('isLoadingData', false);
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_MERGE_REQUESTS_SUCCESS:
      _store = _store.set(
        'mergeRequests',
        fromJS(action.mergeRequests)
          .toMap()
          .mapKeys((key, mergeRequest) => mergeRequest.getIn(['branches', 'branchFromId'])),
      );
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_PRODUCTION_COMPONENTS_SUCCESS:
      _store = _store.set(
        'productionComponents',
        renameOrchestratorToFlow(
          convertComponentsToByKeyStructure(action.components),
          ApplicationStore.hasFlows(),
        ),
      );
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_PRODUCTION_DELETED_COMPONENTS_SUCCESS:
      _store = _store.set(
        'productionDeletedComponents',
        convertComponentsToByKeyStructure(action.components),
      );
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.LOAD_PRODUCTION_COMPONENTS_METADATA_SUCCESS:
      _store = _store.set(
        'productionComponentsMetadata',
        Map().withMutations((metadata) => {
          action.result.forEach((item) => {
            metadata.setIn([item.idComponent, item.configurationId], fromJS(item.metadata));
          });
        }),
      );

      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.INITIALIZE_SELECTED_CHANGES:
      _store = _store.set(
        'selectedConfigurationChanges',
        action.updatedComponents.map((component) =>
          component.get('configurations').keySeq().toList(),
        ),
      );

      if (action.createdVariables) {
        _store = _store.set(
          'selectedVariables',
          List(action.createdVariables.map((variable) => variable.hash)),
        );
      }
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.RESET_SELECTED_CHANGES:
      _store = _store.set('selectedConfigurationChanges', Map()).set('selectedVariables', List());
      return DevBranchesStore.emitChange();

    case devBranchesActionTypes.TOGGLE_ALL_CONFIGURATIONS: {
      _store = _store.set('selectedConfigurationChanges', action.components);
      return DevBranchesStore.emitChange();
    }

    case devBranchesActionTypes.TOGGLE_CONFIGURATION_CHANGE: {
      const indexOfConfiguration = _store
        .getIn(['selectedConfigurationChanges', action.componentId], List())
        .indexOf(action.configurationId);

      _store = _store
        .updateIn(
          ['selectedConfigurationChanges', action.componentId],
          List(),
          (configurations) => {
            if (indexOfConfiguration >= 0 && action.select === false) {
              return configurations.delete(indexOfConfiguration);
            }
            if (indexOfConfiguration < 0 && action.select === true) {
              return configurations.push(action.configurationId);
            }

            return configurations;
          },
        )
        .update('selectedConfigurationChanges', (components) =>
          components.filter((component) => !component.isEmpty()),
        );

      return DevBranchesStore.emitChange();
    }

    case devBranchesActionTypes.TOGGLE_VARIABLE_CHANGE:
      _store = _store.update('selectedVariables', List(), (variables) => {
        return action.checked
          ? variables.push(action.variable.hash)
          : variables.delete(variables.indexOf(action.variable.hash));
      });
      return DevBranchesStore.emitChange();

    case MetadataActionTypes.BRANCH_METADATA_LOAD_SUCCESS:
      _store = _store.set('metadata', fromJS(action.metadata));
      return DevBranchesStore.emitChange();

    case MetadataActionTypes.METADATA_SAVE_SUCCESS:
      if (action.objectType === ObjectTypes.BRANCH) {
        _store = _store.set('metadata', fromJS(action.metadata));
      }
      return DevBranchesStore.emitChange();

    case MetadataActionTypes.METADATA_DELETE_SUCCESS:
      if (action.objectType === ObjectTypes.BRANCH) {
        _store = _store.update('metadata', List(), (metadata) => {
          return metadata.filter((row) => row.get('id') !== action.metadataId);
        });
        return DevBranchesStore.emitChange();
      }
      break;

    default:
  }
});

export default DevBranchesStore;
