/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classnames from 'classnames';
import { List, Map } from 'immutable';

import { canManageBucket, canWriteBucket } from '@/modules/admin/privileges';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { routeNames, sortEntities, storageInitialLimit } from '@/modules/storage/constants';
import {
  getFilteredData,
  isBucket,
  isTable,
  isTableShared,
  sortByDisplayName,
  sortByExactMatch,
} from '@/modules/storage/helpers';
import { useSorter } from '@/modules/storage/hooks';
import BlockButton from '@/react/common/BlockButton';
import BucketLabels from '@/react/common/BucketLabels';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import CreatedDate from '@/react/common/CreatedDate';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import MarkedText from '@/react/common/MarkedText';
import Link from '@/react/common/RouterLink';
import RoutesStore from '@/stores/RoutesStore';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import {
  BucketActions,
  CellSelection,
  HeaderActions,
  HeaderSelection,
  LastChangeColumn,
  TableActions,
} from './IndexTable';
import NativeTypesLabel from './NativeTypesLabel';

const Table = ({ columns, data }) => {
  const [showAll, setShowAll] = React.useState(false);

  const tableInstance = useReactTable({
    columns,
    data,
    getRowId: (row) => row.item.get('id'),
    getCoreRowModel: getCoreRowModel(),
  });

  let rows = tableInstance.getRowModel().rows;

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div className="table-hover react-table table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="tr with-action-buttons is-sticky bg-color-white">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.column.id}
                    className={classnames('th', {
                      'w-52': header.column.id === 'selection',
                      'w-200 text-right': header.column.id === 'last_modified',
                    })}
                    {...(header.column.id === 'selection' && {
                      onClick: onClickSelectionCell,
                    })}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row) => {
            const item = row.original.item;
            const rowAction = () =>
              item.has('bucketTables')
                ? RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                    bucketId: item.get('id'),
                  })
                : RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                    bucketId: item.getIn(['bucket', 'id']),
                    tableName: item.get('name'),
                  });

            return (
              <div
                key={row.id}
                tabIndex={0}
                role="button"
                className="tr clickable hoverable-actions-with-replacement"
                onClick={rowAction}
                onKeyDown={onTableRowKeyDown(rowAction)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <div
                      key={cell.id}
                      className={classnames('td', {
                        'bg-selected': row.getIsSelected(),
                        'text-right': cell.column.id === 'last_modified',
                        'dev-bucket': isCreatedInDevBranch(
                          item.has('stage') ? item : item.get('bucket'),
                        ),
                      })}
                      {...(cell.column.id === 'selection' && {
                        onClick: onClickSelectionCell,
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.getRowModel().rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => setShowAll(true)} />
      )}
    </>
  );
};

const IndexTableResults = ({
  sapiToken,
  buckets,
  query,
  searchFilters,
  bucketsSort,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openCreateWorkpaceModal,
  openSnapshotModal,
  deletingTables,
  exportingTables,
  createSnapshotsTables,
  deletingBuckets,
  canExportTable,
  hasSnowflakePartnerConnectLimited,
}) => {
  const { sort, setSort, sorter } = useSorter(bucketsSort);

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        header: ({ table }) => (
          <span className="tw-relative tw-top-0.5">
            <HeaderSelection sapiToken={sapiToken} table={table} />
          </span>
        ),
        cell: ({ row }) => (
          <span className="tw-relative tw-top-0.5">
            <CellSelection sapiToken={sapiToken} row={row} />
          </span>
        ),
      },
      {
        header: ({ table }) => {
          return (
            <HeaderActions
              allBuckets={buckets}
              openDeleteModal={openDeleteModal}
              openCreateWorkpaceModal={openCreateWorkpaceModal}
              canExportTable={canExportTable}
              openExportTablesModal={openExportTablesModal}
              openSnapshotModal={openSnapshotModal}
              table={table}
              sort={sort}
              setSort={setSort}
              hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
              isCreatingSnapshot={!createSnapshotsTables.isEmpty()}
              isExportingTables={!exportingTables.isEmpty()}
            />
          );
        },
        cell: ({ row }) => {
          const item = row.original.item;

          if (isBucket(item)) {
            const bucketToExport = buckets.get(item.get('id'));

            return (
              <span className="flex-container flex-start overflow-break-anywhere">
                <FontAwesomeIcon
                  fixedWidth
                  icon={
                    bucketToExport.get('bucketTables', List()).isEmpty()
                      ? ['far', 'folder']
                      : 'folder'
                  }
                  className="text-muted"
                />
                <Link
                  to={routeNames.BUCKET}
                  params={{ bucketId: item.get('id') }}
                  className="link-inherit"
                >
                  <MarkedText mark={query} source={item.get('displayName')} />
                </Link>
                <BucketLabels bucket={item} />
              </span>
            );
          }

          return (
            <span className="flex-container flex-start overflow-break-anywhere">
              <FontAwesomeIcon fixedWidth icon="table" className="text-muted" />
              <Link
                to={routeNames.TABLE}
                params={{
                  bucketId: item.getIn(['bucket', 'id']),
                  tableName: item.get('name'),
                }}
                className={classnames('link-inherit', {
                  'dotted-underline': item.get('isAlias', false),
                })}
              >
                {item.getIn(['bucket', 'displayName'])}
                <BucketStageLabel round placement="right" stage={item.getIn(['bucket', 'stage'])} />
                <FontAwesomeIcon
                  icon={['far', 'angle-right']}
                  className="text-muted icon-addon-left icon-addon-right"
                />
                <MarkedText mark={query} source={item.get('displayName')} />
              </Link>
              <NativeTypesLabel isTyped={item.get('isTyped', false)} />
              <ExternalTableLabel
                tableType={item.get('tableType')}
                hasExternalSchema={item.getIn(['bucket', 'hasExternalSchema'])}
              />
              {isTable(item) && isTableShared(item) && (
                <BucketLabels
                  bucket={item.get('bucket')}
                  withStageLabel={false}
                  withExternalLabel={false}
                  withLinkedLabel={false}
                />
              )}
            </span>
          );
        },
        accessorKey: 'item',
      },
      {
        id: 'last_modified',
        header: () => <LastChangeColumn sort={sort} setSort={setSort} />,
        cell: ({ row }) => {
          if (row.original.item.has('stage')) {
            const bucket = row.original.item;
            const isDeleting = deletingBuckets.get(bucket.get('id'), false);
            const currentBucket = buckets.get(bucket.get('id'));
            const isExportingBucket =
              !currentBucket.get('bucketTables').isEmpty() &&
              currentBucket
                .get('bucketTables')
                .every((table) => exportingTables.get(table.get('id'), false));
            const isCreatingSnapshotBucket =
              !currentBucket.get('bucketTables').isEmpty() &&
              currentBucket
                .get('bucketTables')
                .every((table) => createSnapshotsTables.get(table.get('id'), false));

            return (
              <div
                className={classnames('actions-container', {
                  'force-actions': isDeleting || isExportingBucket || isCreatingSnapshotBucket,
                })}
              >
                <div className="not-actions">
                  <CreatedDate
                    createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                  />
                </div>
                <div className="actions">
                  <BucketActions
                    sapiToken={sapiToken}
                    bucket={currentBucket}
                    isDeleting={isDeleting}
                    isExporting={isExportingBucket}
                    isCreatingSnapshot={isCreatingSnapshotBucket}
                    openModal={openDeleteBucketModal}
                    canExportTable={canExportTable}
                    openExportTablesModal={openExportTablesModal}
                    openSnapshotModal={openSnapshotModal}
                    canManageBucket={canManageBucket(sapiToken, bucket)}
                  />
                </div>
              </div>
            );
          }

          const table = row.original.item;
          const isDeleting = deletingTables.get(table.get('id'), false);
          const isExporting = exportingTables.get(table.get('id'), false);
          const isCreatingSnapshot = createSnapshotsTables.get(table.get('id'), false);

          return (
            <div
              className={classnames('actions-container', {
                'force-actions': isDeleting || isExporting || isCreatingSnapshot,
              })}
            >
              <div className="not-actions">
                <CreatedDate createdTime={table.get('lastChangeDate') || table.get('created')} />
              </div>
              <div className="actions">
                <TableActions
                  table={table}
                  isDeleting={isDeleting}
                  isExporting={isExporting}
                  isCreatingSnapshot={isCreatingSnapshot}
                  openDeleteTableModal={openDeleteTableModal}
                  openExportTablesModal={openExportTablesModal}
                  openSnapshotModal={openSnapshotModal}
                  canExportTable={canExportTable}
                  canWriteTable={canWriteBucket(sapiToken, table.get('bucket'))}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [
      sort,
      setSort,
      openDeleteModal,
      openCreateWorkpaceModal,
      canExportTable,
      openExportTablesModal,
      openSnapshotModal,
      hasSnowflakePartnerConnectLimited,
      createSnapshotsTables,
      exportingTables,
      query,
      buckets,
      sapiToken,
      deletingBuckets,
      deletingTables,
      openDeleteBucketModal,
      openDeleteTableModal,
    ],
  );

  const memoizedRows = React.useMemo(() => {
    let data = getFilteredData(buckets, query, searchFilters.toJS());

    if (sort.get('entity') === sortEntities.NAME && !!query) {
      data = data.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
    } else {
      data = data.sort(sorter);
    }

    return data
      .map((bucket) => {
        let tables = bucket.get('bucketTables');

        if (sort.get('entity') === sortEntities.NAME && !!query) {
          tables = tables.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
        } else {
          tables = tables.sort(sorter);
        }

        return [
          bucket.get('matches') && { item: bucket },
          ...tables.map((table) => ({ item: table })).toArray(),
        ].filter(Boolean);
      })
      .toArray()
      .flat();
  }, [buckets, query, searchFilters, sort, sorter]);

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets or tables are matching your criteria</div>
      </div>
    );
  }

  return (
    <div className="box">
      <Table columns={columns} data={memoizedRows} />
    </div>
  );
};

IndexTableResults.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  deletingBuckets: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  createSnapshotsTables: PropTypes.instanceOf(Map).isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openDeleteBucketModal: PropTypes.func.isRequired,
  openDeleteTableModal: PropTypes.func.isRequired,
  openExportTablesModal: PropTypes.func.isRequired,
  openCreateWorkpaceModal: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  searchFilters: PropTypes.instanceOf(Map).isRequired,
  bucketsSort: PropTypes.instanceOf(Map).isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
};

export default IndexTableResults;
