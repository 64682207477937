import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@keboola/design';

const DescriptionButton = (props: {
  onClick: () => void;
  isFilled: boolean;
  readOnly?: boolean;
  iconOnly?: boolean;
  showsInModalOnly?: boolean;
}) => {
  if (props.readOnly && !props.isFilled) return null;

  const icon: IconProp = props.isFilled ? 'note-sticky' : ['far', 'note-sticky'];

  if (props.iconOnly) {
    return (
      <IconButton
        onClick={props.onClick}
        icon={icon}
        isFilledIcon={props.isFilled}
        variant="outline"
      />
    );
  }

  return (
    <Button onClick={props.onClick} variant="outline">
      <FontAwesomeIcon icon={icon} />
      <>
        {props.isFilled ? (props.showsInModalOnly || props.readOnly ? 'Show' : 'Edit') : 'Add'}{' '}
        description
      </>
    </Button>
  );
};

export default DescriptionButton;
