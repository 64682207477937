import React from 'react';
import { cn } from '@keboola/design';

import dayjs from '@/date';
import { formatAbsolute } from './helpers';

type Props = {
  createdTime?: string;
  fallback?: React.ReactNode;
  className?: string;
  absolute?: boolean;
  relative?: boolean;
  withSeconds?: boolean;
};

const CreatedDate = ({
  createdTime,
  withSeconds,
  absolute,
  relative,
  fallback,
  className,
}: Props) => {
  if (!createdTime) {
    return <span className={className}>{fallback || 'N/A'}</span>;
  }

  const renderRelative = () => {
    return (
      <span
        className={cn('tw-whitespace-nowrap', className)}
        title={formatAbsolute(createdTime, withSeconds)}
      >
        {dayjs(createdTime).fromNow()}
      </span>
    );
  };

  const renderAbsolute = () => {
    return (
      <span className={cn('tw-whitespace-nowrap', className)}>
        {formatAbsolute(createdTime, withSeconds)}
      </span>
    );
  };

  if (relative) {
    return renderRelative();
  }

  return absolute || dayjs(createdTime).isBefore(dayjs().subtract(1, 'day'))
    ? renderAbsolute()
    : renderRelative();
};

export default CreatedDate;
