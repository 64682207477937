import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { tableDisplayNameWithBucketAndStage, tableName } from '@/modules/storage/helpers';
import Select from '@/react/common/Select';
import { tableLabel } from '@/react/common/selectLabels';

const CreateDockerSandboxForm = createReactClass({
  propTypes: {
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  },
  getInitialState() {
    return {
      rows: 0,
      tables: List(),
    };
  },
  render: function () {
    return (
      <form className="form-horizontal">
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Tables</ControlLabel>
          </div>
          <div className="col-xs-9">
            <Select
              multi
              value={this.state.tables}
              options={this.getTablesOptions()}
              onChange={this.onChangeTables}
              placeholder="Select tables to load..."
            />
            <HelpBlock>
              The required tables must be loaded into {this.props.type} when creating the sandbox.
              Data cannot be added later.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Sample rows</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="number"
              placeholder="Number of rows"
              value={this.state.rows}
              onChange={this.onChangeRows}
            />
            <HelpBlock>To import all rows, use 0.</HelpBlock>
          </div>
        </FormGroup>
      </form>
    );
  },
  onChangeRows: function (e) {
    const rows = parseInt(e.target.value, 10);
    this.setState({
      rows,
    });
    this.onChange({ rows });
  },
  onChangeTables: function (tables) {
    this.setState({
      tables,
    });
    this.onChange({ tables });
  },
  getTablesOptions: function () {
    return filterProductionAndCurrentDevBranchTables(this.props.tables, this.props.buckets)
      .sortBy((table) => tableDisplayNameWithBucketAndStage(table))
      .map((table) => ({
        value: table.get('id'),
        label: tableLabel(table),
        name: tableName(table),
      }))
      .toArray();
  },
  onChange: function ({ rows = this.state.rows, tables = this.state.tables }) {
    this.props.onChange({
      input: {
        tables: tables.map((table) => {
          let retVal = {
            source: table,
            destination: table + '.csv',
          };
          if (rows > 0) {
            retVal.limit = rows;
          }
          return retVal;
        }),
      },
    });
  },
});

export default CreateDockerSandboxForm;
