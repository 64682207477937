import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';

import string from '@/utils/string';
import MoveActionButton from './MoveActionButton';

type SelectedRows = { config: Map<string, any>; component: Map<string, any> }[];

type Props = {
  openDeleteModal: () => void;
  openMoveModal: () => void;
  supportFolders: boolean;
  selectedConfigurations: SelectedRows;
  renderAdditionalMultiActions?: (selectedConfigurations: SelectedRows) => React.ReactNode;
  entity?: string;
  disableDeleteReason?: string;
};

const ActionButtons = (props: Props) => {
  const selectedConfigurationsCount = props.selectedConfigurations.length;

  return (
    <div className="tw-inline-flex tw-items-center">
      {selectedConfigurationsCount === 0 ? (
        'Name'
      ) : (
        <strong>
          {selectedConfigurationsCount}{' '}
          {string.pluralize(selectedConfigurationsCount, props.entity || 'configuration')} selected
        </strong>
      )}
      {selectedConfigurationsCount > 0 && (
        <div className="table-action-buttons no-wrap">
          <Tooltip
            placement="top"
            tooltip={props.disableDeleteReason || 'Delete Selected'}
            type={props.disableDeleteReason ? 'explanatory' : 'action'}
          >
            <Button
              bsStyle="link"
              className={classNames('btn-link-inline btn-link-muted', {
                disabled: !!props.disableDeleteReason,
              })}
              onClick={() => !props.disableDeleteReason && props.openDeleteModal()}
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
          {props.supportFolders && (
            <MoveActionButton mode="sidebar" onClick={props.openMoveModal} />
          )}
          {props.renderAdditionalMultiActions?.(props.selectedConfigurations)}
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
