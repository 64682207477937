import React from 'react';
import PropTypes from 'prop-types';
import { PanelWithDetails } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import SaveButtons from '@/react/common/SaveButtons';

const CollapsibleSection = (TitleComponent, InnerComponent, options = {}) => {
  const { includeSaveButtons = false, additionalProps = {} } = options;

  return createReactClass({
    propTypes: {
      context: PropTypes.instanceOf(Map),
      stackFeatures: PropTypes.instanceOf(List),
      imageParameters: PropTypes.instanceOf(Map),
      readOnly: PropTypes.bool,
      disabled: PropTypes.bool,
      isChanged: PropTypes.bool,
      isComplete: PropTypes.bool,
      onSave: PropTypes.func,
      onChange: PropTypes.func,
      onReset: PropTypes.func,
      value: PropTypes.any,
      actions: PropTypes.any,
    },

    render() {
      return (
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">
              <TitleComponent value={this.props.value} />
            </h2>
            {includeSaveButtons && this.renderButtons()}
          </div>
          <div className="box-content only-with-panel -tw-mx-5">
            <PanelWithDetails
              defaultExpanded={this.props.isChanged || !this.props.isComplete}
              placement="bottom"
              labelOpen={this.renderLabel('Open')}
              labelCollapse={this.renderLabel('Hide')}
            >
              <InnerComponent
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                onSave={this.props.onSave}
                value={this.props.value}
                actions={this.props.actions}
                readOnly={this.props.readOnly}
                stackFeatures={this.props.stackFeatures}
                imageParameters={this.props.imageParameters}
                context={this.props.context}
                isChanged={this.props.isChanged}
                {...additionalProps}
              />
            </PanelWithDetails>
          </div>
        </div>
      );
    },

    renderLabel(type) {
      return (
        <>
          {type} <TitleComponent value={this.props.value} /> settings
        </>
      );
    },

    renderButtons() {
      if (this.props.readOnly) {
        return null;
      }

      return (
        <SaveButtons
          isSaving={this.props.disabled}
          isChanged={this.props.isChanged}
          onSave={this.props.onSave}
          onReset={this.props.onReset}
        />
      );
    },
  });
};

export default CollapsibleSection;
