import React from 'react';
import { Dropdown } from 'react-bootstrap';

import dayjs, { TIME_FORMAT } from '@/date';
import {
  allowedTimezones,
  currentTimeInTimezone,
  displayTimezone,
  resolveLocalTimezone,
} from '@/modules/scheduler/timezones';
import MarkedText from '@/react/common/MarkedText';
import Select from '@/react/common/Select';

type TimezoneOption = ({ divider: true } | { value: string | null; name: string; time: string })[];

type Props = {
  onChange: (timezone: string) => void;
  crontabTimezone: string;
};

class ScheduleTimezone extends React.Component<Props> {
  state = {
    isOpen: false,
  };

  render() {
    return (
      <span>
        <span className="tw-text-neutral-400">Timezone: </span>
        <Dropdown
          pullRight
          rootCloseEvent="mousedown"
          id="select-timezone-dropdown"
          open={this.state.isOpen}
          onToggle={(isOpen: boolean, event: any, data: { source: string }) => {
            if (
              data.source === 'rootClose' &&
              event.composedPath?.().some((el: Element) => el.id === 'select-timezone-dropdown')
            ) {
              return;
            }

            this.setState({ isOpen });
          }}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className="btn-link-inline !tw-font-medium !tw-text-neutral-800"
          >
            {displayTimezone(this.props.crontabTimezone)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.isOpen && (
              <Select
                asSearch
                autoFocus
                forceOpen
                clearable={false}
                placeholder="Search"
                noResultsText="No timezone found"
                options={this.prepareOptions()}
                onChange={this.handleChangeTimezone}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  }

  prepareOptions = () => {
    const localTimezone = resolveLocalTimezone();

    let options: TimezoneOption = [
      {
        value: null,
        name: 'UTC',
        time: dayjs.utc().format(TIME_FORMAT),
      },
      { divider: true },
      ...allowedTimezones
        .filter((timezone) => ![localTimezone, 'UTC'].includes(timezone))
        .map((timezone) => ({
          value: timezone,
          name: displayTimezone(timezone),
          time: currentTimeInTimezone(timezone),
        })),
    ];

    if (localTimezone) {
      options = [
        {
          value: localTimezone,
          name: `My Timezone (${displayTimezone(localTimezone)})`,
          time: dayjs().format(TIME_FORMAT),
        },
        ...options,
      ];
    }

    return options.map((option) => {
      if ('divider' in option) {
        return option;
      }

      return {
        value: option.value,
        name: option.name,
        label: (inputString: string) => (
          <div className="tw-flex tw-content-center tw-justify-between">
            <MarkedText className="tw-text-neutral-800" source={option.name} mark={inputString} />
            <span className="tw-text-xs tw-text-neutral-400">{option.time}</span>
          </div>
        ),
      };
    });
  };

  handleChangeTimezone = (timezone: string) => {
    this.props.onChange(timezone);
    this.setState({ isOpen: false });
  };
}

export default ScheduleTimezone;
