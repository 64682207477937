import React from 'react';
import type { ReactNode } from 'react';
import { ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import { HelpBlock, Link, Tabs, TabsContent } from '@keboola/design';
import type { Map } from 'immutable';

import { AUTH_KEY, CRAWLER_KEY, OPTIONS_KEY } from '@/modules/apify/constants';
import SapiTableSelector from '@/modules/components/react/components/SapiTableSelector';
import CodeEditor from '@/react/common/CodeEditor';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import ApifyObjectSelector from './ApifyObjectSelector';

type Props = {
  localState: Map<string, any>;
  settings: string;
  action: string;
  updateSettings: () => void;
  actors: Map<string, any>;
  tasks: Map<string, any>;
  inputTableId?: string;
  updateInputTableId: () => void;
  step: string;
  parameters: Map<string, any>;
  loadActors: () => void;
  loadTasks: () => void;
  updateParameters: (parameters: unknown) => void;
  selectTab: () => void;
  buckets: Map<string, any>;
  tables: Map<string, any>;
};

const TabbedWizard = (props: Props) => {
  const renderRadioOption = (param: string, label: string, help: string) => {
    return (
      <FormGroup>
        <Radio
          value={param}
          checked={props.action === param}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateParameter('action', event.target.value)
          }
        >
          {label}
        </Radio>
        <HelpBlock>{help}</HelpBlock>
      </FormGroup>
    );
  };

  const renderOptionsContent = () => {
    switch (props.action) {
      case 'dataset':
        return renderDatasetSettingsForm();
      case 'actor':
        return renderActorSettingsForm();
      case 'actor-last-run':
        return renderActorLastRunDatasetItemsSettingsForm();
      case 'task':
        return renderTaskSettingsForm();
      case 'task-last-run':
        return renderTaskLastRunDatasetItemsSettingsForm();
      default:
        return null;
    }
  };

  const renderActorSettingsForm = () => {
    return (
      <div className="form-horizontal">
        {renderActorSelector()}
        {renderInputTableIdSelector('actor')}
        {renderOutputField()}
        {renderMemoryField()}
        {renderBuildField()}
        <div className="form-group">
          <label className="col-xs-2 control-label">Actor Input</label>
          <div className="col-xs-10">
            <CodeEditor
              value={props.settings}
              onChange={props.updateSettings}
              options={{ lineNumbers: false }}
            />
            <HelpBlock>(Optional) Contains input for the Actor in JSON format.</HelpBlock>
          </div>
        </div>
      </div>
    );
  };

  const renderActorLastRunDatasetItemsSettingsForm = () => {
    return (
      <div className="form-horizontal">
        {renderActorSelector()}
        {renderOutputField()}
      </div>
    );
  };

  const renderTaskSettingsForm = () => {
    return (
      <div className="form-horizontal">
        {renderTaskSelector()}
        {renderInputTableIdSelector('task')}
        {renderOutputField()}
        {renderMemoryField()}
        {renderBuildField()}
        <div className="form-group">
          <label className="col-xs-2 control-label">Input Overrides</label>
          <div className="col-xs-10">
            <CodeEditor
              value={props.settings}
              onChange={props.updateSettings}
              options={{ lineNumbers: false }}
            />
            <HelpBlock>
              (Optional) Here you can enter a JSON object to override the task input configuration.
              Only the provided fields will be overridden, the rest will be left unchanged.
            </HelpBlock>
          </div>
        </div>
      </div>
    );
  };

  const renderTaskLastRunDatasetItemsSettingsForm = () => {
    return (
      <div className="form-horizontal">
        {renderTaskSelector()}
        {renderOutputField()}
      </div>
    );
  };

  const renderDatasetSettingsForm = () => {
    return (
      <div className="form-horizontal">
        {renderInput(
          'Dataset',
          'datasetId',
          'ID or name of the dataset to fetch data from',
          'Enter dataset ID or dataset name',
        )}
        {renderOutputField()}
      </div>
    );
  };

  const renderInputTableIdSelector = (type: string) => {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">Input Table</label>
        <div className="col-xs-10">
          <SapiTableSelector
            clearable
            placeholder="Select table"
            tables={props.tables}
            buckets={props.buckets}
            value={props.inputTableId || ''}
            onSelectTableFn={props.updateInputTableId}
          />
          <HelpBlock>
            (Optional) Data from the input table will be pushed to {type}, where you can access them
            through the key-value store. The ID of the key-value store and key of record will be
            saved to the input of {type} in attribute inputTableRecord.
          </HelpBlock>
        </div>
      </div>
    );
  };

  const renderOutputField = () => {
    const rowValue = props.parameters.get('fields');
    const value = typeof rowValue === 'string' ? rowValue.split(',') : rowValue;

    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Output fields</ControlLabel>
        </div>
        <div className="col-xs-10">
          <Select
            multi
            allowCreate
            value={value}
            placeholder="Enter output fields"
            onChange={(value) => updateParameter('fields', value)}
            promptTextCreator={(label) => `Add field "${label}"`}
          />
          <HelpBlock>
            (Optional) A list of fields which should be picked from the dataset items
          </HelpBlock>
        </div>
      </FormGroup>
    );
  };

  const renderMemoryField = () => {
    return renderInput(
      'Memory',
      'memory',
      `(Optional) Specifies the amount of memory allocated for run`,
      '2048',
    );
  };

  const renderBuildField = () => {
    return renderInput(
      'Build',
      'build',
      `(Optional) Tag or number of the build to run (e.g., latest or 1.2.34)`,
      'latest',
    );
  };

  const isTabDisabled = (tabKey: string) => {
    return props.step !== tabKey;
  };

  const renderTokenForm = () => {
    return (
      <div className="form-horizontal">
        {renderProtectedInput(
          'Token',
          '#token',
          <>
            Generate a fresh API token for Keboola within your{' '}
            <Link href="https://console.apify.com/account/integrations">account settings</Link>.
          </>,
          'Enter token',
        )}
      </div>
    );
  };

  const renderActorSelector = () => {
    return (
      <ApifyObjectSelector
        objectName="actor"
        objectLabelKey="name"
        object={props.actors}
        selectedValue={props.parameters.get('actId')}
        onLoadObjectsList={props.loadActors}
        onSelect={(actId) => updateParameter('actId', actId)}
      />
    );
  };

  const renderTaskSelector = () => {
    return (
      <ApifyObjectSelector
        objectName="task"
        objectLabelKey="name"
        object={props.tasks}
        selectedValue={props.parameters.get('actorTaskId')}
        onLoadObjectsList={props.loadTasks}
        onSelect={(actorTaskId) => updateParameter('actorTaskId', actorTaskId)}
      />
    );
  };

  const renderInput = (
    label: string,
    propertyPath: string,
    helpText: string,
    placeholder: string,
  ) => {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">{label}</label>
        <div className="col-xs-10">
          <FormControl
            type="text"
            value={props.parameters.get(propertyPath, '')}
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateParameter(propertyPath, e.target.value)
            }
          />
          <HelpBlock>{helpText}</HelpBlock>
        </div>
      </div>
    );
  };

  const renderProtectedInput = (
    label: string,
    propertyPath: string,
    helpText: ReactNode,
    placeholder: string,
  ) => {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">{label}</label>
        <div className="col-xs-10">
          <PasswordControl
            placeholder={placeholder}
            value={props.parameters.get(propertyPath, '')}
            onChange={(e) => updateParameter(propertyPath, e.target.value)}
          />
          <HelpBlock>{helpText}</HelpBlock>
        </div>
      </div>
    );
  };

  const updateParameter = (key: string, newValue: unknown) => {
    props.updateParameters(props.parameters.set(key, newValue));
  };

  return (
    <Tabs
      value={props.step}
      onValueChange={props.selectTab}
      inModal
      triggers={[
        {
          value: CRAWLER_KEY,
          title: 'Action',
          disabled: isTabDisabled(CRAWLER_KEY),
        },
        {
          value: AUTH_KEY,
          title: 'Authentication',
          disabled: isTabDisabled(AUTH_KEY),
        },
        {
          value: OPTIONS_KEY,
          title: 'Specification',
          disabled: isTabDisabled(OPTIONS_KEY),
        },
      ]}
    >
      <TabsContent value={CRAWLER_KEY}>
        <div className="clearfix">
          {renderRadioOption(
            'actor',
            'Run actor',
            'Runs a specific Actor and retrieves its dataset items if it finishes successfully.',
          )}
          {renderRadioOption(
            'actor-last-run',
            'Retrieve dataset items from the last Actor run',
            'Retrieves items from the dataset of a specific Actor’s last run.',
          )}
          {renderRadioOption(
            'task',
            'Run task',
            'Runs a specific task and retrieves its dataset items if it finishes successfully.',
          )}
          {renderRadioOption(
            'task-last-run',
            'Retrieve items from the last task run',
            'Retrieves items from the dataset of a task’s last run.',
          )}
          {renderRadioOption(
            'dataset',
            'Retrieve items from a dataset',
            'Retrieves items from a dataset specified by its ID or name.',
          )}
        </div>
      </TabsContent>
      <TabsContent value={AUTH_KEY}>{renderTokenForm()}</TabsContent>
      <TabsContent value={OPTIONS_KEY}>
        {props.step === OPTIONS_KEY && renderOptionsContent()}
      </TabsContent>
    </Tabs>
  );
};

export default TabbedWizard;
