import React, { useContext } from 'react';
import { useNodesInitialized, useReactFlow } from '@xyflow/react';
import ELK from 'elkjs/lib/elk.bundled.js';
import type { ElkNode } from 'elkjs/lib/elk-api';
import _ from 'underscore';

import { isMyHandleHidden } from './components/dynamicEdges';
import { elkOptions, getFitViewOptions } from './components/initConfig';
import { GraphContext } from './contexts/GraphContext';
import type { CustomEdge, CustomNode } from './rfTypes';

const elk = new ELK();

const getLayoutedElements = (nodes: CustomNode[], edges: CustomEdge[]) => {
  const allNodesHidden = nodes.every((n) => !n.measured?.width && !n.measured?.height);
  const opacity = {
    // We set this to prevent flickering at the beggining. Cannot do this through hidden directly as it will not
    // render at all and will not be measured. The key is it needs to be rendered and measured by react flow,
    // and then remeasured by elk to make a layout. So we hide what was rendered by react flow with opacity (so it
    // still stays at DOM).
    opacity: allNodesHidden ? 0 : 1,
  };

  const visibleEdges = edges.filter((edge) => {
    return (
      !isMyHandleHidden('source', edge.source, edge.sourceHandle, null) &&
      !isMyHandleHidden('target', edge.target, edge.targetHandle, null)
    );
  });

  const graph: ElkNode & { children: CustomNode[] } = {
    id: 'root',
    layoutOptions: elkOptions,
    children: nodes.map((node) => {
      return { ...node, width: node.measured?.width || 340, height: node.measured?.height || 96 };
    }),
    edges: visibleEdges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => {
      return {
        nodes: (layoutedGraph.children ?? [])?.map((node) => {
          return {
            ..._.omit(node, 'width', 'height', 'x', 'y'),
            position: { x: node.x ?? 0, y: node.y ?? 0 },
            style: { ...node.style, ...opacity },
            hidden: false,
          };
        }),
        edges: visibleEdges.map((edge) => {
          return { ...edge, style: { ...edge.style, ...opacity }, hidden: false };
        }),
      };
    })
    .catch((e) => {
      console.error(e);
      return { nodes: [], edges: [] };
    });
};

const useLayoutNodes = () => {
  const nodesInitialized = useNodesInitialized();
  const { getNodes, getEdges, setNodes, setEdges, fitView } = useReactFlow<
    CustomNode,
    CustomEdge
  >();
  const { mainNodeId } = useContext(GraphContext);

  React.useEffect(() => {
    const detailNodeId = new URLSearchParams(window.location.search).get('node');
    const id = detailNodeId ?? mainNodeId;

    if (nodesInitialized) {
      getLayoutedElements(getNodes(), getEdges()).then(
        ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
          setNodes(layoutedNodes);
          setEdges(layoutedEdges);

          window.requestAnimationFrame(() => {
            fitView(getFitViewOptions(id ? { id } : null, layoutedNodes));
          });
        },
      );
    }
  }, [nodesInitialized, getNodes, getEdges, setNodes, setEdges, fitView, mainNodeId]);
};

export { useLayoutNodes };
