import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Tooltip } from '@keboola/design';
import classnames from 'classnames';

import { ASSETS_URL, PLATFORM_CHANGELOG_URL } from '@/constants/KbcConstants';
import { PLATFORM_NEWS_LAST_SEEN } from '@/constants/localStorageKeys';
import dayjs from '@/date';
import { request } from '@/helpers';
import CustomLoader from '@/react/common/CustomLoader';
import Truncated from '@/react/common/Truncated';
import { getItem, setItem } from '@/utils/localStorage';

type Post = {
  excerpt: string;
  published_at: string;
  slug: string;
  title: string;
  unread?: boolean;
};

const UTM_QUERY_PARAMS = 'utm_campaign=news-dropdown&utm_source=platform';

const PlatformNews = () => {
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [unreadPostsCount, setUnreadPostsCount] = useState(0);

  useEffect(() => {
    request('GET', `${ASSETS_URL}/platform-changelog/published-posts.json`).then(
      ({ body }: { body: Post[] }) => {
        let unreadCount = 0;
        const lastSeen = getItem(PLATFORM_NEWS_LAST_SEEN);
        const sortedPosts = body
          .sort(
            (postA, postB) =>
              new Date(postB.published_at).valueOf() - new Date(postA.published_at).valueOf(),
          )
          .slice(0, 4)
          .map((post) => {
            if (!lastSeen || dayjs(post.published_at).diff(lastSeen) > 0) {
              unreadCount++;

              return { ...post, unread: true };
            }

            return post;
          });

        setPosts(sortedPosts);
        setUnreadPostsCount(unreadCount);
      },
    );
  }, []);

  return (
    <Tooltip tooltip="What's New" placement="bottom">
      <Dropdown
        id="platform-news"
        rootCloseEvent="mousedown"
        open={open}
        onToggle={(isOpen: boolean, event: any, data: { source: string }) => {
          if (
            data.source === 'rootClose' &&
            event.composedPath?.().some((el: Element) => el.id === 'platform-news')
          ) {
            return;
          }

          setOpen(isOpen);
          if (isOpen) {
            setUnreadPostsCount(0);
            setItem(PLATFORM_NEWS_LAST_SEEN, new Date().toString());
          }
        }}
        pullRight
      >
        <Dropdown.Toggle noCaret bsStyle="link">
          <span className="news-icon">
            <FontAwesomeIcon icon="gift" className="font-size-inherit" />
            {!!unreadPostsCount && <span className="unread-count">{unreadPostsCount}</span>}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!posts.length ? (
            <div className="text-center">
              <CustomLoader text="Loading news..." className="tw-min-h-0 tw-pt-1" />
              <hr />
            </div>
          ) : (
            posts.map(({ excerpt, slug, title, unread }) => (
              <React.Fragment key={slug}>
                <Link
                  href={`${PLATFORM_CHANGELOG_URL}/${slug}/?${UTM_QUERY_PARAMS}`}
                  onClick={() => {
                    setPosts(
                      posts.map((post) => ({
                        ...post,
                        ...(post.slug === slug && { unread: false }),
                      })),
                    );
                  }}
                  className="display-block color-inherit no-underline"
                >
                  <Truncated text={title} className={classnames('font-medium', { unread })} />
                  <Truncated
                    twoLines
                    text={excerpt}
                    noTooltip
                    className="text-muted f-12 font-medium line-height-22 tw-mt-1"
                  />
                </Link>
                <hr className="mt-1 mb-1" />
              </React.Fragment>
            ))
          )}
          <div className="text-center">
            <Link href={`${PLATFORM_CHANGELOG_URL}/?${UTM_QUERY_PARAMS}`}>
              See all changes &rarr;
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </Tooltip>
  );
};

export default PlatformNews;
