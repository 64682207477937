import React from 'react';
import { Link } from '@keboola/design';

const ComponentLicence = (props: { href?: string }) => {
  if (!props.href) {
    return null;
  }

  return (
    <p className="text-muted">
      You agree to the <Link href={props.href}>vendor&apos;s license agreement</Link> by using this
      component.
    </p>
  );
};

export default ComponentLicence;
