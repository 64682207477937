import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard, Tooltip } from '@keboola/design';
import { Promise } from 'bluebird';
import { fromJS, Map } from 'immutable';

import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import string from '@/utils/string';
import CodeEditor from './CodeEditor';
import ComponentIcon from './ComponentIcon';
import FullScreenEditor from './FullScreenEditor';
import Loader from './Loader';
import Truncated from './Truncated';

const RawConfigurationButton = (props: {
  version: Map<string, any>;
  component: Map<string, any>;
  configId: string;
  rowId?: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState<Map<string, any>>(Map());

  const getValue = () => {
    return JSON.stringify(config.get('configuration', Map()).toJSON(), null, '  ');
  };

  return (
    <>
      <Tooltip tooltip="Show raw configuration" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={() => {
            if (!config.isEmpty()) {
              return setOpen(true);
            }

            setLoading(true);
            Promise.resolve()
              .then(() => {
                if (props.rowId) {
                  return installedComponentsApi.getComponentConfigRowByVersion(
                    props.component.get('id'),
                    props.configId,
                    props.rowId,
                    props.version.get('version'),
                  );
                }

                return installedComponentsApi.getComponentConfigByVersion(
                  props.component.get('id'),
                  props.configId,
                  props.version.get('version'),
                );
              })
              .then((data) => {
                setConfig(fromJS(data));
                setOpen(true);
              })
              .finally(() => setLoading(false));
          }}
        >
          {loading ? <Loader /> : <FontAwesomeIcon icon="code" fixedWidth />}
        </Button>
      </Tooltip>
      {open && (
        <FullScreenEditor
          className="without-tabs"
          renderTitle={() => {
            return (
              <>
                <ComponentIcon component={props.component} size="40" className="icon-addon-right" />
                <div className="flex-container flex-column flex-start align-top">
                  <Truncated text={props.version.get('name')} />
                  <div className="flex-container flex-start f-14 line-height-20 text-muted">
                    <strong className="icon-addon-right">#{props.version.get('version')}</strong>
                    <Truncated text={props.version.get('changeDescription') || 'No description'} />
                  </div>
                </div>
              </>
            );
          }}
          renderEditor={() => {
            return (
              <CodeEditor
                value={getValue()}
                options={{ lineNumbers: false, cursorHeight: 0, readOnly: true }}
              />
            );
          }}
          renderButtons={() => {
            return (
              <>
                <Clipboard
                  inline={false}
                  tooltipText=""
                  text={getValue()}
                  label="Copy To Clipboard"
                  tooltipPlacement="bottom"
                />
                <a
                  className="btn btn-default"
                  download={`${string.webalize(props.version.get('name'))}-${props.version.get(
                    'version',
                  )}.json`}
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(getValue())}`}
                >
                  <FontAwesomeIcon icon="down-to-line" className="btn-icon" />
                  Download
                </a>
                <span className="btn-separator" />
              </>
            );
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default RawConfigurationButton;
