import React from 'react';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_VARIABLES,
  ORCHESTRATOR,
} from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import * as dataAppsActions from '@/modules/data-apps/actions';
import { loadTriggersForce } from '@/modules/event-trigger/actions';
import OldOrchestratorActionCreators from '@/modules/orchestrations/ActionCreators';
import OrchestratorApi from '@/modules/orchestrations/OrchestrationsApi';
import WorkspacesActions from '@/modules/workspaces/WorkspacesActions';
import ApplicationStore from '@/stores/ApplicationStore';
import ConfigurationCopiedNotification from './react/components/ConfigurationCopiedNotification';
import versionsMishmashDetected from './react/components/notifications/versionsMishmashDetected';
import Store from './stores/VersionsStore';
import { shouldWarnAboutNewVersion } from './helpers';
import InstalledComponentsActionCreators from './InstalledComponentsActionCreators';
import Api from './InstalledComponentsApi';
import Constants from './VersionsConstants';

const VersionsActionCreators = {
  loadVersions: function (componentId, configId) {
    if (Store.hasVersions(componentId, configId)) {
      this.loadVersionsForce(componentId, configId);
      return Promise.resolve();
    }
    return this.loadVersionsForce(componentId, configId);
  },

  loadVersionsForce: function (componentId, configId) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      type: Constants.ActionTypes.VERSIONS_LOAD_START,
    });
    return Api.getComponentConfigVersions(componentId, configId)
      .then(function (result) {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          type: Constants.ActionTypes.VERSIONS_LOAD_SUCCESS,
          versions: result,
        });
        return result;
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          type: Constants.ActionTypes.VERSIONS_LOAD_ERROR,
        });
        throw error;
      });
  },

  reloadVersionsAndNotifyIfDoNotMatch: function (componentId, configId) {
    if (Store.isLoadingVersions(componentId, configId)) {
      return Promise.resolve();
    }

    return Api.getComponentConfigVersions(componentId, configId).then((versions) => {
      const previousVersions = Store.getVersions(componentId, configId);
      const newVersions = fromJS(versions);

      if (
        shouldWarnAboutNewVersion(previousVersions, newVersions, ApplicationStore.getCurrentAdmin())
      ) {
        const notification = () => {
          ApplicationActionCreators.sendNotification({
            type: 'warning',
            message: versionsMishmashDetected(componentId, configId, newVersions.first()),
            id: 'versions-mishmash',
          });
        };

        if (!document.hasFocus()) {
          window.addEventListener('focus', notification, { passive: true, once: true });
        } else {
          notification();
        }
      }

      dispatcher.handleViewAction({
        componentId: componentId,
        configId: configId,
        type: Constants.ActionTypes.VERSIONS_RELOAD_SUCCESS,
        versions: newVersions,
      });
    });
  },

  loadComponentConfigByVersion(componentId, configId, version) {
    if (Store.hasConfigByVersion(componentId, configId, version)) {
      return Promise.resolve();
    }
    return this.loadComponentConfigByVersionForce(componentId, configId, version);
  },

  loadComponentConfigByVersionForce(componentId, configId, version) {
    this.pendingStart(componentId, configId, version, 'config');
    return Api.getComponentConfigByVersion(componentId, configId, version)
      .then((result) => {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          version: version,
          data: result,
          type: Constants.ActionTypes.VERSIONS_CONFIG_LOAD_SUCCESS,
        });
      })
      .finally(() => {
        this.pendingStop(componentId, configId, version, 'config');
      });
  },

  loadTwoComponentConfigVersions(componentId, configId, version1, version2) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      pivotVersion: version1,
      type: Constants.ActionTypes.VERSIONS_MULTI_PENDING_START,
    });
    return Promise.all([
      this.loadComponentConfigByVersion(componentId, configId, version1),
      version2 && this.loadComponentConfigByVersion(componentId, configId, version2),
    ]).finally(() => {
      dispatcher.handleViewAction({
        componentId: componentId,
        configId: configId,
        pivotVersion: version1,
        type: Constants.ActionTypes.VERSIONS_MULTI_PENDING_STOP,
      });
    });
  },

  rollbackVersion: function (componentId, configId, version, reloadCallback) {
    this.pendingStart(componentId, configId, version, 'rollback');
    return Api.rollbackVersion(componentId, configId, version).then(() => {
      dispatcher.handleViewAction({
        componentId: componentId,
        configId: configId,
        version: version,
        type: Constants.ActionTypes.VERSIONS_ROLLBACK_SUCCESS,
      });
      return Promise.all([this.loadVersionsForce(componentId, configId), ...reloadCallback()])
        .then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: 'Configuration rollback successful.',
          });
        })
        .finally(() => {
          this.pendingStop(componentId, configId, version, 'rollback');
        });
    });
  },

  copyVersion: function (componentId, configId, version, name, excludeRows) {
    this.pendingStart(componentId, configId, version, 'copy');
    return Promise.resolve()
      .then(() => {
        if (componentId === ORCHESTRATOR) {
          return OrchestratorApi.createConfigCopy(componentId, configId, version, name).then(
            (response) => {
              return Promise.all([
                loadTriggersForce(),
                OldOrchestratorActionCreators.loadOrchestrationsForce(),
              ]).then(() => response);
            },
          );
        }

        return Api.getComponentConfigByVersion(componentId, configId, version)
          .then(fromJS)
          .then((config) => {
            if (componentId === KEBOOLA_DATA_APPS) {
              return dataAppsActions
                .createApp(
                  name,
                  config.get('description'),
                  config.get('configuration', Map()).deleteIn(['parameters', 'id']).toJS(),
                )
                .then((configId) => ({ id: configId }));
            }

            if (
              !config.get('rows', List()).isEmpty() ||
              !config.hasIn(['configuration', 'variables_id'])
            ) {
              return Promise.resolve()
                .then(() => {
                  if (excludeRows) {
                    // it is easier create a new configuration than create a copy and remove each row one by one
                    return Api.createConfiguration(componentId, {
                      name,
                      configuration: JSON.stringify(config.get('configuration', Map()).toJS()),
                      description: `Created from ${config.get(
                        'name',
                      )} version #${version} (without rows)\n\n${
                        config.get('description') || ''
                      }`.trim(),
                    });
                  }

                  return Api.createConfigCopy(componentId, configId, version, name);
                })
                .tap((response) => {
                  if (componentId === KEBOOLA_NO_CODE_DBT_TRANSFORMATION) {
                    return WorkspacesActions.createConfigurationWorkspace(
                      componentId,
                      response.id,
                      {
                        backend: 'snowflake',
                        readOnlyStorageAccess: ApplicationStore.hasReadOnlyStorage(),
                      },
                    )
                      .then((workspace) =>
                        InstalledComponentsActionCreators.loadComponentConfigDataForce(
                          componentId,
                          response.id,
                        ).then((configuration) =>
                          fromJS(configuration).setIn(
                            ['parameters', 'authorization'],
                            workspace
                              .get('connection', Map())
                              .set('#password', workspace.getIn(['connection', 'password']))
                              .delete('password')
                              .delete('backend'),
                          ),
                        ),
                      )
                      .then((authorizedConfiguration) =>
                        InstalledComponentsActionCreators.saveComponentConfigData(
                          componentId,
                          response.id,
                          authorizedConfiguration,
                          'Authorize',
                        ),
                      );
                  }
                });
            }

            return Api.getComponentConfiguration(
              KEBOOLA_VARIABLES,
              config.getIn(['configuration', 'variables_id']),
            )
              .then(fromJS)
              .then((variableDefinition) => {
                return Api.createConfigCopy(
                  KEBOOLA_VARIABLES,
                  variableDefinition.get('id'),
                  variableDefinition.get('version'),
                  `Variables definition for ${componentId}/${configId}`,
                );
              })
              .then((response) => {
                return Api.getComponentConfiguration(KEBOOLA_VARIABLES, response.id);
              })
              .then(fromJS)
              .then((newVariables) => {
                return Api.createConfiguration(
                  componentId,
                  {
                    name,
                    description: config.get('description'),
                    configuration: JSON.stringify({
                      ...config.get('configuration', Map()).toJS(),
                      variables_id: newVariables.get('id'),
                      variables_values_id: newVariables.getIn(['rows', 0, 'id']),
                    }),
                  },
                  `Copied from configuration "${config.get(
                    'name',
                  )}" (${configId}) version ${version}`,
                );
              });
          });
      })
      .then((response) => {
        return InstalledComponentsActionCreators.loadComponentConfigsData(componentId).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: (props) => (
              <ConfigurationCopiedNotification
                componentId={componentId}
                configId={response.id}
                name={name}
                onClick={props.onClick}
                hasFlows={ApplicationStore.hasFlows()}
              />
            ),
          });
        });
      })
      .finally(() => this.pendingStop(componentId, configId, version, 'copy'));
  },

  pendingStart: function (componentId, configId, version, action) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      version: version,
      action: action,
      type: Constants.ActionTypes.VERSIONS_PENDING_START,
    });
  },

  pendingStop: function (componentId, configId, version, action) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      version: version,
      action: action,
      type: Constants.ActionTypes.VERSIONS_PENDING_STOP,
    });
  },
};

export default VersionsActionCreators;
