import { List, Map } from 'immutable';

import { KEBOOLA_VARIABLES } from '@/constants/componentIds';

const prepareVariables = (
  allInstalledComponents,
  componentId,
  configId,
  forcedVariablesValuesId,
  forcedVariablesValues,
) => {
  const configData = allInstalledComponents.getIn(
    [componentId, 'configurations', configId, 'configuration'],
    Map(),
  );

  const variablesId = configData.get('variables_id');
  const variableValuesId = forcedVariablesValuesId || configData.get('variables_values_id');

  const variables = allInstalledComponents.getIn(
    [KEBOOLA_VARIABLES, 'configurations', variablesId, 'configuration', 'variables'],
    List(),
  );

  if (variables.isEmpty()) {
    return List();
  }

  const variablesValues = forcedVariablesValues
    ? forcedVariablesValues.get('values', List())
    : allInstalledComponents
        .getIn([KEBOOLA_VARIABLES, 'configurations', variablesId, 'rows'], Map())
        .find((row) => row.get('id') === variableValuesId, null, Map())
        .getIn(['configuration', 'values'], List());

  if (variablesValues.isEmpty()) {
    return variables.toList();
  }

  const variablesValuesMap = variablesValues.toMap().mapKeys((key, val) => val.get('name'));

  return variables
    .map((variable) => {
      if (variablesValuesMap.hasIn([variable.get('name'), 'value'])) {
        return variable.set('value', variablesValuesMap.getIn([variable.get('name'), 'value']));
      }
      return variable;
    })
    .sortBy((variable) => variable.get('name'));
};

const getMissingSharedCodesVariables = (config, variables, allSharedCodes, allVariables) => {
  const sharedCodes = allSharedCodes.toMap().mapKeys((key, val) => val.get('id'));
  const defineVariables = variables.map((variable) => variable.get('name'));
  return config
    .get('shared_code_row_ids', List())
    .filter((sharedCodeId) => sharedCodes.hasIn([sharedCodeId, 'configuration', 'variables_id']))
    .map((sharedCodeId) => sharedCodes.getIn([sharedCodeId, 'configuration', 'variables_id']))
    .flatMap((variableId) => allVariables.getIn([variableId, 'configuration', 'variables'], List()))
    .map((variable) => variable.get('name'))
    .filter((name) => !defineVariables.includes(name))
    .toSet()
    .toList();
};

const isVariableDuplicate = (variables, oldName, newName) => {
  if (oldName === newName) {
    return false;
  }
  return !!variables.find(
    (variable) => variable.get('name') !== oldName && variable.get('name') === newName,
  );
};

const getClipboardVariable = (variableName) => `{{ ${variableName} }}`;

export {
  prepareVariables,
  getMissingSharedCodesVariables,
  isVariableDuplicate,
  getClipboardVariable,
};
