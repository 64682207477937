import React from 'react';
import type { NodeProps } from '@xyflow/react';

import { canAccessProject } from '@/modules/admin/privileges';
import NoAccessIcon from '@/modules/lineage/components/NoAccessIcon';
import type { OnSelectProject, ProjectNode } from '@/modules/lineage/rfTypes';
import BaseNode from './BaseNode';

const Project = ({ data, onClick }: NodeProps<ProjectNode> & { onClick: OnSelectProject }) => {
  return (
    <BaseNode
      isLink
      nodeId={data.fqid}
      nodeTitle={data.name}
      additionalTitleContent={
        canAccessProject(data.id) ? null : <NoAccessIcon classNames="tw-text-xs" />
      }
      nodeType="PRJ"
      iconName={['far', 'square-kanban']}
      onClick={() => onClick(data.name, data.id)}
      iconClassName="tw-text-neutral-400 tw-fill-neutral-400"
      backgroundColor="tw-bg-pink-200"
    />
  );
};

export default Project;
