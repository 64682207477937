import React from 'react';
import type { BadgeProps } from '@keboola/design';
import { Badge } from '@keboola/design';

type Props = {
  placement?: BadgeProps['placement'];
  className?: string;
};

const ConfigurationDisabledLabel = (props: Props) => {
  return (
    <Badge variant="purple" className={props.className} placement={props.placement}>
      Disabled
    </Badge>
  );
};

export default ConfigurationDisabledLabel;
