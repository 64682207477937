import React from 'react';
import { Tooltip } from '@keboola/design';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import RouterLink from '@/react/common/RouterLink';

/*
  Creates link depending on component type
  - Link to current SPA page if UI is present
  - Disabled link if UI is not prepared at all
*/
const ComponentConfigurationLink = ({
  componentId,
  configId,
  rowId,
  isDeleted = false,
  isDisabled = false,
  hasFlows,
  className,
  query,
  hash,
  routeState,
  onClick,
  children,
  openInNewTab,
}: {
  componentId: string;
  configId: string;
  rowId?: string;
  isDeleted?: boolean;
  isDisabled?: boolean;
  hasFlows?: boolean;
  className?: string;
  query?: Record<string, string>;
  hash?: string;
  routeState?: object;
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  openInNewTab?: boolean;
}) => {
  if (isDeleted) {
    const tooltip = !!rowId
      ? 'This configuration row has been deleted.'
      : 'This configuration has been deleted.';

    return (
      <Tooltip tooltip={tooltip} placement="top" type="explanatory" triggerClassName={className}>
        {children}
      </Tooltip>
    );
  }

  const linkParams = resolveRouterLinkParams(componentId, configId, rowId, hasFlows);

  if (!linkParams) {
    return <span className={className}>{children}</span>;
  }

  return (
    <RouterLink
      className={className}
      to={linkParams.to}
      params={linkParams.params}
      query={query}
      onClick={onClick}
      hash={hash}
      disabled={isDisabled}
      state={routeState}
      openInNewTab={openInNewTab}
    >
      {children}
    </RouterLink>
  );
};

export default ComponentConfigurationLink;
