import React from 'react';
import type { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard, Tooltip } from '@keboola/design';
import classNames from 'classnames';
import { capitalize } from 'underscore.string';

import Loader from '@/react/common/Loader';

type Props = {
  readOnly: boolean;
  name: string;
  value?: ReactNode;
  deleteVariableFn: () => void;
  startEditingFn: () => void;
  isDeleting: boolean;
  nameOnly?: boolean;
  clipboardText?: string;
  entity: string;
};

const Filled = ({
  readOnly,
  name,
  value = 'Default value not set',
  deleteVariableFn,
  startEditingFn,
  isDeleting,
  nameOnly,
  clipboardText,
  entity,
}: Props) => {
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => {
        if (!isDeleting) {
          startEditingFn();
        }
      }}
      className={classNames('flex-container variable-static', {
        clickable: !isDeleting,
      })}
    >
      <div className="variable-name tw-break-normal">
        <FontAwesomeIcon icon="code" fixedWidth className="icon-addon-right" />
        {name}
      </div>
      {!nameOnly && (
        <div className="variable-value overflow-break-anywhere">
          {value === '' ? <code>EMPTY STRING</code> : value}
        </div>
      )}
      {!readOnly && clipboardText && (
        <div className="variable-actions no-wrap">
          <Clipboard
            inline={false}
            tooltipText={`Copy ${capitalize(entity)}`}
            text={clipboardText}
            tooltipPlacement="top"
            stopEventPropagation
            className="hover:tw-text-secondary-500"
            btnClassName="btn-link text-muted"
          />
          <Tooltip placement="top" tooltip={`Edit ${capitalize(entity)}`}>
            <Button
              className="text-muted"
              bsStyle="link"
              disabled={isDeleting}
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                startEditingFn();
              }}
            >
              <FontAwesomeIcon icon="pen" fixedWidth />
            </Button>
          </Tooltip>
          <Tooltip placement="top" tooltip={`Delete ${capitalize(entity)}`}>
            <Button
              className="text-muted"
              bsStyle="link"
              disabled={isDeleting}
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                deleteVariableFn();
              }}
            >
              {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Filled;
