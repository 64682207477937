import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { isObsoleteComponent } from '@/modules/trash/utils';
import FlowsValidationBox from '@/react/common/FlowsValidationBox';

type Props = {
  confirmationMsg: string;
  configName?: string;
  componentId: string;
  config: Map<string, any>;
  flows: Map<string, any>;
};

const DeleteConfigConfirmBody = ({
  confirmationMsg,
  configName,
  componentId,
  flows,
  config,
}: Props) => (
  <>
    <p>
      {confirmationMsg}
      {configName ? <strong> {configName}</strong> : ''}?
    </p>
    {isObsoleteComponent(componentId) && (
      <p>
        <FontAwesomeIcon icon="triangle-exclamation" className="icon-addon-right" />
        This configuration can&apos;t be restored.
      </p>
    )}
    <FlowsValidationBox flows={flows} configurations={[{ componentId, config }]} />
  </>
);

export default DeleteConfigConfirmBody;
