import React from 'react';
import type { Map } from 'immutable';
import _ from 'underscore';

import { addNotification, removeNotification } from '@/modules/notifications/actions';
import {
  getNotificationTolerance,
  updateNotificationTolerance,
} from '@/modules/notifications/helpers';
import Select from '@/react/common/Select';

type Props = {
  savingType: string | null;
  isReadOnly: boolean;
  email: string;
  notification: Map<string, any>;
  onChange: (type: string, callback: () => Promise<void>) => void;
};

const ToleranceInput = ({ savingType, isReadOnly, email, notification, onChange }: Props) => {
  const type = `tolerance-${email}`;

  const handleToleranceChange = (
    type: string,
    notification: Map<string, any>,
    tolerance: string,
  ) => {
    const updatedNotification = updateNotificationTolerance(notification, tolerance)
      .delete('id')
      .toJS();

    onChange(type, () => {
      return addNotification(updatedNotification).then(() => {
        return removeNotification(notification.get('id'));
      });
    });
  };

  return (
    <>
      <Select
        allowCreate
        clearable={false}
        className="w-175"
        menuPlacement="top"
        isLoading={savingType === type}
        disabled={isReadOnly || savingType === type}
        promptTextCreator={(label) => `Set to ${label}%`}
        isValidNewOption={(inputValue) => /^\d+$/.test(inputValue.trim())}
        options={_.range(100, 0, -10).map((value) => ({
          value: value.toString(),
          label: value.toString(),
        }))}
        value={getNotificationTolerance(notification) || ''}
        onChange={(tolerance: string) => handleToleranceChange(type, notification, tolerance)}
      />
      <span className="no-wrap ml-1 mr-2">% longer</span>
    </>
  );
};

export default ToleranceInput;
