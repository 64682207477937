import type { DurationUnitType } from 'dayjs/plugin/duration';

import date from '@/date';

export const prepareExpirationOption = (value: number, unit?: DurationUnitType) => {
  return { value, label: !!unit ? date.duration(value, unit).humanize() : value };
};

export const humanitazeExpiration = (timeoutInSeconds: number) => {
  const duration = date.duration(timeoutInSeconds, 'second');

  if (duration.asDays() >= 30) {
    return duration.humanize();
  }

  if (duration.asDays() >= 1) {
    return duration.format(duration.hours() > 0 ? 'D[d] H[h]' : 'D[d]');
  }

  if (duration.asHours() >= 1) {
    return duration.format(duration.minutes() > 0 ? 'H[h] m[m]' : 'H[h]');
  }

  if (duration.asMinutes() >= 1) {
    return duration.format(duration.seconds() > 0 ? 'm[m] s[s]' : 'm[m]');
  }

  return duration.format('s[s]');
};
