import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import Confirm from '@/react/common/Confirm';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import { humanitazeExpiration } from '@/react/common/TimeoutControl/helpers';
import TimeoutControl from '@/react/common/TimeoutControl/TimeoutControl';

const DEFAULT_PROCESS_TIMEOUT = 3600;

type Props = {
  configuration: Map<string, any>;
  component: Map<string, any>;
  readOnly: boolean;
  mode?: 'menuitem';
};

const ProcessTimeoutModal = (props: Props) => {
  const currentTimeout =
    props.configuration.getIn(['configuration', 'runtime', 'process_timeout']) ||
    props.component.getIn(['data', 'process_timeout']) ||
    DEFAULT_PROCESS_TIMEOUT;

  const [timeout, setTimeout] = React.useState(currentTimeout);
  const [showCustomExpiration, setCustomExpiration] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      props.component.get('id'),
      props.configuration.get('id'),
      {
        configuration: JSON.stringify(
          props.configuration
            .get('configuration')
            .setIn(['runtime', 'process_timeout'], timeout)
            .toJS(),
        ),
      },
      'Change process timeout',
    ).finally(() => setIsLoading(false));
  };

  const handleSetTimeout = (value: number, customExpiration: boolean) => {
    setTimeout(value);
    setCustomExpiration(customExpiration);
  };

  const commonProps = {
    closeAfterResolve: true,
    icon: 'gear' as IconProp,
    title: 'Set timeout',
    buttonLabel: 'Set timeout',
    buttonType: 'primary',
    isLoading,
    isDisabled: props.readOnly,
    isDisabledConfirmButton: !timeout,
    ...(props.readOnly && { disabledReason: READ_ONLY_TOOLTIP_MESSAGE }),
    onConfirm: handleSubmit,
    text: (
      <>
        <p>Set the timeout for the entire job.</p>
        <TimeoutControl
          isDisabled={props.readOnly}
          value={timeout}
          onChange={handleSetTimeout}
          showCustom={showCustomExpiration}
        />
      </>
    ),
    onEnter: () => setTimeout(currentTimeout),
    onHide: () => setTimeout(currentTimeout),
  };

  const label = (
    <>
      <FontAwesomeIcon icon="gear" fixedWidth />
      Timeout: <span className="tw-font-medium">{humanitazeExpiration(currentTimeout)}</span>
    </>
  );

  if (props.mode === 'menuitem') {
    return <ConfirmMenuItem {...commonProps}>{label}</ConfirmMenuItem>;
  }

  return (
    <Confirm
      {...commonProps}
      childrenRootElement="button"
      childrenRootElementClass="btn btn-link btn-block"
    >
      {label}
    </Confirm>
  );
};

export default ProcessTimeoutModal;
