import React from 'react';
import { cn, Skeleton } from '@keboola/design';

type Props = {
  className?: string;
};

const AnimationBar = ({ className }: Props) => (
  <div className={cn('tw-flex tw-flex-col tw-gap-2', className)}>
    <Skeleton height="xs" className="tw-w-60" />
    <Skeleton height="xs" className="tw-w-40" />
  </div>
);

export default AnimationBar;
