import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@keboola/design';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE } from '@/constants/features';
import { canManageBuckets, canRegisterExternalBucket } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { createBucket } from '@/modules/storage/actions';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import CreateBucketModal from './CreateBucketModal';
import RegisterExternalBucketModal from './RegisterExternalBucketModal';

const HeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, BucketsStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      allBuckets: BucketsStore.getAll(),
      isCreatingBucket: BucketsStore.isCreatingBucket(),
      hasSnowflakeRegisterExternalSecureDataShare: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE,
      ),
    };
  },

  getInitialState() {
    return {
      showCreateBucketModal: false,
      showRegisterExternalBucketModal: false,
    };
  },

  render() {
    if (
      !canManageBuckets(this.state.sapiToken) &&
      !canRegisterExternalBucket(this.state.sapiToken)
    ) {
      return null;
    }

    const backend = this.state.sapiToken.getIn(['owner', 'defaultBackend']);

    return (
      <>
        {this.renderButtons(backend)}
        {this.renderModals(backend)}
      </>
    );
  },

  renderButtons(backend) {
    return (
      <ButtonGroup>
        {canRegisterExternalBucket(this.state.sapiToken) && (
          <Button
            variant="secondary"
            disabled={this.state.isCreatingBucket}
            onClick={() => this.setState({ showRegisterExternalBucketModal: true })}
            className={NEW_ENTITY_BUTTON}
          >
            Register your {capitalize(backend)} Dataset
          </Button>
        )}
        {canManageBuckets(this.state.sapiToken) && (
          <Button
            className={NEW_ENTITY_BUTTON}
            disabled={this.state.isCreatingBucket}
            onClick={() =>
              this.setState({
                showCreateBucketModal: true,
              })
            }
          >
            <FontAwesomeIcon icon="plus" />
            Create bucket
          </Button>
        )}
      </ButtonGroup>
    );
  },

  renderModals(backend) {
    return (
      <>
        <RegisterExternalBucketModal
          backend={backend}
          openModal={this.state.showRegisterExternalBucketModal}
          onHide={() => this.setState({ showRegisterExternalBucketModal: false })}
          isSaving={this.state.isCreatingBucket}
          buckets={this.state.allBuckets}
          hasSnowflakeRegisterExternalSecureDataShare={
            this.state.hasSnowflakeRegisterExternalSecureDataShare
          }
        />
        <CreateBucketModal
          openModal={this.state.showCreateBucketModal}
          sapiToken={this.state.sapiToken}
          onSubmit={createBucket}
          onHide={() => this.setState({ showCreateBucketModal: false })}
          isSaving={this.state.isCreatingBucket}
          buckets={this.state.allBuckets}
        />
      </>
    );
  },
});

export default HeaderButtons;
