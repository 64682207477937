import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import Sortable from 'react-sortablejs';
import { URLS } from '@keboola/constants';
import { Alert, Link } from '@keboola/design';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { FEATURE_TRANSFORMATIONS_MIXED_BACKENDS } from '@/constants/features';
import { defaultOptions } from '@/constants/sortable';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import mappingDefinitions from '@/modules/components/utils/mappingDefinitions';
import TransformationsActionCreators from '@/modules/transformations/ActionCreators';
import { transformationType } from '@/modules/transformations/Constants';
import BackendVersionWarning from '@/modules/transformations/react/components/backend-version/Warning';
import DupliciteOutputMappingWarning from '@/modules/transformations/react/components/duplicite-output-mapping/Warning';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import ApplicationStore from '@/stores/ApplicationStore';
import contactSupport from '@/utils/contactSupport';
import AddInputMapping from './AddInputMapping';
import AddOutputMapping from './AddOutputMapping';
import DependentTransformations from './DependentTransformations';
import InputMappingRow from './InputMappingRow';
import OutputMappingRow from './OutputMappingRow';
import Packages from './Packages';
import Queries from './Queries';
import Requires from './Requires';
import SavedFiles from './SavedFiles';
import Scripts from './Scripts';

const TransformationDetailStatic = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    bucket: PropTypes.object.isRequired,
    transformation: PropTypes.object.isRequired,
    editingFields: PropTypes.object.isRequired,
    isEditingValid: PropTypes.bool.isRequired,
    isQueriesProcessing: PropTypes.bool.isRequired,
    transformations: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
    transformationId: PropTypes.string.isRequired,
    showDetails: PropTypes.bool.isRequired,
    highlightQueryNumber: PropTypes.number,
  },

  // TODO move this to component definition UI Options
  openRefine: {
    inputMappingDefinitions: fromJS([
      {
        label: 'Load data from table',
        destination: 'data.csv',
      },
    ]),
    outputMappingDefinitions: fromJS([
      {
        label: 'Save result to table',
        source: 'data.csv',
      },
    ]),
  },

  render() {
    if (!this.props.showDetails) {
      return (
        <Alert variant="warning" className="tw-mb-5">
          This transformation is not supported in the UI.
        </Alert>
      );
    }

    return (
      <>
        {this.renderBackendVersionWarning()}
        {this.renderDuplicateWarning()}
        {this.renderDescription()}
        {this.renderOpenRefineInfo()}
        {this.renderRequires()}
        {this.renderInputMapping()}
        {this.renderOutputMapping()}
        {this.renderPackages()}
        <CatchUnsavedChanges
          isDirty={this.props.editingFields.get('queriesChanged', false)}
          onSave={() => {
            if (this.props.transformation.get('backend') === 'docker') {
              return this.editScriptsSubmit();
            }
            return this.editQueriesSubmit();
          }}
          onDirtyLeave={() => {
            if (this.props.transformation.get('backend') === 'docker') {
              return this.editScriptsCancel();
            }
            return this.editQueriesCancel();
          }}
        >
          {this.renderCodeEditor()}
        </CatchUnsavedChanges>
      </>
    );
  },

  renderBackendVersionWarning() {
    if (!this.props.transformation.has('imageTag')) {
      return null;
    }

    return <BackendVersionWarning />;
  },

  renderDuplicateWarning() {
    return (
      <DupliciteOutputMappingWarning
        transformation={this.props.transformation}
        transformations={this.props.transformations}
        bucketId={this.props.bucketId}
      />
    );
  },

  renderOpenRefineInfo() {
    if (!this._isOpenRefineTransformation()) {
      return null;
    }

    return (
      <div className="box">
        <div className="box-content">
          <h2>OpenRefine Beta Warning</h2>
          <p>OpenRefine transformations are now in public beta.</p>
          <p>Remember that things may change before the transformations make it to production.</p>
          <p>
            If you encounter any errors, please{' '}
            <button className="btn btn-link btn-link-inline" onClick={() => contactSupport()}>
              contact us
            </button>{' '}
            or read more in the{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/transformations/openrefine/`}>
              documentation
            </Link>
            .
          </p>
        </div>
      </div>
    );
  },

  renderDescription() {
    return (
      <ComponentDescription
        key={`${this.props.bucketId}-${this.props.transformationId}`}
        componentId="transformation"
        configId={this.props.bucketId}
        rowId={this.props.transformationId}
        placeholderEntity="Transformation"
      />
    );
  },

  renderRequires() {
    const backend = this.props.transformation.get('backend');
    const hasMixedBackends = ApplicationStore.hasCurrentProjectFeature(
      FEATURE_TRANSFORMATIONS_MIXED_BACKENDS,
    );
    const supportedType = [
      transformationType.PYTHON,
      transformationType.JULIA,
      transformationType.R,
    ].includes(this.props.transformation.get('type'));

    if (backend === 'docker' && (!supportedType || !hasMixedBackends)) {
      return null;
    }

    return (
      <div className="box info-row">
        <div className="info-row-section">
          <Requires
            transformation={this.props.transformation}
            transformations={this.props.transformations}
            isSaving={this.props.pendingActions.has('save-requires')}
            disabled={this.isDisabled()}
            requires={this.props.editingFields.get(
              'requires',
              this.props.transformation.get('requires'),
            )}
            bucketId={this.props.bucketId}
            onEditChange={(newValue) => {
              TransformationsActionCreators.updateTransformationEditingField(
                this.props.bucketId,
                this.props.transformationId,
                'requires',
                newValue,
              );
              return TransformationsActionCreators.saveTransformationEditingField(
                this.props.bucketId,
                this.props.transformationId,
                'requires',
              );
            }}
          />
        </div>
        <div className="info-row-section">
          <DependentTransformations
            transformations={this.props.transformations}
            transformation={this.props.transformation}
            bucket={this.props.bucket}
          />
        </div>
      </div>
    );
  },

  renderInputMapping() {
    const isInputEmpty = this._getInputMappingValue().isEmpty();
    const isOutputEmpty = this._getOutputMappingValue().isEmpty();

    return (
      <div
        className={classnames('box', {
          'no-mapping': isInputEmpty,
          'bottom-radius': isInputEmpty && !isOutputEmpty,
        })}
      >
        <div className="box-header above-table-with-buttons old-transformation">
          <h2 className="box-title">Input Mapping</h2>
          {!this.props.readOnly && !this._isOpenRefineTransformation() && (
            <AddInputMapping
              tables={this.props.tables}
              buckets={this.props.buckets}
              transformation={this.props.transformation}
              bucket={this.props.bucket}
              mapping={this.props.editingFields.get('new-input-mapping', Map())}
              otherDestinations={this._inputMappingDestinations()}
            />
          )}
        </div>
        {!isInputEmpty && <div className="box-content p-0">{this.renderInputMappingContent()}</div>}
      </div>
    );
  },

  renderInputMappingContent() {
    return (
      <span className="table table-hover overflow-break-anywhere">
        <Sortable
          className="tbody"
          options={{ ...defaultOptions, disabled: this.props.readOnly }}
          onChange={(order, sortable, event) => {
            TransformationsActionCreators.updateInputMappingOrder(
              this.props.bucketId,
              this.props.transformationId,
              event.oldIndex,
              event.newIndex,
            );
          }}
        >
          {this._getInputMappingValue()
            .map((input, key) => {
              let definition;
              if (this._isOpenRefineTransformation()) {
                definition = mappingDefinitions.findInputMappingDefinition(
                  this.openRefine.inputMappingDefinitions,
                  input,
                );
              }
              return (
                <InputMappingRow
                  key={`${input.get('source', input.getIn(['source_search', 'value']))}-${key}`}
                  readOnly={this.props.readOnly}
                  transformation={this.props.transformation}
                  bucket={this.props.bucket}
                  inputMapping={input}
                  tables={this.props.tables}
                  buckets={this.props.buckets}
                  editingInputMapping={this.props.editingFields.get(`input-${key}`, input)}
                  editingId={`input-${key}`}
                  mappingIndex={key.toString()}
                  pendingActions={this.props.pendingActions}
                  otherDestinations={this._inputMappingDestinations(key)}
                  definition={definition}
                  disabled={this.isDisabled()}
                />
              );
            })
            .toArray()}
        </Sortable>
      </span>
    );
  },

  renderOutputMapping() {
    const isOutputEmpty = this._getOutputMappingValue().isEmpty();

    return (
      <div className={classnames('box', { 'no-mapping bottom-radius': isOutputEmpty })}>
        <div className="box-header above-table-with-buttons old-transformation">
          <h2 className="box-title">Output Mapping</h2>
          {!this.props.readOnly && !this._isOpenRefineTransformation() && (
            <AddOutputMapping
              tables={this.props.tables}
              buckets={this.props.buckets}
              transformation={this.props.transformation}
              bucket={this.props.bucket}
              mapping={this.props.editingFields.get('new-output-mapping', Map())}
            />
          )}
        </div>
        {!isOutputEmpty && (
          <div className="box-content p-0">{this.renderOutputMappingContent()}</div>
        )}
      </div>
    );
  },

  renderOutputMappingContent() {
    return (
      <span className="table table-hover overflow-break-anywhere">
        <span className="tbody">
          {this._getOutputMappingValue()
            .map((output, key) => {
              let definition;
              if (this._isOpenRefineTransformation()) {
                definition = mappingDefinitions.findOutputMappingDefinition(
                  this.openRefine.outputMappingDefinitions,
                  output,
                );
              }
              return (
                <OutputMappingRow
                  key={key}
                  readOnly={this.props.readOnly}
                  transformation={this.props.transformation}
                  bucket={this.props.bucket}
                  outputMapping={output}
                  editingOutputMapping={this.props.editingFields.get(`input-${key}`, output)}
                  editingId={`input-${key}`}
                  mappingIndex={key}
                  tables={this.props.tables}
                  pendingActions={this.props.pendingActions}
                  buckets={this.props.buckets}
                  definition={definition}
                  otherOutputMappings={this.props.transformation
                    .get('output')
                    .filter(
                      (otherOutputMapping, otherOutputMappingKey) => otherOutputMappingKey !== key,
                    )}
                  disabled={this.isDisabled()}
                />
              );
            })
            .toArray()}
        </span>
      </span>
    );
  },

  renderPackages() {
    if (
      this.props.transformation.get('backend') !== 'docker' ||
      this.props.transformation.get('type') === 'openrefine'
    ) {
      return null;
    }

    return (
      <>
        <Packages
          transformationType={this.props.transformation.get('type')}
          disabled={this.isDisabled()}
          packages={this.props.editingFields.get(
            'packages',
            this.props.transformation.get('packages', List()),
          )}
          onEditChange={(newValue) => {
            TransformationsActionCreators.updateTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'packages',
              newValue,
            );

            return TransformationsActionCreators.saveTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'packages',
            );
          }}
        />
        <SavedFiles
          isSaving={this.props.pendingActions.has('save-tags')}
          disabled={this.isDisabled()}
          tags={this.props.editingFields.get('tags', this.props.transformation.get('tags', List()))}
          onEditChange={(newValue) => {
            TransformationsActionCreators.updateTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'tags',
              newValue,
            );
            return TransformationsActionCreators.saveTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'tags',
            );
          }}
        />
      </>
    );
  },

  renderCodeEditor() {
    if (this.props.transformation.get('backend') === 'docker') {
      return (
        <Scripts
          transformation={this.props.transformation}
          isSaving={this.props.pendingActions.has('save-queries')}
          disabled={this.isDisabled()}
          scripts={this.props.editingFields.get(
            'queriesString',
            this.props.transformation.get('queriesString'),
          )}
          isEditingValid={this.props.isEditingValid}
          isChanged={this.props.editingFields.get('queriesChanged', false)}
          changeDescription={this.props.editingFields.get('description', '')}
          onDescriptionChange={this.onDescriptionChange}
          onEditCancel={this.editScriptsCancel}
          onEditChange={this.editScriptsChange}
          onEditSubmit={this.editScriptsSubmit}
        />
      );
    }

    return (
      <Queries
        transformation={this.props.transformation}
        isSaving={this.props.pendingActions.has('save-queries')}
        disabled={this.isDisabled()}
        queries={this.props.editingFields.get(
          'queriesString',
          this.props.transformation.get('queriesString'),
        )}
        splitQueries={this.props.editingFields.get(
          'splitQueries',
          this.props.transformation.get('queries'),
        )}
        isQueriesProcessing={this.props.isQueriesProcessing}
        isChanged={this.props.editingFields.get('queriesChanged', false)}
        highlightQueryNumber={this.props.highlightQueryNumber}
        changeDescription={this.props.editingFields.get('description', '')}
        onDescriptionChange={this.onDescriptionChange}
        onEditCancel={this.editQueriesCancel}
        onEditChange={this.editQueriesChange}
        onEditSubmit={this.editQueriesSubmit}
      />
    );
  },

  _isOpenRefineTransformation() {
    return (
      this.props.transformation.get('backend') === 'docker' &&
      this.props.transformation.get('type') === 'openrefine'
    );
  },

  _getInputMappingValue() {
    const value = this.props.transformation.get('input', List());
    if (this._isOpenRefineTransformation()) {
      return mappingDefinitions.getInputMappingValue(
        this.openRefine.inputMappingDefinitions,
        value,
      );
    }
    return value;
  },

  _getOutputMappingValue() {
    const value = this.props.transformation.get('output', List());
    if (this._isOpenRefineTransformation()) {
      return mappingDefinitions.getOutputMappingValue(
        this.openRefine.outputMappingDefinitions,
        value,
      );
    }
    return value;
  },

  _inputMappingDestinations(exclude) {
    return this._getInputMappingValue()
      .filter((mapping, key) => key !== exclude)
      .map((mapping) => mapping.get('destination').toLowerCase());
  },

  isDisabled() {
    return (
      this.props.readOnly || this.props.pendingActions.delete('queries-processing').count() > 0
    );
  },

  onDescriptionChange(description) {
    return TransformationsActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'description',
      description,
    );
  },

  editScriptsCancel() {
    TransformationsActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
    );
    return TransformationsActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesChanged',
    );
  },

  editQueriesCancel() {
    TransformationsActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'splitQueries',
    );
    TransformationsActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
    );
    return TransformationsActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesChanged',
    );
  },

  editScriptsChange(newValue) {
    TransformationsActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
      newValue,
    );
    if (!this.props.editingFields.get('queriesChanged', false)) {
      return TransformationsActionCreators.updateTransformationEditingField(
        this.props.bucketId,
        this.props.transformationId,
        'queriesChanged',
        true,
      );
    }
  },

  editQueriesChange(newValue) {
    TransformationsActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
      newValue,
    );
    TransformationsActionCreators.updateTransformationEditingFieldQueriesString(
      this.props.bucketId,
      this.props.transformationId,
      newValue,
    );
    if (!this.props.editingFields.get('queriesChanged', false)) {
      return TransformationsActionCreators.updateTransformationEditingField(
        this.props.bucketId,
        this.props.transformationId,
        'queriesChanged',
        true,
      );
    }
  },

  editScriptsSubmit() {
    return TransformationsActionCreators.saveTransformationScript(
      this.props.bucketId,
      this.props.transformationId,
    );
  },

  editQueriesSubmit() {
    return TransformationsActionCreators.saveTransformationQueries(
      this.props.bucketId,
      this.props.transformationId,
    );
  },
});

export default TransformationDetailStatic;
