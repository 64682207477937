import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { canWriteBucket } from '@/modules/admin/privileges';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { updateTable } from '@/modules/storage/actions';
import { validateTableName } from '@/modules/storage/helpers';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import nextTick from '@/utils/nextTick';
import EditNameWithDevBranchWarningModal from './EditNameWithDevBranchWarningModal';

const TableNameEdit = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, TablesStore)],

  propTypes: {
    tableName: PropTypes.string.isRequired,
    bucketId: PropTypes.string.isRequired,
  },

  getInitialState() {
    const table = TablesStore.getTableByName(this.props.tableName, this.props.bucketId);

    return {
      value: table.get('displayName', this.props.tableName),
      isEditingInModal: false,
      isEditing: false,
      isSaving: false,
      error: null,
    };
  },

  getStateFromStores() {
    const table = TablesStore.getTableByName(this.props.tableName, this.props.bucketId);

    return {
      table,
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      readOnly: !canWriteBucket(ApplicationStore.getSapiToken(), table.get('bucket', Map())),
      originalValue: table.get('displayName', this.props.tableName),
      bucketTables: TablesStore.getAll().filter((table) => {
        return (
          table.getIn(['bucket', 'id']) === this.props.bucketId &&
          table.get('name') !== this.props.tableName
        );
      }),
    };
  },

  render() {
    return (
      <>
        <FontAwesomeIcon
          icon="table"
          fixedWidth
          style={{ fontSize: '0.75em' }}
          className="text-muted icon-addon-right"
        />
        {this.renderTableName()}
      </>
    );
  },

  renderTableName() {
    if (this.state.readOnly) {
      return this.state.value;
    }

    if (this.state.isEditingInModal) {
      return (
        <>
          {this.state.originalValue}
          <EditNameWithDevBranchWarningModal
            entity="tableName"
            name={this.state.value}
            onSubmit={this.handleEditSubmit}
            onChange={this.handleEditChange}
            onHide={() => nextTick(this.handleEditCancel)}
            isSaving={this.state.isSaving}
            error={this.state.error}
          />
        </>
      );
    }

    return (
      <span className={classNames({ 'input-error': !!this.state.error })}>
        <Tooltip
          placement="bottom"
          type="explanatory"
          tooltip={this.state.error}
          forceShow={!!this.state.error}
          forceHide={!this.state.error}
        >
          <InlineEditTextInput
            isValid={!this.state.error}
            isChanged={this.state.value !== this.state.originalValue}
            text={this.state.value}
            isSaving={this.state.isSaving}
            isEditing={this.state.isEditing}
            onEditStart={this.handleEditStart}
            onEditCancel={this.handleEditCancel}
            onEditChange={this.handleEditChange}
            onEditSubmit={this.handleEditSubmit}
          />
        </Tooltip>
      </span>
    );
  },

  handleEditStart() {
    if (
      this.state.isDevModeActive &&
      !this.state.hasProtectedDefaultBranch &&
      !isCreatedInDevBranch(this.state.table.get('bucket'))
    ) {
      return this.setState({ isEditingInModal: true, error: null });
    }

    this.setState({ isEditing: true, error: null });
  },

  handleEditChange(newValue) {
    this.setState({
      value: newValue,
      error: validateTableName(newValue, this.state.bucketTables, { strict: false }),
    });
  },

  handleEditCancel() {
    this.setState({
      error: null,
      isSaving: false,
      isEditing: false,
      isEditingInModal: false,
      value: this.state.originalValue,
    });
  },

  handleEditSubmit() {
    this.setState({ error: null, isSaving: true });
    return updateTable(this.state.table.get('id'), { displayName: this.state.value })
      .catch((error) => this.setState({ error }))
      .finally(() => this.setState({ isSaving: false, isEditing: false }));
  },
});

export default TableNameEdit;
