import React from 'react';
import { Badge } from '@keboola/design';

type Props = {
  className?: string;
};

const ConfigurationFromTemplateLabel = (props: Props) => {
  return <Badge text="Template" variant="purple" className={props.className} />;
};

export default ConfigurationFromTemplateLabel;
