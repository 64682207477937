import React from 'react';
import later from '@breejs/later';
import type { List } from 'immutable';
import _ from 'underscore';
import { lpad } from 'underscore.string';

import Select from '@/react/common/Select';

const PERIOD_OPTIONS = [
  { value: later.hour.name, label: 'Hour' },
  { value: later.day.name, label: 'Day' },
  { value: later.dayOfWeek.name, label: 'Week' },
  { value: later.month.name, label: 'Month' },
  { value: later.year.name, label: 'Year' },
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

type Props = {
  M: number[];
  D: number[];
  d: number[];
  h: number[];
  m: number[];
  period: string;
  onChange: (key: string, value: List<number>) => void;
  onPeriodChange: (value: string) => void;
  timezone: string;
};

class CronSchedulerInputs extends React.Component<Props> {
  render() {
    return (
      <>
        <div className="form-group">
          <label>Every</label>
          <div>{this._periodSelect()}</div>
        </div>
        {this.renderCurrentPeriod()}
      </>
    );
  }

  renderCurrentPeriod() {
    const currentPeriod = this.props.period;

    switch (currentPeriod) {
      case later.year.name:
        return (
          <>
            <div className="form-group">
              <label>on the</label>
              {this._daySelect()}
            </div>
            <div className="form-group">
              <label>of</label>
              {this._monthSelect()}
            </div>
            {this._hoursAndMinutes()}
          </>
        );

      case later.month.name:
        return (
          <>
            <div className="form-group">
              <label>on the</label>
              {this._daySelect()}
            </div>
            {this._hoursAndMinutes()}
          </>
        );

      case later.dayOfWeek.name:
        return (
          <>
            <div className="form-group">
              <label>on</label>
              {this._dayOfWeekSelect()}
            </div>
            {this._hoursAndMinutes()}
          </>
        );

      case later.day.name:
        return this._hoursAndMinutes();

      default:
        return (
          <div className="form-group">
            <label>at</label>
            {this._minuteSelect()}
          </div>
        );
    }
  }

  _hoursAndMinutes() {
    return (
      <>
        <div className="form-group">
          <label>at</label>
          {this._hourSelect()}
          <p className="help-block">Hours</p>
        </div>
        <div className="form-group">
          {this._minuteSelect()}
          <p className="help-block">Minutes</p>
        </div>
      </>
    );
  }

  _periodSelect() {
    return (
      <Select
        clearable={false}
        options={PERIOD_OPTIONS}
        value={this.props.period}
        onChange={this.props.onPeriodChange}
      />
    );
  }

  _daySelect() {
    return (
      <Select
        multi
        placeholder="-- Every Day --"
        value={this.props.D}
        options={_.range(0, 31).map((value) => ({ value: value + 1, label: `${value + 1}.` }))}
        onChange={(values) => this.props.onChange('D', values)}
      />
    );
  }

  _monthSelect() {
    return (
      <Select
        multi
        placeholder="-- Every Month --"
        value={this.props.M}
        options={MONTHS.map((value, key) => ({ value: key + 1, label: value }))}
        onChange={(values) => this.props.onChange('M', values)}
      />
    );
  }

  _dayOfWeekSelect() {
    return (
      <Select
        multi
        placeholder="-- Every Week Day --"
        value={this.props.d}
        options={DAYS.map((value, key) => ({ value: key + 1, label: value }))}
        onChange={(values) => this.props.onChange('d', values)}
      />
    );
  }

  _hourSelect() {
    return (
      <Select
        multi
        placeholder="-- Every Hour --"
        value={this.props.h}
        options={_.range(0, 24).map((value) => ({ value, label: lpad(String(value), 2, '0') }))}
        onChange={(values) => this.props.onChange('h', values)}
      />
    );
  }

  _minuteSelect() {
    return (
      <Select
        multi
        placeholder="-- Every Minute --"
        value={this.props.m}
        options={_.range(0, 60).map((value) => ({ value, label: lpad(String(value), 2, '0') }))}
        onChange={(values) => this.props.onChange('m', values)}
      />
    );
  }
}

export default CronSchedulerInputs;
