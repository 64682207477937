import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@keboola/design';
import type { Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import type { List, Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import MarkedText from '@/react/common/MarkedText';
import SortIcon from '@/react/common/SortIcon';
import Truncated from '@/react/common/Truncated';
import hasSelections from '@/utils/hasSelections';

type DataRow = Row<{ id: string; name: string }>;

const AccountsSelector = ({
  isLoading,
  accounts,
  savedAccounts,
  selectedAccounts,
  searchQuery,
  selectAccount,
  error,
}: {
  isLoading: boolean;
  accounts: Map<string, any>;
  error: string | null;
  savedAccounts: Map<string, any>;
  selectedAccounts: List<any>;
  searchQuery: string;
  selectAccount: (account: any, selected: boolean) => void;
}) => {
  const isSaved = React.useCallback(
    (row: DataRow) => savedAccounts.has(row.original.id),
    [savedAccounts],
  );
  const isSelected = React.useCallback(
    (row: DataRow) => selectedAccounts.includes(row.original.id),
    [selectedAccounts],
  );

  const columns = React.useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: ({ table }: any) => {
          const rows = table.getRowModel().rows;
          const isSelectable = rows.some((row: DataRow) => !isSaved(row));
          const isAllSelected = !accounts.isEmpty() && rows.every(isSelected);
          const isSomeSelected = !isAllSelected && rows.some(isSelected);

          return (
            <>
              {!isSelectable ? (
                <Checkbox className="tw-mr-3" disabled tooltip="All accounts are already added" />
              ) : (
                <Checkbox
                  className="tw-mr-3"
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                  onChange={() => {
                    selectAccount(
                      accounts.map((account) => account.get('id')).toArray(),
                      !isAllSelected && !isSomeSelected,
                    );
                  }}
                />
              )}
              Account Name
            </>
          );
        },
        cell: ({ row }: { row: DataRow }) => {
          return (
            <div className="tw-inline-flex tw-items-center">
              {isSaved(row) ? (
                <Checkbox disabled tooltip="Account is already added" />
              ) : (
                <Checkbox
                  checked={isSelected(row)}
                  onChange={() => selectAccount([row.original.id], !isSelected(row))}
                />
              )}
              <FontAwesomeIcon icon="user" className="text-muted tw-mx-3" />
              <Truncated
                tooltip={row.original.name}
                text={<MarkedText source={row.original.name} mark={searchQuery} />}
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'id',
        header: 'Account ID',
        cell: ({ row }: { row: DataRow }) => (
          <Truncated
            className="tw-text-neutral-400"
            tooltip={row.original.id}
            text={<MarkedText source={row.original.id} mark={searchQuery} />}
          />
        ),
      },
      {
        enableSorting: false,
        accessorKey: 'status',
        header: () => null,
        cell: ({ row }: { row: DataRow }) => {
          if (!isSaved(row)) {
            return null;
          }

          return (
            <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-neutral-400">Added</span>
          );
        },
      },
    ];
  }, [searchQuery, accounts, selectAccount, isSelected, isSaved]);

  const data = React.useMemo(() => {
    return accounts
      .map((account) => ({ name: account.get('name'), id: account.get('id') }))
      .toArray();
  }, [accounts]);

  const initialState = React.useMemo(() => {
    return { sorting: [{ id: 'name', desc: false }] };
  }, []);

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const rows = tableInstance.getRowModel().rows;

  return (
    <Table hover className="react-table stretch-modal">
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    <div
                      className="tw-inline-flex tw-cursor-pointer tw-items-center"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && (
                        <SortIcon
                          className="icon-addon-left"
                          isSorted={!!header.column.getIsSorted()}
                          isSortedDesc={header.column.getIsSorted() === 'desc'}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {!rows.length && error && (
          <tr className="no-hover">
            <td colSpan={3}>
              <Alert variant="error" className="tw-mt-3">
                {error}
              </Alert>
            </td>
          </tr>
        )}
        {!rows.length && !error && (
          <tr className="no-hover">
            <td colSpan={3}>
              {isLoading ? (
                <>
                  <Loader className="icon-addon-right" />
                  Loading accounts...
                </>
              ) : (
                `No account ${!!searchQuery ? 'found' : 'available'}`
              )}
            </td>
          </tr>
        )}
        {rows.map((row) => {
          const isAccountAdded = isSaved(row);

          return (
            <tr
              key={row.id}
              className={classNames({ clickable: !isAccountAdded })}
              onClick={() => {
                if (isAccountAdded || hasSelections()) {
                  return;
                }

                return selectAccount([row.original.id], !isSelected(row));
              }}
            >
              {row.getAllCells().map((cell) => {
                return (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AccountsSelector;
