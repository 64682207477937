import React from 'react';
import PropTypes from 'prop-types';
import { Badge, HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import OrchestrationTaskRunButton from '@/modules/orchestrations/react/components/OrchestrationTaskRunButton';
import TaskParametersEditModal from '@/modules/orchestrations/react/modals/TaskParametersEdit';
import Check from '@/react/common/Check';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import descriptionExcerpt from '@/utils/descriptionExcerpt';

const TasksTableRow = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    onRun: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    component: PropTypes.object,
  },

  render() {
    return (
      <tr className="hoverable-actions">
        <td>
          {this.props.component && (
            <ComponentIcon
              component={this.props.component}
              className="icon-addon-right"
              size="32"
            />
          )}
          {this.props.component ? (
            <ComponentName component={this.props.component} showType />
          ) : (
            this.props.task.get('componentUrl')
          )}
        </td>
        <td>
          {this.props.task.has('config') ? (
            <ComponentConfigurationLink
              componentId={this.props.task.get('component')}
              configId={this.props.task.getIn(['config', 'id'])}
              hasFlows={false}
            >
              {this.props.task.getIn(['config', 'name'])}
              <HelpBlock>
                <small className="overflow-break-anywhere">
                  {descriptionExcerpt(this.props.task.getIn(['config', 'description']))}
                </small>
              </HelpBlock>
            </ComponentConfigurationLink>
          ) : (
            'N/A'
          )}
        </td>
        <td>
          <Badge text={this.props.task.get('action')} variant="blue" />
        </td>
        <td>
          <Check isChecked={this.props.task.get('active', false)} />
        </td>
        <td>
          <Check isChecked={this.props.task.get('continueOnFailure', false)} />
        </td>
        <td className="pr-1">
          <RowActionDropdown>
            {!this.props.readOnly && (
              <OrchestrationTaskRunButton
                orchestration={this.props.orchestration}
                onRun={this.props.onRun}
                task={this.props.task}
              />
            )}
            <TaskParametersEditModal parameters={this.props.task.get('actionParameters').toJS()} />
          </RowActionDropdown>
        </td>
      </tr>
    );
  },
});

export default TasksTableRow;
