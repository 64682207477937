import React from 'react';
import type { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

type Props = {
  tooltip: string;
  icon?: IconProp;
  onClick: () => void;
  disabled?: boolean;
} & PropsWithChildren;

const TableIconAction = ({ tooltip, icon, children, ...buttonProps }: Props) => {
  return (
    <Tooltip placement="top" tooltip={tooltip} triggerClassName="tw-align-middle">
      <Button {...buttonProps} bsStyle="link" className={'btn-link-inline btn-link-muted'}>
        {icon ? <FontAwesomeIcon icon={icon} fixedWidth /> : children}
      </Button>
    </Tooltip>
  );
};

export default TableIconAction;
