import React, { useState } from 'react';
import type { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '../utils';

import { Collapse } from './Collapse';

type Props = PropsWithChildren<{
  defaultExpanded?: boolean;
  labelCollapse?: string;
  labelOpen?: string;
  placement?: 'top' | 'bottom';
  className?: string;
}>;

export const PanelWithDetails = ({
  defaultExpanded = false,
  labelCollapse = 'Hide details',
  labelOpen = 'Show details',
  placement = 'top',
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);

  return (
    <Collapse
      open={isOpen}
      className={cn(placement === 'bottom' ? 'tw-flex-col-reverse' : 'tw-flex-col')}
      header={
        <div
          className={cn(
            'tw-flex tw-cursor-pointer tw-items-center tw-rounded-sm tw-bg-neutral-100 tw-px-4 tw-py-3 tw-text-sm tw-text-neutral-400',
            'hover:tw-text-secondary-500',
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="tw-mr-2 tw-flex tw-shrink-0 tw-items-center">
            <FontAwesomeIcon
              icon="chevron-down"
              className={cn(
                'tw-text-xs',
                !isOpen ? '-tw-rotate-90' : placement === 'bottom' ? 'tw-rotate-180' : '',
              )}
            />
          </div>

          <div className="tw-py-0.5">{isOpen ? labelCollapse : labelOpen}</div>
        </div>
      }
    >
      <div className="tw-px-5 tw-py-3">{children}</div>
    </Collapse>
  );
};
