import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import CsvDelimiterInput from '@/react/common/CsvDelimiterInput';
import string from '@/utils/string';

class SaveSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Save Settings</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Table name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.name}
                onChange={(e) => {
                  this.props.onChange({ name: string.sanitizeKbcTableIdString(e.target.value) });
                }}
                disabled={this.props.disabled}
                placeholder="mytable"
              />
              <HelpBlock>Name of the table stored in Storage {nameWarning}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.incremental}
                onChange={(checked) => this.props.onChange({ incremental: checked })}
                disabled={this.props.disabled}
              >
                Incremental load
              </Checkbox>
              <HelpBlock>
                If incremental load is turned on, the table will be updated instead of rewritten.
                Tables with a primary key will have rows updated, tables without a primary key will
                have rows appended.
              </HelpBlock>
            </div>
          </FormGroup>
          <CsvDelimiterInput
            value={this.props.delimiter}
            disabled={this.props.disabled}
            onChange={(value) => {
              this.props.onChange({ delimiter: value });
            }}
          />
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Enclosure</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.enclosure}
                disabled={this.props.disabled}
                onChange={(e) => {
                  this.props.onChange({ enclosure: e.target.value });
                }}
                placeholder={'"'}
              />
              <HelpBlock>
                Field enclosure used in the CSV file. The default value is <code>&quot;</code>.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

SaveSettings.propTypes = {
  name: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  incremental: PropTypes.bool.isRequired,
  delimiter: PropTypes.string.isRequired,
  enclosure: PropTypes.any.isRequired,
};

export default SaveSettings;
