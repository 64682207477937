import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { cn } from '@keboola/design';
import type { NodeProps } from '@xyflow/react';
import { Controls, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';
import _ from 'underscore';

import { LINEAGE_LEGEND } from '@/constants/localStorageKeys';
import {
  calculateHighlightedColumns,
  highlightedEdges,
  highlightedEdgeStyles,
} from '@/modules/lineage/columns/columnsHelpers';
import { ColumnsHighlightingContext } from '@/modules/lineage/contexts/ColumnsHighlightingContext';
import { GraphContext } from '@/modules/lineage/contexts/GraphContext';
import { isEdgeCalculatedWithLlm } from '@/modules/lineage/helpers';
import type {
  ConfigurationNode,
  CustomEdge,
  CustomNode,
  NodeData,
  OnSelectProject,
  OnSetShownHandles,
  ProjectNode,
  ShownHandles,
  TableNode,
} from '@/modules/lineage/rfTypes';
import type { EdgeInfo } from '@/modules/lineage/types';
import { useLayoutNodes } from '@/modules/lineage/useLayoutNodes';
import useResetFocusedNode from '@/modules/lineage/useResetFocusedNode';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import Configuration from './CustomNode/Configuration';
import Project from './CustomNode/Project';
import Table from './CustomNode/Table/Table';
import CenterButton from './CenterButton';
import CommonEdge from './CommonEdge';
import {
  HIGHLIGHT_MUTED_EDGES_OPACITY,
  HIGHLIGHT_MUTED_OPACITY,
  LLM_EDGE_COLOR,
} from './constants';
import { isMyHandleHidden } from './dynamicEdges';
import FitViewButton from './FitViewButton';
import { highlightPath } from './highlighting';
import { initEdgesProps, initNodesProps, proOptions, zoomOptions } from './initConfig';
import Legend from './Legend';
import LegendButton from './LegendButton';
import NodesLoadingPlaceholder from './NodesLoadingPlaceholder';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

const RENDER_ONLY_VISIBLE_ELEMENTS_LIMIT = 500;

const edgeTypes = {
  default: CommonEdge,
};

// hidden property works in combination with opacity to prevent showing unmeasured edges and nodes
const getInitEdges = (edgesData: EdgeInfo[]): CustomEdge[] => {
  return edgesData.map((link) => {
    const {
      originalSource,
      originalTarget,
      lineageSource,
      originalTargetHandle,
      originalSourceHandle,
    } = link;
    return {
      ...initEdgesProps,
      ...link,
      markerEnd: {
        ...initEdgesProps.markerEnd,
        ...(isEdgeCalculatedWithLlm(link) ? { color: LLM_EDGE_COLOR } : {}),
      },
      hidden: true,
      data: {
        originalSource,
        originalTarget,
        originalSourceHandle,
        originalTargetHandle,
        lineageSource,
        isHighlighted: false,
      },
    };
  });
};

const getInitNodes = (nodesData: NodeData[], withColumns: boolean): CustomNode[] => {
  return nodesData.map((node) => {
    const commonProps = { id: node.fqid, ...initNodesProps, hidden: true };

    switch (node.type) {
      case 'table':
        return {
          ...commonProps,
          type: node.type,
          data: { ...node, columns: withColumns ? node.columns : [] },
        };

      case 'component':
        return { ...commonProps, type: node.type, data: node };

      case 'project':
        return { ...commonProps, type: node.type, data: node };
    }
  });
};

type Props = {
  nodesData: NodeData[];
  edgesData: EdgeInfo[];
  onOpenSidebar?: (nodeId: string | null) => void;
  openedSidebar?: boolean;
  inModal?: boolean;
  onSelectProject: OnSelectProject;
  withColumns?: boolean;
};

const DataLineageReactFlow = ({
  nodesData,
  edgesData,
  onOpenSidebar,
  onSelectProject,
  openedSidebar = false,
  inModal = false,
  withColumns = false,
}: Props) => {
  const { graphId, setNodeFocus, focusedNodeId } = useContext(GraphContext);
  const {
    chosenColumn,
    setChosenColumn,
    setHighlightedColumns,
    setHighlightedEdges,
    setShouldResetColumnsHighlight,
  } = useContext(ColumnsHighlightingContext) ?? {};

  const [showLegend, setShowLegend] = useLocalStorage(LINEAGE_LEGEND, true);

  // Dynamic handles. First we only have main handle, then we can dynamically add more. To prevent an edge to assign to random handle
  // if its own handle is not visible (will be fixed in react-flow 12), we will go through visible handles here and set opacity 0 to the edges
  // that have their handles hidden.
  const shownHandles = useRef<ShownHandles>(
    nodesData.map((node) => ({ id: node.fqid, sourceHandles: ['main'], targetHandles: ['main'] })),
  );

  const initEdges = useMemo(() => getInitEdges(edgesData), [edgesData]);

  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>(initEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNode>(
    getInitNodes(nodesData, withColumns),
  );
  const { getNodes, getEdges } = useReactFlow<CustomNode, CustomEdge>();

  useLayoutNodes();

  const handleSetShownHandles: OnSetShownHandles = useCallback(
    (nodeId, columns, chosenTableId = null, chosenColumnName = null, highlightedEdgeIds = []) => {
      const handles = shownHandles.current.map((node) => {
        if (node.id !== nodeId) {
          return node;
        }

        return {
          ...node,
          sourceHandles: ['main', ...columns.map((column) => `${column}-source`)],
          targetHandles: ['main', ...columns.map((column) => `${column}-target`)],
        };
      });

      setEdges((prevEdges) => {
        const mutedNodes = getNodes()
          .filter((node) => node.style?.opacity === HIGHLIGHT_MUTED_OPACITY)
          .map((node) => node.id);

        const mutedEdges = prevEdges
          .filter((edge) => edge.style?.opacity === HIGHLIGHT_MUTED_EDGES_OPACITY)
          .map((edge) => edge.id);

        const nonHiddenEdges = initEdges.filter(
          ({ source, target, sourceHandle, targetHandle }) => {
            return (
              !isMyHandleHidden('source', source, sourceHandle, handles) &&
              !isMyHandleHidden('target', target, targetHandle, handles)
            );
          },
        );

        return nonHiddenEdges.map((edge: CustomEdge) => {
          const foundEdge = prevEdges.find((prevEdge) => prevEdge.id === edge.id);
          const isHighlighted =
            !!chosenColumnName &&
            !!chosenTableId &&
            highlightedEdgeIds.some((id) => edge.id === id);

          return {
            ...edge,
            zIndex: isHighlighted ? 1 : 0,
            hidden: false,
            data: {
              ...edge.data,
              isHighlighted,
            },
            markerEnd: {
              ...initEdgesProps.markerEnd,
              ...(isHighlighted
                ? { color: highlightedEdgeStyles.stroke }
                : !!edge.data && isEdgeCalculatedWithLlm(edge.data)
                  ? { color: LLM_EDGE_COLOR }
                  : {}),
            },
            style: {
              ...edge.style,
              ...(foundEdge && foundEdge?.style),
              opacity:
                mutedEdges.includes(edge.id) ||
                mutedNodes.includes(edge.source) ||
                mutedNodes.includes(edge.target)
                  ? HIGHLIGHT_MUTED_EDGES_OPACITY
                  : 1,
            },
          };
        });
      });

      shownHandles.current = handles;
    },
    [setEdges, getNodes, initEdges],
  );

  const handleSelectColumn = useCallback(
    (nodeId: string, column: string) => {
      setChosenColumn({ nodeId, column });

      // get edges that should be highlighted
      const edgesToHighlight = highlightedEdges(initEdges, nodeId, column);

      // from edges get columns that should be highlighted
      const columnsToHighlight = calculateHighlightedColumns(edgesToHighlight, nodeId);

      setHighlightedColumns(columnsToHighlight);
      setHighlightedEdges(edgesToHighlight.map((e) => e.id));

      setEdges((prevEdges) => {
        // because of pagination some edges that will now be highlighted were not present in prevEdges
        const newUniqueEdges = _.uniq([...prevEdges, ...edgesToHighlight], (edge) => edge.id);

        // we set highlighted to true, because if edges won't change here
        // then handleSetShownHandles won't be called as no new nodes will be opened, so no highlight will be set
        return newUniqueEdges.map((edge) => ({
          ...edge,
          data: {
            ...edge.data,
            isHighlighted: edgesToHighlight.some((e) => e.id === edge.id),
          },
        }));
      });
    },
    [initEdges, setChosenColumn, setEdges, setHighlightedColumns, setHighlightedEdges],
  );

  const resetHighlightedPath = useCallback(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => ({
        ...node,
        style: { ...node.style, opacity: 1, pointerEvents: 'all' },
      })),
    );
    setEdges((prevEdges) =>
      prevEdges.map((edge) => ({ ...edge, style: { ...edge.style, opacity: 1 } })),
    );
  }, [setEdges, setNodes]);

  const resetHighlightedEdges = useCallback(() => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) => {
        return { ...edge, style: _.omit(edge.style, 'strokeWidth', 'stroke') };
      }),
    );
    setHighlightedColumns([]);
    setHighlightedEdges([]);
  }, [setEdges, setHighlightedColumns, setHighlightedEdges]);

  const handleResetColumn = useCallback(() => {
    setChosenColumn(null);
    resetHighlightedEdges();
    setShouldResetColumnsHighlight(true);
  }, [resetHighlightedEdges, setChosenColumn, setShouldResetColumnsHighlight]);

  const handleResetFocusedNode = useCallback(() => {
    if (focusedNodeId) {
      setNodeFocus(null);
      resetHighlightedPath();
    }
    if (chosenColumn) {
      handleResetColumn();
    }
  }, [chosenColumn, focusedNodeId, handleResetColumn, resetHighlightedPath, setNodeFocus]);

  const nodeTypes = useMemo(() => {
    const commonProps = {
      onHighlight: (nodeId: string) => {
        setNodeFocus(nodeId);
        highlightPath(nodeId, getNodes(), getEdges(), setNodes, setEdges);

        if (onOpenSidebar && new URLSearchParams(window.location.search).has('node')) {
          onOpenSidebar(nodeId);
        }
      },
      onSetShownHandles: handleSetShownHandles,
      onClick: (nodeId: string) => {
        onOpenSidebar?.(nodeId);

        const node = getNodes().find((node) => node.id === nodeId);

        if (node?.style?.opacity === HIGHLIGHT_MUTED_OPACITY) {
          resetHighlightedPath();
        }
      },
    };

    return {
      table: (props: NodeProps<TableNode>) => (
        <Table
          {...props}
          {...commonProps}
          onSelectProject={onSelectProject}
          graphId={graphId}
          onSelectColumn={handleSelectColumn}
          onResetColumn={handleResetColumn}
        />
      ),
      component: (props: NodeProps<ConfigurationNode>) => (
        <Configuration
          {...props}
          onHighlight={commonProps.onHighlight}
          onClick={commonProps.onClick}
          graphId={graphId}
        />
      ),
      project: (props: NodeProps<ProjectNode>) => <Project {...props} onClick={onSelectProject} />,
    };
  }, [
    handleSetShownHandles,
    setNodeFocus,
    getNodes,
    getEdges,
    setNodes,
    setEdges,
    onOpenSidebar,
    resetHighlightedPath,
    onSelectProject,
    graphId,
    handleSelectColumn,
    handleResetColumn,
  ]);

  useResetFocusedNode(focusedNodeId, handleResetFocusedNode, openedSidebar);

  return (
    <ReactFlow
      id={graphId}
      panOnScroll
      nodes={nodes}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      minZoom={zoomOptions.minZoom}
      maxZoom={zoomOptions.maxZoom}
      onPaneClick={handleResetFocusedNode}
      proOptions={proOptions}
      disableKeyboardA11y
      edgesFocusable={false}
      nodesFocusable={false}
      deleteKeyCode={null}
      onlyRenderVisibleElements={nodes.length + edges.length > RENDER_ONLY_VISIBLE_ELEMENTS_LIMIT}
    >
      <NodesLoadingPlaceholder>
        <Controls
          className={cn(
            '!tw-right-6 tw-m-0 tw-flex tw-flex-col tw-rounded tw-text-neutral-400 [&>span:last-child_button]:!tw-border-b-0 [&_button]:!tw-border-0 [&_button]:!tw-border-b [&_button]:!tw-border-solid [&_button]:!tw-border-neutral-200 [&_button]:tw-p-4 [&_svg]:tw-size-4 [&_svg]:tw-max-h-4 [&_svg]:tw-max-w-4',
            inModal ? '!tw-top-6' : '!tw-top-[88px]',
          )}
          position="top-right"
          showZoom={false}
          showFitView={false}
          showInteractive={false}
        >
          <LegendButton show={showLegend} onClick={() => setShowLegend(!showLegend)} />
          <ZoomInButton />
          <ZoomOutButton />
          <CenterButton nodes={nodes} />
          <FitViewButton />
        </Controls>
        {showLegend && (
          <Legend
            nodes={nodes}
            llmCalculated={edges.some((e) => e.data && isEdgeCalculatedWithLlm(e.data))}
          />
        )}
      </NodesLoadingPlaceholder>
    </ReactFlow>
  );
};

export default DataLineageReactFlow;
