import React, { useState } from 'react';
import { NavItem } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { Clipboard, Link } from '@keboola/design';

import bashLogo from '../../../../images/bash-logo.svg';
import javascriptLogo from '../../../../images/javascript-logo.svg';
import pythonLogo from '../../../../images/python-logo.svg';

import type { ExampleLanguageName } from '@/modules/stream/constants';
import { EXAMPLES_LANGUAGES } from '@/modules/stream/constants';
import { getExample, resolveExampleEditorMode } from '@/modules/stream/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import TabNav from '@/react/common/TabNav';

const LANGUAGES_ICONS = {
  PYTHON: pythonLogo,
  JAVASCRIPT: javascriptLogo,
  BASH: bashLogo,
} as const;

type Props = {
  sourceId: string;
  sourceUrl?: string;
};

const IntegrationExamples = (props: Props) => {
  const [exampleLanguage, setExampleLanguage] = useState<ExampleLanguageName>(
    EXAMPLES_LANGUAGES.PYTHON,
  );
  const exampleValue = getExample(exampleLanguage, props.sourceUrl ?? 'YOUR_WEBHOOK_URL');

  return (
    <>
      <p className="mbp-6 text-muted f-12 line-height-16">
        Use our Python, Javascript or Bash code examples that will connect your app to Keboola Data
        Streams or check{' '}
        <Link href={`${URLS.DEVELOPERS_DOCUMENTATION}/integrate/data-streams/tutorial/`}>
          our documentation
        </Link>
        .
      </p>
      <div className="tabs-with-border-wrapper">
        <TabNav>
          {Object.entries(EXAMPLES_LANGUAGES).map(([key, label]) => {
            return (
              <NavItem
                key={key}
                eventKey={label}
                active={exampleLanguage === label}
                onSelect={(label: ExampleLanguageName) => setExampleLanguage(label)}
                className="[&>a]:!tw-flex [&>a]:tw-items-center [&>a]:!tw-pb-4 [&>a]:!tw-text-sm"
              >
                <img
                  src={LANGUAGES_ICONS[key as keyof typeof EXAMPLES_LANGUAGES]}
                  width="16"
                  height="16"
                  loading="lazy"
                  className="mrp-2"
                />
                {label}
              </NavItem>
            );
          })}
        </TabNav>
      </div>
      <div className="tw-relative tw-h-full tw-w-full">
        <CodeEditor
          withSearch={false}
          key={`stream-example-${exampleLanguage}`}
          value={exampleValue}
          options={{
            mode: resolveExampleEditorMode(exampleLanguage),
            cursorHeight: 0,
            readOnly: true,
          }}
          editorDidMount={(editor: any) => editor?.setSize('100%', 350)}
          className="tw-peer"
        />
        <Clipboard
          tooltipPlacement="top"
          text={exampleValue ?? ''}
          className="tw-absolute tw-right-1 tw-top-1 peer-[.react-codemirror2]:peer-has-[.CodeMirror-vscrollbar]:tw-right-5"
        />
      </div>
    </>
  );
};

export default IntegrationExamples;
