import React from 'react';
import { Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';
import { Alert } from '@keboola/design';

import callDockerAction from '@/modules/components/DockerActionsApi';
import Loader from '@/react/common/Loader';
import Markdown from '@/react/common/Markdown';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { prepareConfigData } from '@/utils/json-editor/helpers';

const TestConnectionApp = () => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  return (
    <>
      <Button
        bsStyle="primary"
        disabled={loading || ApplicationStore.isReadOnly()}
        onClick={() => {
          const componentId = RoutesStore.getCurrentRouteComponentId();
          const configId = RoutesStore.getCurrentRouteParam('configId');
          const rowId = RoutesStore.getCurrentRouteParam('row');

          setLoading(true);
          setResult(null);
          return callDockerAction(componentId, 'testConnection', {
            configData: prepareConfigData(componentId, configId, rowId).toJS(),
          })
            .then((response) => {
              return setResult({ error: response?.status === 'error', message: response?.message });
            })
            .finally(() => setLoading(false));
        }}
      >
        {loading ? (
          <>
            <Loader className="icon-addon-right" />
            Testing Connection
          </>
        ) : (
          'Test Connection'
        )}
      </Button>
      {result && (
        <Alert
          className="tw-mb-5 tw-mt-3.5"
          variant={result.error ? 'error' : 'success'}
          title={result.error ? 'Failed to connect!' : 'Connected!'}
        >
          <Markdown collapsible={false} source={result.message} />
        </Alert>
      )}
    </>
  );
};

export default class TestConnectionEditor extends JSONEditor.AbstractEditor {
  build() {
    const buttonRoot = window.document.createElement('span');

    this.container.appendChild(buttonRoot);

    this.root = createRoot(buttonRoot);

    this.root.render(<TestConnectionApp />);
  }

  destroy() {
    setTimeout(() => this.root?.unmount());

    super.destroy();
  }
}
