import React from 'react';
import { Alert } from '@keboola/design';

const CatchUnsavedRunWarning = () => {
  return (
    <Alert variant="warning" className="tw-mb-5">
      You have unsaved changes! Running the component will use the last saved version.
    </Alert>
  );
};

export default CatchUnsavedRunWarning;
