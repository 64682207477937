import React from 'react';
import { Map } from 'immutable';

import { activeSchedules, getSingleScheduler, prettyCron } from '@/modules/scheduler/helpers';
import Truncated from '@/react/common/Truncated';

const ScheduleInfo = (props: {
  config: Map<string, any>;
  triggers: Map<string, any>;
  children: (label: React.ReactNode) => React.ReactNode;
}) => {
  if (!props.config) {
    return null;
  }

  const schedules = activeSchedules(props.triggers, props.config.get('schedulers'));

  if (schedules > 1) {
    return props.children(`${schedules} Schedules`);
  }

  if (schedules === 0) {
    return 'No Schedule';
  }

  if (!props.triggers.isEmpty()) {
    return 'Event trigger';
  }

  const schedule = getSingleScheduler(props.config.get('schedulers')).getIn(
    ['configuration', 'schedule'],
    Map(),
  );

  return (
    <Truncated twoLines text={prettyCron(schedule.get('cronTab'), schedule.get('timezone'))} />
  );
};

export default ScheduleInfo;
