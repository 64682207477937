import React, { useState } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { ACTION_TOOLTIP_LIMIT, DELAY_SHOW_TOOLTIP } from '../constants';
import { cn } from '../utils';

const TYPE_STYLES = {
  action:
    'tw-font-medium tw-text-xs tw-text-center tw-uppercase tw-tracking-wider tw-px-3 tw-py-2 tw-max-w-fit',
  explanatory: 'tw-font-normal tw-text-sm tw-text-left tw-px-4 tw-py-3 tw-max-w-80',
};

type TooltipType = 'action' | 'explanatory' | 'auto';
type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left';

export type Props = {
  tooltip: ReactNode;
  children: ReactNode;
  type?: TooltipType;
  placement?: TooltipPlacement;
  forceHide?: boolean;
  forceShow?: boolean;
  className?: string;
  triggerClassName?: string;
  triggerOnClick?: (e: SyntheticEvent) => void;
};

const TooltipProvider = RadixTooltip.Provider;

const stopPropagationHandler = (e: SyntheticEvent) => e.stopPropagation();

const Tooltip = ({
  tooltip,
  children,
  className,
  triggerClassName,
  type = 'action',
  placement = 'right',
  forceHide = false,
  forceShow = false,
  triggerOnClick,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <RadixTooltip.Root
      open={!!tooltip && (open || forceShow) && !forceHide}
      onOpenChange={setOpen}
      delayDuration={DELAY_SHOW_TOOLTIP}
    >
      <RadixTooltip.Trigger asChild>
        <span data-tooltip-trigger onClick={triggerOnClick} className={triggerClassName}>
          {children}
        </span>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          data-tooltip-content
          hideWhenDetached
          sideOffset={8}
          collisionPadding={8}
          side={placement}
          className={cn(
            'tw-inline-block tw-rounded tw-bg-neutral-800 tw-text-white tw-break-anywhere',
            TYPE_STYLES[resolveType(type, tooltip)],
            className,
          )}
          onClick={stopPropagationHandler}
        >
          {tooltip}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

const resolveType = (type: TooltipType, tooltip: ReactNode) => {
  if (type !== 'auto') return type;
  return typeof tooltip === 'string' && tooltip.length > ACTION_TOOLTIP_LIMIT
    ? 'explanatory'
    : 'action';
};

export { Tooltip, TooltipProvider };
