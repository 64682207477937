import React from 'react';
import { Clipboard } from '@keboola/design';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import LoadingBlock from '@/react/common/LoadingBlock';
import useStores from '@/react/hooks/useStores';
import ErrorContent from '@/react/pages/ErrorContent';
import RoutesStore from '@/stores/RoutesStore';
import CompileResults from './components/CompileResults';
import { DBT_COMPONENTS, routeNames as transformationRoutes } from './constants';

const GanttChart = React.lazy(
  () => import(/* webpackChunkName: "gantt-chart" */ './components/GanttChart'),
);

const DbtDiscovery = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('configId');

      return {
        componentId,
        configId,
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore],
  );
  const [compiledSql, setCompiledSql] = React.useState('');

  if (!DBT_COMPONENTS.includes(state.componentId)) {
    return <ErrorContent headerText="Not found" text="The component do not support discovery" />;
  }

  return (
    <>
      <ConfigurationTabs
        componentId={state.componentId}
        configId={state.configId}
        versionsLinkTo={transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS}
        notificationsLinkTo={transformationRoutes.GENERIC_TRANSFORMATION_NOTIFICATIONS}
      />
      <div className="box box-separator">
        <div className="box-header big-padding">
          <h3 className="box-title">Model Timing</h3>
        </div>
        <div className="box-content pt-0">
          <React.Suspense fallback={<LoadingBlock label="Loading graph..." className="tw-h-24" />}>
            <GanttChart
              componentId={state.componentId}
              configId={state.configId}
              configData={state.configData}
            />
          </React.Suspense>
        </div>
      </div>
      <CollapsibleBox
        title="Compiled Queries"
        titleSuffix={compiledSql && <Clipboard text={compiledSql} />}
        entity="queries"
      >
        <CompileResults
          componentId={state.componentId}
          configId={state.configId}
          configData={state.configData}
          onLoad={setCompiledSql}
        />
      </CollapsibleBox>
    </>
  );
};

export default DbtDiscovery;
