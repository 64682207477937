import { useEffect } from 'react';

import FaviconProduction from '../../../images/favicon.ico';
import FaviconBranch from '../../../images/favicon-branch.ico';
import FaviconInReview from '../../../images/favicon-in-review.ico';
import FaviconToMerge from '../../../images/favicon-to-merge.ico';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isMergeRequestApproved, isMergeRequestInReview } from '@/modules/dev-branches/helpers';
import useStores from '@/react/hooks/useStores';

const FAVICON_ID = 'favicon';

type State = 'production' | 'branch' | 'inReview' | 'toMerge';

const icons: Record<State, string> = {
  production: FaviconProduction,
  branch: FaviconBranch,
  inReview: FaviconInReview,
  toMerge: FaviconToMerge,
};

const Favicon = () => {
  const { isDevModeActive, currentDevBranchMergeRequest } = useStores(
    () => {
      return {
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        currentDevBranchMergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
      };
    },
    [],
    [DevBranchesStore],
  );

  useEffect(() => {
    // create favicon link if it doesn't exist
    if (!document.getElementById(FAVICON_ID)) {
      const faviconLink = document.createElement('link');
      faviconLink.id = FAVICON_ID;
      faviconLink.rel = 'icon';
      faviconLink.href = FaviconProduction;
      document.head.appendChild(faviconLink);
    }

    const faviconUpdate = () => {
      const favicon = document.getElementById('favicon');

      if (!favicon || !('href' in favicon)) {
        return;
      }

      const currentState: State = isMergeRequestInReview(currentDevBranchMergeRequest)
        ? 'inReview'
        : isMergeRequestApproved(currentDevBranchMergeRequest)
          ? 'toMerge'
          : isDevModeActive
            ? 'branch'
            : 'production';

      const newIcon = icons[currentState];

      if (favicon.href === newIcon) {
        return;
      }

      favicon.href = newIcon;
    };

    faviconUpdate();
  }, [currentDevBranchMergeRequest, isDevModeActive]);

  return null;
};

export default Favicon;
