import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import { Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';
import FilterPanel from '@/react/common/FilterPanel';
import Loader from '@/react/common/Loader';
import matchByWords from '@/utils/matchByWords';
import string from '@/utils/string';
import AccountLink from './AccountLink';

const AccountsTable = (props: {
  accounts: Map<string, any>;
  onSave: (accounts: Map<string, any>) => Promise<any>;
  componentId: string;
  readOnly: boolean;
}) => {
  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState(Map());
  const [isDeleting, setDeleting] = React.useState(Map());
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const filteredAccounts = props.accounts.filter((account: Map<string, any>) => {
    return matchByWords([account.get('name'), account.get('id')], query);
  });

  const renderAccount = () => {
    if (!query && props.accounts.isEmpty()) {
      return (
        <tr className="no-hover">
          <td colSpan={3}>No account selected</td>
        </tr>
      );
    }

    if (!!query && filteredAccounts.isEmpty()) {
      return (
        <tr className="no-hover">
          <td colSpan={3}>No account found</td>
        </tr>
      );
    }

    return filteredAccounts
      .map((account: Map<string, any>) => {
        const id = account.get('id');
        const isSelected = selected.has(id);

        return (
          <tr key={id} className="hoverable-actions">
            <td>
              <div className="tw-flex tw-items-center tw-justify-items-start">
                {!props.readOnly && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => {
                      setSelected(isSelected ? selected.delete(id) : selected.set(id, true));
                    }}
                    className="tw-mr-3"
                  />
                )}
                <AccountLink
                  account={account}
                  searchQuery={query}
                  componentId={props.componentId}
                />
              </div>
            </td>
            <td className="tw-text-neutral-400">{account.get('id')}</td>
            <td className="pr-1">
              {!props.readOnly && (
                <Tooltip placement="top" tooltip="Delete selected">
                  <Button
                    bsStyle="link"
                    className="text-muted"
                    onClick={() => {
                      setDeleting(isDeleting.set(id, true));
                      props.onSave(props.accounts.delete(account.get('id'))).finally(() => {
                        setDeleting(isDeleting.delete(id));
                      });
                    }}
                    disabled={isDeleting.has(id)}
                  >
                    {isDeleting.has(id) ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                  </Button>
                </Tooltip>
              )}
            </td>
          </tr>
        );
      })
      .toArray();
  };

  const isAllSelected =
    !filteredAccounts.isEmpty() && selected.count() === filteredAccounts.count();
  const isSomeSelected = !selected.isEmpty() && selected.count() < filteredAccounts.count();

  return (
    <>
      <FilterPanel query={query} onChange={setQuery} className="tw-mb-4" />
      <div className="box tw-mb-6">
        <Table hover className="condensed no-spacing">
          <thead>
            <tr>
              <th>
                <div className="tw-flex tw-items-center tw-justify-items-start">
                  {!props.readOnly && (
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={isSomeSelected}
                      disabled={filteredAccounts.isEmpty()}
                      onChange={() => {
                        setSelected(
                          isAllSelected || isSomeSelected
                            ? Map()
                            : (filteredAccounts
                                .toMap()
                                .mapKeys((key, account) => account.get('id'))
                                .map(() => true) as Map<string, boolean>),
                        );
                      }}
                      className="tw-mr-3"
                    />
                  )}
                  Account
                  {!selected.isEmpty() && (
                    <div className="table-action-buttons">
                      <Tooltip placement="top" tooltip="Delete selected">
                        <Button
                          bsStyle="link"
                          className="btn-link-inline btn-link-muted"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          <FontAwesomeIcon icon="trash" fixedWidth />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </th>
              <th className="tw-w-4/12">Account ID</th>
              <th className="tw-w-20" />
            </tr>
          </thead>
          <tbody>{renderAccount()}</tbody>
        </Table>
        <ConfirmModal
          closeAfterResolve
          icon="trash"
          buttonType="danger"
          title="Delete selected accounts"
          buttonLabel="Delete"
          show={showDeleteModal}
          isLoading={!!isDeleting.get('selected')}
          onHide={() => setShowDeleteModal(false)}
          text={
            <>
              Are you sure you want to delete the <b>{selected.count()}</b> selected{' '}
              {string.pluralize(selected.count(), 'account')}?
            </>
          }
          onConfirm={() => {
            const accounts = filteredAccounts
              .filter((accounts) => !selected.has(accounts.get('id')))
              .toMap();

            setDeleting(isDeleting.set('selected', true));
            return props
              .onSave(accounts)
              .then(() => {
                setSelected(Map());
                setShowDeleteModal(false);
              })
              .finally(() => setDeleting(isDeleting.delete('selected')));
          }}
        />
      </div>
    </>
  );
};

export default AccountsTable;
