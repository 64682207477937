import React, { useState } from 'react';
import { Promise } from 'bluebird';
import type { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import {
  addNotification,
  loadNotificationsForce,
  removeNotification,
} from '@/modules/notifications/actions';
import {
  EVENT_JOB_FAILED,
  EVENT_JOB_PROCESSING,
  EVENT_JOB_SUCCESS,
  EVENT_JOB_WARNING,
} from '@/modules/notifications/constants';
import { prepareNotification } from '@/modules/notifications/helpers';
import NotificationBox from './NotificationBox';

type Props = {
  admins: Map<string, any>;
  allNotifications: List<any>;
  notifications: List<any>;
  componentId: string;
  configId: string;
  readOnly: boolean;
};

const Notifications = (props: Props) => {
  const [savingType, setSavingType] = useState<string | null>(null);

  const isFlow = props.componentId === KEBOOLA_ORCHESTRATOR;
  const entity = isFlow ? 'flow' : 'configuration';

  const onChange = (type: string, action: () => Promise<unknown>) => {
    setSavingType(type);
    action()
      .finally(loadNotificationsForce)
      .finally(() => setSavingType(null));
  };

  const getValue = (type: string): List<any> => {
    return props.notifications
      .filter((notification) => notification.get('event') === type)
      .toList();
  };

  const getCurrentEmails = (type: string) => {
    return getValue(type)
      .map((notification) => notification.getIn(['recipient', 'address']))
      .toList();
  };

  const handleAddNotification = (type: string, email: string) => {
    const currentEmails = getCurrentEmails(type);
    const newNotifications = email
      .split(',')
      .filter((email) => !!email && !currentEmails.includes(email.toLowerCase()))
      .map((email) => {
        return prepareNotification(
          props.componentId,
          props.configId,
          props.notifications,
          type,
          email,
        );
      });

    onChange(type, () => {
      return Promise.map(newNotifications, addNotification, { concurrency: 3 });
    });
  };

  const handleDeleteNotification = (type: string, notification: Map<string, any>) => {
    onChange(type, () => removeNotification(notification.get('id')));
  };

  const types = [
    {
      title: 'Success',
      text: `Get notified when the ${entity} finishes successfully.`,
      icon: 'check',
      iconColor: 'green',
      type: EVENT_JOB_SUCCESS,
      emails: getCurrentEmails(EVENT_JOB_SUCCESS),
    },
    {
      title: 'Warnings',
      text: `Get notified when the ${entity} finishes with a warning.`,
      icon: 'exclamation',
      iconColor: 'orange',
      type: EVENT_JOB_WARNING,
      hidden: !isFlow,
      emails: getCurrentEmails(EVENT_JOB_WARNING),
    },
    {
      title: 'Errors',
      text: `Get notified when the ${entity} finishes with an error.`,
      icon: 'exclamation',
      iconColor: 'red',
      type: EVENT_JOB_FAILED,
      emails: getCurrentEmails(EVENT_JOB_FAILED),
    },
    {
      title: 'Processing',
      text: 'Get notified when the job is processing longer than usual (%).',
      icon: 'spinner',
      iconColor: 'blue',
      type: EVENT_JOB_PROCESSING,
      emails: getCurrentEmails(EVENT_JOB_PROCESSING),
    },
  ] as const;

  return (
    <>
      {types.map((values) =>
        'hidden' in values && values.hidden ? null : (
          <NotificationBox
            key={values.type}
            admins={props.admins}
            allNotifications={props.allNotifications}
            isReadOnly={props.readOnly}
            savingType={savingType}
            onAddNotification={handleAddNotification}
            onDeleteNotification={handleDeleteNotification}
            onGetValue={getValue}
            onChange={onChange}
            {...values}
          />
        ),
      )}
    </>
  );
};

export default Notifications;
