import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactFlowProvider } from '@xyflow/react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import { getTablesLineage } from '@/modules/lineage/api';
import Breadcrumbs from '@/modules/lineage/Breadcrumbs';
import ActionsPanel from '@/modules/lineage/components/ActionsPanel';
import { UPPER_PANEL_HEIGHT } from '@/modules/lineage/components/constants';
import GraphContextProvider from '@/modules/lineage/contexts/GraphContext';
import { mapTablesDataToGraph, onProjectOpenInNewWindow } from '@/modules/lineage/helpers';
import type { NodeData } from '@/modules/lineage/rfTypes';
import TeasingContent from '@/modules/lineage/TeasingContent';
import type { EdgeInfo } from '@/modules/lineage/types';
import { TablesLineage } from '@/modules/lineage/types';
import FullScreenModal from '@/react/common/FullScreenModal';
import Content from './Content';

const TablesLineage = ({
  startingPointFqid,
  onOpenModal = null,
  onCloseModal = null,
  allComponents,
  isLocked,
  projectName = null,
  nodeName = null,
  projectId,
}: {
  startingPointFqid: string;
  onOpenModal?: (() => void) | null;
  onCloseModal?: (() => void) | null;
  allComponents: Map<string, any>;
  isLocked: boolean;
  projectName?: string | null;
  nodeName?: string | null;
  projectId: string | null;
}) => {
  const [data, setData] = React.useState<{ graph: TablesLineage } | null>(null);
  const [graph, setGraph] = React.useState<{
    nodes: NodeData[];
    edges: EdgeInfo[];
    computeTimestamp: string;
  } | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [showTablesOnly, setShowTablesOnly] = React.useState(false);

  const [height, setHeight] = React.useState('0px');

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getTablesLineage({ fqid: startingPointFqid })
      .then((data) => {
        setData(data);
        if (
          Object.keys(data.graph.tables).length === 0 &&
          Object.keys(data.graph.configurations).length === 0
        ) {
          setIsError(true);
          return;
        }
        const graph = mapTablesDataToGraph(data.graph, allComponents, showTablesOnly, projectId);
        setGraph(graph);
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [allComponents, showTablesOnly, startingPointFqid, projectId]);

  useEffect(() => {
    if (!isLocked && !data && !isError) {
      fetchData();
    }
  }, [isLocked, fetchData, data, isError, isLoading]);

  const handleToggleShowTablesOnly = () => {
    setShowTablesOnly((prevValue) => !prevValue);
    if (!data) {
      return;
    }
    const graph = mapTablesDataToGraph(data.graph, allComponents, !showTablesOnly, projectId);
    setGraph(graph);
  };

  const LineageContent = (
    <Content
      inModal={!!onCloseModal}
      withColumns
      isLocked={isLocked}
      isLoading={isLoading}
      isError={isError}
      graph={graph}
      projectId={projectId}
      onSelectProject={onProjectOpenInNewWindow}
    />
  );

  return onCloseModal ? (
    <FullScreenModal className="lineage-modal" onHide={onCloseModal}>
      <GraphContextProvider mainNodeId={startingPointFqid}>
        <ReactFlowProvider>
          <ActionsPanel
            showActions={!isLocked && !isLoading && !isError}
            showTablesOnly={showTablesOnly}
            onShowTablesToggle={handleToggleShowTablesOnly}
            onClose={onCloseModal}
            inModal
            left={<Breadcrumbs projectName={projectName} nodeName={nodeName} />}
          />
          <Modal.Body
            className={classNames(
              'tw-flex tw-flex-col tw-content-center tw-justify-center tw-p-0',
              {
                'grid-background': !isLocked,
              },
            )}
            style={{ height: `calc(100vh - ${UPPER_PANEL_HEIGHT}px)` }}
          >
            {isLocked ? <TeasingContent /> : LineageContent}
          </Modal.Body>
        </ReactFlowProvider>
      </GraphContextProvider>
    </FullScreenModal>
  ) : (
    <div
      className="tw-absolute tw-left-1/2 tw-z-10 tw--mt-6 tw-w-full -tw-translate-x-1/2 tw-shadow-[0_-1px_0_theme(colors.neutral.200)]"
      ref={(wrapperElement) => {
        const baseHeight = '100vh';
        const topPosition = wrapperElement?.getBoundingClientRect().top;

        if (wrapperElement && topPosition && topPosition > 0) {
          setHeight(`calc(${baseHeight} - ${topPosition}px)`);
        }
      }}
      style={{ height }}
    >
      <div
        className={classNames('tw-relative tw-h-full tw-w-full', { 'grid-background': !isLocked })}
      >
        <div className="tw-flex tw-h-full tw-min-h-[500px] tw-flex-col tw-items-center">
          <GraphContextProvider mainNodeId={startingPointFqid}>
            <ReactFlowProvider>
              {isLocked ? (
                <TeasingContent className="tw-mt-8" />
              ) : (
                <>
                  <ActionsPanel
                    showActions={!isLoading && !isError}
                    showTablesOnly={showTablesOnly}
                    onShowTablesToggle={handleToggleShowTablesOnly}
                    onOpenModal={onOpenModal}
                  />
                  {LineageContent}
                </>
              )}
            </ReactFlowProvider>
          </GraphContextProvider>
        </div>
      </div>
    </div>
  );
};

export default TablesLineage;
