import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import { HelpBlock, Link, Tooltip } from '@keboola/design';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map, OrderedMap } from 'immutable';
import { capitalize } from 'underscore.string';

import * as componentFlags from '@/constants/componentFlags';
import { KEBOOLA_DATABRICKS_TRANSFORMATION, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { SIDEBAR } from '@/constants/external';
import dayjs from '@/date';
import {
  getAllowedTransformations,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  prepareCreatedFromMetadata,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import { DatabricksParametersModal } from '@/modules/components/react/components/DatabricksParameters';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import FileOutputMapping from '@/modules/components/react/components/generic/FileOutputMapping';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableInputMappingReadOnlyInfo from '@/modules/components/react/components/generic/TableInputMappingReadOnlyInfo';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import MappingWrapper from '@/modules/components/react/components/MappingsWrapper';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import FileInputMappingOverview from '@/modules/components/react/pages/file-input-mapping/Overview';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import RuntimesStore from '@/modules/runtimes/store';
import SandboxesActions from '@/modules/sandboxes/Actions';
import {
  AUTO_SLEEP_NODE,
  CONTAINER_BASED,
  DISABLE_SHARING_MESSAGE,
  ENABLE_SHARING_MESSAGE,
  ONLY_READONLY_STORAGE,
  routeNames,
  SANDBOX_LABELS,
  SANDBOX_TYPE,
} from '@/modules/sandboxes/Constants';
import {
  canCreateTransformation,
  getBackendSizeNote,
  prepareSandboxes,
  prepareSandboxTypeLabel,
  resolveComponentIdFromSandboxType,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Confirm from '@/react/common/Confirm';
import CreatedDate from '@/react/common/CreatedDate';
import CreatedFrom from '@/react/common/CreatedFrom';
import ParametersBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import RouterLink from '@/react/common/RouterLink';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ErrorContent from '@/react/pages/ErrorContent';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import CreateTransformationModal from './CreateTransformationModal';
import CredentialsModal from './CredentialsModal';
import LoadDataButton from './LoadDataButton';
import OwnerAndSharing from './OwnerAndSharing';
import RestoreWorkspace from './RestoreWorkspace';
import UpdateTransformationModal from './UpdateTransformationModal';

const SandboxDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      DevBranchesStore,
      SandboxesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      ComponentsStore,
      NotificationsStore,
      LatestJobsStore,
      JobsStore,
      VersionsStore,
      StackFeaturesStore,
    ),
  ],

  getStateFromStores() {
    const componentId = KEBOOLA_SANDBOXES;
    const configId = RoutesStore.getCurrentRouteParam('config');
    const sapiToken = ApplicationStore.getSapiToken();
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));
    const isCurrentOwner = sandbox.get('tokenId') === sapiToken.get('id');
    const hasNewQueue = ApplicationStore.hasNewQueue();

    return {
      componentId,
      configId,
      sandbox,
      configData,
      sapiToken,
      hasNewQueue,
      createdFrom: prepareCreatedFromMetadata(
        componentId,
        configId,
        InstalledComponentsStore.getAll(),
        InstalledComponentsStore.getAllMetadata(),
      ),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      allComponents: ComponentsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      component: ComponentsStore.getComponent(
        resolveComponentIdFromSandboxType(sandbox.get('type')),
      ),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      latestJobs: hasNewQueue
        ? JobsStore.getLatestJobs(componentId, configId)
        : LatestJobsStore.getJobs(componentId, configId),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      sandboxComponent: ComponentsStore.getComponent(componentId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      componentPendingActions: InstalledComponentsStore.getPendingActions(componentId, configId),
      pendingActions: SandboxesStore.getPendingActions(),
      mlflowInstanceUrl: SandboxesStore.getMlflowInstanceUrl(),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      versions: VersionsStore.getVersions(componentId, configId),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
      isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
      isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      readOnly: !sandbox.get('shared') && !isCurrentOwner,
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      userReadOnly: ApplicationStore.isReadOnly(),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(componentId, null).get(
        'clusters',
      ),
    };
  },

  getInitialState() {
    return {
      showCreateTransformationModal: false,
      showUpdateTransformationModal: false,
      showCredentialsModal: false,
    };
  },

  render() {
    if (!this.state.sandbox.count()) {
      return <ErrorContent headerText="Workspace not found" />;
    }

    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configId}
          versionsLinkTo={routeNames.WORKSPACE_VERSIONS}
        />
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              readOnly={this.state.readOnly || this.state.userReadOnly}
              placeholderEntity="Workspace"
            />
            {this.renderDetail()}
            <MappingWrapper>
              {this.tableInputMapping()}
              {this.fileInputMapping()}
              {this.tableOutputMapping()}
              {this.fileOutputMapping()}
            </MappingWrapper>
            <CreateTransformationModal
              config={this.state.config}
              sandbox={this.state.sandbox}
              show={this.state.showCreateTransformationModal}
              onHide={() => this.setState({ showCreateTransformationModal: false })}
              transformationComponent={this.state.component}
            />
            <UpdateTransformationModal
              config={this.state.config}
              metadata={this.state.componentsMetadata}
              transformationComponent={this.state.component}
              existingTransformations={this.state.allConfigurations.getIn(
                [this.state.component.get('id'), 'configurations'],
                Map(),
              )}
              show={this.state.showUpdateTransformationModal}
              onHide={() => this.setState({ showUpdateTransformationModal: false })}
            />
            <CredentialsModal
              sandbox={this.state.sandbox}
              show={this.state.showCredentialsModal}
              onHide={() => this.setState({ showCredentialsModal: false })}
            />
          </div>
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>
              <div className="nav nav-stacked">
                {this.renderConnectButton()}
                {this.renderMlFlowButton()}
                {this.renderRestoreButton()}
                {this.renderTerminateButton()}
                {this.renderLoadButton()}
                {this.renderUnloadButton()}
                {this.renderShareButton()}
                {this.renderCreateTransformationButton()}
                {this.renderCopyToExistingTransformationButton()}
                {this.renderRawConfigurationButton()}
                {this.renderDocumentationButton()}
                {this.renderDeleteButton()}
              </div>
              {this.renderCreatedFrom()}
              <SidebarJobs
                jobs={this.state.latestJobs}
                componentId={KEBOOLA_SANDBOXES}
                configId={this.state.configId}
                hasNewQueue={this.state.hasNewQueue}
                allConfigurations={this.state.allConfigurations}
                admins={this.state.admins}
                notifications={this.state.notifications}
                currentAdmin={this.state.currentAdmin}
              />
              <hr />
              <SidebarVersions
                configId={this.state.configId}
                component={this.state.sandboxComponent}
                config={this.state.config}
                versionsLinkTo={routeNames.WORKSPACE_VERSIONS}
                versions={this.state.versions}
                versionsConfigs={this.state.versionsConfigs}
                isLoading={this.state.isLoadingVersions}
                isPending={this.state.isPendingVersions}
                pendingMultiLoad={this.state.pendingMultiLoadVersions}
                admins={this.state.admins}
              />
            </div>
          </div>
        </div>
      </>
    );
  },

  renderConnectButton() {
    const isActive = this.state.sandbox.get('active');
    const hideTooltip = isActive && !this.state.readOnly;
    const isDisabled = !isActive || this.state.readOnly;

    return (
      <>
        <Tooltip
          placement="top"
          forceHide={hideTooltip}
          tooltip={this.state.readOnly ? 'Workspace is private' : 'Workspace is inactive'}
        >
          <Button
            block
            bsStyle="success"
            className={classNames({ disabled: isDisabled })}
            onClick={() => !isDisabled && this.setState({ showCredentialsModal: true })}
          >
            <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
            Connect
          </Button>
        </Tooltip>
        <hr />
      </>
    );
  },

  renderMlFlowButton() {
    if (
      SANDBOX_TYPE.PYTHON_MLFLOW !== this.state.sandbox.get('type') ||
      !this.state.mlflowInstanceUrl ||
      this.state.isDevModeActive
    ) {
      return null;
    }

    return (
      <Link className="btn btn-link btn-block btn-link-inline" href={this.state.mlflowInstanceUrl}>
        <FontAwesomeIcon icon="arrow-up-right-from-square" /> Open MLflow
      </Link>
    );
  },

  renderRestoreButton() {
    if (
      this.state.sandbox.get('active') ||
      !CONTAINER_BASED.includes(this.state.sandbox.get('type')) ||
      this.state.userReadOnly
    ) {
      return null;
    }

    return (
      <RestoreWorkspace
        mode="sidebar"
        config={this.state.config}
        sandbox={this.state.sandbox}
        configData={this.state.configData}
        isRestoring={this.isRestoring()}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  renderTerminateButton() {
    if (
      !this.state.sandbox.get('active') ||
      !CONTAINER_BASED.includes(this.state.sandbox.get('type')) ||
      this.state.userReadOnly
    ) {
      return null;
    }

    return (
      <Confirm
        icon="circle-pause"
        buttonType="danger"
        title="Sleep Workspace"
        text={
          <>
            Are you sure you want to sleep the workspace{' '}
            <strong>{this.state.config.get('name')}</strong>?
          </>
        }
        buttonLabel="Sleep Workspace"
        onConfirm={this.handleTerminate}
        isDisabled={this.isTerminating() || this.state.readOnly}
        childrenRootElement="a"
      >
        {this.isTerminating() ? <Loader /> : <FontAwesomeIcon icon="circle-pause" fixedWidth />}
        Sleep workspace
      </Confirm>
    );
  },

  renderLoadButton() {
    if (this.state.userReadOnly) {
      return null;
    }

    return (
      <LoadDataButton
        configId={this.state.config.get('id')}
        sandbox={this.state.sandbox}
        configData={this.state.configData}
        pendingActions={this.state.pendingActions}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderUnloadButton() {
    if (!CONTAINER_BASED.includes(this.state.sandbox.get('type')) || this.state.userReadOnly) {
      return null;
    }

    const isActive = this.state.sandbox.get('active');
    const hasOutputMapping = this.state.configData.getIn(['storage', 'output'], Map()).count() > 0;
    const isUnloadingData = this.state.pendingActions.hasIn([
      'unload-data',
      this.state.sandbox.get('id'),
    ]);

    return (
      <Confirm
        icon="cloud-arrow-down"
        buttonType="success"
        title="Unload Data from Workspace"
        text="You are about to unload data based on the output mapping."
        buttonLabel="Unload Data"
        onConfirm={this.handleUnloadData}
        isDisabled={!isActive || !hasOutputMapping || isUnloadingData || this.state.readOnly}
        disabledReason={
          this.state.readOnly
            ? ''
            : !isActive
              ? 'Workspace is inactive'
              : isUnloadingData
                ? ''
                : 'No output mapping defined'
        }
        childrenRootElement="a"
        childrenRootElementClass="tw-flex"
      >
        {isUnloadingData ? <Loader /> : <FontAwesomeIcon icon="cloud-arrow-down" fixedWidth />}
        Unload data
      </Confirm>
    );
  },

  renderShareButton() {
    if (
      this.state.sandbox.get('tokenId') !== this.state.sapiToken.get('id') ||
      this.state.userReadOnly
    ) {
      return null;
    }

    const isSharePending = this.state.pendingActions.hasIn(['share', this.state.sandbox.get('id')]);

    if (this.state.sandbox.get('shared')) {
      return (
        <Confirm
          closeAfterResolve
          buttonType="success"
          icon="share"
          title="Disable Sharing"
          text={DISABLE_SHARING_MESSAGE}
          buttonLabel="Disable sharing"
          onConfirm={() => SandboxesActions.unshareSandbox(this.state.sandbox.get('id'))}
          isDisabled={isSharePending || this.state.readOnly}
          isLoading={isSharePending}
          childrenRootElement="a"
        >
          <FontAwesomeIcon icon="share" fixedWidth />
          Disable sharing
        </Confirm>
      );
    }

    return (
      <Confirm
        closeAfterResolve
        buttonType="success"
        icon="share"
        title="Enable Sharing"
        text={ENABLE_SHARING_MESSAGE}
        buttonLabel="Enable sharing"
        onConfirm={() => SandboxesActions.shareSandbox(this.state.sandbox.get('id'))}
        isDisabled={isSharePending || this.state.readOnly}
        isLoading={isSharePending}
        childrenRootElement="a"
      >
        <FontAwesomeIcon icon="share" fixedWidth />
        Enable sharing
      </Confirm>
    );
  },

  renderCreateTransformationButton() {
    if (!canCreateTransformation(this.state.sandbox.get('type')) || this.state.userReadOnly) {
      return null;
    }

    return (
      <Button
        block
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => this.setState({ showCreateTransformationModal: true })}
        disabled={this.state.readOnly}
      >
        <span className="fa-layers">
          <FontAwesomeIcon icon="circle" />
          <FontAwesomeIcon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Create new transformation
      </Button>
    );
  },

  renderCopyToExistingTransformationButton() {
    if (!canCreateTransformation(this.state.sandbox.get('type')) || this.state.userReadOnly) {
      return null;
    }

    return (
      <Button
        block
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => this.setState({ showUpdateTransformationModal: true })}
        disabled={this.state.readOnly}
      >
        <span className="fa-layers">
          <FontAwesomeIcon icon="circle" />
          <FontAwesomeIcon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Copy to existing transformation
      </Button>
    );
  },

  renderRawConfigurationButton() {
    return (
      <li>
        <RouterLink
          to={componentsRoutes.GENERIC_CONFIG_RAW}
          params={{ component: KEBOOLA_SANDBOXES, config: this.state.configId }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <FontAwesomeIcon icon="bug" fixedWidth />
          Debug mode
        </RouterLink>
      </li>
    );
  },

  renderDeleteButton() {
    if (this.state.userReadOnly) {
      return null;
    }

    const isDeleting = this.state.pendingActions.hasIn(['delete', this.state.sandbox.get('id')]);
    const isLoadingData = this.state.pendingActions.hasIn([
      'load-data',
      this.state.sandbox.get('id'),
    ]);

    return (
      <>
        <hr />
        <Confirm
          icon="trash"
          buttonType="danger"
          title="Delete Workspace"
          text={
            <>
              Are you sure you want to delete the workspace{' '}
              <strong>{this.state.config.get('name')}</strong>?
            </>
          }
          buttonLabel="Delete"
          onConfirm={this.handleDelete}
          isDisabled={isDeleting || isLoadingData || this.state.readOnly}
          childrenRootElement="a"
        >
          {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          Delete workspace
        </Confirm>
      </>
    );
  },

  renderDocumentationButton() {
    return <DocumentationLink href={`${URLS.USER_DOCUMENTATION}/transformations/workspace`} />;
  },

  renderCreatedFrom() {
    return <CreatedFrom from={this.state.createdFrom} />;
  },

  renderDetail() {
    let rows = OrderedMap({
      'Workspace ID': {
        noCopy: true,
        text: this.state.sandbox.get('id'),
      },
      Type: {
        noCopy: true,
        text: prepareSandboxTypeLabel(this.state.sandbox.get('type')),
      },
      Created: {
        noCopy: true,
        component: <CreatedDate createdTime={this.state.sandbox.get('createdTimestamp')} />,
      },
      'Last Change': {
        noCopy: true,
        component: <CreatedDate createdTime={this.state.sandbox.get('updatedTimestamp')} />,
      },
      'Owner, Sharing': {
        noCopy: true,
        component: (
          <OwnerAndSharing
            sandbox={this.state.sandbox}
            config={this.state.config}
            sapiToken={this.state.sapiToken}
            admins={this.state.admins}
          />
        ),
      },
    });
    const runtimes = RuntimesStore.getRuntimes(
      resolveComponentIdFromSandboxType(this.state.sandbox.get('type')),
    );

    if (runtimes.length !== 0) {
      const activeVersion = this.state.sandbox.get('imageVersion', '');
      const runtime =
        runtimes.find((runtime) => runtime.sandboxImageTag === activeVersion) ??
        runtimes.find((runtime) => runtime.isTypeDefault) ??
        null;

      rows = rows.set('Backend Version', {
        noCopy: true,
        text: runtime?.description ?? activeVersion,
      });
    }

    if (!!this.state.sandbox.get('size')) {
      const sandboxSize = this.state.sandbox.get('size');

      rows = rows.set('Backend Size', {
        noCopy: true,
        text: SANDBOX_LABELS[sandboxSize] || capitalize(sandboxSize),
        ...(!this.state.hasPayAsYouGo && { hint: getBackendSizeNote() }),
      });
    }

    if (this.state.sandbox.get('type') === SANDBOX_TYPE.PYTHON_DATABRICKS) {
      rows = rows
        .set('Node Type', {
          noCopy: true,
          text: this.state.sandbox.getIn(
            ['databricks', 'nodeType'],
            this.state.sandboxComponent.getIn([
              'data',
              'image_parameters',
              'databricks',
              'defaultNodeType',
            ]),
          ),
        })
        .set('Number of nodes', {
          noCopy: true,
          text: this.state.sandbox.getIn(
            ['databricks', 'numberOfNodes'],
            this.state.sandboxComponent.getIn([
              'data',
              'image_parameters',
              'databricks',
              'defaultNumberOfNodes',
            ]),
          ),
        })
        .set('Runtime Version', {
          noCopy: true,
          text: this.state.sandbox.getIn(
            ['databricks', 'sparkVersion'],
            this.state.sandboxComponent.getIn([
              'data',
              'image_parameters',
              'databricks',
              'defaultSparkVersion',
            ]),
          ),
        });
    }

    const expiration = this.state.sandbox.get('expirationAfterHours');

    if (expiration > 0) {
      rows = rows.set('Auto Sleep', {
        noCopy: true,
        text: `Activated (${expiration} ${string.pluralize(expiration, 'hour')})`,
        hint: AUTO_SLEEP_NODE,
      });
    } else if (!!this.state.sandbox.get('expirationTimestamp')) {
      const expiration = dayjs(this.state.sandbox.get('expirationTimestamp'));

      rows = rows.set(expiration > dayjs() ? 'Expires' : 'Expired', {
        noCopy: true,
        text: expiration.fromNow(),
      });
    }

    if (this.state.configData.getIn(['parameters', 'packages'], List()).count() > 0) {
      rows = rows.set(
        'Packages',
        this.state.configData.getIn(['parameters', 'packages']).toArray().join(', '),
      );
    }

    if (!!this.state.sandbox.getIn(['persistentStorage', 'pvcName'])) {
      rows = rows.set('Data Persistency', {
        hint: 'Changes are saved with Auto Sleep. Ask our support team to disable it.',
        noCopy: true,
        text: 'Enabled',
      });
    }

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Workspace Parameters</h2>
          {this.state.sandbox.get('type') === SANDBOX_TYPE.PYTHON_DATABRICKS && (
            <DatabricksParametersModal
              component={this.state.sandboxComponent}
              configId={this.state.configId}
              configData={this.state.sandbox.set(
                'storage',
                this.state.configData.get('storage', Map()),
              )}
            />
          )}
        </div>
        <div className="box-content">
          <ParametersBox rows={rows} noBorder />
          {this.renderPackagesInfo()}
        </div>
      </div>
    );
  },

  renderPackagesInfo() {
    let packagesLink = null;

    switch (this.state.sandbox.get('type')) {
      case SANDBOX_TYPE.PYTHON:
        packagesLink = `${URLS.USER_DOCUMENTATION}/transformations/python-plain#packages`;
        break;

      case SANDBOX_TYPE.R:
        packagesLink = `${URLS.USER_DOCUMENTATION}/transformations/r-plain#packages`;
        break;
    }

    if (!packagesLink) {
      return null;
    }

    return (
      <HelpBlock className="mt-1">
        This workspace came with pre-installed packages. Read more in the{' '}
        <Link href={packagesLink}>documentation</Link>.
      </HelpBlock>
    );
  },

  tableInputMapping() {
    if (ONLY_READONLY_STORAGE.includes(this.state.sandbox.get('type'))) {
      return <TableInputMappingReadOnlyInfo />;
    }

    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_INPUT)) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={this.state.readOnly || this.state.userReadOnly}
        componentId={this.state.componentId}
        replacementComponentId={this.state.component.get('id')}
        destinationType={getDestinationTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'input']),
        )}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        allComponents={this.state.allComponents}
        allowedComponents={this.state.allowedTransformationComponents}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  fileInputMapping() {
    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT)) {
      return null;
    }

    if (this.state.component.get('id') === KEBOOLA_DATABRICKS_TRANSFORMATION) {
      return (
        <FileInputMappingOverview
          readOnly={this.state.readOnly || this.state.userReadOnly}
          value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
          componentId={this.state.componentId}
          configId={this.state.configId}
          allComponents={this.state.allComponents}
          allConfigurations={this.state.allConfigurations}
          isDevModeActive={this.state.isDevModeActive}
          onDeleteMappings={(...args) =>
            InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
          }
          pendingActions={this.state.componentPendingActions}
        />
      );
    }

    return (
      <FileInputMapping
        readOnly={this.state.readOnly || this.state.userReadOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allComponents={this.state.allComponents}
        allowedComponents={this.state.allowedTransformationComponents}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  tableOutputMapping() {
    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_OUTPUT)
    ) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={this.state.readOnly || this.state.userReadOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        configName={this.state.config.get('name')}
        value={this.state.configData.getIn(['storage', 'output', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        sourceType={getSourceTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'output']),
        )}
      />
    );
  },

  fileOutputMapping() {
    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT)) {
      return null;
    }

    return (
      <FileOutputMapping
        readOnly={this.state.readOnly || this.state.userReadOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'output', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
      />
    );
  },

  handleTerminate() {
    return SandboxesActions.terminateSandbox(
      this.state.sandbox.get('id'),
      this.state.config.get('id'),
    );
  },

  handleUnloadData() {
    return SandboxesActions.unloadData(
      this.state.sandbox.get('id'),
      this.state.config.get('id'),
      this.state.configData.getIn(['storage', 'output'], Map()).toJS(),
    );
  },

  handleDelete() {
    RoutesStore.getRouter().transitionTo(routeNames.WORKSPACES);
    SandboxesActions.deleteSandbox(this.state.sandbox.get('id'), this.state.config.get('id'));
  },

  isTerminating() {
    return this.state.pendingActions.hasIn(['terminate', this.state.sandbox.get('id')]);
  },

  isRestoring() {
    return this.state.pendingActions.hasIn(['restore', this.state.sandbox.get('id')]);
  },
});

export default SandboxDetail;
