import React from 'react';
import { DOCUMENTATION_URL } from '@keboola/constants';

import { KeyCode } from '../KeyCode';
import { Link } from '../Link';

export const AutocompleteHelp = () => (
  <div className="tw-flex tw-gap-1">
    <span>
      Use <KeyCode>Ctrl+Space</KeyCode> to trigger
    </span>
    <Link href={DOCUMENTATION_URL.AUTOCOMPLETION}>autocomplete</Link>.
  </div>
);
