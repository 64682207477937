import { fromJS, Map } from 'immutable';

import Dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

let _store = initStore(
  'EventTriggersStore',
  Map({
    triggers: Map(),
    isLoaded: false,
  }),
);

const EventTriggersStore = StoreUtils.createStore({
  get(component, configurationId) {
    return _store.getIn(['triggers', component, configurationId], Map());
  },

  // helper for old modules which count with maximum of single trigger
  getSingle(component, configurationId) {
    return this.get(component, configurationId).first() || Map();
  },

  getForComponent(component) {
    return _store.getIn(['triggers', component], Map());
  },

  getAll() {
    return _store.get('triggers', Map());
  },

  getIsLoaded() {
    return _store.get('isLoaded', false);
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case actionTypes.TRIGGERS_LOAD_SUCCESS: {
      const triggers = fromJS(action.triggers)
        .toMap()
        .mapKeys((key, trigger) => trigger.get('id'))
        .groupBy((trigger) => trigger.get('component'))
        .map((triggers) => triggers.groupBy((trigger) => trigger.get('configurationId')));

      _store = _store.set('triggers', triggers).set('isLoaded', true);
      return EventTriggersStore.emitChange();
    }

    case actionTypes.TRIGGERS_DELETE_SUCCESS:
      _store = _store.update('triggers', (components) => {
        return components
          .map((configs) => {
            return configs.map((triggers) => {
              return triggers.filter((trigger) => trigger.get('id') !== action.id);
            });
          })
          .update((components) => components.filter((configs) => !configs.isEmpty()));
      });
      return EventTriggersStore.emitChange();

    case actionTypes.TRIGGERS_CREATE_SUCCESS:
    case actionTypes.TRIGGERS_UPDATE_SUCCESS:
      _store = _store.setIn(
        ['triggers', action.trigger.component, action.trigger.configurationId, action.trigger.id],
        fromJS(action.trigger),
      );
      return EventTriggersStore.emitChange();

    default:
  }
});

export default EventTriggersStore;
