import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn, Tooltip } from '@keboola/design';

const CodeEditorExpandButton = (props: {
  isCollapsed: boolean;
  onClick: () => void;
  inline?: boolean;
}) => {
  return (
    <Tooltip
      key="full-screen"
      placement="bottom"
      tooltip={`Switch to ${props.isCollapsed ? 'full' : 'half'} screen view`}
    >
      <Button
        bsStyle={props.inline ? 'link' : 'default'}
        className={cn('!tw-ml-2 !tw-px-3 !tw-py-[9px] !tw-text-sm', {
          'btn-link-inline dark muted': props.inline,
        })}
        onClick={() => {
          props.onClick();
          (document.activeElement as HTMLElement | null)?.blur();
        }}
      >
        <FontAwesomeIcon
          fixedWidth
          icon={
            props.isCollapsed
              ? 'up-right-and-down-left-from-center'
              : 'down-left-and-up-right-to-center'
          }
        />
      </Button>
    </Tooltip>
  );
};

export default CodeEditorExpandButton;
