import React from 'react';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '@/constants/componentIds';
import { getNewComponentTypeLabel, prepareCreatedFromMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentTypeIcon from '@/modules/components-directory/components/ComponentTypeIcon';
import { allowedTypes } from '@/modules/components-directory/constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import ConfigurationDisabledLabel from '@/react/common/ConfigurationDisabledLabel';
import ConfigurationFromTemplateLabel from '@/react/common/ConfigurationFromTemplateLabel';
import { CreatedFromInline } from '@/react/common/CreatedFrom';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import Truncated from '@/react/common/Truncated';
import { getRealComponentId } from './helpers';

type Props = {
  allConfigurations: Map<string, any>;
  configuration: Map<string, any>;
  component: Map<string, any>;
  componentsMetadata: Map<string, any>;
  readOnly: boolean;
  showComponentIcon: boolean;
  isDevModeActive: boolean;
  nameLabel?: React.ReactNode;
  customConfigurationIcon?: React.ReactNode;
  customLabel?: React.ReactNode;
  hasFlows?: boolean;
};

const ConfigurationNameWithDescription = (props: Props) => {
  const configurationMetadata = props.componentsMetadata.getIn(
    [props.component.get('id'), props.configuration.get('id')],
    List(),
  );

  const isDisabled =
    !!props.configuration.get('isDisabled') || !!props.configuration.get('isDummyItem');

  const renderIcon = () => {
    return (
      <span
        className={classnames({
          'tw-opacity-50': isDisabled || props.configuration.get('isCreating'),
        })}
      >
        {props.showComponentIcon && (
          <>
            {props.customConfigurationIcon || (
              <ComponentIcon
                component={props.component}
                size="36"
                className="icon-addon-right align-start"
              />
            )}
          </>
        )}
        {!props.showComponentIcon && allowedTypes.includes(props.component.get('type')) && (
          <span className="icon-addon-right">
            <ComponentTypeIcon type={props.component.get('type')} />
          </span>
        )}
      </span>
    );
  };

  const renderDescription = () => {
    const createdFrom = prepareCreatedFromMetadata(
      props.component.get('id'),
      props.configuration.get('id'),
      props.allConfigurations,
      props.componentsMetadata,
    );

    if (!createdFrom.isEmpty() && !props.configuration.get('description', '')) {
      return (
        <Truncated
          className={classnames('f-13 text-muted', { 'opacity-half': isDisabled })}
          tooltip={createdFrom.get('name')}
          text={<CreatedFromInline from={createdFrom} />}
        />
      );
    }

    return (
      <div className={classnames('f-13 text-muted', { 'opacity-half': isDisabled })}>
        <InlineDescriptionEditInput
          entity={getNewComponentTypeLabel(props.component.get('type'))}
          description={props.configuration.get('description', '')}
          onSave={(newDescription: string) => {
            return InstalledComponentsActionCreators.updateComponentConfiguration(
              getRealComponentId(props.configuration, props.component),
              props.configuration.get('id'),
              { description: newDescription },
              'Change description',
            );
          }}
          readOnly={props.readOnly || !!props.configuration.get('isCreating') || isDisabled}
          component={props.component}
          config={props.configuration}
        />
      </div>
    );
  };

  const content = (
    <div className="tw-inline-flex tw-items-center">
      {renderIcon()}
      <div>
        <div className="tw-flex tw-items-center">
          {props.nameLabel}
          <ComponentConfigurationLink
            className="link-inherit"
            isDisabled={props.configuration.get('isCreating') || isDisabled}
            componentId={getRealComponentId(props.configuration, props.component)}
            configId={props.configuration.get('id')}
            hasFlows={props.hasFlows}
          >
            <Truncated
              className={classnames('config-name', { 'opacity-half': isDisabled })}
              text={props.configuration.get('name')}
            />
          </ComponentConfigurationLink>
          {configurationMetadata.some((row: Map<string, any>) => {
            return row.get('key') === MetadataKeys.TEMPLATES_INSTANCE_ID;
          }) && <ConfigurationFromTemplateLabel className="icon-addon-left" />}
          {props.customLabel}
          {props.configuration.get('isDisabled') && (
            <ConfigurationDisabledLabel className="icon-addon-left" />
          )}
        </div>
        {renderDescription()}
      </div>
    </div>
  );

  if (
    props.isDevModeActive &&
    props.configuration.getIn(['component', 'id']) === KEBOOLA_NO_CODE_DBT_TRANSFORMATION
  ) {
    return (
      <>
        <Tooltip
          tooltip="We are sorry no-code manipulations are not available in development branch at the moment"
          type="explanatory"
        >
          {content}
        </Tooltip>
      </>
    );
  }

  return content;
};

const MemoizedConfigurationNameWithDescription = React.memo(ConfigurationNameWithDescription);

export default MemoizedConfigurationNameWithDescription;
