import { SERVICE_SCHEDULER } from '@/constants/serviceIds';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';

export const createRequest = (method: 'POST' | 'DELETE', path: string) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_SCHEDULER)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

const SchedulerApi = {
  applySchedulerChanges(configurationId: string) {
    return createRequest('POST', 'schedules')
      .send({ configurationId })
      .promise()
      .then((response) => response.body);
  },
  removeSchedule(configurationId: string) {
    return createRequest('DELETE', `configurations/${configurationId}`)
      .promise()
      .then((response) => response.body);
  },
};

export default SchedulerApi;
