import React from 'react';
import { URLS } from '@keboola/constants';
import { Clipboard, Link } from '@keboola/design';
import type Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import CodeEditor from '@/react/common/CodeEditor';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import SaveButtons from '@/react/common/SaveButtons';
import { isValidJsonConfig } from '@/utils/validation';

const prepareData = (value: Map<string, any>) => {
  const isEmpty = !Map.isMap(value) || value.isEmpty();
  const initialValue = JSON.stringify(isEmpty ? { before: [], after: [] } : value.toJS(), null, 2);

  return { isEmpty, initialValue };
};

const Processors = (props: {
  value: Map<string, any>;
  onSubmit: (processors: Record<string, any> | null) => Promise<any>;
  readOnly: boolean;
}) => {
  const { isEmpty, initialValue } = prepareData(props.value);

  const [isSaving, setIsSaving] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);

  const handleSubmit = () => {
    const processors = value === '' || value === '{}' ? null : JSON.parse(value);

    setIsSaving(true);
    return props
      .onSubmit(processors)
      .then(fromJS)
      .then((newConfig) => {
        const { initialValue } = prepareData(newConfig.get('processors', Map()));

        setValue(initialValue);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <CollapsibleBox
      hideSuffixIfCollapsed
      title="Processors"
      titleSuffix={
        !isEmpty && <Clipboard stopEventPropagation text={value} btnClassName="text-muted-light" />
      }
      entity="processors"
      defaultOpen={!isEmpty}
      additionalActions={() => {
        if (props.readOnly) {
          return null;
        }

        return (
          <SaveButtons
            isSaving={isSaving}
            disabled={value !== '' && !isValidJsonConfig(value)}
            isChanged={value !== initialValue}
            onSave={handleSubmit}
            onReset={() => setValue(initialValue)}
          />
        );
      }}
    >
      <div className="edit">
        <CodeEditor
          value={value}
          onChange={setValue}
          options={{ readOnly: props.readOnly || isSaving }}
        />
        <div className="help-block mb-0">
          Learn more about{' '}
          <Link href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/component/processors/`}>
            Processors
          </Link>
        </div>
      </div>
    </CollapsibleBox>
  );
};

export default Processors;
