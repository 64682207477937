import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { HelpBlock, Link } from '@keboola/design';
import createReactClass from 'create-react-class';

import { endpointTypes } from '@/modules/ex-google-analytics-v4/constants';

const availableEndpoints = [
  {
    label: 'Reporting API',
    value: endpointTypes.ENDPOINT_REPORT,
  },
  {
    label: 'Multi-Channel Funnels API',
    value: endpointTypes.ENDPOINT_MCF,
  },
];

const EndpointSelect = createReactClass({
  propTypes: {
    selectedValue: PropTypes.string.isRequired,
    onSelectValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    hasNewProfiles: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <FormGroup>
        <ControlLabel className="col-md-2">{this.props.name}</ControlLabel>
        <div className="col-xs-10">
          <ToggleButtonGroup
            name="endpoint"
            value={this.props.selectedValue}
            onChange={this.props.onSelectValue}
          >
            {this.props.hasNewProfiles && (
              <ToggleButton key="data-api" value="data-api" disabled={this.props.disabled}>
                GA4 - Data API
              </ToggleButton>
            )}
            {availableEndpoints.map((item) => {
              return (
                <ToggleButton
                  key={`endpoint-${item.value}`}
                  value={item.value}
                  disabled={this.props.disabled}
                >
                  {item.label}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <HelpBlock>
            Switch between{' '}
            {this.props.hasNewProfiles && (
              <>
                <Link href="https://developers.google.com/analytics/devguides/reporting/data/v1">
                  GA4 - Data API
                </Link>
                ,{' '}
              </>
            )}
            core{' '}
            <Link href="https://developers.google.com/analytics/devguides/reporting/core/v4/">
              Reporting API
            </Link>{' '}
            and{' '}
            <Link href="https://developers.google.com/analytics/devguides/reporting/mcf/v3/">
              Multi-Channel Funnels API
            </Link>
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },
});

export default EndpointSelect;
