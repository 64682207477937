import React from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  MenuItem,
  Modal,
  SplitButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, TabsContent, Tooltip } from '@keboola/design';
import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { canRunJob } from '@/modules/admin/privileges';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ChangeDescriptionModal from '@/react/common/ChangeDescriptionModal';
import CodeEditor from '@/react/common/CodeEditor';
import ComponentIcon from '@/react/common/ComponentIcon';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FullScreenEditor from '@/react/common/FullScreenEditor';
import Loader from '@/react/common/Loader';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { isValidJsonConfig } from '@/utils/validation';

const DEFAULT_CHANGE_DESCRIPTION = 'Raw configuration update';
const prepareData = (config: Map<string, any>, key: string) => {
  return JSON.stringify(config.get(key, Map()).toJS(), null, '  ');
};

const TAB_CONTENT = 'tw-relative tw-h-[calc(100vh-178px)]';

const GenericDetailRaw = () => {
  const {
    componentId,
    configId,
    rowId,
    row,
    config,
    component,
    sapiToken,
    readOnly,
    hasFlows,
    editing,
    stateEditing,
  } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('configId');
      const rowId = RoutesStore.getCurrentRouteParam('row');
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const row = ConfigurationRowsStore.get(componentId, configId, rowId);
      const realComponentId =
        componentId === KEBOOLA_EX_SAMPLE_DATA
          ? config.getIn(['configuration', 'parameters', 'componentId'])
          : componentId;

      return {
        row,
        config,
        rowId,
        configId,
        componentId,
        component: ComponentsStore.getComponent(realComponentId),
        sapiToken: ApplicationStore.getSapiToken(),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
        editing: prepareData(rowId ? row : config, 'configuration'),
        stateEditing: prepareData(rowId ? row : config, 'state'),
      };
    },
    [],
    [
      ApplicationStore,
      RoutesStore,
      InstalledComponentsStore,
      ComponentsStore,
      ConfigurationRowsStore,
    ],
  );
  const [value, setValue] = React.useState(editing);
  const [isEditing, setIsEditing] = React.useState(false);
  const [stateValue, setStateValue] = React.useState(stateEditing);
  const [isStateEditing, setStateIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = React.useState(false);
  const [showRunModal, setShowRunModal] = React.useState(false);
  const [changeDescription, setChangeDescription] = React.useState(DEFAULT_CHANGE_DESCRIPTION);

  const hideRun = componentId === KEBOOLA_SANDBOXES;

  const runComponent = (method: 'run' | 'debug', tag?: string) => {
    setIsRunning(true);
    return InstalledComponentsActionCreators.runComponent({
      method,
      component: componentId,
      data: { config: configId, ...(rowId && { row: rowId }), ...(tag && { tag }) },
    }).finally(() => setIsRunning(false));
  };

  const saveConfiguration = () => {
    if (!isEditing) return Promise.resolve();

    setIsSaving(true);
    return Promise.resolve()
      .then(() => {
        if (rowId) {
          return ConfigurationRowsActionCreators.saveConfigurationSimple(
            componentId,
            configId,
            rowId,
            fromJS(JSON.parse(value)),
            changeDescription,
          );
        }

        return InstalledComponentsActionCreators.saveComponentConfigData(
          componentId,
          configId,
          fromJS(JSON.parse(value)),
          changeDescription,
        );
      })
      .then((configData) => {
        setIsEditing(false);
        setValue(JSON.stringify(configData, null, '  '));
      })
      .finally(() => setIsSaving(false));
  };

  const saveState = () => {
    if (!isStateEditing) return Promise.resolve();

    setStateIsEditing(true);
    return Promise.resolve()
      .then(() => {
        if (rowId) {
          return ConfigurationRowsActionCreators.updateComponentState(
            componentId,
            configId,
            rowId,
            fromJS(JSON.parse(stateValue)),
          );
        }

        return InstalledComponentsActionCreators.updateComponentState(
          componentId,
          configId,
          fromJS(JSON.parse(stateValue)),
        );
      })
      .then(fromJS)
      .then((config) => {
        setStateIsEditing(false);
        setStateValue(prepareData(config, 'state'));
      })
      .finally(() => setIsSaving(false));
  };

  const getTitle = () => {
    const title = `${component.get('name')} - ${config.get('name')}`;

    if (!row?.isEmpty()) {
      return `${title} - ${row.get('name')}`;
    }

    return title;
  };

  return (
    <FullScreenEditor
      disableEscClose
      renderTitle={() => {
        return (
          <>
            <ComponentIcon component={component} size="40" className="icon-addon-right" />
            <Truncated text={getTitle()} />
          </>
        );
      }}
      renderEditor={() => {
        return (
          <>
            <Tabs
              triggers={[
                {
                  value: 'config',
                  title: 'Update Configuration',
                },
                {
                  value: 'state',
                  title: 'Update State',
                },
              ]}
            >
              <TabsContent value="config" className={TAB_CONTENT}>
                <CodeEditor
                  autoFocus
                  value={value}
                  onChange={(value: string) => {
                    setIsEditing(value !== editing);
                    setValue(value);
                  }}
                  options={{
                    viewportMargin: 1000,
                    placeholder: 'Your JSON configuration goes here...',
                    readOnly,
                  }}
                />
              </TabsContent>
              <TabsContent value="state" className={TAB_CONTENT}>
                <CodeEditor
                  autoFocus
                  value={stateValue}
                  onChange={(value: string) => {
                    setStateIsEditing(value !== stateEditing);
                    setStateValue(value);
                  }}
                  options={{ readOnly }}
                />
              </TabsContent>
            </Tabs>
            <RunTagModal
              show={showRunModal}
              isRunning={isRunning}
              onSubmit={(tag: string) => runComponent('run', tag)}
              onHide={() => setShowRunModal(false)}
            />
            <ChangeDescriptionModal
              show={showDescriptionModal}
              value={changeDescription}
              isSaving={isSaving}
              onChange={setChangeDescription}
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                e.stopPropagation();

                saveConfiguration()
                  .then(saveState)
                  .then(() => {
                    setShowDescriptionModal(false);
                    setChangeDescription(DEFAULT_CHANGE_DESCRIPTION);
                  });
              }}
              onHide={() => setShowDescriptionModal(false)}
            />
          </>
        );
      }}
      renderButtons={() => {
        if (!canRunJob(sapiToken)) {
          return null;
        }

        const isJsonValid = isValidJsonConfig(value);

        return (
          <>
            {!hideRun && (
              <SplitButton
                pullRight
                bsStyle="success"
                title={
                  isRunning ? (
                    <>
                      <Loader className="btn-icon" />
                      Running Job...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon="play-circle" className="btn-icon" />
                      Run Job
                    </>
                  )
                }
                disabled={isRunning || isEditing || isStateEditing}
                onClick={() => runComponent('run')}
                id="row-editor-run-job"
              >
                <MenuItem onSelect={() => runComponent('debug')}>Run debug job</MenuItem>
                <MenuItem onSelect={() => setShowRunModal(true)}>Run custom tag</MenuItem>
              </SplitButton>
            )}
            {(isEditing || isStateEditing) && (
              <>
                <Button
                  onClick={() => {
                    setIsEditing(false);
                    setValue(editing);
                    setStateIsEditing(false);
                    setStateValue(stateEditing);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
                  Reset
                </Button>
                <Tooltip
                  placement="bottom"
                  tooltip="Invalid JSON configuration"
                  forceHide={isJsonValid}
                >
                  <SplitButton
                    pullRight
                    bsStyle="success"
                    title={isSaving ? 'Saving...' : 'Save'}
                    disabled={isSaving || !isJsonValid}
                    onClick={() => saveConfiguration().then(saveState)}
                    id="row-editor-save-config"
                  >
                    <MenuItem onSelect={() => setShowDescriptionModal(true)}>
                      Save with description
                    </MenuItem>
                  </SplitButton>
                </Tooltip>
              </>
            )}
            {(isEditing || isStateEditing || !hideRun) && <span className="btn-separator" />}
          </>
        );
      }}
      onClose={() => {
        const linkParams = resolveRouterLinkParams(componentId, configId, rowId, hasFlows);

        if (linkParams) {
          RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
        }
      }}
    />
  );
};

const RunTagModal = (props: {
  show: boolean;
  isRunning: boolean;
  onHide: () => void;
  onSubmit: (tag: string) => Promise<any>;
}) => {
  const [tag, setTag] = React.useState('latest');

  return (
    <Modal show={props.show} onHide={props.onHide} onEnter={() => setTag('latest')}>
      <Form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          e.stopPropagation();

          props.onSubmit(tag).then(props.onHide);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              autoFocus
              value={tag}
              placeholder="Enter custom tag"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)}
              disabled={props.isRunning}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Run with tag"
            saveButtonType="submit"
            isSaving={props.isRunning}
            isDisabled={!tag}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GenericDetailRaw;
