import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type Promise from 'bluebird';
import classnames from 'classnames';
import type { Map } from 'immutable';
import { debounce } from 'underscore';

import type { InputValue, Step, StepGroup, StepPayload } from '@/api/routes/templatesService';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import OAuthStore from '@/modules/oauth-v2/Store';
import actions from '@/modules/templates/actions';
import {
  hasAnyConfiguratorFormChanges,
  mapStepValuesToInputs,
  prepareStepHiddenMap,
} from '@/modules/templates/helpers';
import TemplatesStore2, { type Store } from '@/modules/templates/store';
import CircleIcon from '@/react/common/CircleIcon';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import Markdown from '@/react/common/Markdown';
import ModalIcon from '@/react/common/ModalIcon';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { getCommonIcon } from '@/utils/getCommonIcon';
import string from '@/utils/string';
import InstanceConfiguratorInput from './InstanceConfiguratorInput';
import StepStatusIcon from './StepStatusIcon';

const InstanceConfigurator = () => {
  const {
    templateInfo,
    instanceConfiguratorForm,
    allComponents,
    allAdmins,
    adminEmail,
    allOauthCredentials,
  } = useStores(
    () => {
      const isEditingExistingInstance = !!RoutesStore.getCurrentRouteParam('instanceId');
      const { templateVersionDetail, instanceDetail, instanceConfiguratorForm } =
        TemplatesStore2.getStore();
      let templateInfo = {
        repositoryName: templateVersionDetail?.repository?.name,
        id: templateVersionDetail?.template?.id,
        name: templateVersionDetail?.template.name,
        version: templateVersionDetail?.version,
      };

      if (isEditingExistingInstance) {
        templateInfo = {
          repositoryName: instanceDetail?.repositoryName,
          id: instanceDetail?.templateId,
          name: '',
          version:
            RoutesStore.getRouterState()?.getIn(['location', 'query', 'v']) ||
            instanceDetail?.version,
        };
      }

      return {
        templateInfo,
        instanceConfiguratorForm,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allAdmins: ApplicationStore.getAdmins() as Map<string, any>,
        adminEmail: ApplicationStore.getCurrentAdmin()?.get('email') as string | undefined,
        allOauthCredentials: OAuthStore.getAllCredentials() as Map<string, any>,
      };
    },
    [],
    [RoutesStore, ApplicationStore, ComponentsStore, TemplatesStore2, OAuthStore],
  );

  const [editingStepId, setEditingStepId] = useState('');

  useEffect(() => actions.resetInstanceConfiguratorForm, []);

  if (
    !templateInfo.repositoryName ||
    !templateInfo.id ||
    !templateInfo.version ||
    !instanceConfiguratorForm.inputs
  )
    return null;

  const editingStep = instanceConfiguratorForm.inputs.stepGroups
    .flatMap((group) => group.steps)
    .find(({ id }) => id === editingStepId);
  const validatedStep = instanceConfiguratorForm.validation?.stepGroups
    .flatMap((group) => group.steps)
    .find((step) => step.id === editingStep?.id);

  const saveStepHandler = (
    newStep: { id: string; inputs?: InputValue[] },
    options?: { softReset: boolean },
  ) => {
    const allSteps = [...(instanceConfiguratorForm.userValues || [])];
    const editingStepIndex = allSteps?.findIndex((step) => step.id === newStep.id);
    const initialStepValidation = instanceConfiguratorForm.inputs?.initialState.stepGroups
      .flatMap((group) => group.steps)
      .find(({ id }) => id === newStep.id);

    if (options?.softReset && !newStep.inputs && initialStepValidation?.configured) {
      newStep.inputs = instanceConfiguratorForm.inputs?.stepGroups
        .flatMap((group) => group.steps)
        ?.find((step) => step.id === newStep.id)
        ?.inputs.map((inputObj) => ({
          id: inputObj.id,
          value: inputObj.default,
        }));
    }

    const isStepPayload = (v: { id: string; inputs?: InputValue[] }): v is StepPayload =>
      !!v.inputs;

    if (isStepPayload(newStep)) {
      if (editingStepIndex >= 0) {
        allSteps[editingStepIndex] = newStep;
      } else {
        allSteps.push(newStep);
      }
    } else if (editingStepIndex >= 0) {
      delete allSteps[editingStepIndex];
    }

    // TODO: Fix types & remove casting
    return actions.changeInstanceConfiguratorForm(
      templateInfo.repositoryName as string,
      templateInfo.id as string,
      templateInfo.version as string,
      allSteps,
    );
  };

  return (
    <div className="bt">
      <div className="template-instance-configurator">
        {instanceConfiguratorForm.inputs.stepGroups.map((group, index) => {
          const arePreviousGroupsValid = !!instanceConfiguratorForm.inputs?.stepGroups
            .slice(0, index)
            .reverse()
            .filter((group) => group.steps.some((step) => !!step.inputs?.length))
            .every(
              (group) =>
                instanceConfiguratorForm.validation?.stepGroups.find(({ id }) => id === group.id)
                  ?.valid,
            );

          return (
            <StepGroupBox
              key={group.id}
              group={group}
              instanceConfiguratorForm={instanceConfiguratorForm}
              arePreviousGroupsValid={arePreviousGroupsValid}
              hasFollowingGroup={
                !!instanceConfiguratorForm.inputs &&
                instanceConfiguratorForm.inputs.stepGroups.length > index + 1
              }
              allComponents={allComponents}
              openStepHandler={setEditingStepId}
              resetStepHandler={saveStepHandler}
            />
          );
        })}
        <StepForm
          templateInfo={templateInfo as { repositoryName: string; id: string; version: string }} // TODO: Fix types & remove casting
          allAdmins={allAdmins}
          adminEmail={adminEmail}
          allOauthCredentials={allOauthCredentials}
          step={editingStep}
          savedValuesProp={
            instanceConfiguratorForm.userValues?.find((step) => step.id === editingStep?.id)?.inputs
          }
          isConfigured={!!validatedStep?.configured}
          isHiddenDefault={prepareStepHiddenMap(validatedStep?.inputs)}
          saveHandler={saveStepHandler}
          closeHandler={() => setEditingStepId('')}
          allComponents={allComponents}
        />
      </div>
    </div>
  );
};

const StepGroupBox = ({
  group,
  instanceConfiguratorForm,
  arePreviousGroupsValid,
  hasFollowingGroup,
  allComponents,
  openStepHandler,
  resetStepHandler,
}: {
  group: StepGroup;
  instanceConfiguratorForm: Store['instanceConfiguratorForm'];
  arePreviousGroupsValid: boolean;
  hasFollowingGroup: boolean;
  allComponents: Map<string, any>;
  openStepHandler: (id: string) => void;
  resetStepHandler: (
    newStep: { id: string; inputs?: InputValue[] },
    options?: { softReset: boolean },
  ) => Promise<void>;
}) => {
  const groupValidation = instanceConfiguratorForm.validation?.stepGroups.find(
    ({ id }) => id === group.id,
  );
  const configuredSteps = groupValidation?.steps.filter((step) => step.configured);
  const containsInvalidStep = configuredSteps?.some((step) => !step.valid);
  const hasMultipleChildren = group.steps.length > 1;
  const stepGroupContent = (
    <div
      className={classnames('box-container ml-0 mr-0', {
        'with-border': hasMultipleChildren,
        'opacity-half pointer-events-none': !arePreviousGroupsValid,
      })}
      // it is used in product fruit https://keboola.atlassian.net/browse/UI-3366
      // eslint-disable-next-line react/no-unknown-property
      step-group-description={group.description}
    >
      {group.steps.some((step) => step.inputs.length) && (
        <div className="instance-step-group-requirements ptp-1 prp-2 pbp-1 plp-2 f-12 line-height-16 text-muted">
          {['optional', 'zeroOrOne'].includes(group.required) ? (
            `Optional ${string.pluralize(group.steps.length, 'configuration')}`
          ) : group.steps.length === 1 ? (
            'Required configuration'
          ) : (
            <>
              <span
                className={
                  configuredSteps &&
                  ((group.required === 'all' && configuredSteps.length === group.steps.length) ||
                    (group.required === 'atLeastOne' && configuredSteps.length > 0) ||
                    (group.required === 'exactlyOne' && configuredSteps.length === 1))
                    ? 'color-success-muted'
                    : 'color-orange'
                }
              >
                {configuredSteps?.length ?? 0} of{' '}
                {group.required === 'atLeastOne' ? 'at least ' : ''}
                {group.required === 'all' ? group.steps.length : 1}
              </span>{' '}
              required configurations
            </>
          )}
        </div>
      )}
      {group.steps.map((step) => {
        const stepValidation = groupValidation?.steps.find(({ id }) => id === step.id);
        const hasAnyChanges = hasAnyConfiguratorFormChanges(
          instanceConfiguratorForm.inputs,
          instanceConfiguratorForm.userValues,
          step.id,
        );
        const isConfigurable = !!step?.inputs.length;

        return (
          <StepBox
            key={step.id}
            step={step}
            isConfigurable={isConfigurable}
            isConfigured={isConfigurable && hasAnyChanges && !!stepValidation?.configured}
            isPreconfigured={
              isConfigurable &&
              !!instanceConfiguratorForm.inputs?.initialState.stepGroups
                .flatMap((group) => group.steps)
                .find(({ id }) => id === step.id)?.configured &&
              !!instanceConfiguratorForm.userValues?.find(({ id }) => id === step.id)?.inputs.length
            }
            isValid={!!stepValidation?.valid}
            hasAnyChanges={hasAnyChanges}
            arePreviousGroupsValid={arePreviousGroupsValid}
            groupHasConfiguredAllRequiredSteps={
              !!configuredSteps?.length && !!groupValidation?.valid
            }
            allComponents={allComponents}
            openHandler={() => openStepHandler(step.id)}
            resetHandler={(options?: { softReset: boolean }) =>
              resetStepHandler({ id: step.id }, options)
            }
          />
        );
      })}
      {hasFollowingGroup && (
        <div className="instance-connector-icon">
          <FontAwesomeIcon icon="circle-chevron-down" className="text-muted simple-icon f-16" />
          <FontAwesomeIcon icon="caret-down" className="text-muted f-18" />
        </div>
      )}
    </div>
  );

  if (containsInvalidStep) {
    return (
      <Tooltip
        type="explanatory"
        className="tw-bg-error-500 tw-text-white"
        tooltip={
          <>
            <p className="tooltip-title">Action Required</p>
            <p>
              There are errors you need to fix. Some items need to be reconfigured to make this
              work.
            </p>
          </>
        }
      >
        {stepGroupContent}
      </Tooltip>
    );
  }

  return stepGroupContent;
};

const StepBox = ({
  step,
  isConfigurable,
  isConfigured,
  isPreconfigured,
  isValid,
  groupHasConfiguredAllRequiredSteps,
  hasAnyChanges,
  arePreviousGroupsValid,
  allComponents,
  openHandler,
  resetHandler,
}: {
  step: Step;
  isConfigurable: boolean;
  isConfigured: boolean;
  isPreconfigured: boolean;
  isValid: boolean;
  groupHasConfiguredAllRequiredSteps: boolean;
  hasAnyChanges: boolean;
  arePreviousGroupsValid: boolean;
  allComponents: Map<string, any>;
  openHandler: () => void;
  resetHandler: ({ softReset }?: { softReset: boolean }) => Promise<void>;
}) => {
  const [isReseting, setIsReseting] = useState(false);

  const commonIconProps = getCommonIcon(allComponents, step.icon);

  const stepContent = (
    <div
      className={classnames('box box-panel pb-0 ml-0 mr-0', {
        'border-danger': (isConfigured || isPreconfigured) && !isValid,
      })}
    >
      <div className="box-header flex-container flex-start pl-0 pr-0">
        <div className="icon-with-icon">
          {commonIconProps && <CircleIcon {...commonIconProps} bold bigger />}
          <StepStatusIcon
            isConfigurable={isConfigurable}
            isPreconfigured={isPreconfigured}
            isConfigured={isConfigured}
            isValid={isValid}
          />
        </div>
        <div className="box-header-inner ml-1">
          <div className="flex-container">
            <h2 className="box-title">
              <Truncated text={step.name} />
            </h2>
            {hasAnyChanges && (
              <Tooltip placement="top" tooltip="Reset Changes">
                <Button
                  bsStyle="link"
                  disabled={isReseting}
                  className="btn-link-inline circle-button text-muted icon-addon-left"
                  onClick={() => {
                    setIsReseting(true);
                    resetHandler({ softReset: true }).finally(() => setIsReseting(false));
                  }}
                >
                  {isReseting ? (
                    <Loader />
                  ) : (
                    <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
                  )}
                </Button>
              </Tooltip>
            )}
            {isPreconfigured && (
              <Tooltip placement="top" tooltip="Delete configuration">
                <Button
                  bsStyle="link"
                  disabled={isReseting}
                  className="btn-link-inline circle-button text-muted icon-addon-left"
                  onClick={() => {
                    setIsReseting(true);
                    resetHandler().finally(() => setIsReseting(false));
                  }}
                >
                  {isReseting ? <Loader /> : <FontAwesomeIcon icon={['fal', 'xmark']} fixedWidth />}
                </Button>
              </Tooltip>
            )}
          </div>
          <Truncated text={step.description} className="text-muted f-12" twoLines />
        </div>
      </div>
      {isConfigurable && (
        <div className="box-panel-content p-0 mb-1">
          <Button
            bsStyle={
              isConfigured || isPreconfigured || groupHasConfiguredAllRequiredSteps
                ? 'default'
                : 'primary'
            }
            onClick={openHandler}
            tabIndex={arePreviousGroupsValid ? 0 : -1}
            disabled={isReseting}
            block
          >
            {isConfigured || isPreconfigured ? 'Edit Configuration' : 'Configure'}
          </Button>
        </div>
      )}
    </div>
  );

  if (isConfigurable) {
    if ((isPreconfigured || isConfigured) && !isValid) {
      return (
        <Tooltip key={step.id} placement="top" tooltip="This step is not valid">
          {stepContent}
        </Tooltip>
      );
    }

    return stepContent;
  }

  return (
    <Tooltip key={step.id} placement="top" tooltip="This step is not configurable">
      {stepContent}
    </Tooltip>
  );
};

const StepForm = ({
  templateInfo,
  allAdmins,
  adminEmail,
  allOauthCredentials,
  step,
  savedValuesProp,
  isConfigured,
  isHiddenDefault,
  saveHandler,
  closeHandler,
  allComponents,
}: {
  templateInfo: { repositoryName: string; id: string; name?: string; version: string };
  allAdmins: Map<string, any>;
  adminEmail?: string;
  allOauthCredentials: Map<string, any>;
  step?: Step;
  savedValuesProp?: InputValue[];
  isConfigured: boolean;
  isHiddenDefault: Record<string, boolean> | null;
  saveHandler: (stepValues: StepPayload) => Promise<void>;
  closeHandler: () => void;
  allComponents: Map<string, any>;
}) => {
  const [values, setValues] = useState<Record<string, InputValue['value']> | null>(null);
  const [hidden, setHidden] = useState(isHiddenDefault);
  const [validation, setValidation] = useState<Record<string, string | undefined> | null>(null);
  const [validationPending, setValidationPending] = useState(false);
  const [isStepValid, setIsStepValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!hidden && isHiddenDefault) {
      setHidden(isHiddenDefault);
    }
  }, [hidden, isHiddenDefault]);

  const setValue = (inputId: string, newValue: InputValue['value']) => {
    const newValues = { ...values, [inputId]: newValue };

    setValues(newValues);
    debouncedValidateInput(newValues, [inputId]);
  };

  let cancelablePromise: Promise<any> | undefined;
  let cancelableTimeout: NodeJS.Timeout | undefined;
  const validateInput = (newValues: typeof values, inputIds?: string[]) => {
    if (!step?.id || !newValues) return;

    const hasAnyChanges =
      !!newValues &&
      Object.keys(newValues).some((id) => {
        const savedValue = savedValuesProp?.find((input) => input.id === id)?.value;

        return newValues[id] !== savedValue;
      });

    cancelablePromise?.cancel();
    if (cancelableTimeout) {
      clearTimeout(cancelableTimeout);
    }

    // show loading and disable submit only if validation take more than 200ms
    cancelableTimeout = setTimeout(
      () => setValidationPending(!!cancelablePromise?.isPending()),
      200,
    );

    cancelablePromise = actions
      .validateStep(templateInfo.repositoryName, templateInfo.id, templateInfo.version, {
        id: step.id,
        inputs: mapStepValuesToInputs(newValues),
      })
      .then((stepValidation) => {
        if (inputIds?.length) {
          const newValidation = {
            ...validation,
            /* Save validation result only for specified inputs. Without this separation the
                validation messages would always appear for all inputs even after only one input has been changed. */
            ...inputIds.reduce(
              (invalidInputs, inputId) => ({
                ...invalidInputs,
                [inputId]: stepValidation?.inputs.find(({ id }) => id === inputId)?.error,
              }),
              {},
            ),
          };

          setValidation(newValidation);
        }

        setIsStepValid(hasAnyChanges && !!stepValidation?.configured && stepValidation.valid);
        setHidden(prepareStepHiddenMap(stepValidation?.inputs));
      })
      .then(() => setValidationPending(false));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidateInput = useCallback(debounce(validateInput, 300), [
    step?.id,
    templateInfo.repositoryName,
    templateInfo.id,
    templateInfo.version,
  ]);

  const modalIconProps = getCommonIcon(allComponents, step?.icon);

  return (
    <Modal
      show={!!step?.id}
      onEnter={() => {
        if (!step) return;

        const initialValues = step.inputs.reduce((allInputs, input) => {
          const savedValue = savedValuesProp?.find(({ id }) => id === input.id)?.value;

          return { ...allInputs, [input.id]: savedValue ?? input.default };
        }, {});

        setValues(initialValues);
        if (isConfigured) {
          validateInput(
            initialValues,
            step.inputs.map(({ id }) => id),
          );
        } else {
          validateInput(initialValues);
          setValidation(null);
          setIsStepValid(false);
        }
      }}
      onHide={closeHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title>{step?.dialogName}</Modal.Title>
        {modalIconProps && <ModalIcon {...modalIconProps} bold />}
      </Modal.Header>
      <Modal.Body>
        {!!step?.dialogDescription && (
          <Markdown className="text-muted mb-1" source={step.dialogDescription} />
        )}
        {step?.inputs
          .filter((input) => !hidden?.[input.id])
          .map((input, index) => (
            <InstanceConfiguratorInput
              key={input.id}
              index={index}
              input={input}
              onChange={setValue}
              allInputs={step?.inputs}
              allValues={values}
              allValidation={validation}
              allOauthCredentials={allOauthCredentials}
              allAdmins={allAdmins}
              adminEmail={adminEmail}
              templateInfo={templateInfo}
            />
          ))}
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          saveLabel={validationPending ? 'Validating inputs...' : 'Save Configuration'}
          onSave={() => {
            if (!values || !step) {
              return closeHandler();
            }

            setIsSaving(true);
            saveHandler({ id: step.id, inputs: mapStepValuesToInputs(values) })
              .then(closeHandler)
              .finally(() => setIsSaving(false));
          }}
          isSaving={validationPending || isSaving}
          isDisabled={!isStepValid || validationPending || isSaving}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default InstanceConfigurator;
