import React, { useState } from 'react';
import type { ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';

import keyCodes from '@/constants/keyCodes';
import ConfirmModal from './ConfirmModal';

type Props = {
  title: string;
  text: ReactNode;
  onConfirm: () => Promise<any>;
  buttonLabel: string;
  onHide?: () => void;
  onEnter?: () => void;
  icon?: IconProp;
  buttonType?: string;
  buttonClass?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  disabledReason?: string;
  isDisabledConfirmButton?: boolean;
  closeAfterResolve?: boolean;
  handleErrorInModal?: boolean;
  children?: ReactNode;
  childrenRootElement?: keyof React.ReactHTML;
  childrenRootElementClass?: string;
  modalClass?: string;
  modalSize?: 'lg' | 'large' | 'sm' | 'small';
};

const Confirm = ({
  title,
  text,
  onConfirm,
  buttonLabel,
  onHide,
  onEnter,
  icon,
  buttonType = 'danger',
  buttonClass,
  isLoading,
  isDisabled = false,
  disabledReason,
  isDisabledConfirmButton = false,
  closeAfterResolve,
  handleErrorInModal,
  children,
  childrenRootElement = 'span',
  childrenRootElementClass,
  modalClass,
  modalSize,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    onHide?.();
  };

  const onButtonClick = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isDisabled) {
      setShowModal(true);
    }
  };

  const renderBody = () => {
    const Wrapper = childrenRootElement;

    return (
      <Wrapper
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === keyCodes.ENTER) {
            onButtonClick(e);
          }
        }}
        onClick={onButtonClick}
        className={classnames(childrenRootElementClass, {
          disabled: isDisabled,
        })}
      >
        {children}
      </Wrapper>
    );
  };

  const renderButton = () => {
    if (!children) return null;

    if (isDisabled && disabledReason) {
      return (
        <Tooltip tooltip={disabledReason} placement="top" type="explanatory">
          {renderBody()}
        </Tooltip>
      );
    }

    return renderBody();
  };

  return (
    <>
      {renderButton()}
      <ConfirmModal
        show={showModal}
        onHide={closeModal}
        icon={icon}
        title={title}
        text={text}
        isLoading={isLoading}
        isDisabled={isDisabledConfirmButton}
        closeAfterResolve={closeAfterResolve}
        handleErrorInModal={handleErrorInModal}
        onConfirm={onConfirm}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        buttonClass={buttonClass}
        onEnter={onEnter}
        modalClass={modalClass}
        size={modalSize}
      />
    </>
  );
};

export default Confirm;
