import React from 'react';
import { URLS } from '@keboola/constants';
import { Alert, cn, Link } from '@keboola/design';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import { KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OAuthStore from '@/modules/oauth-v2/Store';
import {
  filterCurrentBranchVariables,
  separateOauthVariables,
  separateSelectedOauthVariables,
} from '@/modules/vault/helpers';
import VariablesStore from '@/modules/vault/store';
import BoxLoader from '@/react/common/BoxLoader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import ConfigurationTable from './components/ConfigurationTable';
import MergeRequestInfoPanel from './components/MergeRequestInfoPanel';
import OauthVariableTable from './components/OauthVariableTable';
import VariableTable from './components/VariableTable';
import DevBranchesStore from './DevBranchesStore';
import {
  findUpdatedConfigurations,
  findUpdatedMetadata,
  isMergeRequestApproved,
  isMergeRequestInReview,
} from './helpers';

const MergeIndex = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      DevBranchesStore,
      VariablesStore,
      InstalledComponentsStore,
      OAuthStore,
    ),
  ],

  getStateFromStores() {
    const configurations = InstalledComponentsStore.getAll();
    const deletedConfigurations = InstalledComponentsStore.getAllDeleted();
    const productionComponents = DevBranchesStore.getProductionComponents();
    const productionDeletedComponents = DevBranchesStore.getProductionDeletedComponents();
    const updatedMetadata = findUpdatedMetadata(
      InstalledComponentsStore.getAllMetadata(),
      DevBranchesStore.getProductionComponentsMetadata(),
    );
    const hasProtectedDefaultBranch = ApplicationStore.hasProtectedDefaultBranch();

    return {
      configurations,
      updatedMetadata,
      productionComponents,
      productionDeletedComponents,
      hasProtectedDefaultBranch,
      sapiToken: ApplicationStore.getSapiToken(),
      hasFlows: ApplicationStore.hasFlows(),
      hasNewNativeTypes: ApplicationStore.hasNewNativeTypes(),
      isLoadingData: DevBranchesStore.getIsLoadingData(),
      admins: ApplicationStore.getAdmins(),
      updatedInstalledComponents: findUpdatedConfigurations(
        configurations,
        productionComponents,
        deletedConfigurations,
        productionDeletedComponents,
        updatedMetadata,
      ),
      sharedCodes: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE),
      devBranchVariables: filterCurrentBranchVariables(VariablesStore.getStore().variables),
      currentDevBranchMergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
      selectedConfigurationChanges: DevBranchesStore.getSelectedConfigurationChanges(),
      selectedVariables: DevBranchesStore.getSelectedVariables(),
      allComponents: ComponentsStore.getAll(),
      allOauthCredentials: OAuthStore.getAllCredentials(),
    };
  },

  render() {
    if (this.state.updatedInstalledComponents.isEmpty() && !this.state.devBranchVariables.length) {
      if (this.state.isLoadingData) {
        return <BoxLoader entity="data" />;
      }

      return (
        <div className="box">
          <div className="box-content">No changes have been found.</div>
        </div>
      );
    }

    return (
      <>
        {this.renderInfoAlert()}
        {this.renderMergeRequestInfo()}
        {this.renderVariablesTable()}
        {this.renderConfigurationsTable()}
      </>
    );
  },

  renderConfigurationsTable() {
    return (
      <ConfigurationTable
        components={this.state.updatedInstalledComponents}
        productionComponents={this.state.productionComponents}
        productionDeletedComponents={this.state.productionDeletedComponents}
        allComponents={this.state.allComponents}
        updatedMetadata={this.state.updatedMetadata}
        sharedCodes={this.state.sharedCodes}
        selectedConfigurationChanges={this.state.selectedConfigurationChanges}
        hasFlows={this.state.hasFlows}
        admins={this.state.admins}
        readOnly={this.isTableReadOnly()}
      />
    );
  },

  renderVariablesTable() {
    if (!this.state.hasProtectedDefaultBranch) {
      return null;
    }

    const { variables, oauthVariables } = separateOauthVariables(this.state.devBranchVariables);
    const { selectedVariables, selectedOauthVariables } = separateSelectedOauthVariables(
      oauthVariables,
      this.state.selectedVariables.toJS(),
    );

    return (
      <>
        <VariableTable
          selected={fromJS(selectedVariables)}
          variables={variables}
          readOnly={this.isTableReadOnly()}
        />
        <OauthVariableTable
          selected={fromJS(selectedOauthVariables)}
          variables={oauthVariables}
          allComponents={this.state.allComponents}
          allConfigurations={this.state.configurations}
          allOauthCredentials={this.state.allOauthCredentials}
          readOnly={this.isTableReadOnly()}
          hasFlows={this.state.hasFlows}
        />
      </>
    );
  },

  renderInfoAlert() {
    if (this.state.hasProtectedDefaultBranch) {
      return null;
    }

    return (
      <Alert title="Merge to Production" className="tw-mb-5">
        <p className={cn({ 'tw-mb-0': !this.state.hasNewNativeTypes })}>
          Merging this branch will not affect production storage. That&apos;s why, if any
          configurations in this branch depend on buckets or tables created in the branch, you must
          prepare those in production before you run the configurations.{' '}
          <Link href={`${URLS.USER_DOCUMENTATION}/components/branches`}>More info</Link>
        </p>
        {this.state.hasNewNativeTypes && (
          <p className="tw-mb-0">
            Also existing tables in production without data types will stay untyped after merging,
            even if configurations in this branch create typed tables in development branch. Newly
            created typed tables in development branch will be also created as typed tables in
            production.{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/storage/tables/data-types/#native-data-types`}>
              More info
            </Link>
          </p>
        )}
      </Alert>
    );
  },

  renderMergeRequestInfo() {
    if (!this.state.hasProtectedDefaultBranch) {
      return null;
    }

    return <MergeRequestInfoPanel mergeRequest={this.state.currentDevBranchMergeRequest} />;
  },

  isTableReadOnly() {
    if (!this.state.hasProtectedDefaultBranch) {
      return false;
    }

    if (this.state.sapiToken.getIn(['admin', 'role']) === ADMIN_ROLES.PRODUCTION_MANAGER) {
      return true;
    }

    return (
      isMergeRequestInReview(this.state.currentDevBranchMergeRequest) ||
      isMergeRequestApproved(this.state.currentDevBranchMergeRequest)
    );
  },
});

export default MergeIndex;
