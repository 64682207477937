import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { Map } from 'immutable';
import _ from 'underscore';

import { canUnlinkBucket } from '@/modules/admin/privileges';
import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import { routeNames } from '@/modules/data-catalog/constants';
import { getDescriptionWithDefaultValue } from '@/modules/data-catalog/helpers';
import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import CircleIcon from '@/react/common/CircleIcon';
import NoResultsFound from '@/react/common/NoResultsFound';
import Link from '@/react/common/RouterLink';
import Truncated from '@/react/common/Truncated';
import LinkButton from './LinkButton';
import UnlinkButton from './UnlinkButton';

type Props = {
  token: Map<string, any>;
  allBuckets: Map<string, any>;
  sharedBuckets: Map<string, any>;
  sharedAndLinkedBuckets: Map<string, any>;
  query?: string;
};

class SharedBuckets extends React.Component<Props> {
  render() {
    if (this.props.query && this.props.sharedAndLinkedBuckets.isEmpty()) {
      return <NoResultsFound entityName="shared buckets" />;
    }

    return (
      <div className="box-container two-columns">
        {this.props.sharedAndLinkedBuckets
          .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
          .map((bucket) => {
            const linkedBucket = bucket.get('linkedBucket');
            const unsharedLinkedBucket =
              !!linkedBucket &&
              canUnlinkBucket(this.props.token, linkedBucket) &&
              !this.props.sharedBuckets.some((sharedBucket) => {
                return sharedBucket.get('id') === bucket.get('id');
              });

            return (
              <div
                className="box box-panel"
                key={`${bucket.getIn(['project', 'id'])}-${bucket.get('id')}`}
              >
                <div className="box-header with-border with-big-icon">
                  <div className="flex-container mr-1">
                    <div className="tw-relative">
                      <CircleIcon
                        icon="folder"
                        generatedBackground={bucketDisplayNameWithStage(bucket)}
                      />
                      {unsharedLinkedBucket && (
                        <Tooltip
                          placement="top"
                          type="explanatory"
                          tooltip={
                            <>
                              This bucket is no longer shared
                              <br /> with you. If you unlink it, you
                              <br /> won&apos;t be able to link it back.
                            </>
                          }
                          triggerClassName="tw-inline"
                        >
                          <i className="tw-absolute tw--bottom-1 tw--right-1 tw-h-[22px] tw-w-[22px] tw-rounded-xl tw-border-2 tw-border-solid tw-border-white tw-bg-white">
                            <FontAwesomeIcon
                              icon="circle"
                              className="tw-h-[18px] tw-w-[18px] tw-align-baseline tw-text-warning-500"
                            />
                            <FontAwesomeIcon
                              icon="triangle-exclamation"
                              className="tw-absolute tw-right-1 tw-top-1 tw-h-[10px] tw-w-[10px] tw-text-white"
                            />
                          </i>
                        </Tooltip>
                      )}
                    </div>
                    <h2 className="box-title pl-1">
                      <div className="flex-container flex-start">
                        <Link
                          to={routeNames.SHARED_WITH_YOU_DETAIL}
                          params={{
                            projectId: bucket.getIn(['project', 'id']),
                            bucketId: bucket.get('id'),
                          }}
                        >
                          <Truncated text={bucket.get('displayName')} />
                        </Link>
                        {linkedBucket && (
                          <span className="no-shrink text-muted plp-2"> used as </span>
                        )}
                      </div>
                      {linkedBucket && (
                        <>
                          <StorageApiBucketLinkEx
                            bucketId={linkedBucket.get('id')}
                            truncateBucketName
                          />
                          <br />
                        </>
                      )}
                      <small className="text-muted">by {bucket.getIn(['project', 'name'])}</small>
                    </h2>
                  </div>
                  {linkedBucket ? (
                    <UnlinkButton size="sm" token={this.props.token} bucket={linkedBucket} />
                  ) : (
                    <LinkButton
                      size="sm"
                      buckets={this.props.allBuckets}
                      token={this.props.token}
                      bucket={bucket}
                    />
                  )}
                </div>
                <div className="box-panel-content overflow-break-anywhere">
                  <p className="box-description-header">About Data Bucket</p>
                  <p>{getDescriptionWithDefaultValue(bucket)}</p>
                </div>
              </div>
            );
          })
          .toArray()}
        {this.renderEmptyBoxes()}
      </div>
    );
  }

  renderEmptyBoxes() {
    const bucketsCount = this.props.sharedAndLinkedBuckets.count();

    if ((this.props.query && !bucketsCount) || bucketsCount > 8) {
      return null;
    }

    return _.times(8 - bucketsCount, (index) => {
      return index === 0 && bucketsCount === 0 ? (
        <div className="box box-panel empty" key={`empty-${index}`}>
          <h3 className="mt-2">Why is this place empty?</h3>
          <p className="text-muted mb-1">
            Looks like you just started a new project or your team members did not share any data
            with you.
          </p>
        </div>
      ) : (
        <div className="box box-panel empty" key={`empty-${index}`} />
      );
    });
  }
}

export default SharedBuckets;
