import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { List, Map, Set } from 'immutable';

import { defaultOptions } from '@/constants/sortable';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import { sortRowsByName } from '@/modules/configurations/utils/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import SortByName from '@/react/common/SortByName';
import string from '@/utils/string';
import ConfigurationRowsTableRow from './ConfigurationRowsTableRow';

const ConfigurationRowsTable = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    rows: PropTypes.instanceOf(List).isRequired,
    config: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    columns: PropTypes.object.isRequired,
    rowDelete: PropTypes.func.isRequired,
    rowEnableDisable: PropTypes.func.isRequired,
    rowDeletePending: PropTypes.func.isRequired,
    rowEnableDisablePending: PropTypes.func.isRequired,
    rowLinkTo: PropTypes.string.isRequired,
    onOrder: PropTypes.func.isRequired,
    orderPending: PropTypes.object.isRequired,
    disabledMove: PropTypes.bool.isRequired,
    isRowConfiguration: PropTypes.bool.isRequired,
    getRowRunParams: PropTypes.func,
  },

  getInitialState() {
    return {
      selected: Set(),
      showDeleteModal: false,
      sort: null,
    };
  },

  render() {
    return (
      <>
        <LazyList
          rootElement="div"
          className="table table-hover"
          items={this.props.rows}
          render={this.renderTable}
        />
        <ConfirmModal
          closeAfterResolve
          show={this.state.showDeleteModal}
          icon="trash"
          title="Remove Selected"
          text={`Are you sure you want to remove the ${
            this.state.selected.count() > 1 ? 'selected rows' : 'row'
          }?`}
          buttonLabel="Remove"
          buttonType="danger"
          onConfirm={() => {
            return Promise.each(this.state.selected.toArray(), (rowId) => {
              const row = this.props.rows.find((row) => row.get('id') === rowId);

              return ConfigurationRowsActionCreators.deleteSimple(
                this.props.component.get('id'),
                this.props.config.get('id'),
                rowId,
                `Row ${row ? row.get('name') : rowId} deleted`,
              );
            }).finally(() => this.setState({ selected: Set() }));
          }}
          onHide={() => this.setState({ showDeleteModal: false })}
        />
      </>
    );
  },

  renderTable(rows) {
    const selectedCount = this.state.selected.count();
    const isAllSelected = selectedCount === this.props.rows.count();
    const isSomeSelected = selectedCount > 0 && selectedCount !== this.props.rows.count();

    return (
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            {!this.props.readOnly && this.props.isRowConfiguration ? (
              <>
                <span className="th with-row-sort-handle w-52 pr-0">
                  <Tooltip
                    placement="top"
                    tooltip={`${isAllSelected || isSomeSelected ? 'Deselect' : 'Select'} all rows`}
                  >
                    <Checkbox
                      checked={isAllSelected}
                      onChange={(checked) =>
                        this.setState(() => ({
                          selected: !checked
                            ? Set()
                            : this.props.rows.map((row) => row.get('id')).toSet(),
                        }))
                      }
                      indeterminate={isSomeSelected && !isAllSelected}
                    />
                  </Tooltip>
                </span>
                <span className="th pl-0 w-250">
                  <div className="flex-container flex-start">
                    {!selectedCount ? (
                      <SortByName
                        allowReset
                        sortBy={this.state.sort}
                        onClick={(sort) => this.setState({ sort })}
                      />
                    ) : (
                      <strong>
                        {selectedCount} {string.pluralize(selectedCount, 'row')} selected
                      </strong>
                    )}
                    {selectedCount > 0 && (
                      <div className="table-action-buttons">
                        <Tooltip placement="top" tooltip="Delete Selected">
                          <Button
                            bsStyle="link"
                            className="text-muted"
                            onClick={() => this.setState({ showDeleteModal: true })}
                            disabled={this.state.isDeleting}
                          >
                            {this.state.isDeleting ? (
                              <Loader />
                            ) : (
                              <FontAwesomeIcon icon="trash" fixedWidth />
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </span>
              </>
            ) : (
              <span className="th w-250">Name</span>
            )}
            {this.props.columns.map((columnDefinition, index) => (
              <span className="th" key={index}>
                {columnDefinition.get('name')}
              </span>
            ))}
            <span className="th" />
          </div>
        </div>
        <Sortable
          className="tbody"
          options={{
            ...defaultOptions,
            disabled: this.props.readOnly || !this.props.isRowConfiguration,
          }}
          onChange={(order, sortable, event) => {
            const allRowsOrder = [
              ...order,
              ...this.props.rows
                .map((row) => row.get('id'))
                .toArray()
                .slice(order.length),
            ];

            return this.props.onOrder(allRowsOrder, order[event.newIndex]);
          }}
        >
          {this.renderTableRows(rows)}
        </Sortable>
      </div>
    );
  },

  renderTableRows(rows) {
    return rows
      .sort(sortRowsByName(this.state.sort))
      .map((row) => {
        const thisRowOrderPending = this.props.orderPending.get(row.get('id'), false);
        const rowsOrderPending = this.props.orderPending.count() > 0;
        const isSelected = this.state.selected.has(row.get('id'));

        return (
          <ConfigurationRowsTableRow
            key={row.get('id')}
            row={row}
            columns={this.props.columns}
            config={this.props.config}
            component={this.props.component}
            linkTo={this.props.rowLinkTo}
            isSelected={isSelected}
            toggleSelected={() => {
              this.setState({
                selected: isSelected
                  ? this.state.selected.delete(row.get('id'))
                  : this.state.selected.add(row.get('id')),
              });
            }}
            isDeletePending={this.props.rowDeletePending(row.get('id'))}
            onDelete={() => this.props.rowDelete(row.get('id'))}
            isEnableDisablePending={this.props.rowEnableDisablePending(row.get('id'))}
            onEnableDisable={() => this.props.rowEnableDisable(row.get('id'))}
            disabledMove={this.props.disabledMove || rowsOrderPending || !!this.state.sort}
            orderPending={thisRowOrderPending}
            readOnly={this.props.readOnly}
            isRowConfiguration={this.props.isRowConfiguration}
            {...(this.props.getRowRunParams && { runParams: this.props.getRowRunParams(row) })}
          />
        );
      })
      .toArray();
  },
});

export default ConfigurationRowsTable;
