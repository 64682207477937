import React, { useState } from 'react';
import type { KeyboardEvent } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { deleteFlow } from '@/modules/flows/actions';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import { updateFlowTasks } from './actions';
import Confirm from './Confirm';
import ConfirmMenuItem from './ConfirmMenuItem';
import DeleteConfigConfirmBody from './DeleteConfigConfirmBody';
import { usedFlows } from './helpers';
import Loader from './Loader';

type ModeType = 'navigation' | 'menuitem' | 'sidebar';

type Props = {
  componentId: string;
  config: Map<string, any>;
  flows: Map<string, any>;
  mode?: ModeType;
  forceTransition?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
};

const DeleteConfigurationButton = ({
  forceTransition,
  mode,
  flows,
  config,
  componentId,
  onKeyDown,
}: Props) => {
  const [isDeleting, setDeleting] = useState(false);

  const componentIdReal = getRealComponentId(config, Map({ id: componentId }));
  const isFlow = componentIdReal === KEBOOLA_ORCHESTRATOR;
  const labelText = `Delete ${isFlow ? 'flow' : 'configuration'}`;
  const configName = config.get('name', '');
  const confirmationMsg = `Are you sure you want to delete the ${isFlow ? 'flow' : 'configuration'}`;

  const Icon = isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />;

  const handleConfirm = () => {
    const options = {
      notification: true,
      transition: forceTransition || mode !== 'menuitem',
    };

    setDeleting(true);

    return Promise.resolve()
      .then(() => {
        if (isFlow) return deleteFlow(config, options);

        return Promise.each(usedFlows(flows, componentIdReal, config), (flow: Map<string, any>) => {
          return updateFlowTasks(flow, componentIdReal, config);
        }).then(() => {
          return InstalledComponentsActionCreators.deleteConfiguration(
            componentIdReal,
            config.get('id'),
            options,
          );
        });
      })
      .finally(() => setDeleting(false));
  };

  const DeleteConfirmationText = (
    <DeleteConfigConfirmBody
      confirmationMsg={confirmationMsg}
      configName={configName}
      componentId={componentIdReal}
      flows={flows}
      config={config}
    />
  );

  if (mode === 'menuitem') {
    return (
      <ConfirmMenuItem
        icon="trash"
        buttonLabel="Delete"
        title={labelText}
        text={DeleteConfirmationText}
        onConfirm={handleConfirm}
        isDisabled={isDeleting}
        onKeyDown={onKeyDown}
        closeAfterResolve
      >
        {Icon}
        {labelText}
      </ConfirmMenuItem>
    );
  }

  return (
    <Confirm
      icon="trash"
      buttonLabel="Delete"
      title={labelText}
      text={DeleteConfirmationText}
      onConfirm={handleConfirm}
      isDisabled={isDeleting}
    >
      <Button bsStyle="link" className={mode === 'navigation' ? 'text-muted p-1' : 'btn-block'}>
        {Icon}
        {mode !== 'navigation' && labelText}
      </Button>
    </Confirm>
  );
};

export default DeleteConfigurationButton;
