import React from 'react';

import type { AutomationDetail } from '@/api/routes/aiService';
import CreatedDate from '@/react/common/CreatedDate';
import RouterLink from '@/react/common/RouterLink';
import { routeNames } from './constants';

const Automations = (props: { automations: AutomationDetail[] }) => {
  if (!props.automations.length) {
    return null;
  }

  return (
    <>
      <h2 className="tw-mb-6 tw-text-2xl">Your automations</h2>
      <div className="box">
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <div className="th">Name</div>
              <div className="th text-right tw-w-24">Conversations</div>
              <div className="th text-right tw-w-72">Created</div>
            </div>
          </div>
          <div className="tbody">
            {props.automations.map((automation) => (
              <RouterLink
                className="tr"
                key={automation.id}
                to={routeNames.DETAIL}
                params={{ id: automation.id }}
              >
                <div className="td">{automation.name}</div>
                <div className="td text-right tw-w-24">{automation.conversations?.length}</div>
                <div className="td text-right tw-w-72">
                  <CreatedDate createdTime={automation.createdAt} />
                </div>
              </RouterLink>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Automations;
