import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import type { List, Map } from 'immutable';

import { getProfileName } from '@/modules/ex-google-analytics-v4/helpers';
import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import MarkedText from '@/react/common/MarkedText';
import SortIcon from '@/react/common/SortIcon';
import Truncated from '@/react/common/Truncated';
import hasSelections from '@/utils/hasSelections';

type DataRow = Row<{ name: string }>;

const every = (rows: DataRow[], predicate: (row: DataRow) => boolean): boolean => {
  return rows.every((row) => {
    return row.subRows.length > 0 ? every(row.subRows, predicate) : predicate(row);
  });
};

const some = (rows: DataRow[], predicate: (row: DataRow) => boolean): boolean => {
  return rows.some((row) => {
    return row.subRows.length > 0 ? some(row.subRows, predicate) : predicate(row);
  });
};

const ProfilesSelector = ({
  isLoading,
  profiles,
  savedProfiles,
  selectedProfiles,
  searchQuery,
  selectProfiles,
}: {
  isLoading: boolean;
  profiles: Map<string, any>;
  savedProfiles: Map<string, any>;
  selectedProfiles: List<any>;
  searchQuery: string;
  selectProfiles: (profiles: any, selected: boolean) => void;
}) => {
  const isSaved = React.useCallback(
    (row: DataRow) => {
      return savedProfiles.some((profile) => row.original.name === getProfileName(profile));
    },
    [savedProfiles],
  );
  const isSelected = React.useCallback(
    (row: DataRow) => selectedProfiles.includes(row.original.name),
    [selectedProfiles],
  );

  const prepareRowProps = React.useCallback(
    (rows: DataRow[]) => {
      const prepareProfiles = (rows: any[]): any[] => {
        return rows.reduce((acc, row) => {
          return row.subRows.length > 0
            ? [...acc, ...prepareProfiles(row.subRows)]
            : isSaved(row)
              ? acc
              : [...acc, row.original.name];
        }, []);
      };

      const isSelectable = some(rows, (row) => !isSaved(row));
      const isAllSelected = isSelectable && every(rows, (row) => isSelected(row) || isSaved(row));
      const isSomeSelected = !isAllSelected && isSelectable && some(rows, isSelected);

      return {
        isSelectable,
        checkboxProps: {
          checked: isAllSelected,
          indeterminate: isSomeSelected,
          onChange: () => selectProfiles(prepareProfiles(rows), !isAllSelected && !isSomeSelected),
        },
      };
    },
    [isSaved, isSelected, selectProfiles],
  );

  const columns = React.useMemo(() => {
    return [
      {
        accessorKey: 'data',
        header: ({ table }: any) => {
          const { isSelectable, checkboxProps } = prepareRowProps(table.getRowModel().rows);

          return (
            <>
              {isSelectable && <Checkbox className="tw-mr-3" {...checkboxProps} />}
              Profiles
            </>
          );
        },
        cell: ({ row }: { row: DataRow }) => {
          const isProfile = row.depth === 2;
          const { isSelectable, checkboxProps } = prepareRowProps(isProfile ? [row] : row.subRows);

          return (
            <div
              className={classNames('flex-container flex-start', {
                'tw-ml-4': row.depth === 1,
                'tw-ml-8': isProfile,
              })}
            >
              {!isSelectable ? (
                <Checkbox
                  disabled
                  tooltip={isProfile ? 'Profile is already added' : 'All profiles already added'}
                />
              ) : (
                <Checkbox {...checkboxProps} />
              )}
              <FontAwesomeIcon
                icon={isProfile ? 'user' : row.getIsExpanded() ? 'folder-open' : 'folder'}
                className="text-muted tw-mx-3"
              />
              <Truncated
                tooltip={row.original.name}
                text={<MarkedText source={row.original.name} mark={searchQuery} />}
              />
              {isProfile && !isSelectable && (
                <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-neutral-400">
                  Added
                </span>
              )}
            </div>
          );
        },
      },
    ];
  }, [searchQuery, prepareRowProps]);

  const data = React.useMemo(() => {
    return profiles
      .map((group, groupName) => {
        return {
          name: groupName || 'Unknown group',
          subRows: group
            .map((account: Map<string, any>, accountName: string) => {
              return {
                name: accountName,
                subRows: account
                  .map((profile: Map<string, any>) => {
                    return { name: getProfileName(profile) };
                  })
                  .toArray(),
              };
            })
            .toArray(),
        };
      })
      .toArray();
  }, [profiles]);

  const initialState = React.useMemo(() => {
    let expanded = {};

    if (!!searchQuery) {
      expanded = data
        .map((group, index) => {
          return [
            `${index}`,
            ...group.subRows.map((account: any, accountIndex: number) => {
              return `${index}.${accountIndex}`;
            }),
          ];
        })
        .flat()
        .reduce((all, key) => ({ ...all, [key]: true }), {});
    }

    return { sorting: [{ id: 'data', desc: false }], expanded };
  }, [searchQuery, data]);

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    enableSortingRemoval: false,
    autoResetExpanded: !!searchQuery,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const rows: DataRow[] = tableInstance.getRowModel().rows;

  return (
    <Table hover className="react-table stretch-modal">
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    <div
                      className="tw-inline-flex tw-cursor-pointer tw-items-center"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <SortIcon
                        className="icon-addon-left"
                        isSorted={!!header.column.getIsSorted()}
                        isSortedDesc={header.column.getIsSorted() === 'desc'}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {!rows.length && (
          <tr className="no-hover">
            <td>
              {isLoading ? (
                <>
                  <Loader className="icon-addon-right" />
                  Loading profiles...
                </>
              ) : (
                `No profiles ${!!searchQuery ? 'found' : 'available'}`
              )}
            </td>
          </tr>
        )}
        {rows.map((row) => {
          const isProfileAdded = row.depth === 2 && isSaved(row);

          return (
            <tr
              key={row.id}
              className={classNames({ clickable: !isProfileAdded })}
              onClick={() => {
                if (isProfileAdded || hasSelections()) {
                  return;
                }

                return row.depth < 2
                  ? row.toggleExpanded()
                  : selectProfiles([row.original.name], !isSelected(row));
              }}
            >
              {row.getAllCells().map((cell) => {
                return (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ProfilesSelector;
