import _ from 'underscore';

export const actionTypes = {
  TRIGGERS_LOAD: 'TRIGGERS_LOAD',
  TRIGGERS_LOAD_ERROR: 'TRIGGERS_LOAD_ERROR',
  TRIGGERS_LOAD_SUCCESS: 'TRIGGERS_LOAD_SUCCESS',
  TRIGGERS_DELETE_SUCCESS: 'TRIGGERS_DELETE_SUCCESS',
  TRIGGERS_CREATE_SUCCESS: 'TRIGGERS_CREATE_SUCCESS',
  TRIGGERS_UPDATE_SUCCESS: 'TRIGGERS_UPDATE_SUCCESS',
};

export const DEFAULT_COOLDOWN_PERIOD_MINUTES = 5;

export const defaultTrigger = { coolDownPeriodMinutes: DEFAULT_COOLDOWN_PERIOD_MINUTES };

export const crontabDefault = () => `${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * *`;
