import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import {
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
} from '@/constants/componentIds';
import { ioType } from '@/modules/components/Constants';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { getCurrentBranchTableWithProductionFallback } from '@/modules/storage/helpers';
import InlineTooltipWarningIcon from '@/react/common/InlineTooltipWarningIcon';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import Loader from '@/react/common/Loader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import NullablePrimaryKeyWarning from '@/react/common/NullablePrimaryKeyWarning';
import SourceSearchInPanel from '@/react/common/SourceSearch/SourceSearchInPanel';
import { getFileLocationHint, isDifferenceInColumns } from './helpers';
import TableInputMappingModal from './TableInputMappingModal';

const TableInputMappingRow = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    otherMappings: PropTypes.object.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired,
    replacementComponentId: PropTypes.string,
    singleMapping: PropTypes.bool,
    isDisabled: PropTypes.bool,
  },

  getInitialState() {
    return {
      isDeleting: false,
    };
  },

  render() {
    return (
      <span
        className={classNames('tr hoverable-actions', { 'opacity-half': this.props.isDisabled })}
      >
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    return (
      <>
        {!this.props.readOnly && !this.props.singleMapping && (
          <span className="td pr-0">
            <div className="tw-flex tw-h-full tw-items-center">
              <MultiActionsSelectCheckbox
                isChecked={this.props.isSelected}
                isDisabled={this.props.isDisabled || this.state.isDeleting}
                onToggle={this.props.toggleSelection}
                entity="mapping"
              />
            </div>
          </span>
        )}
        <span className="td col-xs-5">
          <div className="flex-container flex-start">
            {this.renderSource()}
            {this.renderNullablePrimaryKeyWarning()}
            {this.renderUnsynchronizedColumnsWarning()}
            {this.renderForceProductionWarning()}
          </div>
        </span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">
          <InputOutputTypeIcon type={this.props.destinationType} />
          {this.renderDestination()}
        </span>
      </>
    );
  },

  renderNullablePrimaryKeyWarning() {
    if (
      this.props.componentId === KEBOOLA_SNOWFLAKE_TRANSFORMATION &&
      this.props.value.get('source', '') &&
      this.props.value.get('column_types', Map()).some((datatype) => {
        return (
          datatype.get('convert_empty_values_to_null', false) &&
          this.getBranchedSourceTable().get('primaryKey', List()).includes(datatype.get('source'))
        );
      })
    ) {
      return <NullablePrimaryKeyWarning />;
    }
    return null;
  },

  renderUnsynchronizedColumnsWarning() {
    if (!this.hasUnsychronizedColumns()) {
      return null;
    }

    return (
      <InlineTooltipWarningIcon message="This table's column data types are out of sync. Please re-save it to fix this." />
    );
  },

  renderForceProductionWarning() {
    if (!this.props.value.get('source_branch_id') || !DevBranchesStore.isDevModeActive()) {
      return null;
    }

    return <InlineTooltipWarningIcon message="Forced production table" />;
  },

  renderDestination() {
    const destination = this.props.value.get('destination', this.props.value.get('source'));

    if (this.props.destinationType === ioType.FILE) {
      return `${getFileLocationHint(this.props.componentId)}/tables/${destination}`;
    }

    if (this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
      return destination.toUpperCase();
    }

    return destination;
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <TableInputMappingModal
          componentId={this.props.componentId}
          replacementComponentId={this.props.replacementComponentId}
          tables={this.props.tables}
          buckets={this.props.buckets}
          mapping={this.props.value}
          savedMapping={this.props.value}
          onSave={this.props.onSave}
          otherMappings={this.props.otherMappings}
          hasSourceTable={!this.getBranchedSourceTable().isEmpty()}
          destinationType={this.props.destinationType}
        />
        {this.props.value.get('source') !== '' && !this.props.singleMapping && (
          <Tooltip placement="top" tooltip="Delete Input">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => {
                this.setState({ isDeleting: true });
                this.props.onDelete().finally(() => this.setState({ isDeleting: false }));
              }}
              disabled={this.props.isDisabled || this.state.isDeleting}
            >
              {this.state.isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            </Button>
          </Tooltip>
        )}
      </span>
    );
  },

  renderSource() {
    if (this.props.value.has('source_search')) {
      return <SourceSearchInPanel />;
    }

    if (!this.props.value.get('source')) {
      return 'Not set';
    }

    return (
      <StorageApiTableLinkEx
        tableId={this.getBranchedSourceTable().get('id', this.props.value.get('source'))}
        forceProduction={
          this.props.value.has('source_branch_id') && DevBranchesStore.isDevModeActive()
        }
      />
    );
  },

  renderRightArrow() {
    const isFiltered =
      !!this.props.value.get('where_column') || this.props.value.get('columns', List()).count() > 0;
    const isIncremental = !!this.props.value.get('changed_since');

    if (!isFiltered && !isIncremental) {
      return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <>
            {isFiltered && <div>Filtered</div>}
            {isIncremental && <div>Incremental</div>}
          </>
        }
      >
        <FontAwesomeIcon icon={['far', 'chevrons-right']} className="text-muted" />
      </Tooltip>
    );
  },

  hasUnsychronizedColumns() {
    const columnsFromDataTypes = this.props.value
      .get('column_types', List())
      .map((column) => column.get('source'));

    return (
      !columnsFromDataTypes.isEmpty() &&
      isDifferenceInColumns(
        this.props.value.get('columns', List()),
        columnsFromDataTypes,
        this.getBranchedSourceTable().get('columns', List()),
      )
    );
  },

  getBranchedSourceTable() {
    return getCurrentBranchTableWithProductionFallback(
      this.props.tables,
      this.props.value.get('source'),
    );
  },
});

export default TableInputMappingRow;
