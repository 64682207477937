import Dispatcher from '@/Dispatcher';
import StorageApi from '@/modules/components/StorageApi';
import { actionTypes } from './constants';
import EventTriggersStore from './EventTriggersStore';

export const loadTriggersForce = () => {
  Dispatcher.handleViewAction({
    type: actionTypes.TRIGGERS_LOAD,
  });

  return StorageApi.listTriggers()
    .then((triggers) => {
      return Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_SUCCESS,
        triggers,
      });
    })
    .catch((error) => {
      Dispatcher.handleViewAction({
        type: actionTypes.TRIGGERS_LOAD_ERROR,
      });

      throw error;
    });
};

export const loadTriggers = () => {
  if (EventTriggersStore.getIsLoaded()) {
    loadTriggersForce();

    return Promise.resolve();
  }

  return loadTriggersForce();
};

export const createTrigger = (data: Record<string, any>) => {
  return StorageApi.createTrigger(data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_CREATE_SUCCESS,
      trigger,
    });
  });
};

export const updateTrigger = (id: string, data: Record<string, any>) => {
  return StorageApi.updateTrigger(id, data).then((trigger) => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_UPDATE_SUCCESS,
      trigger,
    });
  });
};

export const deleteTrigger = (id: string) => {
  return StorageApi.deleteTrigger(id).then(() => {
    Dispatcher.handleViewAction({
      type: actionTypes.TRIGGERS_DELETE_SUCCESS,
      id,
    });
  });
};
