import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Alert } from '@keboola/design';
import { Map } from 'immutable';
import { debounce } from 'underscore';

import { factory as eventsFactory } from '@/modules/sapi-events/EventsService';
import BlockButton from '@/react/common/BlockButton';
import SearchBar from '@/react/common/SearchBar';
import EventsTable from './EventsTable';

class Events extends React.Component {
  state = {
    events: Map(),
    isLoadingOlder: false,
    isLoading: false,
    hasMore: true,
    searchQuery: '',
    searchQueryLocal: '',
  };

  componentDidMount() {
    this.createEventsService();
  }

  componentWillUnmount() {
    this.destroyEventsService();
  }

  render() {
    return (
      <>
        {this.state.errorMessage && (
          <Alert variant="error" className="tw-mb-5">
            {this.state.errorMessage}
          </Alert>
        )}
        <div className="box">
          <div className="box-header above-table">
            <h2 className="box-title">Log</h2>
            <div className="box-filters">
              <SearchBar
                placeholder="Search events"
                query={this.state.searchQueryLocal}
                onChange={(query) => {
                  this.handleQueryChange(query);
                  this.handleDebouncedSearch(query);
                }}
                className="condensed as-input tw-mb-0"
              />
            </div>
          </div>
          <InfiniteScroll
            initialLoad={false}
            hasMore={this.state.hasMore}
            loadMore={this.handleLoadMore}
          >
            <EventsTable
              events={this.state.events}
              admins={this.props.admins}
              isLoading={this.state.isLoading}
              loadEvent={this.handleLoadEvent}
              searchQuery={this.state.searchQuery}
            />
          </InfiniteScroll>
          {this.renderMoreButton()}
        </div>
      </>
    );
  }

  renderMoreButton() {
    if (!this.state.hasMore) {
      return null;
    }

    return (
      <BlockButton
        onClick={this.handleLoadMore}
        isLoading={this.state.isLoadingOlder}
        disabled={this.state.isLoading || this.state.isLoadingOlder}
      />
    );
  }

  handleLoadMore = () => {
    if (this.state.isLoading || this.state.isLoadingOlder) {
      return;
    }

    this._events.loadMoreMax();
  };

  handleQueryChange = (query) => {
    this.setState({ searchQueryLocal: query });
  };

  handleDebouncedSearch = debounce((query) => {
    this._events.setQuery(query);
    this._events.load();
  }, 500);

  handleLoadEvent = (id) => {
    return this._events.loadEvent(id);
  };

  handleChange = () => {
    this.setState({
      searchQuery: this._events.getQuery(),
      events: this._events.getEvents(),
      isLoading: this._events.getIsLoading(),
      isLoadingOlder: this._events.getIsLoadingOlder(),
      hasMore: this._events.getHasMore(),
      errorMessage: this._events.getErrorMessage(),
    });
  };

  createEventsService = () => {
    this._events = eventsFactory(this.props.params, this.props.eventsApi);

    this._events.addChangeListener(this.handleChange);
    this._events.load();
    this._events.setAutoReload(this.props.autoReload);
  };

  destroyEventsService = () => {
    this._events.removeChangeListener(this.handleChange);
    this._events.reset();
  };
}

Events.propTypes = {
  admins: PropTypes.object.isRequired,
  params: PropTypes.object,
  autoReload: PropTypes.bool,
  eventsApi: PropTypes.object,
};

export default Events;
