import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, HelpBlock } from '@keboola/design';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import {
  KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE,
  KEBOOLA_PROCESSOR_SPLIT_TABLE,
} from '@/constants/componentIds';
import PrimaryKeyWarning from '@/modules/components/react/components/generic/PrimaryKeyWarning';
import {
  convertColumnCaseProcessor,
  paralledUploadProcessor,
} from '@/modules/ex-db-generic/constants';
import {
  hasCdc,
  supportConvertColumnCase,
  supportSplitLoading,
} from '@/modules/ex-db-generic/helpers';
import { getCustomFieldsForComponent } from '@/modules/ex-db-generic/templates/customFields';
import editorMode from '@/modules/ex-db-generic/templates/editorMode';
import {
  getQueryEditorHelpText,
  getQueryEditorPlaceholder,
} from '@/modules/ex-db-generic/templates/helpAndHints';
import Checkbox from '@/react/common/Checkbox';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import TableSelectorForm from '@/react/common/TableSelectorForm';
import nextTick from '@/utils/nextTick';
import AsynchActionError from './AsynchActionError';
import ColumnLoader from './ColumnLoaderQueryEditor';
import LastFetchedValue from './LastFetchedValue';
import RefreshTables from './RefreshTables';

const QueryEditor = createReactClass({
  propTypes: {
    component: PropTypes.instanceOf(Map).isRequired,
    processors: PropTypes.instanceOf(Map).isRequired,
    query: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onProcessorsChange: PropTypes.func.isRequired,
    showSimple: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    configId: PropTypes.string.isRequired,
    getDefaultOutputTable: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    isLoadingSourceTables: PropTypes.bool.isRequired,
    isLoadingColumns: PropTypes.bool.isRequired,
    sourceTables: PropTypes.object.isRequired,
    sourceTablesError: PropTypes.string,
    destinationEditing: PropTypes.bool.isRequired,
    onDestinationEdit: PropTypes.func.isRequired,
    getPKColumns: PropTypes.func.isRequired,
    queryNameExists: PropTypes.bool.isRequired,
    onResetState: PropTypes.func.isRequired,
    credentialsHasDatabase: PropTypes.bool,
    credentialsHasSchema: PropTypes.bool,
    refreshMethod: PropTypes.func.isRequired,
    isConfigRow: PropTypes.bool,
    incrementalCandidates: PropTypes.object,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  isExistingTable() {
    const destinationTable = this.props.query.get('outputTable');
    if (!destinationTable || destinationTable === '') {
      return false;
    }
    return this.props.tables.has(destinationTable);
  },

  handleToggleUseQueryEditor(checked) {
    return this.props.onChange(this.props.query.set('advancedMode', checked));
  },

  handleDestinationChange(newValue) {
    return this.props.onChange(this.props.query.set('outputTable', newValue));
  },

  onDestinationEdit() {
    this.props.onChange(this.props.query);
    this.props.onDestinationEdit(this.props.configId, this.props.query.get('id'));
  },

  handlePrimaryKeyChange(newValue) {
    return this.props.onChange(this.props.query.set('primaryKey', newValue));
  },

  handleIncrementalChange(checked) {
    return this.props.onChange(this.props.query.set('incremental', checked));
  },

  handleCdcChange(checked) {
    return this.props.onChange(this.props.query.set('cdcMode', checked));
  },

  handleParallelLoadChange(checked) {
    return this.props.onProcessorsChange(
      this.props.processors
        .update('after', List(), (after) => {
          if (checked) {
            return after.push(paralledUploadProcessor);
          }

          return after.filter((processor) => {
            return (
              processor.getIn(['definition', 'component']) !==
              paralledUploadProcessor.getIn(['definition', 'component'])
            );
          });
        })
        .update((processors) => {
          if (processors.get('after', List()).isEmpty()) {
            return processors.delete('after');
          }

          return processors;
        }),
    );
  },

  handleConvertColumnCaseChange(selected) {
    const processors = this.props.processors
      .update('after', List(), (after) => {
        const processorIndex = after.findIndex((processor) => {
          return (
            processor.getIn(['definition', 'component']) === KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE
          );
        });

        if (!selected) {
          return processorIndex === -1 ? after : after.delete(processorIndex);
        }

        return processorIndex === -1
          ? after.insert(
              0,
              convertColumnCaseProcessor.setIn(['parameters', 'convert_to'], selected),
            )
          : after.setIn([processorIndex, 'parameters', 'convert_to'], selected);
      })
      .update((processors) => {
        return processors.get('after', List()).isEmpty() ? processors.delete('after') : processors;
      });

    return this.props.onProcessorsChange(processors);
  },

  handleIncrementalFetchingColumnChange(newValue) {
    return this.props.onChange(this.props.query.set('incrementalFetchingColumn', newValue));
  },

  handleIncrementalFetchingLimitChange(event) {
    return this.props.onChange(
      this.props.query.set('incrementalFetchingLimit', parseInt(event.target.value, 10)),
    );
  },

  handleQueryChange(value) {
    return this.props.onChange(this.props.query.set('query', value));
  },

  handleNameChange(event) {
    const currentOutputTable = this.props.query.get('outputTable');
    return this.props.onChange(
      this.props.query
        .set('name', event.target.value)
        .set(
          'outputTable',
          !currentOutputTable
            ? this.props.getDefaultOutputTable(event.target.name)
            : currentOutputTable,
        ),
    );
  },

  handleCustomCheckboxChange(propName, checked) {
    return this.props.onChange(this.props.query.set(propName, checked));
  },

  sourceTableSelectOptions() {
    if (!this.props.sourceTables || !this.props.sourceTables.count()) {
      return [];
    }

    return this.props.sourceTables
      .filter((table) => table.get('schema') && table.get('name'))
      .groupBy((table) => table.get('schema'))
      .map((tables, schema) => ({
        label: schema,
        options: tables
          .map((table) => {
            const label = `${schema}.${table.get('name')} ${
              table.get('cdcEnabled') ? ' [CDC]' : ''
            }`;

            return {
              value: {
                schema,
                tableName: table.get('name'),
              },
              label,
            };
          })
          .toArray(),
      }))
      .toArray();
  },

  getCandidateTable() {
    const selectedTable = this.props.query.get('table');
    if (!selectedTable) {
      return null;
    }
    return this.props.incrementalCandidates.find((candidate) => {
      return (
        candidate.get('tableName') === selectedTable.get('tableName') &&
        candidate.get('schema') === selectedTable.get('schema')
      );
    });
  },

  incrementalFetchingOptions() {
    if (!this.props.incrementalCandidates || !this.props.query.get('table')) {
      return [];
    }
    let candidateTable = this.getCandidateTable();
    if (candidateTable) {
      return candidateTable
        .get('candidates')
        .map((candidate) => {
          return {
            value: candidate.get('name'),
            label: candidate.get('name'),
          };
        })
        .toList()
        .toJS();
    } else {
      return [];
    }
  },

  getPrimaryKey(newValue) {
    return this.isExistingTable()
      ? this.props.tables.getIn([this.props.query.get('outputTable'), 'primaryKey'], List())
      : this.props
          .getPKColumns(fromJS(newValue), this.props.sourceTables)
          .map((column) => column.get('name'))
          .toList();
  },

  primaryKeyHelp() {
    if (!this.isExistingTable()) {
      const selectedColumns = this.props.query.get('columns', List());
      const primaryKeys = this.getSelectedTableColumns().filter((column) => {
        return column.get('primaryKey', false);
      });
      const allowedPrimaryKeys = primaryKeys.filter((column) => {
        return selectedColumns.isEmpty() || selectedColumns.includes(column.get('name'));
      });

      if (allowedPrimaryKeys.isEmpty() || allowedPrimaryKeys.count() < primaryKeys.count()) {
        return null;
      }

      return (
        <HelpBlock>
          <Button
            bsStyle="link"
            className="btn-link-inline"
            onClick={(e) => {
              e.preventDefault();
              this.handlePrimaryKeyChange(allowedPrimaryKeys.map((column) => column.get('name')));
            }}
          >
            Set the primary keys
          </Button>{' '}
          from the source table.
        </HelpBlock>
      );
    }

    const destinationPKs = this.props.tables.getIn(
      [this.props.query.get('outputTable'), 'primaryKey'],
      List(),
    );

    if (destinationPKs.equals(this.props.query.get('primaryKey'))) {
      return null;
    }

    return <PrimaryKeyWarning primaryKey={destinationPKs} onChange={this.handlePrimaryKeyChange} />;
  },

  handleSourceTableChange(newValue) {
    const currentName = this.props.query.get('name');
    const oldTableName = this.props.query.getIn(['table', 'tableName'], '');
    const newName = currentName && currentName !== oldTableName ? currentName : newValue.tableName;

    let newQuery = this.props.query
      .set('table', newValue === '' ? newValue : fromJS(newValue))
      .set('name', newName ? newName : '')
      .set('columns', List())
      .set('primaryKey', newValue === '' ? List() : this.getPrimaryKey(newValue));

    if (this.props.isConfigRow) {
      newQuery = newQuery.set('incrementalFetchingColumn', '');
    }

    this.props.onChange(newQuery);

    if (newValue && supportSplitLoading(this.props.componentId)) {
      this.props.refreshMethod(this.props.query.get('id')).then(() => {
        return nextTick(() => {
          this.props.onChange(newQuery.set('primaryKey', this.getPrimaryKey(newValue)));
        });
      });
    }
  },

  getColumnsOptions() {
    return this.getSelectedTableColumns()
      .map((column) => {
        return {
          label: column.get('type')
            ? `${column.get('name')} [${column.get('type').toUpperCase()}]`
            : column.get('name'),
          value: column.get('name'),
        };
      })
      .toJS();
  },

  getSelectedTableColumns() {
    const schema = this.props.query.getIn(['table', 'schema']);
    const name = this.props.query.getIn(['table', 'tableName']);

    return this.props.sourceTables
      .find((table) => table.get('schema') === schema && table.get('name') === name, null, Map())
      .get('columns', List());
  },

  handleChangeColumns(newValue) {
    return this.props.onChange(this.props.query.set('columns', newValue));
  },

  getQuery() {
    return this.props.query.get('query') || '';
  },

  getTable() {
    return this.props.sourceTables.find(
      (t) =>
        t.get('schema') === this.props.query.getIn(['table', 'schema']) &&
        t.get('name') === this.props.query.getIn(['table', 'tableName']),
    );
  },

  getTableValue() {
    if (this.props.query.get('table')) {
      const name = `${this.props.query.get('table').get('schema')}.${this.props.query
        .get('table')
        .get('tableName')}`;

      return `${name} ${this.isCdcAvailable() ? ' [CDC]' : ''}`;
    }

    return '';
  },

  isCdcAvailable() {
    return this.getTable()?.get('cdcEnabled');
  },

  getOutputTableValue() {
    if (this.props.query.get('outputTable') !== '') {
      return this.props.query.get('outputTable');
    } else {
      return this.props.getDefaultOutputTable(this.props.query.get('name'));
    }
  },

  getPkValue() {
    const pk = this.props.query.get('primaryKey');
    if (List.isList(pk)) {
      return pk;
    }
    return List();
  },

  render() {
    return (
      <div className="form-horizontal">
        <AsynchActionError
          componentId={this.props.componentId}
          configId={this.props.configId}
          sourceTablesLoading={this.props.isLoadingSourceTables}
          sourceTablesError={this.props.sourceTablesError}
        />
        {this.props.showSimple && !this.props.query.get('advancedMode') && (
          <div className="box">
            <div className="box-header big-padding with-border">
              <h3 className="box-title">Data Source</h3>
            </div>
            <div className="box-content">
              {this.renderSimpleTable()}
              {this.renderSimpleColumns()}
            </div>
          </div>
        )}
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">General Settings</h3>
          </div>
          <div className="box-content">
            <div className={this.props.queryNameExists ? 'form-group has-error' : 'form-group'}>
              <label className="col-md-3 control-label">Name</label>
              <div className="col-md-9">
                <input
                  className="form-control"
                  type="text"
                  value={this.props.query.get('name')}
                  placeholder="e.g., Untitled Query"
                  disabled={this.props.disabled}
                  onChange={this.handleNameChange}
                />
                {this.props.queryNameExists && (
                  <div className="help-block">This name already exists</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label">Destination</label>
              <div className="col-md-9">
                <TableSelectorForm
                  tables={this.props.tables}
                  buckets={this.props.buckets}
                  value={this.getOutputTableValue() || ''}
                  onChange={this.handleDestinationChange}
                  disabled={this.props.disabled}
                  onEdit={this.onDestinationEdit}
                  editing={this.props.destinationEditing}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderIncrementalFetchingSection()}
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Loading Options</h3>
          </div>
          <div className="box-content">
            <div className="form-group">
              <label className="col-md-3 control-label">Primary Key</label>
              <div className="col-md-9">
                <Select
                  name="primaryKey"
                  value={this.getPkValue()}
                  multi
                  disabled={this.props.disabled || this.props.isLoadingColumns}
                  allowCreate
                  placeholder={this.isExistingTable() ? 'Cannot add a column' : 'Add a column'}
                  onChange={this.handlePrimaryKeyChange}
                  options={this.getColumnsOptions()}
                  promptTextCreator={(label) =>
                    label ? 'Add the "' + label + '" column as the primary key' : ''
                  }
                />
                {this.primaryKeyHelp()}
              </div>
            </div>
            {this.renderConvertColumnCaseOption()}
            {this.renderIncrementalLoadOption()}
            {this.renderParalledLoadOption()}
            {this.renderCustomFields()}
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Advanced Mode</h3>
          </div>
          <div className="box-content">
            {this.renderQueryToggle()}
            {this.renderQueryEditor()}
          </div>
        </div>
      </div>
    );
  },

  renderCustomFields() {
    const isAdvancedMode = this.props.query.get('advancedMode');
    return getCustomFieldsForComponent(this.props.componentId).reduce((customFields, field) => {
      if ((field.showInAdvancedMode && isAdvancedMode) || !isAdvancedMode) {
        if (field.type === 'checkbox') {
          customFields.push(
            <FormGroup key={`custom-field-${field.name}`}>
              <div className="col-xs-9 col-xs-offset-3">
                <Checkbox
                  checked={!!this.props.query.get(field.name)}
                  disabled={this.props.disabled}
                  onChange={(checked) => this.handleCustomCheckboxChange(field.name, checked)}
                >
                  {field.label}
                </Checkbox>
                <HelpBlock>{field.help}</HelpBlock>
              </div>
            </FormGroup>,
          );
        }
      }
      return customFields;
    }, []);
  },

  renderIncrementalLoadOption() {
    return (
      <FormGroup>
        <div className="col-xs-9 col-xs-offset-3">
          <Checkbox
            checked={this.props.query.get('incremental')}
            onChange={this.handleIncrementalChange}
            disabled={this.props.disabled}
          >
            Incremental loading
          </Checkbox>
          <HelpBlock>
            If incremental load is turned on, the table will be updated instead of rewritten. Tables
            with a primary key will have rows updated, tables without a primary key will have rows
            appended.
          </HelpBlock>
          {this.props.query.get('incrementalFetchingColumn') &&
            !this.props.query.get('incremental') && (
              <Alert variant="warning" className="tw-mb-5">
                It is recommended to enable incremental loading if using incremental fetching. If
                incremental loading is <strong>not</strong> enabled, the Storage table will always
                contain only the most recently fetched results.
              </Alert>
            )}
        </div>
      </FormGroup>
    );
  },

  renderParalledLoadOption() {
    if (
      !this.props.isConfigRow ||
      !this.props.component
        .getIn(['emptyConfigurationRow', 'processors', 'after'], List())
        .some((processor) => {
          return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_SPLIT_TABLE;
        })
    ) {
      return null;
    }

    const isActive = this.props.processors.get('after', List()).some((processor) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_SPLIT_TABLE;
    });

    return (
      <FormGroup>
        <div className="col-xs-9 col-xs-offset-3">
          <Checkbox
            checked={isActive}
            disabled={this.props.disabled}
            onChange={this.handleParallelLoadChange}
          >
            Parallel load to Storage
          </Checkbox>
          <HelpBlock>
            The exported table will be sliced and imported into Storage in parallel.
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },

  renderConvertColumnCaseOption() {
    const processorConfig = this.props.processors.get('after', List()).find((processor) => {
      return (
        processor.getIn(['definition', 'component']) === KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE
      );
    });

    if (!processorConfig && !supportConvertColumnCase(this.props.componentId)) {
      return null;
    }

    return (
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Convert columns to</ControlLabel>
        </div>
        <div className="col-xs-9">
          <Select
            clearable={false}
            value={processorConfig?.getIn(['parameters', 'convert_to']) ?? ''}
            onChange={this.handleConvertColumnCaseChange}
            options={[
              { value: '', label: 'Do not convert' },
              { value: 'uppercase', label: 'UPPERCASE' },
              { value: 'lowercase', label: 'lowercase' },
            ]}
          />
          <HelpBlock>
            Use this option to convert the column case to UPPERCASE or lowercase if needed.
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },

  renderQueryToggle() {
    if (this.props.showSimple) {
      return (
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">
            <Checkbox
              checked={!!this.props.query.get('advancedMode')}
              onChange={this.handleToggleUseQueryEditor}
              disabled={this.props.disabled}
            >
              Create your own query using an SQL editor
            </Checkbox>
          </div>
        </FormGroup>
      );
    }
  },

  renderQueryEditor() {
    if (this.props.query.get('advancedMode')) {
      return (
        <div>
          <label className="control-label">SQL Query</label>
          {this.renderQueryHelpBlock()}
          <CodeEditor
            value={this.getQuery()}
            onChange={this.handleQueryChange}
            options={{
              mode: editorMode(this.props.componentId),
              placeholder: getQueryEditorPlaceholder(this.props.componentId),
              readOnly: this.props.disabled,
            }}
          />
        </div>
      );
    }
  },

  renderSimpleTable() {
    return (
      <div className="form-group">
        <label className="col-md-3 control-label">Table</label>
        <div className="col-md-9">
          {this.props.readOnly ? (
            <FormControl.Static>{this.getTableValue()}</FormControl.Static>
          ) : this.props.isLoadingSourceTables ? (
            <FormControl.Static>
              <Loader className="icon-addon-right" />
              Fetching the list of tables from the source database...
            </FormControl.Static>
          ) : (
            <>
              <Select
                name="sourceTable"
                value={this.getTableValue()}
                placeholder="Select source table"
                onChange={this.handleSourceTableChange}
                options={this.sourceTableSelectOptions()}
                disabled={this.props.disabled}
              />
              <RefreshTables refresh={this.props.refreshMethod} />
            </>
          )}
        </div>
      </div>
    );
  },

  renderSimpleColumns() {
    return (
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Columns</ControlLabel>
        </div>
        <div className="col-xs-9">
          <ColumnLoader
            query={this.props.query}
            componentId={this.props.componentId}
            isLoadingColumns={this.props.isLoadingColumns}
            isLoadingSourceTables={this.props.isLoadingSourceTables}
            refreshMethod={() => this.props.refreshMethod(this.props.query.get('id'))}
            columnSelector={
              <Select
                multi
                name="columns"
                value={this.props.query.get('columns', List())}
                disabled={this.props.disabled || !this.props.query.get('table')}
                placeholder="All columns will be imported"
                onChange={this.handleChangeColumns}
                options={this.getColumnsOptions()}
              />
            }
          />
        </div>
      </FormGroup>
    );
  },

  renderQueryHelpBlock() {
    const helpText = getQueryEditorHelpText(this.props.componentId);
    if (helpText) {
      return <div className="help-block">{helpText}</div>;
    } else if (
      this.props.componentId === 'keboola.ex-db-mysql' &&
      !this.props.credentialsHasDatabase
    ) {
      return (
        <div className="help-block">
          <FontAwesomeIcon icon="triangle-exclamation" /> This connection does not have a database
          specified. Please be sure to prefix table names with the schema
          <br />
          (e.g., `schemaName`.`tableName`)
        </div>
      );
    } else if (
      this.props.componentId === 'keboola.ex-db-snowflake' &&
      !this.props.credentialsHasSchema
    ) {
      return (
        <div className="help-block">
          <FontAwesomeIcon icon="triangle-exclamation" /> This connection does not have a schema
          specified. Please be sure to prefix table names with the schema
          <br />
          (e.g., &quot;schemaName&quot;.&quot;tableName&quot;)
        </div>
      );
    }
  },

  renderIncrementalFetchingForm() {
    return (
      <>
        <div className="form-group">
          <label className="col-md-3 control-label">Column</label>
          <div className="col-md-9">
            <Select
              name="incrementalFetching"
              value={this.props.query.get('incrementalFetchingColumn') || ''}
              placeholder="Fetch by column"
              onChange={this.handleIncrementalFetchingColumnChange}
              options={this.incrementalFetchingOptions()}
              disabled={this.props.disabled || !!this.getLastFetchedRowValue()}
            />
            <div className="help-block">{this.incrementalFetchingWarning()}</div>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Limit</label>
          <div className="col-md-9">
            <input
              min="0"
              className="form-control"
              name="incrementalFetchingLimit"
              type="number"
              value={this.props.query.get('incrementalFetchingLimit') || 0}
              onChange={this.handleIncrementalFetchingLimitChange}
              disabled={this.props.disabled || !this.props.query.get('incrementalFetchingColumn')}
            />
            <div className="help-block">
              The number of records to fetch from the source per run. Subsequent runs will start
              from the last record fetched. Note: 0 means unlimited.
            </div>
          </div>
        </div>
        {this.props.query.get('incrementalFetchingColumn') && (
          <div className="form-group">
            <label className="col-md-3 control-label">Last Fetched Value</label>
            <div className="col-md-9">
              <LastFetchedValue
                value={this.props.query.getIn(['state', 'component', 'lastFetchedRow'])}
                incrementalFetchingColumn={this.props.query.get('incrementalFetchingColumn')}
                onReset={this.props.onResetState}
              />
            </div>
          </div>
        )}
      </>
    );
  },

  renderIncrementalFetching() {
    const isCdcModeOn = !!this.props.query.get('cdcMode');
    const hasCdcMode = hasCdc(this.props.componentId);

    return (
      <>
        {!isCdcModeOn &&
        (this.incrementalFetchingOptions().length > 0 ||
          this.props.query.get('incrementalFetchingColumn')) ? (
          this.renderIncrementalFetchingForm()
        ) : (
          <div className="form-group">
            <div className="col-md-8 col-md-offset-3">
              <div className="help-block">
                {hasCdcMode && isCdcModeOn
                  ? 'Incremental fetching is disabled with CDC mode.'
                  : 'Incremental fetching is available for this component but only for tables containing numeric or timestamp/datetime columns.'}
              </div>
            </div>
          </div>
        )}
      </>
    );
  },

  renderIncrementalFetchingSection() {
    if (this.props.query.get('advancedMode') || !this.props.isConfigRow) {
      return null;
    }

    const isCdcModeOn = !!this.props.query.get('cdcMode');

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Incremental Fetching</h3>
        </div>
        <div className="box-content">
          {hasCdc(this.props.componentId) && (
            <div className="form-group">
              <label className="col-md-3 control-label">CDC Mode</label>
              <div className="col-md-9">
                <Checkbox
                  className={classNames('tw-mt-2', this.isCdcAvailable() && 'tw-mb-2')}
                  checked={isCdcModeOn}
                  disabled={!this.isCdcAvailable() || this.props.disabled}
                  onChange={this.handleCdcChange}
                >
                  Enabled
                </Checkbox>
                {!this.isCdcAvailable() && (
                  <HelpBlock>
                    CDC tables must be enabled on the source for this option to become available.
                  </HelpBlock>
                )}
              </div>
            </div>
          )}

          {this.renderIncrementalFetching()}
        </div>
      </div>
    );
  },

  incrementalFetchingWarning() {
    var infoMessage =
      'If enabled, only newly created or updated records since the last run will be fetched.';
    if (
      this.props.query.get('incrementalFetchingColumn') &&
      this.props.sourceTables.count() > 0 &&
      !!this.props.query.get('table')
    ) {
      let candidateTable = this.getCandidateTable();
      if (candidateTable) {
        let candidateColumn = candidateTable
          .get('candidates')
          .find(
            (column) => column.get('name') === this.props.query.get('incrementalFetchingColumn'),
            null,
            Map(),
          );
        if (candidateColumn.get('autoIncrement')) {
          infoMessage =
            'Using an autoIncrement ID means that only new records will be fetched, not updates or' +
            ' deletes.';
        } else {
          infoMessage =
            'Using an update timestamp column means that only new and updated records will be fetched,' +
            ' not deletes.';
        }
      } else {
        infoMessage =
          'In order to enable incremental fetching, the source table must contain a timestamp column or' +
          ' an auto-incrementing primary key.';
      }
    }
    return infoMessage;
  },

  getLastFetchedRowValue() {
    return this.props.query.getIn(['state', 'component', 'lastFetchedRow'], false);
  },
});

export default QueryEditor;
