import React from 'react';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { Alert, Clipboard as ClipboardButton, HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import Processors from '@/modules/components/react/components/Processors';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import actionsProvisioning from '@/modules/ex-email-attachments/actionsProvisioning';
import storeProvisioning, { storeMixins } from '@/modules/ex-email-attachments/storeProvisioning';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Loader from '@/react/common/Loader';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import getDefaultBucket from '@/utils/getDefaultBucket';
import ConfigurationForm from './ConfigurationForm';

const COMPONENT_ID = 'keboola.ex-email-attachments';

const Index = createReactClass({
  mixins: [createStoreMixin(...storeMixins, ApplicationStore, ComponentsStore, StorageTablesStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      configId: configId,
      store: store,
      actions: actions,
      localState: store.getLocalState(),
      settings: store.settings,
      processors: store.processors,
      allTables: StorageTablesStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      allComponents: ComponentsStore.getAll(),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  componentDidMount() {
    this.state.actions.requestEmailAndInitConfig();
  },

  getDefaultBucketName() {
    return getDefaultBucket('in', COMPONENT_ID, this.state.configId) + '.data';
  },

  renderImportedResult() {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Output Table</ControlLabel>
        </div>
        <div className="col-xs-8">
          <InputGroup>
            <SapiTableLinkEx tableId={this.getDefaultBucketName()} />
          </InputGroup>
          <HelpBlock>
            Table in Storage, where the .csv attachments will be imported. If the table or bucket
            does not exist, it will be created.
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.store.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription componentId={COMPONENT_ID} configId={this.state.configId} />
            {this.state.store.requestedEmail ? this.renderConfig() : this.renderInitConfig()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              run={{
                disabled: this.invalidToRun(),
                text: 'You are about to run an extraction.',
              }}
            />
          </div>
        </div>
      </>
    );
  },

  renderInitConfig() {
    if (this.state.store.error) {
      return this.renderError();
    }

    return (
      <div className="box">
        <div className="box-content">
          <p>
            <Loader /> Generating an email address, please wait.
          </p>
        </div>
      </div>
    );
  },

  renderConfig() {
    return (
      <>
        <div className="box">
          <div className="box-content">
            <div className="form-horizontal">
              <FormGroup>
                <div className="col-xs-4">
                  <ControlLabel>Email address</ControlLabel>
                </div>
                <div className="col-xs-8">
                  <div className="tw-flex tw-items-center tw-gap-4">
                    <FormControl
                      type="email"
                      placeholder="Creating an email address, please wait"
                      readOnly
                      value={this.state.store.requestedEmail}
                      title="Email address generated by Email Attachments"
                    />
                    <ClipboardButton
                      inline={false}
                      label="Copy email"
                      text={this.state.store.requestedEmail}
                    />
                  </div>
                  <HelpBlock>
                    Please send emails with .csv attachments to this email address. Received
                    attachments will be imported after configuration execution. Only new emails are
                    imported on subsequent executions.
                  </HelpBlock>
                </div>
              </FormGroup>
              {this.renderImportedResult()}
            </div>
          </div>
        </div>
        <ConfigurationForm
          readOnly={this.state.readOnly}
          incremental={this.state.settings.get('incremental')}
          delimiter={this.state.settings.get('delimiter')}
          enclosure={this.state.settings.get('enclosure')}
          primaryKey={this.state.settings.get('primaryKey')}
          onChange={this.state.actions.editChange}
          requestedEmail={this.state.store.requestedEmail}
          actions={this.state.actions}
          localState={this.state.localState}
        />
        {this.renderProcessors()}
      </>
    );
  },

  renderError() {
    const error = this.state.store.error;
    const code = error.code;
    let message = 'Unexpected error';
    try {
      const jsError = JSON.parse(error).error;
      message = jsError.message || jsError;
    } catch {
      message = error.message || error;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        <p>Error: {message}</p>
        {code >= 500 ? <div>{error.get('exceptionId')}</div> : null}
      </Alert>
    );
  },

  invalidToRun() {
    if (
      !this.state.settings.get('enclosure') ||
      !this.state.settings.get('delimiter') ||
      !this.state.settings.get('email')
    ) {
      return 'Configuration has missing values';
    }
    if (this.state.localState.get('isChanged', false)) {
      return 'Configuration is not saved';
    }

    return '';
  },

  renderProcessors() {
    if (this.state.processors === '{}') {
      return null;
    }

    return (
      <Processors
        readOnly={this.state.readOnly}
        value={this.state.processors}
        onSubmit={this.state.actions.editProcessorsSave}
      />
    );
  },
});

export default Index;
