import React from 'react';
import { cn, DotsLoader } from '@keboola/design';
import type { Map } from 'immutable';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import { roles } from '@/modules/automations/constants';
import { isInteractiveMessage } from '@/modules/automations/helpers';
import Gravatar from '@/react/common/Gravatar';
import Markdown from '@/react/common/Markdown';
import KeboolaLogo from '@/react/layout/KeboolaLogo';
import InteractiveElement from './InteractiveElement';

const Message = ({
  messageObject,
  admin,
  isLastMessage,
  submitMessage,
  flowConfigId,
}: {
  messageObject?: AutomationMessage;
  admin?: Map<string, any>;
  isLastMessage?: boolean;
  submitMessage?: (messageObject: Partial<UserMessage>) => void;
  flowConfigId?: string;
}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);

  const isClientMessage = messageObject?.role === roles.USER;

  React.useEffect(() => {
    if (isLastMessage && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLastMessage]);

  return (
    <div
      ref={elementRef}
      className={cn('tw-flex tw-w-full tw-gap-1 first:tw-mt-auto', {
        'tw-self-end': isClientMessage,
      })}
    >
      {messageObject?.type !== 'flowGenerated' && (
        <>
          {isClientMessage ? (
            <div className="tw-order-last tw-shrink-0">
              <Gravatar user={admin} size={24} />
            </div>
          ) : (
            <KeboolaLogo className="tw-shrink-0" height={24} width={24} withoutText />
          )}
        </>
      )}
      <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-2">
        {(!messageObject || messageObject?.type !== 'flowGenerated') && (
          <div
            className={cn(
              'tw-w-fit tw-rounded-lg tw-p-4',
              isClientMessage
                ? 'tw-self-end tw-rounded-tr-none tw-bg-secondary-600 tw-text-white'
                : 'tw-rounded-tl-none tw-bg-neutral-50',
            )}
          >
            {!!messageObject?.text && (
              <Markdown overflow={false} collapsible={false} source={messageObject.text} />
            )}
            {messageObject?.type &&
              [
                'componentSelectionResponse',
                'sourceTableSelectionResponse',
                'intentConfirmationResponse',
              ].includes(messageObject.type) &&
              (messageObject.data.selectedValues?.length
                ? messageObject.data.selectedValues.join(', ')
                : 'Not Relevant')}
            {!messageObject && <DotsLoader />}
          </div>
        )}
        {isInteractiveMessage(messageObject) && isLastMessage && submitMessage && messageObject && (
          <InteractiveElement
            messageObject={messageObject}
            submitMessage={submitMessage}
            flowConfigId={flowConfigId}
          />
        )}
      </div>
    </div>
  );
};

export default Message;
