import React from 'react';
import { Alert } from '@keboola/design';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ApplicationStore from '@/stores/ApplicationStore';

const DevBranchStorageWarning = (props: { message: string; hasProductionEntity: boolean }) => {
  if (
    !props.hasProductionEntity ||
    !DevBranchesStore.isDevModeActive() ||
    ApplicationStore.hasProtectedDefaultBranch()
  ) {
    return null;
  }

  return (
    <Alert variant="warning" className="tw-mb-5">
      {props.message}
    </Alert>
  );
};

export default DevBranchStorageWarning;
