import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

type Props = {
  onOpen: () => void;
  onDelete: () => void;
  onExploreData?: () => void;
  hasData: boolean;
};

const TaskActions = (props: Props) => {
  return (
    <span className="actions task-actions" onMouseDown={(e) => e.stopPropagation()}>
      <Tooltip placement="top" tooltip="Delete Task">
        <Button
          bsStyle="link"
          className="btn-link-inline circle-button text-muted"
          onClick={props.onDelete}
        >
          <FontAwesomeIcon icon="trash" />
        </Button>
      </Tooltip>
      {props.onExploreData && props.hasData && (
        <Tooltip placement="top" tooltip="Explore Data">
          <Button
            bsStyle="link"
            className="btn-link-inline circle-button text-muted"
            onClick={props.onExploreData}
          >
            <FontAwesomeIcon icon="warehouse" />
          </Button>
        </Tooltip>
      )}
      <Tooltip placement="top" tooltip="Show Details">
        <Button
          bsStyle="link"
          className="btn-link-inline circle-button text-muted"
          onClick={props.onOpen}
        >
          <FontAwesomeIcon icon="gear" />
        </Button>
      </Tooltip>
    </span>
  );
};

export default TaskActions;
