import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clipboard, cn, Tooltip } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { createTablePrimaryKey, removeTablePrimaryKey } from '@/modules/storage/actions';
import { BackendEntityInfoTooltip } from '@/modules/storage/components/BackendEntityInfoTooltip';
import { backends, routeNames } from '@/modules/storage/constants';
import { tableName } from '@/modules/storage/helpers';
import ConfirmModal from '@/react/common/ConfirmModal';
import CreatedDate from '@/react/common/CreatedDate';
import FileSize from '@/react/common/FileSize';
import InfoTooltip from '@/react/common/InfoTooltip';
import Loader from '@/react/common/Loader';
import Link from '@/react/common/RouterLink';
import RowsCount from '@/react/common/RowsCount';
import TableUpdatedBy from '@/react/common/TableUpdatedBy';
import ApplicationStore from '@/stores/ApplicationStore';
import CreatePrimaryKeyModal from './CreatePrimaryKeyModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import ExternalProjectTableLink from './ExternalProjectTableLink';
import LatestImports from './LatestImports';
import NotAvailable from './NotAvailable';
import ProjectAliasLink from './ProjectAliasLink';
import StorageDescription from './StorageDescription';
import AliasFilter from './TableAliasFilter';

const TableOverview = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    canManageBuckets: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    table: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    components: PropTypes.instanceOf(Map).isRequired,
    configurations: PropTypes.instanceOf(Map).isRequired,
    tableAliases: PropTypes.array.isRequired,
    tableLinks: PropTypes.array.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired,
    creatingPrimaryKey: PropTypes.bool.isRequired,
    deletingPrimaryKey: PropTypes.bool.isRequired,
    settingAliasFilter: PropTypes.bool.isRequired,
    removingAliasFilter: PropTypes.bool.isRequired,
    canWriteTable: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showCreatePrimaryKeyModal: false,
      showRemovePrimaryKeyModal: false,
    };
  },

  render() {
    const table = this.props.table;
    const isExternal = table.getIn(['bucket', 'hasExternalSchema']);
    const isInternal = !isExternal;
    const isAlias = table.get('isAlias', false);
    const path = table.getIn(['bucket', 'path']);
    const backend = table.getIn(['bucket', 'backend']);
    const isSnowflakeBackend = backend === backends.SNOWFLAKE;
    const isBigQueryBackend = backend === backends.BIGQUERY;

    const isSchemaTableNameVisible = (() => {
      const isReadOnly = ApplicationStore.hasReadOnlyStorage();
      const isSupportedBackend = isSnowflakeBackend || isBigQueryBackend;
      const pathExist = !!path;

      return isReadOnly && isSupportedBackend && pathExist;
    })();

    return (
      <>
        <div
          className={cn(
            'box',
            // flexbox
            'tw-flex',
            'tw-flex-col',
            'lg:tw-flex-row',

            // flexbox borders
            'tw-divide-x',
            'tw-divide-y-0',
            'lg:tw-divide-solid',
            'tw-divide-neutral-200',

            // font
            'tw-text-base',
            'tw-font-medium',
          )}
        >
          <div
            className={cn('tw-flex tw-flex-grow tw-flex-col tw-gap-3.5 tw-p-6', {
              'tw-flex-grow-[1.5]': !isAlias,
            })}
          >
            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>ID</span>
              <Clipboard
                btnClassName="!tw-text-right"
                tooltipPlacement="top"
                label={table.get('id')}
                text={table.get('id')}
                showIcon={false}
              />
            </div>

            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>Name</span>
              <Clipboard
                btnClassName="!tw-text-right"
                tooltipPlacement="top"
                label={table.get('displayName')}
                text={table.get('displayName')}
                showIcon={false}
              />
            </div>

            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>
                Primary key
                {this.renderPrimaryKeyAction()}
              </span>
              <span className="tw-font-normal tw-text-neutral-400">
                {table.get('primaryKey').isEmpty() ? 'Not set' : table.get('primaryKey').join(', ')}
              </span>
            </div>

            {isInternal && (
              <div className="tw-flex tw-justify-between tw-gap-2">
                <span>Recently updated by</span>
                <span className="tw-font-normal">
                  <TableUpdatedBy
                    table={table}
                    components={this.props.components}
                    configurations={this.props.configurations}
                    hasFlows={this.props.hasFlows}
                  />
                </span>
              </div>
            )}

            {isAlias &&
              (!table.get('selectSql') ? (
                <div className="tw-flex tw-justify-between tw-gap-2">
                  <AliasFilter
                    table={table}
                    canEdit={this.props.canWriteTable}
                    settingAliasFilter={this.props.settingAliasFilter}
                    removingAliasFilter={this.props.removingAliasFilter}
                  />
                </div>
              ) : (
                <div className="tw-flex tw-justify-between tw-gap-2">
                  <span>Alias SQL</span>
                  <code>{table.get('selectSql')}</code>
                </div>
              ))}

            {isSchemaTableNameVisible && (
              <>
                <div className="tw-flex tw-justify-between tw-gap-2">
                  <span className="tw-flex tw-items-center">
                    {isSnowflakeBackend ? 'Schema' : 'Dataset Name'}
                  </span>
                  <span className="tw-flex tw-items-center tw-gap-4">
                    <BackendEntityInfoTooltip isSnowflakeBackend={isSnowflakeBackend} />
                    <Clipboard
                      btnClassName="!tw-text-right"
                      tooltipPlacement="top"
                      className="tw-break-words"
                      label={path}
                      text={path}
                      showIcon={false}
                    />
                  </span>
                </div>

                <div className="tw-flex tw-justify-between tw-gap-2">
                  <span className="tw-text-base tw-font-medium">Table Name</span>
                  <span className="tw-flex tw-flex-row tw-items-center tw-gap-4">
                    <InfoTooltip
                      tooltip={
                        <>
                          This is the name of the table representing this table in{' '}
                          {isSnowflakeBackend ? 'Snowflake' : 'BigQuery'}.
                        </>
                      }
                    />
                    <Clipboard
                      btnClassName="!tw-text-right"
                      tooltipPlacement="top"
                      className="tw-break-words"
                      label={table.get('name')}
                      text={table.get('name')}
                      showIcon={false}
                    />
                  </span>
                </div>
              </>
            )}
          </div>

          <div className="tw-flex tw-grow tw-flex-col tw-gap-2.5 tw-p-6 tw-text-sm">
            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>Stage</span>
              <span className="tw-font-normal tw-text-neutral-400">
                {table.getIn(['bucket', 'stage']).toUpperCase()}
              </span>
            </div>

            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>Created</span>
              <CreatedDate
                className="tw-font-normal tw-text-neutral-400"
                createdTime={table.get('created')}
              />
            </div>

            {table.get('sourceTable') && (
              <div className="tw-flex tw-justify-between tw-gap-2">
                <span>Source table</span>
                {this.renderSourceTable()}
              </div>
            )}
            {isInternal && (
              <>
                <div className="tw-flex tw-justify-between tw-gap-2">
                  <span>Last import</span>
                  <CreatedDate
                    className="tw-font-normal tw-text-neutral-400"
                    createdTime={table.get('lastImportDate')}
                    fallback="Not yet imported"
                  />
                </div>

                <div className="tw-flex tw-justify-between tw-gap-2">
                  <span>Last change</span>
                  <CreatedDate
                    className="tw-font-normal tw-text-neutral-400"
                    createdTime={table.get('lastChangeDate')}
                  />
                </div>
              </>
            )}

            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>Row count</span>
              <span className="tw-font-normal tw-text-neutral-400">
                {isExternal ? (
                  <NotAvailable entity="external tables" />
                ) : (
                  <RowsCount count={table.get('rowsCount')} />
                )}
              </span>
            </div>
            <div className="tw-flex tw-justify-between tw-gap-2">
              <span>Data size</span>
              <span className="tw-font-normal tw-text-neutral-400">
                {isExternal ? (
                  <NotAvailable entity="external tables" />
                ) : (
                  <FileSize size={table.get('dataSizeBytes')} />
                )}
              </span>
            </div>

            {(this.props.tableAliases.length > 0 || this.props.tableLinks.length > 0) && (
              <div className="align-top tw-mt-2 tw-flex tw-justify-between tw-gap-2">
                <span>Alias tables</span>
                {this.renderTableAliases()}
              </div>
            )}
          </div>

          {!isAlias && !isExternal && (
            <div className="tw-flex tw-basis-1/3 tw-flex-col tw-justify-between tw-p-6 tw-text-sm">
              <div>Latest import time</div>
              <div className="tw-font-normal">
                <LatestImports table={table} key={table.get('lastImportDate') || 'table-imports'} />
              </div>
            </div>
          )}
        </div>
        {this.renderDescription()}
        {this.renderCreatePrimaryKeyModal()}
        {this.renderRemovePrimaryKeyModal()}
      </>
    );
  },

  renderDescription() {
    return (
      <StorageDescription
        objectType={ObjectTypes.TABLE}
        objectId={this.props.table.get('id')}
        metadata={this.props.table.get('metadata', List())}
        metadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!this.props.canManageBuckets}
        isDevBucket={isCreatedInDevBranch(this.props.table.get('bucket'))}
      />
    );
  },

  renderPrimaryKeyAction() {
    if (!this.props.canWriteTable || this.props.table.get('isAlias', false)) {
      return null;
    }

    if (!this.props.table.get('primaryKey').isEmpty()) {
      return (
        <Tooltip tooltip="Delete primary key" placement="top">
          <Button
            bsStyle="link"
            className="icon-addon-left btn-link-inline text-muted"
            onClick={this.openRemovePrimaryKeyModal}
            disabled={this.props.deletingPrimaryKey}
          >
            {this.props.deletingPrimaryKey ? (
              <Loader />
            ) : (
              <FontAwesomeIcon icon="trash" fixedWidth />
            )}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip="Create primary key" placement="top">
        <Button
          bsStyle="link"
          className="icon-addon-left btn-link-inline text-muted"
          onClick={this.openCreatePrimaryKeyModal}
          disabled={this.props.creatingPrimaryKey}
        >
          {this.props.creatingPrimaryKey ? <Loader /> : <FontAwesomeIcon icon="pen" fixedWidth />}
        </Button>
      </Tooltip>
    );
  },

  renderSourceTable() {
    const { sapiToken, table } = this.props;

    if (sapiToken.getIn(['owner', 'id']) !== table.getIn(['sourceTable', 'project', 'id'])) {
      return (
        <ExternalProjectTableLink
          table={table.get('sourceTable')}
          urlTemplates={this.props.urlTemplates}
        />
      );
    }

    const sourceTable = this.props.tables.get(table.getIn(['sourceTable', 'id']));

    if (!sourceTable) {
      return 'N/A';
    }

    return (
      <Link
        to={routeNames.TABLE}
        params={{
          bucketId: sourceTable.getIn(['bucket', 'id']),
          tableName: sourceTable.get('name'),
        }}
      >
        {tableName(sourceTable)}
      </Link>
    );
  },

  renderTableAliases() {
    return (
      <div className="text-right">
        {this.props.tableAliases.map((alias) => (
          <div key={alias.get('id')}>
            <Link
              to={routeNames.TABLE}
              params={{ bucketId: alias.getIn(['bucket', 'id']), tableName: alias.get('name') }}
            >
              {tableName(alias)}
            </Link>
          </div>
        ))}

        {this.props.tableLinks.map((alias) => (
          <div key={alias.get('id')}>
            <ProjectAliasLink
              sapiToken={this.props.sapiToken}
              urlTemplates={this.props.urlTemplates}
              alias={alias}
            />
          </div>
        ))}
      </div>
    );
  },

  renderCreatePrimaryKeyModal() {
    return (
      <CreatePrimaryKeyModal
        show={this.state.showCreatePrimaryKeyModal}
        table={this.props.table}
        onSubmit={this.handleCreatePrimaryKey}
        onHide={this.closeCreatePrimaryKeyModal}
      />
    );
  },

  renderRemovePrimaryKeyModal() {
    return (
      <ConfirmModal
        show={this.state.showRemovePrimaryKeyModal}
        icon="trash"
        title="Delete Primary Key"
        buttonType="danger"
        buttonLabel="Remove"
        text={
          <>
            <p>Are you sure you want to delete the table primary key?</p>
            <DevBranchStorageWarning
              message="The primary key will also be deleted from the table in production."
              hasProductionEntity={!isCreatedInDevBranch(this.props.table.get('bucket'))}
            />
          </>
        }
        onConfirm={this.handleRemovePrimaryKey}
        onHide={this.closeRemovePrimaryKeyModal}
      />
    );
  },

  handleCreatePrimaryKey(primaryKeys) {
    const tableId = this.props.table.get('id');
    const params = {
      columns: primaryKeys,
    };

    return createTablePrimaryKey(tableId, params);
  },

  handleRemovePrimaryKey() {
    const tableId = this.props.table.get('id');

    return removeTablePrimaryKey(tableId);
  },

  openCreatePrimaryKeyModal() {
    this.setState({
      showCreatePrimaryKeyModal: true,
    });
  },

  closeCreatePrimaryKeyModal() {
    this.setState({
      showCreatePrimaryKeyModal: false,
    });
  },

  openRemovePrimaryKeyModal() {
    this.setState({
      showRemovePrimaryKeyModal: true,
    });
  },

  closeRemovePrimaryKeyModal() {
    this.setState({
      showRemovePrimaryKeyModal: false,
    });
  },
});

export default TableOverview;
