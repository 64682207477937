import { STACKS } from '@keboola/constants';
import { fromJS, Iterable, List, Map } from 'immutable';
import { capitalize } from 'underscore.string';

import {
  KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_DATABRICKS_TRANSFORMATION,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_JULIA_TRANSFORMATION,
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_R_TRANSFORMATION_V_2,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION,
} from '@/constants/componentIds';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import SandboxesActions from './Actions';
import { CONTAINER_BASED, SANDBOX_TYPE } from './Constants';

const hasSandbox = (componentId) => {
  return !!resolveSandboxTypeFromComponentId(componentId);
};

const canCreateTransformation = (sandboxType) => {
  return hasSandbox(resolveComponentIdFromSandboxType(sandboxType));
};

const resolveComponentIdFromSandboxType = (sandboxType) => {
  switch (sandboxType) {
    case SANDBOX_TYPE.SNOWFLAKE:
      return KEBOOLA_SNOWFLAKE_TRANSFORMATION;

    case SANDBOX_TYPE.REDSHIFT:
      return KEBOOLA_REDSHIFT_TRANSFORMATION;

    case SANDBOX_TYPE.EXASOL:
      return KEBOOLA_EXASOL_TRANSFORMATION;

    case SANDBOX_TYPE.TERADATA:
      return KEBOOLA_TERADATA_TRANSFORMATION;

    case SANDBOX_TYPE.BIGQUERY:
      return KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION;

    case SANDBOX_TYPE.JULIA:
      return KEBOOLA_JULIA_TRANSFORMATION;

    case SANDBOX_TYPE.PYTHON:
      if (
        [STACKS.CSAS_PROD.id, STACKS.CSAS_PROD_CS.id, STACKS.CSAS_TEST.id].includes(
          ApplicationStore.getCurrentStackId(),
        )
      ) {
        return KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2;
      }

      return KEBOOLA_PYTHON_TRANSFORMATION_V_2;

    case SANDBOX_TYPE.PYTHON_MLFLOW:
      return KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION;

    case SANDBOX_TYPE.PYTHON_SNOWPARK:
      return KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION;

    case SANDBOX_TYPE.PYTHON_DATABRICKS:
      return KEBOOLA_DATABRICKS_TRANSFORMATION;

    case SANDBOX_TYPE.R:
      return KEBOOLA_R_TRANSFORMATION_V_2;

    case SANDBOX_TYPE.SYNAPSE:
      return KEBOOLA_SYNAPSE_TRANSFORMATION;

    default:
      return null;
  }
};

const resolveSandboxTypeFromComponentId = (componentId) => {
  switch (componentId) {
    case KEBOOLA_SNOWFLAKE_TRANSFORMATION:
      return SANDBOX_TYPE.SNOWFLAKE;

    case KEBOOLA_REDSHIFT_TRANSFORMATION:
      return SANDBOX_TYPE.REDSHIFT;

    case KEBOOLA_EXASOL_TRANSFORMATION:
      return SANDBOX_TYPE.EXASOL;

    case KEBOOLA_TERADATA_TRANSFORMATION:
      return SANDBOX_TYPE.TERADATA;

    case KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION:
      return SANDBOX_TYPE.BIGQUERY;

    case KEBOOLA_JULIA_TRANSFORMATION:
      return SANDBOX_TYPE.JULIA;

    case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
      return SANDBOX_TYPE.PYTHON;

    case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
      return SANDBOX_TYPE.PYTHON_SNOWPARK;

    case KEBOOLA_DATABRICKS_TRANSFORMATION:
      return SANDBOX_TYPE.PYTHON_DATABRICKS;

    case KEBOOLA_R_TRANSFORMATION_V_2:
      return SANDBOX_TYPE.R;

    case KEBOOLA_SYNAPSE_TRANSFORMATION:
      return SANDBOX_TYPE.SYNAPSE;

    default:
      return null;
  }
};

const prepareSandboxConfiguration = (configData) => {
  const id = configData.getIn(['parameters', 'id']);
  const packages = configData.getIn(['parameters', 'packages'], List());
  const storage = configData.get('storage', Map());
  return {
    parameters: { packages: packages.toJS(), id },
    ...(storage.count() !== 0 ? { storage: storage.toJS() } : {}),
  };
};

const prepareSandboxes = (sandboxes, configurations) => {
  return sandboxes
    .filter((sandbox) => {
      if (
        !configurations.has(sandbox.get('configurationId')) ||
        sandbox.get('type') === SANDBOX_TYPE.STREAMLIT
      ) {
        return false;
      }

      if (sandbox.get('type') === SANDBOX_TYPE.BIGQUERY) {
        return !!sandbox.get('workspaceDetails');
      }

      return !!sandbox.get('host') && !!sandbox.get('user');
    })
    .map((sandbox) => {
      return sandbox.set('configuration', configurations.get(sandbox.get('configurationId')));
    });
};

const prepareCreatingSandboxes = (processingJobs, sandboxes, configurations, hasNewQueue) => {
  const getConfigId = (job) => {
    return hasNewQueue ? job.get('config') : job.getIn(['params', 'config']);
  };

  const getJobProperty = (job, property) => {
    return hasNewQueue
      ? job.getIn(['configData', 'parameters', property])
      : job.getIn(['params', 'configData', 'parameters', property]);
  };

  return processingJobs
    .filter((job) => {
      const configId = getConfigId(job);

      return (
        getJobProperty(job, 'task') === 'create' &&
        getJobProperty(job, 'type') !== SANDBOX_TYPE.STREAMLIT &&
        configurations.has(configId) &&
        !sandboxes.some((sandbox) => {
          return sandbox.get('configurationId') === configId && sandbox.get('active');
        })
      );
    })
    .map((job) => {
      const configId = getConfigId(job);

      return fromJS({
        id: configId,
        type: getJobProperty(job, 'type'),
        shared: getJobProperty(job, 'shared'),
        tokenId: configurations
          .getIn([configId, 'currentVersion', 'creatorToken', 'id'], '')
          .toString(),
        configuration: {
          id: configId,
          name: configurations.getIn([configId, 'name']),
          currentVersion: configurations.getIn([configId, 'currentVersion']),
          isCreating: true,
        },
      });
    })
    .toMap()
    .mapKeys((_, sandbox) => sandbox.get('id'));
};

const isSameTableMapping = (mappingA, mappingB) =>
  mappingA.get('source') === mappingB.get('source') &&
  mappingA.get('destination') === mappingB.get('destination');

const isSameFileInputMapping = (mappingA, mappingB) => {
  return mappingA.equals(mappingB);
};

const isSameFileOutputMapping = (mappingA, mappingB) =>
  mappingA.get('source') === mappingB.get('source');

const mergeConfigurations = (targetConfig, sourceConfig, options = { skipPackages: false }) => {
  return targetConfig.withMutations((targetConfig) => {
    const mergeDeepArray = (path, comparator) => {
      targetConfig.updateIn(path, List(), (items) => {
        let existingItems = items;
        let newItems = sourceConfig.getIn(path, List());

        if (Iterable.isIterable(items.first())) {
          existingItems = items.map(
            (item) => newItems.find((newItem) => comparator(newItem, item)) ?? item,
          );
          newItems = newItems.filter(
            (item) => !existingItems.some((existingItem) => comparator(existingItem, item)),
          );
        }

        return existingItems.concat(newItems).toSet().toList();
      });
    };

    mergeDeepArray(['storage', 'input', 'tables'], isSameTableMapping);
    mergeDeepArray(['storage', 'output', 'tables'], isSameTableMapping);
    mergeDeepArray(['storage', 'input', 'files'], isSameFileInputMapping);
    mergeDeepArray(['storage', 'output', 'files'], isSameFileOutputMapping);

    if (!options?.skipPackages) {
      mergeDeepArray(['parameters', 'packages'], (a, b) => a === b);
    }
  });
};

const createNewWorkspaceFromTransformation = (
  componentId,
  config,
  name,
  type,
  options,
  params,
  description,
) => {
  if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
    const packages = config.get('packages', List());
    const input = config.get('input', Map());
    const output = config.get('output', Map());

    return SandboxesActions.createSandbox(
      {
        name,
        description,
        configuration: JSON.stringify({
          parameters: { packages: packages.toJS() },
          storage: { input: { tables: input }, output: { tables: output } },
        }),
      },
      type,
      options,
      params.withMutations((params) => {
        if (!input.isEmpty()) {
          params.setIn(['storage', 'input', 'tables'], input);
        }

        if (CONTAINER_BASED.includes(type)) {
          params.set('packages', packages).set('script', config.get('queries', List()));
        }

        return params;
      }),
    );
  }

  const configData = config.get('configuration', Map());
  const packages = configData.getIn(['parameters', 'packages'], List());
  return SandboxesActions.createSandbox(
    {
      name,
      description,
      configuration: JSON.stringify(prepareSandboxConfiguration(configData)),
    },
    type,
    options,
    params.update((params) => {
      if (
        !CONTAINER_BASED.includes(type) &&
        !configData.getIn(['storage', 'input'], Map()).isEmpty()
      ) {
        return params.setIn(['storage', 'input'], configData.getIn(['storage', 'input'], Map()));
      }

      let transformation = Map({
        component_id: componentId,
        config_id: config.get('id'),
        config_version: config.get('version'),
      });

      if (configData.has('variables_values_id')) {
        transformation = transformation.set(
          'variable_values_id',
          configData.get('variables_values_id'),
        );
      }

      return params.set('packages', packages).set('transformation', transformation);
    }),
  ).then((data) => {
    // save from which transformation the workspace was created
    return componentsActions
      .setConfigurationMetadata(KEBOOLA_SANDBOXES, data.config.id, [
        {
          key: MetadataKeys.CONFIGURATION_CREATED_FROM,
          value: JSON.stringify({ componentId, configurationId: config.get('id') }),
        },
      ])
      .then(() => data);
  });
};

const updateExistingWorkspace = (config, workspace, preserve, changeDescription) => {
  let data = preserve
    ? mergeConfigurations(
        workspace.getIn(['configuration', 'configuration'], Map()),
        config.get('configuration', Map()),
      )
    : config.get('configuration').setIn(['parameters', 'id'], workspace.get('id'));

  return componentsActions
    .updateComponentConfiguration(
      KEBOOLA_SANDBOXES,
      workspace.get('configurationId'),
      {
        configuration: JSON.stringify(prepareSandboxConfiguration(data)),
      },
      changeDescription,
    )
    .then(() => {
      if (workspace.get('active')) {
        return SandboxesActions.loadDataSimple(
          data.getIn(['parameters', 'id']),
          workspace.get('configurationId'),
          data.getIn(['storage', 'input'], Map()),
          {
            preserve: CONTAINER_BASED.includes(workspace.get('type')) || preserve,
          },
        );
      }

      return SandboxesActions.restoreSandboxSimple(
        data.getIn(['parameters', 'id']),
        workspace.get('configurationId'),
        data.getIn(['storage', 'input'], Map()).toJS(),
      );
    });
};

const prepareInputMappingForWorkspaceLoad = (inputMapping, options) => {
  return inputMapping
    .withMutations((input) => {
      if (options?.preserve) {
        // we need to set overwrite flag for each table, otherwise load will fail
        input.update('tables', List(), (tables) => {
          return tables.map((table) => table.set('overwrite', true));
        });
      } else {
        input.set('preserve', false);
      }
    })
    .toJS();
};

const prepareSandboxUrl = (sandbox) => {
  if (
    CONTAINER_BASED.includes(sandbox.get('type')) &&
    sandbox.get('type') !== SANDBOX_TYPE.STREAMLIT
  ) {
    return `${sandbox.get('url')}?token=${sandbox.get('password')}`;
  }

  return sandbox.get('url');
};

const hasBasicAuthEnabled = (config) => {
  return (
    config?.getIn(['configuration', 'authorization', 'app_proxy', 'auth_providers', 0, 'type']) ===
    'password'
  );
};

const prepareCredentialsData = (sandbox) => {
  const schemaNameHint = `This is the name of the ${sandbox.get('type') === SANDBOX_TYPE.BIGQUERY ? 'dataset' : 'schema'} created for this workspace. All tables loaded via input mapping are stored here. Use it in your queries or set it as the default dataset in your client. Note that this name will change in transformations and other workspaces.`;

  if (sandbox.get('type') === SANDBOX_TYPE.BIGQUERY) {
    const data = encodeURIComponent(JSON.stringify(sandbox.get('credentials', Map()).toJSON()));

    return Map({
      'Project ID': sandbox.getIn(['credentials', 'project_id']),
      'Account Email': sandbox.getIn(['credentials', 'client_email']),
      'Credentials File': {
        download: true,
        name: `credentials-${sandbox.get('projectId')}-${sandbox.get('configurationId')}.json`,
        data: `data:text/json;charset=utf-8,${data}`,
      },
      'Dataset Name': {
        text: sandbox.getIn(['workspaceDetails', 'connection', 'schema']),
        hint: schemaNameHint,
      },
    });
  }

  const isContainerBased = CONTAINER_BASED.includes(sandbox.get('type'));

  let rows = Map({
    Host:
      sandbox.get('type') === SANDBOX_TYPE.SNOWFLAKE
        ? sandbox.get('host')
        : sandbox.get('url', sandbox.get('host')),
  });

  if (sandbox.get('user') && !isContainerBased) {
    rows = rows.set('User', sandbox.get('user'));
  }

  if (sandbox.get('password')) {
    rows = rows.set('Password', { protected: true, text: sandbox.get('password') });
  }

  if (!isContainerBased && sandbox.getIn(['workspaceDetails', 'connection', 'database'])) {
    rows = rows.set('Database', sandbox.getIn(['workspaceDetails', 'connection', 'database']));
  }

  if (!isContainerBased && sandbox.getIn(['workspaceDetails', 'connection', 'schema'])) {
    const text = sandbox.getIn(['workspaceDetails', 'connection', 'schema']);
    rows = rows.set(
      'Schema',
      sandbox.get('type') === SANDBOX_TYPE.SNOWFLAKE ? { text, hint: schemaNameHint } : text,
    );
  }

  if (!isContainerBased && sandbox.getIn(['workspaceDetails', 'connection', 'warehouse'])) {
    rows = rows.set('Warehouse', sandbox.getIn(['workspaceDetails', 'connection', 'warehouse']));
  }

  return rows;
};

const getBackendSizeNote = (isDataApp = false) => {
  return `Backend size can be changed only during the ${
    isDataApp ? 'redeploy' : 'restoration from sleep mode'
  } with new settings.`;
};

const prepareSandboxTypeLabel = (type) => {
  switch (type) {
    case SANDBOX_TYPE.BIGQUERY:
      return 'BigQuery';

    default:
      return capitalize(type);
  }
};

const workspaceActionsDisabled = (bucket) => {
  return (
    bucket.get('hasExternalSchema') ||
    (!ApplicationStore.hasProtectedDefaultBranch() &&
      !DevBranchesStore.isDevModeActive() &&
      isCreatedInDevBranch(bucket))
  );
};

export {
  hasSandbox,
  canCreateTransformation,
  resolveComponentIdFromSandboxType,
  resolveSandboxTypeFromComponentId,
  prepareSandboxConfiguration,
  prepareSandboxes,
  prepareCreatingSandboxes,
  mergeConfigurations,
  hasBasicAuthEnabled,
  createNewWorkspaceFromTransformation,
  updateExistingWorkspace,
  prepareInputMappingForWorkspaceLoad,
  prepareSandboxUrl,
  prepareCredentialsData,
  getBackendSizeNote,
  prepareSandboxTypeLabel,
  workspaceActionsDisabled,
};
