import React from 'react';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import qs from 'qs';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { canExportTable } from '@/modules/admin/privileges';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal';
import { prepareSandboxes, updateExistingWorkspace } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import FilterPanel from '@/react/common/FilterPanel';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import CreateSnapshotModal from './components/CreateSnapshotModal';
import DeleteBucketModal from './components/DeleteBucketModal';
import DeleteBucketsAndTablesModal from './components/DeleteBucketsAndTablesModal';
import DeleteTableModal from './components/DeleteTableModal';
import ExportModal from './components/ExportModal';
import IndexTable from './components/IndexTable';
import IndexTableResults from './components/IndexTableResults';
import StorageTabs from './components/StorageTabs';
import {
  createSnapshots,
  deleteBucket,
  deleteBucketsAndTables,
  deleteTable,
  exportTables,
  updateSearchFilters,
  updateSearchQuery,
} from './actions';
import { FILTERS, FILTERS_GROUP, routeNames } from './constants';
import {
  getTableAliases,
  getTableLinks,
  isBucketLinked,
  isBucketShared,
  prepareMappingFromSelectedBucketsAndTables,
} from './helpers';
import StorageStore from './store';

const prepareBuckets = memoizeOne((allBuckets, tables, searchFilters) => {
  let buckets = DevBranchesStore.isDevModeActive()
    ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
    : searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
      ? filterDevBranchBuckets(allBuckets)
      : filterProductionBuckets(allBuckets);

  return buckets.map((bucket) => {
    return bucket.set(
      'bucketTables',
      tables.filter((table) => table.getIn(['bucket', 'id']) === bucket.get('id')),
    );
  });
});

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StorageStore,
      InstalledComponentsStore,
      ComponentsStore,
      SandboxesStore,
      BucketsStore,
      TablesStore,
      DevBranchesStore,
    ),
  ],

  getStateFromStores() {
    const allBuckets = BucketsStore.getAll();
    const tables = TablesStore.getAll();
    const searchFilters = StorageStore.getSearchFilters();
    const sapiToken = ApplicationStore.getSapiToken();

    return {
      tables,
      allBuckets,
      sapiToken,
      searchFilters,
      buckets: prepareBuckets(allBuckets, tables, searchFilters),
      devBranchBuckets: filterDevBranchBuckets(allBuckets),
      expandedBuckets: StorageStore.getExpandedBuckets(),
      bucketsSort: StorageStore.getBucketsSort(),
      canExportTable: canExportTable(sapiToken),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      searchQuery: StorageStore.getSearchQuery(),
      configurations: InstalledComponentsStore.getAll(),
      components: ComponentsStore.getAll(),
      deletingTables: TablesStore.getDeletingTables(),
      exportingTables: TablesStore.getExportingTables(),
      createSnapshotsTables: TablesStore.getCreatingSnapshotsTables(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      deletingBuckets: BucketsStore.getPendingBucketsActions().get('deleting', Map()),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null,
      ).get('clusters'),
      hasFlows: ApplicationStore.hasFlows(),
      showAllBuckets: StorageStore.getShowAllBuckets(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
    };
  },

  getInitialState() {
    return {
      selected: [],
      selectedBucket: Map(),
      selectedTable: Map(),
      isDeleting: false,
      showDeleteModal: false,
      showDeleteBucketModal: false,
      showDeleteTableModal: false,
      showExportTablesModal: false,
      showSnapshotModal: false,
      showCreateWorkspaceModal: false,
      createWorkspaceForceStep: null,
    };
  },

  componentDidMount() {
    const query = RoutesStore.getRouterState().getIn(['location', 'query']);

    if (query.get('q', '') !== this.state.searchQuery) {
      updateSearchQuery(query.get('q'));
    }

    if (
      query.has('filter') &&
      !this.state.searchFilters.equals(fromJS(qs.parse(query.get('filter'))))
    ) {
      updateSearchFilters(fromJS(qs.parse(query.get('filter'))));
    }

    if (query.get('q', '') !== this.state.searchQuery || query.has('filter')) {
      RoutesStore.getRouter().updateQuery({
        q: this.state.searchQuery,
        filter: qs.stringify(this.state.searchFilters.toJS()),
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.searchQuery !== this.state.searchQuery ||
      !prevState.searchFilters.equals(this.state.searchFilters)
    ) {
      RoutesStore.getRouter().updateQuery({
        q: this.state.searchQuery,
        filter: qs.stringify(this.state.searchFilters.toJS()),
      });
    }
  },

  getCommonTableProps() {
    return {
      buckets: this.state.buckets,
      sapiToken: this.state.sapiToken,
      deletingTables: this.state.deletingTables,
      deletingBuckets: this.state.deletingBuckets,
      exportingTables: this.state.exportingTables,
      createSnapshotsTables: this.state.createSnapshotsTables,
      openDeleteModal: this.handleOpenDeleteModal,
      openDeleteBucketModal: this.handleOpenDeleteBucketModal,
      openDeleteTableModal: this.handleOpenDeleteTableModal,
      openExportTablesModal: this.handleOpenExportTablesModal,
      openCreateWorkpaceModal: this.handleOpenCreateWorkpaceModal,
      openSnapshotModal: this.handleOpenSnapshotModal,
      bucketsSort: this.state.bucketsSort,
      hasSnowflakePartnerConnectLimited: this.state.hasSnowflakePartnerConnectLimited,
      canExportTable: this.state.canExportTable,
      searchFilters: this.state.searchFilters,
    };
  },

  render() {
    const selectedList = List(this.state.selected);

    return (
      <StorageTabs
        activeTab={routeNames.ROOT}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        canEnableDataStreams={this.state.canEnableDataStreams}
      >
        <FilterPanel
          query={this.state.searchQuery}
          placeholder={this.getPlaceholder}
          onChange={(value) => updateSearchQuery(value)}
          additionalActions={this.renderSearchAdditionalActions()}
        />
        {this.state.searchQuery.length > 1 ? (
          <IndexTableResults {...this.getCommonTableProps()} query={this.state.searchQuery} />
        ) : (
          <IndexTable
            {...this.getCommonTableProps()}
            expandedBuckets={this.state.expandedBuckets}
            components={this.state.components}
            configurations={this.state.configurations}
            hasFlows={this.state.hasFlows}
            showAllBuckets={this.state.showAllBuckets}
          />
        )}
        <DeleteBucketsAndTablesModal
          show={this.state.showDeleteModal}
          selected={this.state.selected}
          isLoading={this.state.isDeleting}
          allTables={this.state.tables}
          allBuckets={this.state.allBuckets}
          sapiToken={this.state.sapiToken}
          onHide={() => this.setState({ showDeleteModal: false })}
          onSubmit={() => {
            this.setState({ isDeleting: true });

            return deleteBucketsAndTables(this.state.selected).finally(() => {
              this.setState({ isDeleting: false });
            });
          }}
        />
        <DeleteBucketModal
          show={this.state.showDeleteBucketModal}
          bucket={this.state.selectedBucket}
          tables={this.state.tables.filter(
            (table) => table.getIn(['bucket', 'id']) === this.state.selectedBucket.get('id'),
          )}
          deleting={this.state.deletingBuckets.get(this.state.selectedBucket.get('id'), false)}
          onConfirm={this.handleDeleteBucket}
          onHide={() => this.setState({ showDeleteBucketModal: false, selectedBucket: Map() })}
        />
        <DeleteTableModal
          show={this.state.showDeleteTableModal}
          table={this.state.selectedTable}
          sapiToken={this.state.sapiToken}
          urlTemplates={this.state.urlTemplates}
          tableAliases={getTableAliases(
            this.state.selectedTable,
            this.state.tables,
            this.state.sapiToken,
          )}
          tableLinks={getTableLinks(
            this.state.selectedTable,
            this.state.allBuckets.get(this.state.selectedTable.getIn(['bucket', 'id']), Map()),
          )}
          deleting={this.state.deletingTables.get(this.state.selectedTable.get('id'), false)}
          onConfirm={() => deleteTable(this.state.selectedTable.get('id'))}
          onHide={() => this.setState({ showDeleteTableModal: false, selectedTable: Map() })}
        />
        <ExportModal
          show={this.state.showExportTablesModal}
          tables={selectedList}
          onSubmit={this.handleExportTables}
          onHide={() => this.setState({ showExportTablesModal: false, selected: [] })}
        />
        <CreateSnapshotModal
          show={this.state.showSnapshotModal}
          multiple={selectedList.size > 1}
          onConfirm={this.handleCreateSnapshots}
          onHide={() => this.setState({ showSnapshotModal: false, selected: [] })}
        />
        <AddSandboxModal
          hasTableInputMapping
          show={this.state.showCreateWorkspaceModal}
          forceStep={this.state.createWorkspaceForceStep}
          onHide={() => this.setState({ showCreateWorkspaceModal: false })}
          sandboxComponent={this.state.sandboxComponent}
          workspaces={this.state.sandboxes}
          allowedComponents={this.state.allowedTransformationComponents}
          availableDatabricksClusters={this.state.availableDatabricksClusters}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          onUpdate={(workspace, preserve) => {
            return prepareMappingFromSelectedBucketsAndTables(
              this.state.selected,
              this.state.tables,
              workspace.get('type'),
            ).then((storage) => {
              return updateExistingWorkspace(
                fromJS({ configuration: { parameters: { id: workspace.get('id') }, storage } }),
                workspace,
                preserve,
                'Use mapping from storage tables',
              );
            });
          }}
          onSubmit={(name, type, options, params, description) => {
            return prepareMappingFromSelectedBucketsAndTables(
              this.state.selected,
              this.state.tables,
              type,
            ).then((storage) => {
              return SandboxesActions.createSandbox(
                { name, description, configuration: JSON.stringify({ storage }) },
                type,
                options,
                params.set('storage', storage),
              );
            });
          }}
        />
      </StorageTabs>
    );
  },

  getPlaceholder() {
    const entity = this.state.searchFilters.get(FILTERS_GROUP.ENTITY);
    const sharing = this.state.searchFilters.get(FILTERS_GROUP.SHARING);
    const sharingFilter = [FILTERS.LINKED, FILTERS.SHARED].includes(sharing);

    const buckets = this.state.buckets.count((bucket) => {
      if (!sharing) return true;
      if (sharing === FILTERS.LINKED) return isBucketLinked(bucket);
      if (sharing === FILTERS.SHARED) return isBucketShared(bucket);
      return true;
    });

    const tables = this.state.buckets
      .flatMap((bucket) => bucket.get('bucketTables'))
      .count((table) => {
        if (!sharing) return true;
        if (sharing === FILTERS.LINKED)
          return !table.getIn(['bucket', 'linkedBy'], List()).isEmpty();
        if (sharing === FILTERS.SHARED) return Boolean(table.getIn(['bucket', 'sharing']));
      });

    if (entity === FILTERS.TABLES) {
      return `Search${sharingFilter ? '' : ' all'} tables (${tables})`;
    }

    if (entity === FILTERS.BUCKETS) {
      return `Search${sharingFilter ? '' : ' all'} buckets (${buckets})`;
    }

    return `Search${sharingFilter ? '' : ' all'} tables (${tables}) and buckets (${buckets})`;
  },

  renderSearchAdditionalActions() {
    if (!this.state.allBuckets.count()) {
      return null;
    }

    return (
      <div className="predefined-search-list">
        {this.renderAdditionalActionsButton(FILTERS.ALL, null, 'ALL')}
        {!this.state.hasPayAsYouGo && (
          <>
            <span className="group-separator" />
            {this.renderAdditionalActionsButton(FILTERS.LINKED, FILTERS_GROUP.SHARING, 'LINKED')}
            {this.renderAdditionalActionsButton(FILTERS.SHARED, FILTERS_GROUP.SHARING, 'SHARED')}
          </>
        )}
        <span className="group-separator" />
        {this.renderAdditionalActionsButton(FILTERS.TABLES, FILTERS_GROUP.ENTITY, 'Tables')}
        {this.renderAdditionalActionsButton(FILTERS.BUCKETS, FILTERS_GROUP.ENTITY, 'Buckets')}
        {!this.state.isDevModeActive &&
          !this.state.devBranchBuckets.isEmpty() &&
          this.renderAdditionalActionsButton(FILTERS.DEV, FILTERS_GROUP.ENTITY, 'Dev branch')}
      </div>
    );
  },

  renderAdditionalActionsButton(type, group, label) {
    const active = group
      ? this.state.searchFilters.get(group) === type
      : this.state.searchFilters.isEmpty();

    return (
      <button
        className={classnames('btn predefined-search-link', { active })}
        onClick={() => {
          updateSearchFilters(
            group
              ? active
                ? this.state.searchFilters.remove(group)
                : this.state.searchFilters.set(group, type)
              : Map(),
          );
        }}
      >
        {label}
      </button>
    );
  },

  handleOpenDeleteModal(selected) {
    this.setState({ showDeleteModal: true, selected });
  },

  handleOpenDeleteBucketModal(selectedBucket) {
    this.setState({ showDeleteBucketModal: true, selectedBucket });
  },

  handleOpenDeleteTableModal(selectedTable) {
    this.setState({ showDeleteTableModal: true, selectedTable });
  },

  handleOpenExportTablesModal(tables) {
    this.setState({ showExportTablesModal: true, selected: tables });
  },

  handleOpenSnapshotModal(tables) {
    this.setState({ showSnapshotModal: true, selected: tables });
  },

  handleOpenCreateWorkpaceModal(selected, forceStep) {
    this.setState({
      showCreateWorkspaceModal: true,
      selected,
      createWorkspaceForceStep: forceStep,
    });
  },

  handleExportTables(type) {
    return exportTables(List(this.state.selected), type);
  },

  handleCreateSnapshots(description) {
    return createSnapshots(
      this.state.selected.map((table) => table.get('id')),
      { description },
    );
  },

  handleDeleteBucket() {
    const bucketId = this.state.selectedBucket.get('id');
    const forceDelete =
      this.state.tables.filter((table) => table.getIn(['bucket', 'id']) === bucketId).count() > 0;

    return deleteBucket(bucketId, { forceDelete }).then(() => {
      return this.setState({ showDeleteBucketModal: false, selectedBucket: Map() });
    });
  },
});

export default Index;
