import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@keboola/design';
import { Map } from 'immutable';

import dayjs from '@/date';
import CommonAuthorization from '@/modules/oauth-v2/react/Authorization';
import { resetAuthorization } from '@/modules/simplified-ui/actions';
import Confirm from '@/react/common/Confirm';

class Authorization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isResetting: false,
    };
  }

  static propTypes = {
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    oauthCredentials: PropTypes.instanceOf(Map),
    isConfigured: PropTypes.bool,
  };

  static defaultProps = {
    isConfigured: false,
  };

  render() {
    if (this.props.isConfigured) {
      return (
        <div className="text-center">
          Authorized by{' '}
          <strong>{this.props.oauthCredentials.getIn(['creator', 'description'])}</strong>{' '}
          <Tooltip tooltip={this.props.oauthCredentials.get('created')} placement="top">
            {dayjs(this.props.oauthCredentials.get('created')).fromNow()}
          </Tooltip>
          <br />
          {!this.props.readOnly && (
            <Confirm
              title="Reset Authorization"
              text="Are you sure you want to reset the authorized account?"
              buttonLabel="Reset Authorization"
              closeAfterResolve
              isLoading={this.state.isResetting}
              onConfirm={this.handleReset}
            >
              <Button className="mt-2">Reset Authorization</Button>
            </Confirm>
          )}
        </div>
      );
    }

    return (
      <div className="text-center">
        <h3 className="f-14 text-muted uppercase letter-spacing-narrower mt-0">
          We need access to your data
        </h3>
        <p className="f-24 max-w-500 ml-auto mr-auto letter-spacing-narrower">
          Just one click and you are ready to start working with your data.
        </p>
        <CommonAuthorization componentId={this.props.componentId} configId={this.props.configId} />
      </div>
    );
  }

  handleReset = () => {
    this.setState({ isResetting: true });
    return resetAuthorization(this.props.componentId, this.props.configId).finally(() =>
      this.setState({ isResetting: false }),
    );
  };
}

export default Authorization;
