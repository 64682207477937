import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { URLS } from '@keboola/constants';
import { Alert, HelpBlock, Link } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_SNOWFLAKE_TRANSFORMATION } from '@/constants/componentIds';
import { ioType } from '@/modules/components/Constants';
import { SnowflakeSqlTableExplainTooltip } from '@/modules/components/react/components/generic/SnowflakeSqlTableExplainTooltip';
import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { removeDevBranchReferenceFromTable } from '@/modules/dev-branches/helpers';
import { STAGE } from '@/modules/storage/constants';
import { getCurrentBranchTableWithProductionFallback } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import DestinationTableSelector from '@/react/common/DestinationTableSelector';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import tableIdParser from '@/utils/tableIdParser';
import { supportsWriteAlways } from './helpers';
import PrimaryKeyWarning from './PrimaryKeyWarning';

const TableOutputMappingEditor = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    defaultBucketName: PropTypes.string.isRequired,
    defaultTableName: PropTypes.string.isRequired,
    simple: PropTypes.bool,
  },

  getInitialState() {
    return { overwriteDestination: false };
  },

  _parseDestination() {
    return tableIdParser.parse(this.props.value.get('destination'), {
      defaultStage: STAGE.OUT,
      defaultBucket: this.props.defaultBucketName,
    });
  },

  _handleFocusSource() {
    if (!this._parseDestination().parts.table) {
      return this.setState({ overwriteDestination: true });
    }
  },

  prepareDestinationFromSource(value) {
    let sourceValue = value;
    if (!this.state.overwriteDestination) {
      return null;
    }
    const isFileMapping = true; // generic components always use file system
    const lastDotIdx = sourceValue.lastIndexOf('.');
    if (isFileMapping && lastDotIdx > 0) {
      sourceValue = sourceValue.substring(0, lastDotIdx);
    }
    const dstParser = this._parseDestination();
    const webalizedSourceValue = string.webalize(sourceValue, { caseSensitive: true });
    const newDestination = dstParser.setPart('table', webalizedSourceValue);
    return newDestination.tableId;
  },

  _handleChangeSource(e) {
    const newSource = e.target.value.trim();
    const newDestination = this.prepareDestinationFromSource(newSource);
    let newMapping = this.props.value;
    if (newDestination) {
      newMapping = this.updateMappingWithDestination(newDestination);
    }
    newMapping = newMapping.set('source', newSource);
    return this.props.onChange(newMapping);
  },

  updateMappingWithDestination(newDestination) {
    let value = this.props.value.set('destination', newDestination.trim());
    if (this.props.tables.get(value.get('destination'))) {
      value = value.set(
        'primary_key',
        this.props.tables.getIn([value.get('destination'), 'primaryKey'], List()),
      );
    }
    return value;
  },

  _handleChangeDestination(newValue) {
    return this.props.onChange(this.updateMappingWithDestination(newValue));
  },

  _updateDestinationPart(partName, value) {
    return this._handleChangeDestination(this._parseDestination().setPart(partName, value).tableId);
  },

  _handleChangeIncremental(checked) {
    let value;
    if (checked) {
      value = this.props.value
        .set('incremental', checked)
        .set('delete_where_column', '')
        .set('delete_where_operator', 'eq')
        .set('delete_where_values', List());
    } else {
      value = this.props.value
        .delete('incremental')
        .delete('delete_where_column')
        .delete('delete_where_operator')
        .delete('delete_where_values');
    }
    return this.props.onChange(value);
  },

  _handleChangePrimaryKey(newValue) {
    const value = this.props.value.set('primary_key', newValue);
    return this.props.onChange(value);
  },

  _handleChangeDeleteWhereValues(newValue) {
    const value = this.props.value.set('delete_where_values', newValue);
    return this.props.onChange(value);
  },

  _getTablesAndBuckets() {
    const tablesAndBuckets = this.props.tables.merge(this.props.buckets);

    const inOut = tablesAndBuckets.filter(
      (item) => item.get('id').substr(0, 3) === 'in.' || item.get('id').substr(0, 4) === 'out.',
    );

    const map = inOut.sortBy((item) => item.get('id')).map((item) => item.get('id'));

    return map.toList();
  },

  _getColumnsOptions() {
    const columns = this.props.tables
      .find((table) => table.get('id') === this.props.value.get('destination'), null, Map())
      .get('columns', List())
      .toArray();

    const currentValue = this.props.value.get('delete_where_column', '');

    if (currentValue && !columns.includes(currentValue)) {
      columns.push(currentValue);
    }

    return columns.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  },

  render() {
    if (this.props.simple) {
      return this.renderDestinationInput();
    }

    return (
      <>
        <FormGroup>
          <ControlLabel>
            {this.props.sourceType === ioType.TABLE ? 'Table name' : 'File name'}
          </ControlLabel>
          <FormControl
            type="text"
            name="source"
            autoFocus
            value={this.props.value.get('source', '')}
            disabled={this.props.disabled}
            placeholder={this.props.sourceType === ioType.TABLE ? 'Table name' : 'File name'}
            onFocus={this._handleFocusSource}
            onBlur={() => this.setState({ overwriteDestination: false })}
            onChange={this._handleChangeSource}
          />
          {this.renderSourceHelpText()}
        </FormGroup>
        {this.renderAllInputs()}
      </>
    );
  },

  renderSourceHelpText() {
    if (this.props.sourceType === ioType.FILE) {
      return (
        <HelpBlock>
          The file will be uploaded from{' '}
          <code>{`out/tables/${this.props.value.get('source', '')}`}</code>
        </HelpBlock>
      );
    }

    const isSnowflakeTransformation = this.props.componentId === KEBOOLA_SNOWFLAKE_TRANSFORMATION;
    return (
      <HelpBlock className="tw-flex tw-gap-1">
        <span>Keboola is case-sensitive;</span>

        {isSnowflakeTransformation ? (
          <SnowflakeSqlTableExplainTooltip />
        ) : (
          <span>
            you most likely should use the UPPERCASE name of the table in the output mapping.
          </span>
        )}
      </HelpBlock>
    );
  },

  renderPrimaryKeyHelpText() {
    const sourcePrimaryKey = this.props.tables.getIn([
      this.props.value.get('destination'),
      'primaryKey',
    ]);

    if (!sourcePrimaryKey || this.props.value.get('primary_key', List()).equals(sourcePrimaryKey)) {
      return null;
    }

    return (
      <PrimaryKeyWarning primaryKey={sourcePrimaryKey} onChange={this._handleChangePrimaryKey} />
    );
  },

  renderDestinationInput() {
    const { parts, tableId } = this._parseDestination();
    const productionTableId = tableIdParser.parse(
      removeDevBranchReferenceFromTable(tableId, DevBranchesStore.getCurrentId()),
    ).tableId;

    return (
      <FormGroup>
        <ControlLabel>Destination</ControlLabel>
        <DestinationTableSelector
          currentSource={this.props.value.get('source')}
          updatePart={this._updateDestinationPart}
          defaultBucketName={this.props.defaultBucketName}
          defaultTableName={this.props.defaultTableName}
          parts={{
            ...parts,
            bucket:
              getCurrentBranchTableWithProductionFallback(this.props.tables, tableId).getIn([
                'bucket',
                'name',
              ]) ?? parts.bucket,
          }}
          tables={this.props.tables}
          buckets={this.props.buckets}
          disabled={this.props.disabled}
        />
        {this.renderDestinationHelpText(tableId, parts)}
        {DevBranchesStore.isDevModeActive() &&
          !ApplicationStore.hasProtectedDefaultBranch() &&
          productionTableId === tableId && (
            <Alert variant="warning">
              In the development branch, output mapping always creates tables within the development
              branch. Writing data to production tables is not allowed. You can find more
              information in our{' '}
              <Link href={`${URLS.USER_DOCUMENTATION}/components/branches/#data-pipelines`}>
                documentation
              </Link>
              .
            </Alert>
          )}
      </FormGroup>
    );
  },

  renderDestinationHelpText(tableId, parts) {
    if (this.props.tables.has(tableId)) {
      return (
        <HelpBlock>
          The destination setting points to an existing{' '}
          <StorageApiTableLinkEx openInNewTab tableId={tableId}>
            table
          </StorageApiTableLinkEx>{' '}
          in the Storage.
        </HelpBlock>
      );
    }

    if (this.props.buckets.has(`${parts.stage}.${parts.bucket}`)) {
      return (
        <HelpBlock>
          The destination setting points to an existing{' '}
          <StorageApiBucketLinkEx openInNewTab bucketId={`${parts.stage}.${parts.bucket}`}>
            bucket
          </StorageApiBucketLinkEx>{' '}
          in the Storage.
        </HelpBlock>
      );
    }

    return <HelpBlock>The Storage table where the source-file data will be loaded.</HelpBlock>;
  },

  renderWriteAlways() {
    if (this.props.sourceType !== ioType.TABLE || !supportsWriteAlways(this.props.componentId)) {
      return null;
    }

    return (
      <FormGroup>
        <Checkbox
          disabled={this.props.disabled}
          checked={this.props.value.get('write_always', false)}
          onChange={(checked) => this.props.onChange(this.props.value.set('write_always', checked))}
        >
          Write Always
        </Checkbox>
        <HelpBlock>
          The table will be uploaded to Storage even if the job fails. Read more in the{' '}
          <Link
            href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/common-interface/config-file/#output-mapping--write-even-if-the-job-fails`}
          >
            documentation
          </Link>
          .
        </HelpBlock>
      </FormGroup>
    );
  },

  renderAllInputs() {
    const columnsOptions = this._getColumnsOptions();
    const isIncremental = this.props.value.get('incremental', false);
    const primaryKey = this.props.value.get('primary_key', List());
    const deleteWhereColumn = this.props.value.get('delete_where_column', '');

    return (
      <>
        {this.renderDestinationInput()}
        <FormGroup>
          <Checkbox
            checked={isIncremental}
            disabled={this.props.disabled}
            onChange={this._handleChangeIncremental}
          >
            Incremental
          </Checkbox>
          <HelpBlock>
            If the destination table exists in Storage, output mapping does not overwrite it; it
            only appends the data to it, using an incremental load.
          </HelpBlock>
        </FormGroup>
        {this.renderWriteAlways()}
        <hr />
        <FormGroup>
          <ControlLabel>
            Primary Key{' '}
            {this.props.tables
              .getIn([this.props.value.get('destination'), 'primaryKey'], List())
              .isEmpty() && <OptionalFormLabel />}
          </ControlLabel>
          <Select
            name="primary_key"
            value={primaryKey}
            multi
            disabled={this.props.disabled}
            allowCreate={columnsOptions.length === 0}
            placeholder="Add a column to the primary key"
            noResultsText="No matching column found"
            onChange={this._handleChangePrimaryKey}
            options={columnsOptions}
          />
          {this.renderPrimaryKeyHelpText()}
          {isIncremental && primaryKey.isEmpty() && (
            <Alert variant="warning" className="tw-mt-2">
              If you&apos;re importing data into table that don&apos;t have primary keys, you might
              end up with duplicate records.
            </Alert>
          )}
        </FormGroup>
        {(isIncremental || deleteWhereColumn !== '') && (
          <FormGroup>
            <ControlLabel>
              Delete Rows <OptionalFormLabel />
            </ControlLabel>
            <div className="select-group">
              <Select
                allowCreate
                options={columnsOptions}
                placeholder="Select a column"
                value={deleteWhereColumn}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('delete_where_column', value));
                }}
                promptTextCreator={(label) => (label ? 'Select the "' + label + '" column' : '')}
                disabled={this.props.disabled}
              />
              <Select
                clearable={false}
                searchable={false}
                value={this.props.value.get('delete_where_operator')}
                disabled={this.props.disabled}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('delete_where_operator', value));
                }}
                options={[
                  {
                    label: whereOperatorConstants.EQ_LABEL,
                    value: whereOperatorConstants.EQ_VALUE,
                  },
                  {
                    label: whereOperatorConstants.NOT_EQ_LABEL,
                    value: whereOperatorConstants.NOT_EQ_VALUE,
                  },
                ]}
              />
            </div>
            <Select
              name="deleteWhereValues"
              value={this.props.value.get('delete_where_values')}
              multi
              disabled={this.props.disabled}
              allowCreate
              placeholder="Add a value"
              emptyStrings
              onChange={this._handleChangeDeleteWhereValues}
            />
            <HelpBlock>
              Delete matching rows in the destination table before importing the result
            </HelpBlock>
          </FormGroup>
        )}
      </>
    );
  },
});

export default TableOutputMappingEditor;
