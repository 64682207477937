import React, { useId, useState } from 'react';
import type { ReactNode } from 'react';

import type { NodeData } from '@/modules/lineage/rfTypes';

export type GraphContextType = {
  graphId: string;
  mainNodeId: string | null;
  focusedNodeId: string | null;
  searchQuery: string;
  onSearch: (newQuery: string) => void;
  setNodeFocus: (nodeId: string | null) => void;
  nodeDetail: NodeData | null;
  setNodeDetail: (node: NodeData | null) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  zoomOnHover: boolean;
};

export const GraphContext = React.createContext<GraphContextType>(null!);

const GraphContextProvider = (props: {
  children: ReactNode;
  mainNodeId?: string | null;
  zoomOnHover?: boolean;
}) => {
  const graphId = useId();
  const [searchQuery, setSearchQuery] = useState('');
  const [focusedNodeId, setFocusedNodeId] = useState<string | null>(null);
  const [nodeDetail, setNodeDetail] = React.useState<NodeData | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const contextValue = React.useMemo(() => {
    return {
      graphId,
      mainNodeId: props.mainNodeId ?? null,
      focusedNodeId,
      searchQuery,
      onSearch: setSearchQuery,
      setNodeFocus: setFocusedNodeId,
      nodeDetail,
      setNodeDetail,
      isLoading,
      setIsLoading,
      zoomOnHover: props.zoomOnHover ?? false,
    };
  }, [
    graphId,
    props.mainNodeId,
    props.zoomOnHover,
    focusedNodeId,
    searchQuery,
    nodeDetail,
    isLoading,
  ]);

  return <GraphContext.Provider value={contextValue}>{props.children}</GraphContext.Provider>;
};

export default GraphContextProvider;
