import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';

import Loader from '@/react/common/Loader';

const GenerateButton = (props: {
  isSaving: boolean;
  handleGenerate?: () => void;
  isGenerating?: boolean;
  className?: string;
}) => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="Automatically generates a description using advanced artificial intelligence algorithms. The generated description may not be perfect, but it can serve as a starting point for further editing."
      forceHide={props.isGenerating}
    >
      <Button
        bsStyle="link"
        className={classNames('btn-link-inline m-0', props.className)}
        disabled={props.isGenerating || props.isSaving}
        onClick={props.handleGenerate}
      >
        {props.isGenerating ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon
            fixedWidth
            icon="brain-circuit"
            className="icon-addon-right color-cyan"
          />
        )}
        Generate description
      </Button>
    </Tooltip>
  );
};

export default GenerateButton;
