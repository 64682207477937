import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@keboola/design';
import createReactClass from 'create-react-class';
import _ from 'underscore';
import { rtrim, trim } from 'underscore.string';

import ApplicationStore from '@/stores/ApplicationStore';

const ExternalProjectLink = createReactClass({
  propTypes: {
    stackUrl: PropTypes.string,
    projectId: PropTypes.number,
    children: PropTypes.any,
  },

  getProjectUrl() {
    const projectPath = _.template(ApplicationStore.getProjectUrlTemplate())({
      projectId: this.props.projectId,
    });

    return `${rtrim(this.props.stackUrl, '/')}/${trim(projectPath, '/')}`;
  },

  render() {
    if (this.props.stackUrl && this.props.projectId) {
      return <Link href={this.getProjectUrl()}>{this.props.children}</Link>;
    } else {
      return this.props.children;
    }
  },
});

export default ExternalProjectLink;
