import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';

import type { FeedbackStatusType } from '@/modules/ai/constants';
import { FEEDBACK_STATUS } from '@/modules/ai/constants';

const FeedbackButtons = ({
  feedbackStatus,
  onGood,
  onBad,
  onReload,
  entityName,
  disabled,
}: {
  feedbackStatus: FeedbackStatusType | null;
  onGood: () => void;
  onBad: (message?: string) => void;
  onReload?: () => void;
  entityName: string;
  disabled: boolean;
}) => {
  return (
    <div className="feedback-buttons">
      <Tooltip
        placement="top"
        tooltip={`Like ${entityName}`}
        forceHide={feedbackStatus === FEEDBACK_STATUS.GOOD}
      >
        <Button
          disabled={disabled}
          bsStyle="link"
          className={classNames('btn-link-inline text-muted success', {
            'active tw-cursor-not-allowed': feedbackStatus === FEEDBACK_STATUS.GOOD,
          })}
          onClick={() => {
            if (feedbackStatus === FEEDBACK_STATUS.GOOD) {
              return;
            }
            onGood();
          }}
        >
          <FontAwesomeIcon icon="thumbs-up" />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        tooltip={`Dislike ${entityName}`}
        forceHide={feedbackStatus === FEEDBACK_STATUS.BAD}
      >
        <Button
          disabled={disabled}
          bsStyle="link"
          className={classNames('btn-link-inline text-muted danger', {
            'active tw-cursor-not-allowed': feedbackStatus === FEEDBACK_STATUS.BAD,
          })}
          onClick={() => {
            if (feedbackStatus === FEEDBACK_STATUS.BAD) {
              return;
            }

            onBad();
          }}
        >
          <FontAwesomeIcon icon="thumbs-down" />
        </Button>
      </Tooltip>
      {onReload && (
        <>
          <div className="btn-separator tw-m-0" />
          <Tooltip placement="top" tooltip={`Regenerate ${entityName}`}>
            <Button
              disabled={disabled}
              bsStyle="link"
              className="btn-link-inline text-muted"
              onClick={onReload}
            >
              <FontAwesomeIcon icon="arrows-rotate" />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default FeedbackButtons;
