import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import { DatasetLocations } from '@/modules/ex-google-bigquery-v2/constants';
import Select from '@/react/common/Select';

const Unload = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      storage: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Cloud Storage Bucket Name</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              disabled={disabled}
              onChange={(e) => onChange({ storage: e.target.value.trim() })}
              value={value.storage}
              placeholder="my-cloud-storage-bucket"
            />
            <HelpBlock>
              Existing Google Cloud Storage bucket. There will be data temporarily exported, before
              load to KBC.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Dataset Location</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              clearable={false}
              value={value.location}
              disabled={disabled}
              onChange={(value) => onChange({ location: value })}
              options={DatasetLocations}
            />
            <HelpBlock>The geographic location where dataset exists.</HelpBlock>
          </div>
        </FormGroup>
      </Form>
    );
  },
});

export default Unload;
