import type { Map } from 'immutable';
import { List } from 'immutable';

import type { VariableWithHash } from '@/api/routes/vaultService';
import * as componentFlags from '@/constants/componentFlags';
import {
  KEBOOLA_SAPI_MERGED_EXPORT,
  KEBOOLA_SLICED_FILES_DOWNLOADER,
} from '@/constants/componentIds';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { SHARED_TYPES } from '@/modules/data-catalog/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  isCreatedInDevBranch,
  isMergeRequestApproved,
  isMergeRequestInReview,
  isMergeRequestSentByCurrentAdmin,
} from '@/modules/dev-branches/helpers';
import { SUPPORTS_READONLY_STORAGE_ACCESS_TOGGLE } from '@/modules/sandboxes/Constants';
import SettingsStore from '@/modules/settings/SettingsStore';
import { STAGE } from '@/modules/storage/constants';
import ApplicationStore from '@/stores/ApplicationStore';

const ROLE_PATH = ['admin', 'role'];

const isAdminOrShare = (token: Map<string, any>) => {
  return [ADMIN_ROLES.ADMIN, ADMIN_ROLES.SHARE].includes(token.getIn(ROLE_PATH));
};

const isProductionManager = (token: Map<string, any>) => {
  return token.getIn(ROLE_PATH) === ADMIN_ROLES.PRODUCTION_MANAGER;
};

const isDeveloperOrReviewer = (token: Map<string, any>) => {
  return [ADMIN_ROLES.DEVELOPER, ADMIN_ROLES.REVIEWER].includes(token.getIn(ROLE_PATH));
};

const isProductionManagerInProduction = (token: Map<string, any>) => {
  return !DevBranchesStore.isDevModeActive() && isProductionManager(token);
};

const canChangeProjectOrganization = (
  user: Map<string, any>,
  organizationsViaMaintainer: Map<string, any>,
  project: Map<string, any>,
) => {
  return (
    user.get('isSuperAdmin', false) ||
    !!organizationsViaMaintainer.find(
      (organization) => organization.get('id') === project.getIn(['organization', 'id']),
    )
  );
};

const hasAccessToProjectOrganization = (
  user: Map<string, any>,
  organizations: Map<string, any>,
  organizationsViaMaintainer: Map<string, any>,
  project: Map<string, any>,
) => {
  return (
    canChangeProjectOrganization(user, organizationsViaMaintainer, project) ||
    !!organizations.find(
      (organization) => organization.get('id') === project.getIn(['organization', 'id']),
    )
  );
};

const canDeleteProject = (
  user: Map<string, any>,
  token: Map<string, any>,
  organizationsViaMaintainer: Map<string, any>,
  project: Map<string, any>,
): boolean => {
  if (canChangeProjectOrganization(user, organizationsViaMaintainer, project)) return true;

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return isAdminOrShare(token);
};

const canRunJob = (token: Map<string, any>): boolean => {
  if (!DevBranchesStore.isDevModeActive() && ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return !ApplicationStore.isReadOnly();
};

const canRunConfigDataJob = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return DevBranchesStore.isDevModeActive() && isDeveloperOrReviewer(token);
  }

  return !ApplicationStore.isReadOnly();
};

const canResetState = (
  token: Map<string, any>,
  component: Map<string, any> | undefined,
  settings?: Map<string, any>,
) => {
  if (!component?.get('flags', List()).includes(componentFlags.GENERIC_DOCKER_UI_RESET_STATE)) {
    return false;
  }

  if (!!settings && !settings.getIn(['row', 'hasState'])) {
    return false;
  }

  if (!DevBranchesStore.isDevModeActive() && ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return !ApplicationStore.isReadOnly();
};

const canManageSharedBucket = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return (
    token.getIn(['admin', 'isOrganizationMember'], false) ||
    token.getIn(ROLE_PATH) === ADMIN_ROLES.SHARE
  );
};

const canManageDevBranch = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isDeveloperOrReviewer(token);
  }

  return (
    isAdminOrShare(token) ||
    token.getIn(ROLE_PATH) === ADMIN_ROLES.GUEST ||
    ApplicationStore.getCurrentOrganization().get('isAdmin', false)
  );
};

const canDeleteDevBranch = (token: Map<string, any>) => {
  return canManageDevBranch(token) && token.getIn(ROLE_PATH) !== ADMIN_ROLES.GUEST;
};

const canManageSchedule = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return !ApplicationStore.isReadOnly();
  }

  if (DevBranchesStore.isDevModeActive()) {
    return false;
  }

  return isAdminOrShare(token);
};

const canManageTriggers = (token: Map<string, any>, schedule?: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManagerInProduction(token) && !schedule?.get('cronTab');
  }

  return canManageSchedule(token);
};

const canActivateSchedule = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return isAdminOrShare(token);
};

const canManageNotifications = (token: Map<string, any>): boolean => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManagerInProduction(token);
  }

  return isAdminOrShare(token);
};

const canManageUsers = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return token.getIn(['admin', 'isOrganizationMember'], false);
  }

  return isAdminOrShare(token);
};

const canLoadSharedBuckets = (token: Map<string, any>) => {
  return token.get('canManageBuckets', false);
};

const canManageBuckets = (token: Map<string, any>) => {
  return !ApplicationStore.isReadOnly() && token.get('canManageBuckets', false);
};

const canRegisterExternalBucket = (token: Map<string, any>) => {
  if (!SettingsStore.hasProjectOwnBackend() && !ApplicationStore.hasExternalBuckets()) {
    return false;
  }

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManagerInProduction(token);
  }

  return canManageBuckets(token);
};

const canManageTokenRestrictions = (token: Map<string, any>) => {
  return !isProductionManager(token);
};

const canLinkBucket = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManagerInProduction(token);
  }

  return canManageBuckets(token);
};

const canUnlinkBucket = (token: Map<string, any>, bucket: Map<string, any>) => {
  const canUnlink = ApplicationStore.hasProtectedDefaultBranch()
    ? isProductionManagerInProduction(token)
    : canManageBuckets(token);

  if (!canUnlink) {
    return false;
  }

  if (
    bucket.getIn(['sourceBucket', 'sharing']) === SHARED_TYPES.ORGANIZATION_MEMBER &&
    token.getIn(['admin', 'isOrganizationMember'])
  ) {
    return true;
  }

  if (
    [
      SHARED_TYPES.SELECTED_PROJECT,
      SHARED_TYPES.PROJECT_MEMBERS,
      SHARED_TYPES.SELECTED_PEOPLE,
    ].includes(bucket.getIn(['sourceBucket', 'sharing']))
  ) {
    return true;
  }

  return false;
};

const canManageBucket = (token: Map<string, any>, bucket: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return (
      DevBranchesStore.isDevModeActive() &&
      isDeveloperOrReviewer(token) &&
      bucket.get('idBranch') === DevBranchesStore.getCurrentId()
    );
  }

  return (
    token.getIn(ROLE_PATH) !== ADMIN_ROLES.READ_ONLY &&
    token.getIn(['bucketPermissions', bucket.get('id')], '') === 'manage'
  );
};

const canWriteBucket = (token: Map<string, any>, bucket: Map<string, any> | undefined) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return (
      DevBranchesStore.isDevModeActive() &&
      isDeveloperOrReviewer(token) &&
      bucket?.get('idBranch') === DevBranchesStore.getCurrentId()
    );
  }

  return (
    !!bucket &&
    !bucket.get('hasExternalSchema', false) &&
    token.getIn(ROLE_PATH) !== ADMIN_ROLES.READ_ONLY &&
    ['write', 'manage'].includes(token.getIn(['bucketPermissions', bucket.get('id')]))
  );
};

const canExportTable = (token: Map<string, any>) => {
  if (!ComponentsStore.hasComponent(KEBOOLA_SAPI_MERGED_EXPORT)) {
    return false;
  }

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return canRunConfigDataJob(token);
  }

  return true;
};

const canDownloadSlicedFile = (token: Map<string, any>) => {
  if (!ComponentsStore.hasComponent(KEBOOLA_SLICED_FILES_DOWNLOADER)) {
    return false;
  }

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return canRunConfigDataJob(token);
  }

  return !ApplicationStore.isReadOnly();
};

const canCreateAliasTable = (token: Map<string, any>, bucket: Map<string, any>) => {
  return (
    canWriteBucket(token, bucket) &&
    Object.values(STAGE).includes(bucket.get('stage')) &&
    !isCreatedInDevBranch(bucket)
  );
};

const canPullTable = () => {
  return ApplicationStore.hasProtectedDefaultBranch() && DevBranchesStore.isDevModeActive();
};

const canPurgeTrash = (token: Map<string, any>) => {
  return token.get('canPurgeTrash', false);
};

const canManageTokens = (token: Map<string, any>) => {
  return token.get('canManageTokens', false);
};

const canCreateToken = (token: Map<string, any>) => {
  return canManageTokens(token) && !isDeveloperOrReviewer(token);
};

const canApproveDevBranchReview = (
  token: Map<string, any>,
  admin: Map<string, any>,
  mergeRequest: Map<string, any>,
) => {
  return (
    token.getIn(ROLE_PATH) === ADMIN_ROLES.REVIEWER &&
    !isMergeRequestSentByCurrentAdmin(mergeRequest, admin)
  );
};

const canMergeDevBranchRequest = (token: Map<string, any>) => {
  return isProductionManager(token);
};

const canManageVariables = (token: Map<string, any>) => {
  if (!ApplicationStore.hasProtectedDefaultBranch()) {
    return isAdminOrShare(token);
  }

  const mergeRequest = DevBranchesStore.getCurrentDevBranchMergeRequest();

  if (isMergeRequestInReview(mergeRequest) || isMergeRequestApproved(mergeRequest)) {
    return false;
  }

  return DevBranchesStore.isDevModeActive()
    ? isDeveloperOrReviewer(token)
    : isProductionManager(token);
};

const canManageVariable = (token: Map<string, any>, variable: VariableWithHash) => {
  if (!ApplicationStore.hasProtectedDefaultBranch()) {
    return isAdminOrShare(token);
  }

  const defaultDevBranchId = DevBranchesStore.getDefaultBranchId()?.toString();

  return !variable.attributes?.branchId || variable.attributes.branchId === defaultDevBranchId
    ? isProductionManager(token)
    : isDeveloperOrReviewer(token);
};

const canManageReadOnlyStorageForProvisionedCredentials = (driver: string) => {
  return (
    ApplicationStore.hasReadOnlyStorage() &&
    !ApplicationStore.hasProtectedDefaultBranch() &&
    SUPPORTS_READONLY_STORAGE_ACCESS_TOGGLE.includes(driver)
  );
};

const canManageProjectFeatures = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return isProductionManager(token);
  }

  return isAdminOrShare(token);
};

const canCreateExternalAuthorization = (token: Map<string, any>) => {
  if (ApplicationStore.hasProtectedDefaultBranch()) {
    return false;
  }

  return isAdminOrShare(token);
};

const canAccessProject = (projectId?: string | number) => {
  const project = ApplicationStore.getCurrentOrganization()
    .get('projects')
    .find((project: Map<string, any>) => project.get('id') === Number(projectId));

  return !!project && !project.get('isDisabled');
};

export {
  hasAccessToProjectOrganization,
  canChangeProjectOrganization,
  canDeleteProject,
  canRunJob,
  canRunConfigDataJob,
  canResetState,
  canManageSharedBucket,
  canManageDevBranch,
  canDeleteDevBranch,
  canManageSchedule,
  canManageTriggers,
  canActivateSchedule,
  canManageNotifications,
  canManageUsers,
  canManageBuckets,
  canLoadSharedBuckets,
  canRegisterExternalBucket,
  canManageTokenRestrictions,
  canLinkBucket,
  canUnlinkBucket,
  canManageBucket,
  canWriteBucket,
  canExportTable,
  canDownloadSlicedFile,
  canCreateAliasTable,
  canPullTable,
  canPurgeTrash,
  canManageTokens,
  canCreateToken,
  canApproveDevBranchReview,
  canMergeDevBranchRequest,
  canManageVariables,
  canManageVariable,
  canManageReadOnlyStorageForProvisionedCredentials,
  canManageProjectFeatures,
  canCreateExternalAuthorization,
  canAccessProject,
};
