import React from 'react';
import PropTypes from 'prop-types';
import { Button, Nav, NavItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import { Promise } from 'bluebird';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '@/constants/componentIds';
import keyCodes from '@/constants/keyCodes';
import callDockerAction from '@/modules/components/DockerActionsApi';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { OPERATIONS, OPERATIONS_CATEGORIES } from '@/modules/no-code/operationsDefinition';
import { dataPreview } from '@/modules/storage/actions';
import { isValidName } from '@/react/common/helpers';
import Loader from '@/react/common/Loader';
import Truncated from '@/react/common/Truncated';
import SimpleError from '@/utils/errors/SimpleError';
import nextTick from '@/utils/nextTick';
import OperationModal from './OperationModal';

class NoCodeOperations extends React.Component {
  static propTypes = {
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    allTables: PropTypes.instanceOf(Map).isRequired,
    commonState: PropTypes.object.isRequired,
    setCommonState: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
  };

  state = {
    deletingOperationId: null,
    editingOperation: null,
    activeCategory: OPERATIONS_CATEGORIES.CLEAN,
    sourceTable: null,
    isSaving: false,
  };

  componentDidMount() {
    const savedDataSamplesKeys =
      this.props.commonState.dataSamples &&
      Object.keys(this.props.commonState.dataSamples).filter((key) => key !== 'sourceTables');

    if (
      !this.props.editMode ||
      !savedDataSamplesKeys ||
      savedDataSamplesKeys.some((key) => !this.props.commonState.dataSamples[key]?.isLoading)
    )
      return this.fetchInitialDataSamples();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.editMode
        ? this.getSourceTableId(prevProps.configData) !== this.getSourceTableId()
        : !prevProps.configData
            .getIn(['parameters', 'tables'], List())
            .equals(this.props.configData.getIn(['parameters', 'tables'], List()))
    ) {
      this.fetchInitialDataSamples();
    }
  }

  render() {
    const savedOperations = this.props.configData.getIn(['parameters', 'models'], List()).toJS();
    const filteredOperations = this.props.editMode
      ? savedOperations
      : Object.values(OPERATIONS)
          .filter((operation) => operation.category === this.state.activeCategory)
          .map(({ templateId }) => ({ templateId }));
    const categories = [
      ...new Set(Object.values(OPERATIONS).map((operation) => operation.category)),
    ];

    return (
      <div className="nocode-step-wrapper">
        {!this.props.editMode && (
          <div className="tabs-with-border-wrapper">
            <Nav
              bsStyle="tabs"
              role="navigation"
              activeKey={this.state.activeCategory}
              onSelect={(activeKey) => {
                if (activeKey === this.state.activeCategory) return;

                this.setState({ activeCategory: activeKey });
              }}
            >
              {categories.map((category) => (
                <NavItem key={category} eventKey={category}>
                  {category}
                </NavItem>
              ))}
            </Nav>
          </div>
        )}
        <div className={classNames('nocode-blocks', { 'with-columns': !this.props.editMode })}>
          {filteredOperations.map(
            ({ id = this.getNewOperationId(), templateId, variables }, index) => {
              const operationDefinition = OPERATIONS[templateId] || {};

              return (
                <div
                  key={`${templateId}-${id}`}
                  tabIndex="0"
                  role="button"
                  className="nocode-block clickable flex-container flex-start font-medium"
                  onClick={() => this.startEditingOperation(id, templateId, variables || {}, index)}
                  onKeyDown={(event) => {
                    if (event.key === keyCodes.ENTER) {
                      this.startEditingOperation(id, templateId, variables || {}, index);
                    }
                  }}
                >
                  <div className="icon-square-background icon-with-icon">
                    <img src={operationDefinition.icon} loading="lazy" />
                    {!this.props.editMode &&
                      savedOperations.some(
                        (savedOperation) => savedOperation.templateId === templateId,
                      ) && (
                        <FontAwesomeIcon
                          icon="circle-check"
                          className="text-success align-bottom small-icon"
                        />
                      )}
                  </div>
                  <div>
                    <Truncated className="font-medium color-main" text={operationDefinition.name} />
                    {variables && (
                      <Truncated
                        className="text-muted font-normal"
                        text={Object.values(variables)
                          .map((variableValue) =>
                            !!variableValue || variableValue === 0 ? variableValue : 'N/A',
                          )
                          .join(', ')}
                      />
                    )}
                  </div>
                  {this.props.editMode && (
                    <div className="ml-auto mr-0 action-buttons">
                      <Tooltip tooltip="Delete Manipulation" placement="top">
                        <Button
                          bsStyle="link"
                          className="text-muted"
                          disabled={this.state.isCodeDeleting || !!this.state.editingOperation}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handleOperationRemove(id, templateId);
                          }}
                        >
                          {this.state.deletingOperationId === id ? (
                            <Loader />
                          ) : (
                            <FontAwesomeIcon icon="trash" fixedWidth />
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            },
          )}
        </div>
        <OperationModal
          configData={this.props.configData}
          allTables={this.props.allTables}
          editingOperation={this.state.editingOperation}
          sourceTable={this.state.sourceTable}
          dataSamples={this.props.commonState.dataSamples}
          currentDataSample={this.getDataSample()}
          previousDataSample={this.getPreviousDataSample()}
          fetchDataSample={() =>
            this.fetchDataSample(this.state.editingOperation.id, {
              previewOnly: true,
            })
          }
          resetEditingOperation={this.resetEditingOperation}
          onChangeSourceTable={(sourceTable) =>
            this.setState((state) => ({
              editingOperation: {
                ...state.editingOperation,
                variables: this.getReinitializedVariables(state.editingOperation.templateId),
              },
              sourceTable,
            }))
          }
          onChangeEditingOperation={(editingOperation, { shouldResetHiddenInputs } = {}) =>
            this.setState(
              { editingOperation },
              () => shouldResetHiddenInputs && this.resetHiddenInputs(),
            )
          }
          onConfirm={this.props.editMode ? this.handleOperationChange : this.handleOperationAdd}
          onHide={() => this.setState({ editingOperation: null, sourceTable: null })}
          isEditingOperationValid={this.isEditingOperationValid()}
          isSaving={this.state.isSaving}
        />
      </div>
    );
  }

  isEditingOperationValid = () => {
    if (!this.state.editingOperation?.variables) return false;
    if (this.state.editingOperation.index === 0 && !this.state.sourceTable) return false;

    return Object.entries(this.state.editingOperation.variables).every(([key, value]) => {
      const operationDefinition = OPERATIONS[this.state.editingOperation.templateId];

      if (operationDefinition) {
        const inputDefinition =
          operationDefinition.inputs[key] ||
          Object.values(operationDefinition.inputs).find((inputGroup) => key in inputGroup)?.[key];

        if (inputDefinition?.defaultValue === value) {
          return true;
        }

        if (
          inputDefinition?.type === 'multiselect' &&
          (!value || (List.isList(value) && value.isEmpty()))
        ) {
          return false;
        }

        if (inputDefinition?.predefinedValidation) {
          return isValidName(value);
        }

        if (inputDefinition?.customValidation) {
          return !inputDefinition.customValidation(value, this.getPreviousDataSample());
        }
      }

      return !!value || value === 0;
    });
  };

  isEditingSourceTableChanged = () => {
    if (!this.state.sourceTable) return false;

    return this.getSourceTableId() !== this.state.sourceTable;
  };

  getSourceTableId = (configData = this.props.configData) => {
    const { bucketId, tableName } = configData.get('parameters', Map()).toJS();

    return bucketId && tableName ? `${bucketId}.${tableName}` : '';
  };

  fetchInitialDataSamples = (configData = this.props.configData) => {
    if (this.props.editMode) {
      if (
        !this.getSourceTableId() ||
        configData.getIn(['parameters', 'models'], List()).isEmpty()
      ) {
        return Promise.resolve();
      }

      this.props.setCommonState?.({
        dataSamples: {
          sourceTables: this.props.commonState?.dataSamples?.sourceTables || {},
          ...configData
            .getIn(['parameters', 'models'], List())
            .toJS()
            .reduce(
              (loadingModels, model) => ({
                ...loadingModels,
                [model.id]: {
                  isLoading: true,
                },
              }),
              {},
            ),
        },
      });

      return callDockerAction(this.props.componentId, 'preview', {
        configData: configData
          .setIn(['parameters', 'returnAllResults'], true)
          .deleteIn(['parameters', 'tables'])
          .toJS(),
      }).then((response) => {
        if (response?.status === 'error') {
          throw new SimpleError(
            'Preview failed.',
            response.error || 'An error occurred while loading preview.',
          );
        }

        response.shift();

        this.props.setCommonState?.({
          dataSamples: {
            ...(this.props.commonState?.dataSamples || {}),
            ...response.reduce(
              (loadingModels, dataSample, index) => ({
                ...loadingModels,
                [configData.getIn(['parameters', 'models', index, 'id'])]: {
                  dataSample,
                  isLoading: false,
                },
              }),
              {},
            ),
          },
        });
      });
    }

    return Promise.each(
      configData
        .getIn(['parameters', 'tables'], Map())
        .filter((tableId) => !this.props.commonState?.dataSamples?.sourceTables?.[tableId])
        .toArray(),
      // `nextTick` is necessary here in order to have up-to-date `commonState` prop
      (tableId) => nextTick(() => this.fetchSourceTableDataSample(tableId)),
    );
  };

  fetchSourceTableDataSample = (sourceTableId) => {
    if (!sourceTableId) return Promise.resolve();

    this.props.setCommonState?.({
      dataSamples: {
        ...(this.props.commonState?.dataSamples || {}),
        sourceTables: {
          ...(this.props.commonState?.dataSamples?.sourceTables || {}),
          [sourceTableId]: {
            ...(this.props.commonState?.dataSamples?.sourceTables?.[sourceTableId] || {}),
            isLoading: true,
          },
        },
      },
    });

    return dataPreview(sourceTableId)
      .then((dataSample) => {
        this.props.setCommonState?.({
          dataSamples: {
            ...(this.props.commonState?.dataSamples || {}),
            sourceTables: {
              ...(this.props.commonState?.dataSamples?.sourceTables || {}),
              [sourceTableId]: {
                dataSample,
                isLoading: false,
              },
            },
          },
        });
      })
      .catch((error) => {
        this.props.setCommonState?.({
          dataSamples: {
            ...(this.props.commonState?.dataSamples || {}),
            sourceTables: {
              ...(this.props.commonState?.dataSamples?.sourceTables || {}),
              [sourceTableId]: {
                ...(this.props.commonState?.dataSamples?.sourceTables?.[sourceTableId] || {}),
                isLoading: false,
              },
            },
          },
        });

        throw error;
      });
  };

  fetchDataSample = (id = this.state.editingOperation?.id, options) => {
    if (!this.getSourceTableId() || !this.isEditingOperationValid()) return Promise.resolve();

    const changedConfigData = this.props.editMode
      ? this.handleOperationChange({ skipSave: true })
      : this.handleOperationAdd({ skipSave: true });

    if (options?.previewOnly) {
      this.setState((state) => ({
        editingOperation: {
          ...state.editingOperation,
          preview: { isLoading: true },
        },
      }));
    } else {
      this.props.setCommonState?.({
        dataSamples: {
          ...(this.props.commonState?.dataSamples || {}),
          [id]: {
            ...(this.props.commonState?.dataSamples?.[id] || {}),
            isLoading: true,
          },
        },
      });
    }

    return callDockerAction(this.props.componentId, 'preview', {
      configData: changedConfigData
        .updateIn(['parameters', 'models'], List(), (models) =>
          models.setSize(models.findIndex((model) => model.get('id') === id) + 1),
        )
        .deleteIn(['parameters', 'tables'])
        .toJS(),
    })
      .then((response) => {
        if (response?.status === 'error') {
          throw response;
        }

        if (options?.previewOnly) {
          this.setState((state) => ({
            editingOperation: {
              ...state.editingOperation,
              preview: { dataSample: response, isLoading: false },
            },
          }));
        } else {
          this.props.setCommonState?.({
            dataSamples: {
              ...(this.props.commonState?.dataSamples || {}),
              [id]: { dataSample: response, isLoading: false },
            },
          });
        }
      })
      .catch((error) => {
        if (options?.previewOnly) {
          this.setState((state) => ({
            editingOperation: { ...state.editingOperation, preview: { isLoading: false } },
          }));
        } else {
          this.props.setCommonState?.({
            dataSamples: {
              ...(this.props.commonState?.dataSamples || {}),
              [id]: {
                ...(this.props.commonState?.dataSamples?.[id] || {}),
                isLoading: false,
              },
            },
          });
        }

        throw error;
      });
  };

  getDataSample = () => {
    if (!this.props.commonState?.dataSamples?.sourceTables || !this.state.editingOperation)
      return null;

    if (this.state.editingOperation.preview) return this.state.editingOperation.preview;

    if (
      !this.isEditingSourceTableChanged() &&
      this.state.editingOperation.id in this.props.commonState.dataSamples
    ) {
      return this.props.commonState.dataSamples[this.state.editingOperation.id];
    }

    return this.getPreviousDataSample();
  };

  getPreviousDataSample = () => {
    if (!this.props.commonState.dataSamples?.sourceTables || !this.state.editingOperation)
      return null;

    if (this.state.editingOperation.index === 0) {
      return (
        this.props.commonState.dataSamples.sourceTables[
          this.state.sourceTable || this.getSourceTableId()
        ] || null
      );
    }

    const previousModelId = this.props.configData
      .getIn(['parameters', 'models', this.state.editingOperation.index - 1], Map())
      .get('id');

    if (
      (previousModelId || previousModelId === 0) &&
      previousModelId in this.props.commonState.dataSamples
    ) {
      return this.props.commonState.dataSamples[previousModelId];
    }

    return null;
  };

  startEditingOperation = (id, templateId, variables, index) => {
    if (!this.props.editMode) {
      index = this.props.configData.getIn(['parameters', 'models'], List()).count();
    }

    this.setState({
      editingOperation: {
        id,
        templateId,
        variables: this.getReinitializedVariables(templateId, variables),
        preview: null,
        index,
      },
      sourceTable: index === 0 ? this.getSourceTableId() : null,
    });
  };

  resetEditingOperation = () => {
    const { id, templateId, index } = this.state.editingOperation;
    let variables = {};

    if (this.props.editMode) {
      variables =
        this.props.configData
          .getIn(['parameters', 'models'], List())
          .toJS()
          .find((model) => model.id === id)?.variables || {};
    }

    this.startEditingOperation(id, templateId, variables, index);
  };

  resetHiddenInputs = () => {
    this.setState((state) => ({
      editingOperation: {
        ...state.editingOperation,
        variables: this.getReinitializedVariables(
          state.editingOperation.templateId,
          state.editingOperation.variables,
        ),
      },
    }));
  };

  getReinitializedVariables = (templateId, variables) => {
    return Object.entries(OPERATIONS[templateId].inputs).reduce(
      (newVariables, [inputKey, inputDefinition]) => {
        if (
          (inputDefinition.id && inputDefinition.shouldHide?.(variables)) ||
          inputDefinition.type === 'sourceTable'
        ) {
          return newVariables;
        }

        if (!inputDefinition.id) {
          return Object.values(inputDefinition).reduce(
            (newVariablesNested, inputDefinitionNested) => {
              if (
                (inputDefinitionNested.id && inputDefinitionNested.shouldHide?.(variables)) ||
                inputDefinitionNested.type === 'sourceTable'
              ) {
                return newVariablesNested;
              }

              return {
                ...newVariablesNested,
                [inputDefinitionNested.id]:
                  variables?.[inputDefinitionNested.id] ??
                  (inputDefinitionNested.defaultValue || ''),
              };
            },
            newVariables,
          );
        }

        return {
          ...newVariables,
          [inputKey]: variables?.[inputKey] ?? inputDefinition.defaultValue ?? '',
        };
      },
      {},
    );
  };

  evaluateCode = (templateId, variables) => {
    if (!(templateId in OPERATIONS)) return '';

    return OPERATIONS[templateId].generateCode(
      variables,
      this.getPreviousDataSample()?.dataSample?.columns,
      this.props.commonState?.dataSamples?.sourceTables,
    );
  };

  getNewOperationId = () => {
    const currentHighestId = this.props.configData
      .getIn(['parameters', 'models'], List())
      .maxBy((model) => model.get('id'))
      ?.get('id');

    return currentHighestId >= 0 ? currentHighestId + 1 : 0;
  };

  handleOperationAdd = (options) => {
    if (!this.state.editingOperation) return this.props.configData;

    const { id, templateId, variables } = this.state.editingOperation;
    let changedConfigData = this.props.configData.updateIn(
      ['parameters', 'models'],
      List(),
      (models) =>
        models.push(
          fromJS({ id, templateId, variables, code: this.evaluateCode(templateId, variables) }),
        ),
    );

    if (this.state.sourceTable) {
      const table = this.props.allTables.get(this.state.sourceTable, Map());

      changedConfigData = changedConfigData
        .setIn(['parameters', 'bucketId'], table.getIn(['bucket', 'id']))
        .setIn(['parameters', 'tableName'], table.get('name'));
    }

    if (options?.skipSave) return changedConfigData;

    return this.handleChange(
      changedConfigData,
      `Add ${OPERATIONS[templateId]?.name} manipulation`,
      id,
    );
  };

  handleOperationChange = (options) => {
    if (!this.state.editingOperation) return this.props.configData;

    const { id, templateId, variables, index } = this.state.editingOperation;
    let changedConfigData = this.props.configData.setIn(
      ['parameters', 'models', index],
      fromJS({ id, templateId, variables, code: this.evaluateCode(templateId, variables) }),
    );

    if (this.state.sourceTable) {
      const table = this.props.allTables.get(this.state.sourceTable, Map());

      changedConfigData = changedConfigData
        .setIn(['parameters', 'bucketId'], table.getIn(['bucket', 'id']))
        .setIn(['parameters', 'tableName'], table.get('name'));
    }

    if (options?.skipSave) {
      return changedConfigData.updateIn(['parameters', 'models'], List(), (models) =>
        models.setSize(index + 1),
      );
    }

    return this.handleChange(
      changedConfigData,
      `Change ${OPERATIONS[templateId]?.name} manipulation`,
      id,
    );
  };

  handleOperationRemove = (id, templateId) => {
    const dataSamples = this.props.commonState.dataSamples || {};

    delete dataSamples[id];
    this.props.setCommonState({
      dataSamples,
    });

    this.setState({
      deletingOperationId: id,
    });

    return this.handleChange(
      this.props.configData.updateIn(['parameters', 'models'], List(), (models) =>
        models.filter((model) => model.get('id') !== id),
      ),
      `Remove ${OPERATIONS[templateId]?.name} manipulation`,
      id,
    );
  };

  handleChange = (configData, changeDescription, changedOperationId) => {
    this.setState({ isSaving: true });

    return InstalledComponentsActionCreators.saveComponentConfigData(
      KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
      this.props.configId,
      configData,
      changeDescription,
    )
      .then(() => {
        if (
          configData.getIn(['parameters', 'models'], List()).last()?.get('id') !==
          changedOperationId
        ) {
          nextTick(() => this.fetchInitialDataSamples(configData));
        } else if (
          configData
            .getIn(['parameters', 'models'], List())
            .some((model) => model.get('id') === changedOperationId)
        ) {
          this.fetchDataSample(changedOperationId);
        }
      })
      .finally(() =>
        this.setState({
          isSaving: false,
          deletingOperationId: null,
        }),
      );
  };
}

export default NoCodeOperations;
