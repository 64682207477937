import { Promise } from 'bluebird';
import type { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareConfigurationForSave } from '@/modules/orchestrations-v2/helpers';
import { clearLocalState } from '@/modules/orchestrations-v2/localState';
import { removeSchedulers } from '@/modules/scheduler/actions';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadFlow = (configId: string) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_ORCHESTRATOR, configId);
};

const saveFlow = (config: Map<string, any>, tasks: Map<string, any>, phases: List<any>) => {
  const preparedTasks = tasks.map((task) => {
    return !task.getIn(['task', 'configId']) ? task.set('enabled', false) : task;
  });

  const configuration = prepareConfigurationForSave(
    config.get('configuration'),
    phases,
    preparedTasks,
  ).toJS();

  return updateFlowConfiguration(
    config.get('id'),
    { configuration: JSON.stringify(configuration) },
    'Update flow tasks',
  ).then(() => clearLocalState(config.get('id')));
};

const deleteFlow = (flow: Map<string, any>, options = {}) => {
  return InstalledComponentsActionCreators.deleteConfiguration(
    KEBOOLA_ORCHESTRATOR,
    flow.get('id'),
    { transition: false, notification: true, ...options },
  ).then(() => removeSchedulers(flow.get('schedulers')));
};

const updateFlowConfiguration = (
  configurationId: string,
  configuration: {
    configuration?: string;
    isDisabled?: boolean;
  },
  changeDescription: string,
) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    configurationId,
    configuration,
    changeDescription,
  );
};

export { loadAll, loadFlow, saveFlow, deleteFlow, updateFlowConfiguration };
