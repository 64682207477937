const routeNames = {
  ROOT: 'orchestrations-v2',
  DETAIL: 'orchestrations-v2-detail',
  TASKS: 'orchestrations-v2-tasks',
  FLOW: 'orchestrations-v2-flow',
  NOTIFICATIONS: 'orchestrations-v2-notifications',
  VERSIONS: 'orchestrations-v2-versions',
};

const orchestrationTabs = {
  ALL_RUNS: 'all-runs',
  SUCCESFUL_RUNS: 'successful-runs',
  FAILED_RUNS: 'failed-runs',
};

const orchestrationJobTypes = {
  FAILED: 'error',
  SUCCESSFUL: 'success',
};

export { routeNames, orchestrationTabs, orchestrationJobTypes };
