import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import { HelpBlock, Link } from '@keboola/design';
import createReactClass from 'create-react-class';

const DataSource = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      query: PropTypes.string.isRequired,
      since: PropTypes.string.isRequired,
      until: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Data Source</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Query</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.query}
                onChange={({ target }) => {
                  this.props.onChange({ query: target.value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                GAQL query for downloading a report. You should pick columns to download from
                allowed report values and FROM clause from allowed report types.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Start date of downloaded stats (optional)</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.since}
                onChange={({ target }) => {
                  this.props.onChange({ since: target.value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Use <code>strtotime</code> format (
                <Link href="https://php.net/manual/en/function.strtotime.php">
                  https://php.net/manual/en/function.strtotime.php
                </Link>
                )
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>End date of downloaded stats (optional)</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.until}
                onChange={({ target }) => {
                  this.props.onChange({ until: target.value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Use <code>strtotime</code> format (
                <Link href="https://php.net/manual/en/function.strtotime.php">
                  https://php.net/manual/en/function.strtotime.php
                </Link>
                )
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  },
});

export default DataSource;
