import React from 'react';
import { Button as BootstrapButton, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, cn, Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import {
  getDescriptionValue,
  tableDisplayNameWithBucketAndStage,
  tableName,
} from '@/modules/storage/helpers';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import Checkbox from '@/react/common/Checkbox';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import LazyList from '@/react/common/LazyList';
import MarkedText from '@/react/common/MarkedText';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import matchByWords from '@/utils/matchByWords';
import { windowOpen } from '@/utils/windowOpen';

const TableSelection = (props: {
  tablesIds?: string[];
  onClick: (tablesIds: string[]) => void;
}) => {
  const tablesListRef = React.useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedTables, setSelectedTables] = React.useState<string[]>([]);
  const store = useStores(
    () => {
      return {
        allTables: StorageTablesStore.getAll() as Map<string, any>,
        allBuckets: StorageBucketsStore.getAll() as Map<string, any>,
      };
    },
    [],
    [StorageTablesStore, StorageBucketsStore],
  );
  const searchResults: string[] = React.useMemo(() => {
    if (!searchQuery) return [];

    return filterProductionAndCurrentDevBranchTables(store.allTables, store.allBuckets)
      .filter((table: Map<string, any>) => {
        if (selectedTables.includes(table.get('id'))) return false;

        return (
          searchQuery === table.get('id') || matchByWords(table.get('displayName'), searchQuery)
        );
      })
      .map((table: Map<string, any>) => table.get('id'))
      .sortBy((tableId: string) => tableDisplayNameWithBucketAndStage(store.allTables.get(tableId)))
      .toArray();
  }, [store.allTables, store.allBuckets, selectedTables, searchQuery]);

  return (
    <>
      <div className="tw-relative">
        <FormControl
          type="text"
          placeholder="Search tables..."
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchQuery(event.target.value)
          }
          className="tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-py-3.5 tw-pl-11"
        />
        <FontAwesomeIcon
          icon="search"
          className="tw-absolute tw-inset-y-0 tw-left-4 tw-my-auto tw-text-base tw-text-neutral-400"
        />
      </div>
      {(!!selectedTables.length || !!searchResults.length || !!searchQuery) && (
        <div className="!tw-m-0 tw-flex tw-max-h-72 tw-flex-col tw-overflow-y-auto tw-rounded-lg tw-border tw-border-solid tw-border-neutral-150 tw-p-1">
          {!!selectedTables.length && (
            <div
              className={cn('tw-flex-auto tw-flex-shrink-0 tw-overflow-y-auto', {
                'tw-max-h-[50%]': !!searchQuery,
              })}
            >
              {selectedTables.map((tableId) => (
                <TableSelectionItem
                  key={tableId}
                  tableId={tableId}
                  allTables={store.allTables}
                  isSelected={selectedTables.includes(tableId)}
                  onSelect={setSelectedTables}
                />
              ))}
            </div>
          )}
          {!!selectedTables.length && (!!searchResults.length || !!searchQuery) && (
            <hr className="tw-my-1 tw-w-[calc(100%-1rem)]" />
          )}
          {!!searchResults.length && (
            <div ref={tablesListRef} className="tw-overflow-y-auto">
              <LazyList
                useWindow={false}
                getScrollParent={() => tablesListRef.current}
                items={searchResults}
                render={(items) =>
                  items.map((tableId) => (
                    <TableSelectionItem
                      key={tableId}
                      tableId={tableId}
                      allTables={store.allTables}
                      isSelected={selectedTables.includes(tableId)}
                      onSelect={setSelectedTables}
                      searchQuery={searchQuery}
                    />
                  ))
                }
              />
            </div>
          )}
          {!!searchQuery && !searchResults.length && (
            <span className="tw-px-3 tw-py-2">No results found</span>
          )}
        </div>
      )}
      <div className="tw-flex tw-gap-2">
        <Button
          disabled={selectedTables.length === 0}
          onClick={() => props.onClick(selectedTables)}
        >
          Submit Selection
        </Button>
        <BootstrapButton
          bsStyle="link"
          className="btn-link-inline"
          onClick={() => props.onClick([])}
        >
          Not Relevant
        </BootstrapButton>
      </div>
    </>
  );
};

const TableSelectionItem = ({
  allTables,
  tableId,
  isSelected,
  onSelect,
  searchQuery,
}: {
  allTables: Map<string, any>;
  tableId: string;
  isSelected: boolean;
  onSelect: (setSelectedTables: (selectedTables: string[]) => string[]) => void;
  searchQuery?: string;
}) => {
  const table = allTables.get(tableId);

  if (!table) {
    return null;
  }

  return (
    <div
      key={table.get('id')}
      className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded tw-p-2 hover:tw-bg-neutral-100"
      onClick={() =>
        onSelect((selectedTables) =>
          isSelected ? selectedTables.filter((id) => id !== tableId) : [...selectedTables, tableId],
        )
      }
    >
      <Checkbox checked={isSelected} className="tw-pointer-events-none" />
      <BucketStageLabel
        placement="left"
        stage={table.getIn(['bucket', 'stage'])}
        className="tw-mx-0"
      />
      <DevBranchLabel bucket={table.get('bucket')} />
      <div className="tw-flex tw-flex-col tw-gap-0.5">
        <Truncated
          tooltip={tableName(table)}
          text={
            <>
              {table.getIn(['bucket', 'displayName'])} /
              <MarkedText source={table.get('displayName')} mark={searchQuery} />
            </>
          }
        />
        {getDescriptionValue(table.get('metadata')) && (
          <Truncated
            text={getDescriptionValue(table.get('metadata'))}
            className="tw-text-xs tw-text-neutral-400"
          />
        )}
      </div>
      <div className="tw-ml-auto tw-shrink-0">
        <Tooltip placement="top" tooltip="Show table in new tab">
          <BootstrapButton
            bsStyle="link"
            className="!tw-p-0 !tw-text-neutral-400"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();

              // TODO: Add option to open table preview in current tab. Currently it resets chat history.
              windowOpen(
                RoutesStore.getRouter().createHref(storageRouteNames.TABLE, {
                  bucketId: table.getIn(['bucket', 'id']),
                  tableName: table.get('name'),
                }),
              );
            }}
          >
            <FontAwesomeIcon icon="eye" />
          </BootstrapButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default TableSelection;
