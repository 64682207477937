import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';
import { Map } from 'immutable';

import { AiIcon } from '@/react/common/AI/svgGradient';
import CircleIcon from '@/react/common/CircleIcon';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import Loader from '@/react/common/Loader';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';

class Code extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNameSaving: false,
      isNameEditing: false,
      editingNameValue: '',
      isCodeDeleting: false,
    };

    this.handleEditNameStart = this.handleEditNameStart.bind(this);
    this.handleEditNameCancel = this.handleEditNameCancel.bind(this);
    this.handleEditNameChange = this.handleEditNameChange.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  render() {
    return (
      <span className="flex-container" data-id={this.props.sortableKey}>
        <div
          tabIndex="0"
          role={this.state.isNameEditing ? 'textbox' : 'button'}
          className={classnames('fill-space code-block flex-container flex-start', {
            'is-last': this.props.newCodeButton,
          })}
          onClick={() => {
            if (!this.state.isNameEditing) {
              this.props.onClickCode();
            }
          }}
        >
          {this.renderMoveIcon()}
          {this.renderCodeIcon()}
          {this.renderCodeName()}
          {!this.props.newCodeButton && !this.props.readOnly && (
            <div className="ml-auto mr-0 action-buttons">
              <Tooltip tooltip="Delete Code" placement="top">
                <Button
                  bsStyle="link"
                  className="text-muted"
                  disabled={this.state.isCodeDeleting || this.props.isCodeOpened}
                  onClick={this.handleDelete}
                >
                  {this.state.isCodeDeleting ? (
                    <Loader />
                  ) : (
                    <FontAwesomeIcon icon="trash" fixedWidth />
                  )}
                </Button>
              </Tooltip>
              <RowActionDropdown>
                <RowActionMenuItem
                  disabled={this.state.isCodeDeleting}
                  onSelect={() => {
                    this.handleEditNameCancel();
                    this.props.onClickCode();
                  }}
                >
                  <FontAwesomeIcon fixedWidth icon={this.props.isSharedCode ? 'eye' : 'pen'} />
                  {`${this.props.isSharedCode ? 'View' : 'Edit'} code`}
                </RowActionMenuItem>
                <RowActionMenuItem
                  disabled={this.state.isCodeDeleting || this.props.isCodeOpened}
                  onSelect={() => {
                    this.handleEditNameCancel();
                    this.props.onCopyCode();
                  }}
                >
                  <FontAwesomeIcon fixedWidth icon="clone" />
                  Copy code
                </RowActionMenuItem>
                {!this.props.isSharedCode ? (
                  <RowActionMenuItem
                    disabled={this.state.isCodeDeleting || this.props.isCodeOpened}
                    onSelect={this.props.onCreateSharedCodeFromCode}
                  >
                    <FontAwesomeIcon fixedWidth icon="code" />
                    Share code
                  </RowActionMenuItem>
                ) : (
                  <RowActionMenuItem
                    disabled={this.state.isCodeDeleting || this.props.isCodeOpened}
                    onSelect={this.props.onCreateCodeFromSharedCode}
                  >
                    <FontAwesomeIcon icon="code" fixedWidth className="text-success" />
                    Use as inline code
                  </RowActionMenuItem>
                )}
              </RowActionDropdown>
            </div>
          )}
        </div>
      </span>
    );
  }

  renderCodeName() {
    if (this.props.hasAiConversations && this.props.newCodeButton) {
      return (
        <div className="tw-flex tw-items-center tw-gap-4">
          <span className="tw-text-secondary-600 tw-underline">Write new code</span>
          <span className="tw-text-neutral-400">or</span>
          <span
            className="-tw-ml-[7px] tw-p-[7px] tw-text-secondary-600 tw-underline"
            onClick={() =>
              setTimeout(() => document.dispatchEvent(new CustomEvent('focus-ai-generator')), 300)
            }
          >
            <AiIcon className="tw-mr-2.5" />
            Generate it by AI
          </span>
        </div>
      );
    }

    if (this.props.newCodeButton) {
      return <span className="code-name">Add new code</span>;
    }

    if (this.props.readOnly || this.props.isSharedCode || this.props.isCodeOpened) {
      return <span className="code-name">{this.props.code.get('name')}</span>;
    }

    if (!this.state.isNameEditing) {
      return (
        <>
          <span className="code-name">{this.props.code.get('name')}</span>
          <Tooltip tooltip="Edit code name" placement="top">
            <Button
              bsStyle="link"
              className="btn-link-inline btn-link-muted"
              onClick={this.handleEditNameStart}
            >
              <FontAwesomeIcon fixedWidth icon="pen" />
            </Button>
          </Tooltip>
        </>
      );
    }

    return (
      <InlineEditTextInput
        isEditing
        placeholder="Code name"
        isValid={!!this.state.editingNameValue.trim()}
        isChanged={this.state.editingNameValue !== this.props.code.get('name')}
        text={this.state.editingNameValue}
        isSaving={this.state.isNameSaving}
        onEditCancel={this.handleEditNameCancel}
        onEditChange={this.handleEditNameChange}
        onEditSubmit={this.handleRename}
      />
    );
  }

  renderMoveIcon() {
    if (
      this.props.newCodeButton ||
      this.props.readOnly ||
      this.state.isNameEditing ||
      this.props.isCodeOpened
    ) {
      return null;
    }

    return (
      <Tooltip placement="top" tooltip="Move Code" triggerClassName="code-drag-handle">
        <FontAwesomeIcon
          className="text-muted-light dragable"
          icon="bars"
          onClick={(event) => event.stopPropagation()}
        />
      </Tooltip>
    );
  }

  renderCodeIcon() {
    if (this.props.isSharedCode) {
      return <CircleIcon icon="code" className="icon-addon-right" color="blue" smaller bold />;
    }

    return (
      <FontAwesomeIcon
        icon="code"
        className={classnames('icon-addon-right', {
          'text-success': !this.props.newCodeButton,
        })}
      />
    );
  }

  handleEditNameChange(value) {
    this.setState({ editingNameValue: value });
  }

  handleEditNameStart(e) {
    e.stopPropagation();

    this.setState({ isNameEditing: true, editingNameValue: this.props.code.get('name') });
  }

  handleEditNameCancel() {
    this.setState({ isNameEditing: false, editingNameValue: '' });
  }

  handleRename() {
    this.setState({ isNameSaving: true });
    return this.props.onChangeCodeName(this.state.editingNameValue.trim()).then(() => {
      this.setState({ isNameEditing: false, isNameSaving: false, editingNameValue: '' });
    });
  }

  handleDelete(e) {
    e.stopPropagation();

    this.setState({ isCodeDeleting: true });
    return this.props.onDeleteCode().finally(() => {
      this.setState({ isCodeDeleting: false });
    });
  }
}

Code.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  sortableKey: PropTypes.string.isRequired,
  code: PropTypes.instanceOf(Map).isRequired,
  isSharedCode: PropTypes.bool.isRequired,
  onClickCode: PropTypes.func.isRequired,
  onDeleteCode: PropTypes.func,
  onCopyCode: PropTypes.func,
  onCreateSharedCodeFromCode: PropTypes.func,
  onCreateCodeFromSharedCode: PropTypes.func,
  onChangeCodeName: PropTypes.func,
  newCodeButton: PropTypes.bool,
  isCodeOpened: PropTypes.bool,
  hasAiConversations: PropTypes.bool,
};

Code.defaultProps = {
  newCodeButton: false,
  isCodeOpened: false,
};

export default Code;
