import React, { useRef, useState } from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import { Alert, Clipboard, HelpBlock, Link } from '@keboola/design';
import classNames from 'classnames';
import { fromJS, Map } from 'immutable';

import callDockerAction from '@/modules/components/DockerActionsApi';
import Checkbox from './Checkbox';
import Loader from './Loader';

const Copy = ({ value }: { value: string }) => (
  <Clipboard
    aloneLink
    label="Copy to Clipboard"
    tooltipText="Click to copy"
    tooltipPlacement="top"
    text={value}
  />
);

const PrivateKeyWarning = () => (
  <div className="tw-mt-2 tw-flex tw-items-center tw-text-xs">
    <FontAwesomeIcon
      icon="circle-exclamation"
      className="tw-mr-2 tw-text-base tw-text-warning-500"
    />
    The private key is displayed only once. After that, it will disappear, and you will need to
    generate a new key pair.
  </div>
);

const Help = () => {
  return (
    <Link
      href={`${URLS.USER_DOCUMENTATION}/components/extractors/database/#connecting-to-database`}
    >
      Help
    </Link>
  );
};

const GenerateLabel = ({
  publicKey,
  isGenerating,
}: {
  publicKey: string;
  isGenerating: boolean;
}) => {
  if (isGenerating) {
    return (
      <>
        <Loader className="icon-addon-right" />
        Generating...
      </>
    );
  }

  return publicKey ? 'Regenerate SSH key' : 'Generate SSH key';
};

type NewKeyType = 'generate' | 'custom';

type Props = {
  readOnly: boolean;
  data: Map<string, any>;
  isEnabled: boolean;
  globalData: Map<string, any>;
  hasPassivePortRange?: boolean;
  horizontal?: boolean;
  showHelp?: boolean;
  onChange: (data: Map<string, any>) => void;
};

const SshForm = ({
  readOnly,
  data,
  isEnabled,
  globalData = Map(),
  hasPassivePortRange = false,
  horizontal = true,
  showHelp = true,
  onChange,
}: Props) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [newKeysType, setNewKeysType] = useState<NewKeyType>('generate');
  const [isError, setIsError] = useState(false);

  const publicKey = data.getIn(['keys', 'public']);
  const privateKey = data.getIn(['keys', '#private']);
  const previousPrivateKey = useRef(privateKey);

  const isSshConfigAvailable = data.get('enabled', false);

  const isDisabled = (prop: string) => {
    return !isEnabled || readOnly || globalData.has(prop);
  };

  const handleChangeCustomKeys =
    (type: '#private' | 'public') => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length === 0) {
        const removeEmptyKey = data.get('keys').delete(type);

        return onChange(data.set('keys', removeEmptyKey));
      }

      onChange(data.setIn(['keys', type], e.target.value));
    };

  const handleChangeType = (type: NewKeyType) => {
    handleRemoveKeys();
    setNewKeysType(type);
  };

  const renderFormGroup = (
    label: React.ReactNode | null,
    body: React.ReactNode,
    className?: string,
  ) => {
    return (
      <FormGroup className={className}>
        {horizontal ? (
          <>
            <div className="col-xs-4">
              <ControlLabel>{label}</ControlLabel>
            </div>
            <div className="col-xs-8">{body}</div>
          </>
        ) : (
          <>
            {label && <ControlLabel>{label}</ControlLabel>}
            {body}
          </>
        )}
      </FormGroup>
    );
  };

  const createInput = (
    labelValue: string,
    propName: string,
    type = 'text',
    help?: React.ReactNode,
  ) => {
    return renderFormGroup(
      labelValue,
      <>
        <FormControl
          type={type}
          disabled={isDisabled(propName)}
          value={data.get(propName, '')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value =
              !!e.target.value.length && type === 'number'
                ? parseInt(e.target.value, 10)
                : e.target.value;

            onChange(data.set(propName, value));
          }}
        />
        {help && <HelpBlock>{help}</HelpBlock>}
      </>,
    );
  };

  const renderEnableCheckbox = () => {
    return renderFormGroup(
      null,
      <>
        <Checkbox
          disabled={isDisabled('enabled')}
          checked={isSshConfigAvailable}
          onChange={() => onChange(data.set('enabled', !isSshConfigAvailable))}
        >
          SSH Tunnel {showHelp && <Help />}
        </Checkbox>
        {hasPassivePortRange && (
          <HelpBlock>
            You have to activate the <b>Ignore passive address</b> option in order to set up SSH.
          </HelpBlock>
        )}
      </>,
    );
  };

  const renderGenerateButton = (publicKey: string) => {
    return (
      <div className="tw-mt-4 tw-flex tw-items-center tw-gap-4">
        <Button
          disabled={!isEnabled || isGenerating || readOnly || !globalData.isEmpty()}
          onClick={generateKeys}
        >
          <GenerateLabel publicKey={publicKey} isGenerating={isGenerating} />
        </Button>
        {isError && (
          <span className="text-danger">
            <FontAwesomeIcon
              icon="triangle-exclamation"
              className="tw-mr-1 tw-text-base"
              fixedWidth
            />
            Unable to generate an SSH key. Please try again.
          </span>
        )}
      </div>
    );
  };

  const renderGenerateKeysUI = () => {
    return (
      <div className="tw-mb-2 tw-pl-6">
        {publicKey && (
          <div className="tw-mt-4">
            <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
              <div>SSH Public Key</div>
              <Copy value={publicKey} />
            </div>
            <FormControl
              componentClass="textarea"
              disabled
              value={publicKey}
              className="tw-min-h-[100px] !tw-bg-neutral-150"
            />
          </div>
        )}
        {renderGenerateButton(publicKey)}
      </div>
    );
  };

  const renderCustomKeysUI = () => {
    return (
      <div className="tw-mb-3 tw-pl-6">
        <Alert
          variant="warning"
          className="tw-mb-5 tw-mt-4"
          title="Use a self-generated SSH key pair only at your own risk."
        >
          The person who generated the SSH key is responsible for keeping it safe and not sharing it
          with others. The key generated by the component itself can never be stolen as it&apos;s
          never revealed and linked only to the specific configuration.
        </Alert>

        <div className="tw-mt-4">
          <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
            <div>SSH Private Key</div>
            {privateKey && <Copy value={privateKey} />}
          </div>
          <FormControl
            placeholder="Enter Private Key"
            componentClass="textarea"
            className="tw-min-h-[100px]"
            onChange={handleChangeCustomKeys('#private')}
          />
          <PrivateKeyWarning />
        </div>

        <div className="tw-mt-4">
          <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
            <div>SSH Public Key</div>
            {publicKey && <Copy value={publicKey} />}
          </div>
          <FormControl
            placeholder="Enter Public Key"
            componentClass="textarea"
            className="tw-min-h-[100px]"
            onChange={handleChangeCustomKeys('public')}
          />
        </div>
      </div>
    );
  };

  const renderKeysConfigured = () => {
    return (
      <div className={classNames('tw-mt-3', { 'tw-ml-2': !horizontal })}>
        {publicKey && (
          <>
            <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
              <div>SSH Public Key</div>
              <Copy value={publicKey} />
            </div>
            <FormControl
              componentClass="textarea"
              className="tw-min-h-[100px] !tw-bg-neutral-150"
              disabled
              value={publicKey}
            />
          </>
        )}
        <Button className={publicKey ? 'tw-my-4' : 'tw-mb-4'} onClick={handleRemoveKeys}>
          Edit SSH Keys
        </Button>
      </div>
    );
  };

  const renderKeysNotConfigured = () => {
    return (
      <>
        <div className="tw-mb-2">
          <Radio
            type="radio"
            title="Generated SSH Key"
            onChange={() => handleChangeType('generate')}
            checked={newKeysType === 'generate'}
          >
            Generated SSH Key
          </Radio>
          {newKeysType === 'generate' && renderGenerateKeysUI()}
        </div>

        <div className="tw-mb-2">
          <Radio
            type="radio"
            title="Add Own Private Key Pair"
            onChange={() => handleChangeType('custom')}
            checked={newKeysType === 'custom'}
          >
            Add Own Private Key Pair
          </Radio>
          {newKeysType === 'custom' && renderCustomKeysUI()}
        </div>
      </>
    );
  };

  const renderSshKeys = () => {
    // In case user will edit keys and then reset we need to prevent exposing private key
    const isKeysConfigured = privateKey && previousPrivateKey.current === privateKey;

    return renderFormGroup(
      'SSH Keys',
      isKeysConfigured ? renderKeysConfigured() : renderKeysNotConfigured(),
      classNames({ 'tw-mt-6': !horizontal }),
    );
  };

  const generateKeys = () => {
    setIsGenerating(true);
    setIsError(false);

    callDockerAction('keboola.ssh-keygen-v2', 'generate', { configData: [] })
      .then((result) => {
        if (result.status === 'success' || result.status === 'ok') {
          onChange(
            data.setIn(['keys'], fromJS({ public: result.public, '#private': result.private })),
          );
        } else {
          setIsError(true);
        }
      })
      .finally(() => setIsGenerating(false));
  };

  const handleRemoveKeys = () => {
    onChange(data.delete('keys'));
  };

  return (
    <>
      {renderEnableCheckbox()}
      {isSshConfigAvailable && (
        <>
          {createInput('SSH host', 'sshHost')}
          {createInput('SSH user', 'user')}
          {createInput('SSH port', 'sshPort', 'number')}
          {hasPassivePortRange &&
            createInput(
              'Passive port range',
              'passivePortRange',
              'text',
              <>
                Port range where passive mode of FTP runs (e.g., <code>10000:10005</code>)
              </>,
            )}
          {renderSshKeys()}
        </>
      )}
    </>
  );
};

export default SshForm;
