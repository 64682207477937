import React from 'react';
import { ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import { Alert, HelpBlock, Link } from '@keboola/design';
import type Promise from 'bluebird';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import {
  defaultProxyAuthorization,
  DOCUMENTATION_LINK,
  OIDC_PROVIDERS,
} from '@/modules/data-apps/constants';
import {
  prepareProviderFormData,
  prepareValueFromMapping,
  resolveProvider,
} from '@/modules/data-apps/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import PasswordControl from '@/react/common/PasswordControl';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import fromJSOrdered from '@/utils/fromJSOrdered';

export type Provider = {
  name: string;
  label: string;
  url: string;
  inputs?: {
    label: string;
    property: string;
    optional?: boolean;
    help?: React.ReactNode;
    placeholder?: string;
  }[];
  values?: Record<string, { property: string; regex: RegExp }>;
  defaults?: Record<string, string>;
  mapping?: Record<string, string>;
};

const MODES = {
  BASIC: 'basic',
  OIDC: 'oidc',
  NONE: 'none',
};

const PROVIDERS_PATH = ['authorization', 'app_proxy', 'auth_providers'];
const AUTH_RULES_PATH = ['authorization', 'app_proxy', 'auth_rules'];

const resolveSavedMode = (configData: Map<string, any>) => {
  if (!configData.hasIn(PROVIDERS_PATH) || !configData.hasIn(AUTH_RULES_PATH)) {
    return null;
  }

  const provider = configData.getIn([...PROVIDERS_PATH, 0], Map());

  switch (provider.get('type')) {
    case 'password':
      return MODES.BASIC;

    case 'oidc':
      return MODES.OIDC;

    default: {
      return MODES.NONE;
    }
  }
};

const AuthenticationSettings = (props: {
  readOnly: boolean;
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  onSave: (configData: Map<string, any>) => Promise<any>;
}) => {
  const savedProvider = prepareProviderFormData(
    props.configData.getIn([...PROVIDERS_PATH, 0], Map()),
  );
  const savedMode = resolveSavedMode(props.configData);

  const [isSaving, setSaving] = React.useState(false);
  const [mode, setMode] = React.useState(savedMode);
  const [formData, setFormData] = React.useState(savedProvider);
  const [provider, setProvider] = React.useState(resolveProvider(formData).name);

  const isDisabled = () => {
    if (mode !== MODES.OIDC) {
      return false;
    }

    if (!formData.get('client_id') || !formData.get('#client_secret')) {
      return true;
    }

    return OIDC_PROVIDERS[provider]?.inputs
      ?.filter((input) => !input.optional)
      .some((input) => !formData.get(input.property));
  };

  const renderRadioInput = (label: string, value: string) => {
    return (
      <Radio
        value={value}
        checked={value === mode}
        onChange={() => setMode(value)}
        className="radio-btn tw-flex-1"
        disabled={props.readOnly}
      >
        {label}
      </Radio>
    );
  };

  const renderInput = (
    label: string,
    property: string,
    options?: { optional?: boolean; help?: React.ReactNode; placeholder?: string },
  ) => {
    const inputProps = {
      disabled: props.readOnly,
      value: formData.get(property, ''),
      placeholder: options?.placeholder,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(formData.set(property, event.target.value));
      },
    };

    return (
      <FormGroup>
        <ControlLabel>
          {label} {options?.optional && <OptionalFormLabel />}
        </ControlLabel>
        {property.startsWith('#') ? (
          <PasswordControl {...inputProps} />
        ) : (
          <FormControl type="text" {...inputProps} />
        )}
        {options?.help && <HelpBlock>{options.help}</HelpBlock>}
      </FormGroup>
    );
  };

  const renderForm = () => {
    if (!mode) {
      return null;
    }

    if (mode === MODES.BASIC) {
      return <Alert className="tw-mt-4">Authentication with simple password.</Alert>;
    }

    if (mode === MODES.NONE) {
      return (
        <Alert className="tw-mt-4" variant="warning">
          App will be publicly available without authentication!
        </Alert>
      );
    }

    return (
      <>
        <Alert className="tw-mt-4">
          Full OIDC setup guide available <Link href={DOCUMENTATION_LINK}>here</Link>.
        </Alert>
        <FormGroup className="tw-mt-4">
          <ControlLabel>Provider</ControlLabel>
          <Select
            clearable={false}
            searchable={false}
            value={provider}
            onChange={(type: string) => {
              const newformData = formData
                .set('issuer_url', '')
                .set('logout_url', '')
                .withMutations((data: Map<string, any>) => {
                  const provider = OIDC_PROVIDERS[type];

                  if (provider?.defaults) {
                    Object.entries(provider.defaults).forEach(([key, value]) => {
                      data.set(key, value);
                    });
                  }
                });

              setProvider(type);
              setFormData(newformData);
            }}
            options={Object.values(OIDC_PROVIDERS)
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((item) => ({ label: item.label, value: item.name }))}
            disabled={props.readOnly}
          />
        </FormGroup>
        {renderInput('Client ID', 'client_id')}
        {renderInput('Client secret', '#client_secret')}
        {OIDC_PROVIDERS[provider]?.inputs?.map(({ label, property, ...restOptions }) =>
          renderInput(label, property, restOptions),
        )}
      </>
    );
  };

  return (
    <CollapsibleBox
      title="Authentication"
      defaultOpen={savedProvider.isEmpty()}
      collapsePrefix={
        <span
          className={classNames(
            'tw-mr-2 tw-text-xs tw-font-medium',
            savedMode !== MODES.NONE ? 'tw-text-primary-600' : 'tw-text-warning-600',
          )}
        >
          {savedMode !== MODES.NONE ? 'Configured' : 'None'}
        </span>
      }
      additionalActions={() => {
        if (props.readOnly) {
          return null;
        }

        return (
          <SaveButtons
            isChanged={!savedProvider.equals(formData) || savedMode !== mode}
            onReset={() => {
              setMode(savedMode);
              setFormData(savedProvider);
            }}
            onSave={() => {
              setSaving(true);
              let configData = props.configData.set(
                'authorization',
                fromJS(defaultProxyAuthorization),
              );

              switch (mode) {
                case MODES.BASIC: {
                  configData = configData
                    .setIn(PROVIDERS_PATH, fromJS([{ id: 'simpleAuth', type: 'password' }]))
                    .updateIn([...AUTH_RULES_PATH, 0], (rule) => {
                      return rule.set('auth_required', true).set('auth', List(['simpleAuth']));
                    });
                  break;
                }

                case MODES.OIDC: {
                  const providerId = 'oidc-1';
                  const mappings = OIDC_PROVIDERS[provider]?.mapping;

                  const providerData = Map({
                    id: providerId,
                    type: 'oidc',
                    client_id: formData.get('client_id'),
                    ['#client_secret']: formData.get('#client_secret'),
                    issuer_url: prepareValueFromMapping(formData, 'issuer_url', mappings),
                    logout_url: prepareValueFromMapping(formData, 'logout_url', mappings),
                  });

                  configData = configData
                    .setIn(PROVIDERS_PATH, fromJS([providerData]))
                    .updateIn([...AUTH_RULES_PATH, 0], (rule) => {
                      return rule.set('auth_required', true).set('auth', List([providerId]));
                    });
                  break;
                }
              }

              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                configData,
                'Update authentication',
              )
                .then((response) => {
                  const newFormData = fromJSOrdered(response).getIn([...PROVIDERS_PATH, 0], Map());

                  setFormData(prepareProviderFormData(newFormData));
                })
                .finally(() => setSaving(false));
            }}
            isSaving={isSaving}
            disabled={isDisabled()}
          />
        );
      }}
    >
      <div className="tw-flex tw-gap-2">
        {renderRadioInput('Basic', MODES.BASIC)}
        {renderRadioInput('OIDC', MODES.OIDC)}
        {renderRadioInput('None', MODES.NONE)}
      </div>
      {renderForm()}
    </CollapsibleBox>
  );
};

export default AuthenticationSettings;
