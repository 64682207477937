import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import callDockerAction from '@/modules/components/DockerActionsApi';
import Checkbox from '@/react/common/Checkbox';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import SshForm from '@/react/common/SshForm';
import TestCredentialsButtonGroup from '@/react/common/TestCredentialsButtonGroup';

const SourceServer = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    context: PropTypes.instanceOf(Map),
    imageParameters: PropTypes.instanceOf(Map).isRequired,
    value: PropTypes.shape({
      connectionType: PropTypes.oneOf(['FTP', 'FTPS', 'SFTP', '']),
      host: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      privateKey: PropTypes.string.isRequired,
      port: PropTypes.number.isRequired,
      ignorePassiveAddress: PropTypes.bool.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    isChanged: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const approvedHostnames = this.props.imageParameters.get('approvedHostnames', List());
    const isDisabled = this.props.disabled || this.props.readOnly;

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Connection Type</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              clearable={false}
              placeholder="Select type"
              value={this.props.value.connectionType}
              onChange={(value) => {
                let connectionType = { connectionType: value };

                if (approvedHostnames.isEmpty()) {
                  connectionType.port = value === 'SFTP' ? 22 : 21;
                }

                this.props.onChange(connectionType);
              }}
              options={[
                { value: 'FTP', label: 'FTP' },
                { value: 'FTPS', label: 'FTPS' },
                { value: 'SFTP', label: 'SFTP' },
              ]}
              disabled={isDisabled}
            />
          </div>
        </FormGroup>
        {approvedHostnames.isEmpty() ? (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Hostname/IP</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.host}
                disabled={isDisabled}
                onChange={(e) => this.props.onChange({ host: e.target.value })}
              />
            </div>
          </FormGroup>
        ) : (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Hostname/Port</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Select
                placeholder="Select host"
                value={this.props.value.host}
                onChange={(value) => {
                  const option = approvedHostnames.find((option) => option.get('host') === value);
                  this.props.onChange({ host: option.get('host'), port: option.get('port') });
                }}
                options={approvedHostnames
                  .map((option) => ({
                    value: option.get('host'),
                    label: `${option.get('host')}:${option.get('port')}`,
                  }))
                  .toArray()}
                disabled={isDisabled}
              />
            </div>
          </FormGroup>
        )}
        {approvedHostnames.isEmpty() && (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Port</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="number"
                value={this.props.value.port}
                disabled={isDisabled}
                onChange={(e) => this.props.onChange({ port: e.target.value })}
              />
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Username</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.value.username}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ username: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Password</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              value={this.props.value.password}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ password: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup className={this.props.value.connectionType !== 'SFTP' ? 'hidden' : ''}>
          <div className="col-xs-4">
            <ControlLabel>Private Key</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              componentClass="textarea"
              rows="5"
              value={this.props.value.privateKey}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ privateKey: e.target.value })}
            />
            <HelpBlock>
              Only to use with an SFTP connection type. You can paste a private key with or without{' '}
              <code>---BEGIN/END PRIVATE KEY---</code> block.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              checked={this.props.value.ignorePassiveAddress}
              onChange={(checked) => this.props.onChange({ ignorePassiveAddress: checked })}
              disabled={isDisabled}
            >
              Ignore passive address
            </Checkbox>
            <HelpBlock>Ignore the IP address returned from the server on the PASV reply.</HelpBlock>
          </div>
        </FormGroup>
        <SshForm
          isEnabled
          hasPassivePortRange
          showHelp={false}
          readOnly={isDisabled}
          data={fromJS(this.props.value.ssh)}
          onChange={(data) => this.props.onChange({ ssh: data.toJS() })}
        />
        {!this.props.readOnly && (
          <TestCredentialsButtonGroup
            componentId={this.props.context.get('componentId')}
            configId={this.props.context.get('configurationId')}
            isEditing={this.props.isChanged}
            disabled={this.props.disabled}
            testCredentialsFn={(forceAlterNat) => {
              const { password, privateKey, ...restParameters } = this.props.value;

              return callDockerAction(this.props.context.get('componentId'), 'testConnection', {
                configData: {
                  parameters: {
                    path: '/',
                    ...restParameters,
                    ['#password']: password,
                    ['#privateKey']: privateKey,
                  },
                },
                forceAlterNat,
              });
            }}
          />
        )}
      </Form>
    );
  },
});

export default SourceServer;
