import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import Checkbox from '@/react/common/Checkbox';
import CsvDelimiterInput from '@/react/common/CsvDelimiterInput';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';

const ConfigurationForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    requestedEmail: PropTypes.string.isRequired,
    incremental: PropTypes.bool.isRequired,
    delimiter: PropTypes.string.isRequired,
    enclosure: PropTypes.string.isRequired,
    primaryKey: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    localState: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      panelExpanded: true,
    };
  },

  onChangeDelimiter(value) {
    this.props.onChange('delimiter', value);
  },

  onChangeEnclosure(e) {
    this.props.onChange('enclosure', e.target.value);
  },

  onChangeIncremental() {
    this.props.onChange('incremental', !this.props.incremental);
  },

  onChangePrimaryKey(selected) {
    this.props.onChange('primaryKey', selected);
  },

  renderButtons() {
    if (this.props.readOnly || !this.props.requestedEmail) {
      return null;
    }

    return (
      <SaveButtons
        isSaving={this.props.localState.get('isSaving', false)}
        isChanged={this.props.localState.get('isChanged', false)}
        onSave={this.props.actions.editSave}
        onReset={this.props.actions.editReset}
      />
    );
  },

  accordionArrow() {
    if (this.state.panelExpanded) {
      return <FontAwesomeIcon icon="angle-down" fixedWidth />;
    }

    return <FontAwesomeIcon icon="angle-right" fixedWidth />;
  },

  renderHeader() {
    return (
      <h4>
        {this.accordionArrow()}
        Import settings
      </h4>
    );
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Import Settings</h2>
          {this.renderButtons()}
        </div>
        <div className="box-content only-with-panel">{this.renderForm()}</div>
      </div>
    );
  },

  renderForm() {
    return (
      <div className="form-horizontal">
        <CsvDelimiterInput
          value={this.props.delimiter}
          onChange={this.onChangeDelimiter}
          disabled={this.props.readOnly}
        />
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Enclosure</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              placeholder="Field enclosure used in CSV files"
              value={this.props.enclosure}
              onChange={this.onChangeEnclosure}
              disabled={this.props.readOnly}
            />
            <HelpBlock>
              Field enclosure used in the CSV file. The default value is <code>&quot;</code>.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Primary Key</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              multi
              allowCreate
              placeholder="Add a column"
              value={this.props.primaryKey}
              onChange={this.onChangePrimaryKey}
              disabled={this.props.readOnly}
            />
            <HelpBlock>
              Primary key of the table. If a primary key is set, updates can be done on the table by
              selecting <strong>incremental load</strong>. The primary key can consist of multiple
              columns.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              checked={this.props.incremental}
              onChange={this.onChangeIncremental}
              disabled={this.props.readOnly}
            >
              Incremental load
            </Checkbox>
            <HelpBlock>
              If incremental load is turned on, the table will be updated instead of rewritten.
              Tables with a primary key will have rows updated, tables without a primary key will
              have rows appended.
            </HelpBlock>
          </div>
        </FormGroup>
      </div>
    );
  },
});

export default ConfigurationForm;
