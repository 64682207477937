import { KEBOOLA_DATA_APPS, KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '@/constants/componentIds';

const routeNames = {
  ROOT: 'flows',
  DETAIL: 'flow',
};

const tabs = {
  BUILDER: 'builder',
  ALL_RUNS: 'all-runs',
  SCHEDULES: 'schedules',
  NOTIFICATIONS: 'notifications',
  VERSIONS: 'versions',
};

const disabledInBranch = [KEBOOLA_NO_CODE_DBT_TRANSFORMATION, KEBOOLA_DATA_APPS];

const FLOW = 'flow';

export { routeNames, tabs, disabledInBranch, FLOW };
