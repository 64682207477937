import React from 'react';
import { Map } from 'immutable';

import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import BucketLabels from '@/react/common/BucketLabels';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import NativeTypesLabel from './NativeTypesLabel';

const TableLabels = () => {
  const { table, bucket } = useStores(
    () => {
      return {
        table: TablesStore.getTableByName(
          RoutesStore.getCurrentRouteParam('tableName'),
          RoutesStore.getCurrentRouteParam('bucketId'),
        ),
        bucket: BucketsStore.getBucket(
          RoutesStore.getRouterState().getIn(['params', 'bucketId']),
          Map(),
        ),
      };
    },
    [],
    [RoutesStore, TablesStore],
  );

  if (!table || table.isEmpty()) {
    return null;
  }

  return (
    <>
      <NativeTypesLabel isTyped={table.get('isTyped', false)} />
      <ExternalTableLabel
        tableType={table.get('tableType')}
        hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
      />
      <BucketLabels
        bucket={bucket}
        withStageLabel={false}
        withExternalLabel={false}
        withLinkedLabel={false}
      />
    </>
  );
};

export default TableLabels;
