import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { ReactFlowState } from '@xyflow/react';
import { ControlButton, useReactFlow, useStore } from '@xyflow/react';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';

const zoomOutSelector = (state: ReactFlowState) => state.transform[2] <= state.minZoom;

const ZoomOutButton = () => {
  const { zoomOut } = useReactFlow<CustomNode, CustomEdge>();
  const minZoomReached = useStore(zoomOutSelector);

  return (
    <Tooltip placement="left" tooltip="Zoom Out" type="action">
      <ControlButton onClick={() => zoomOut()} disabled={minZoomReached}>
        <FontAwesomeIcon icon="minus" />
      </ControlButton>
    </Tooltip>
  );
};

export default ZoomOutButton;
