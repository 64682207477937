import React from 'react';
import { IconButton, Tooltip } from '@keboola/design';

type Props = {
  isCollapsed: boolean;
  isDisabled?: boolean;
  onToggle?: () => void;
  entity?: string;
};

const CollapseButton = ({ isCollapsed, isDisabled, onToggle, entity }: Props) => {
  let tooltip = isCollapsed ? 'More Details' : 'Less Details';

  if (entity) {
    tooltip = `${isCollapsed ? 'Expand' : 'Collapse'} ${entity}`;
  }

  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      forceHide={isDisabled}
      triggerClassName="btn-collapse-trigger"
    >
      <IconButton
        onClick={() => !isDisabled && onToggle?.()}
        variant="invisible"
        icon={['far', isCollapsed ? 'angle-down' : 'angle-up']}
        size="small"
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

export default CollapseButton;
