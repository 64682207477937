import React from 'react';

import PromptInput from '@/react/common/AI/PromptInput';
import { createAutomation, redirectToAutomation } from './actions';
import PredefinedExamples from './Examples';

const NewAutomation = () => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [prompt, setPrompt] = React.useState('');

  const handleSubmit = (message: string) => {
    if (isProcessing) return;

    if (message !== prompt) {
      setPrompt(message);
    }

    setIsProcessing(true);
    return createAutomation(message).tap(redirectToAutomation);
  };

  return (
    <div className="box tw-mb-12 tw-p-12">
      <h2 className="tw-mb-6 tw-mt-0 tw-text-2xl">Generate Automation with Keboola AI Agent</h2>
      <PromptInput
        highlight
        autoFocus
        multiLine
        prompt={prompt}
        onChange={setPrompt}
        onSubmit={() => handleSubmit(prompt.trim())}
        isLoading={isProcessing}
        isDisabled={isProcessing || !prompt.trim()}
        isDisabledInput={isProcessing}
        loadingLabel="Processing..."
        placeholder="e.g.  I'd like to see how different restaurants from my chain perform"
      />
      <p className="tw-mt-2 tw-font-medium tw-text-neutral-400">
        What is your <span className="tw-text-neutral-800">intent</span>? Please provide as much
        detail as possible, including your <span className="tw-text-neutral-800">data source</span>,{' '}
        <span className="tw-text-neutral-800">destination</span>, and any data that needs to be{' '}
        <span className="tw-text-neutral-800">transformed</span>.<br />
        The more details you can provide, the better I can assist you!
      </p>
      <PredefinedExamples onClick={handleSubmit} />
    </div>
  );
};

export default NewAutomation;
