import React from 'react';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@keboola/design';
import { Map } from 'immutable';
import { stringify } from 'smol-toml';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { SANDBOX_SIZES } from '@/modules/sandboxes/Constants';
import CreateFolderButton from '@/react/common/ConfigurationsTable/CreateFolderButton';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { createApp } from './actions';
import {
  DEFAULT_EXPIRATION,
  defaultProxyAuthorization,
  routeNames,
  THEME_KEBOOLA,
} from './constants';
import DataAppsStore from './DataAppsStore';
import { prepareDataApps, prepareSlug } from './helpers';

const Header = () => {
  const { dataApps, componentsMetadata } = useStores(
    () => {
      const apps = DataAppsStore.getDataApps();

      return {
        dataApps: prepareDataApps(
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_DATA_APPS),
          apps,
        ),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      };
    },
    [],
    [DataAppsStore, InstalledComponentsStore],
  );
  const [show, setShow] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [formData, setFormData] = React.useState(Map());
  const state = useStores(
    () => {
      return { readOnly: ApplicationStore.isReadOnly() };
    },
    [],
    [ApplicationStore],
  );

  const onHide = () => setShow(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const name = formData.get('name', '') as string;
    const description = formData.get('description', '') as string;

    const configuration = {
      parameters: {
        size: SANDBOX_SIZES.TINY,
        autoSuspendAfterSeconds: DEFAULT_EXPIRATION,
        dataApp: {
          slug: prepareSlug(name),
          streamlit: { 'config.toml': stringify({ theme: THEME_KEBOOLA }) },
        },
      },
      authorization: defaultProxyAuthorization,
    };

    setIsCreating(true);
    return createApp(name, description, configuration)
      .then((configId) => {
        RoutesStore.getRouter().transitionTo(routeNames.DATA_APP_DETAIL, { config: configId });
        return null;
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <>
      <ButtonGroup>
        <CreateFolderButton
          entity="data app"
          configurations={dataApps}
          componentsMetadata={componentsMetadata}
          componentId={KEBOOLA_DATA_APPS}
        />
        <Button onClick={() => setShow(true)} disabled={state.readOnly}>
          <FontAwesomeIcon icon="plus" />
          Create Data App
        </Button>
      </ButtonGroup>
      <Modal
        show={show}
        onHide={onHide}
        onEnter={() => {
          setFormData(formData.set('name', 'My Data App').set('description', ''));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Data App</Modal.Title>
          <ModalIcon icon="plus" color="green" bold />
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                placeholder="Name your data app"
                disabled={isCreating}
                value={formData.get('name', '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData(formData.set('name', e.target.value));
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                Description <OptionalFormLabel />
              </ControlLabel>
              <FormControl
                componentClass="textarea"
                placeholder="Describe your data app"
                disabled={isCreating}
                value={formData.get('description', '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData(formData.set('description', e.target.value))
                }
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              showCancel={false}
              saveButtonType="submit"
              saveLabel="Create Data App"
              isSaving={isCreating}
              isDisabled={!formData.get('name', '')}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Header;
