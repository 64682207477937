import _ from 'underscore';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import { roles } from './constants';

const constructMessage = (
  { text = null, type = 'plainText', role = roles.USER, data = {} }: Partial<UserMessage>,
  context?: {
    flowConfiguration?: Record<string, any>;
  },
) => {
  const messageObject = { text, type, role, data };

  if (context && !_.isEmpty(context.flowConfiguration)) {
    return {
      ...messageObject,
      type: 'flowConfigurationInProgress' as const,
      data: {
        configuration: context.flowConfiguration,
        ...messageObject.data,
      },
    };
  }

  return messageObject;
};

const isInteractiveMessage = (messageObject?: AutomationMessage) => {
  if (!messageObject) return false;

  return [
    'componentSelectionRequest',
    'sourceTableSelectionRequest',
    'intentConfirmationRequest',
    'flowGenerated',
  ].includes(messageObject.type);
};

export { constructMessage, isInteractiveMessage };
