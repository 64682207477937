import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';

type Props = {
  show: boolean;
  isDisabled: boolean;
  onToggle: () => void;
  columnsLength: number;
};

const ToggleNodeButton = ({ show, isDisabled, onToggle, columnsLength }: Props) => {
  const button = (
    <Button
      bsStyle="link"
      className={classNames(
        'tw-group/columnsToggle tw-flex tw-w-full tw-justify-between tw-rounded-none !tw-bg-white tw-px-2 tw-py-3 tw-text-xs tw-font-normal tw-normal-case tw-leading-4 tw-tracking-normal hover:tw-no-underline focus:tw-no-underline',
        { 'tw-rounded-b-md': !show },
        isDisabled
          ? 'disabled !tw-text-neutral-800 [&_svg]:!tw-text-neutral-400'
          : 'hover:!tw-bg-neutral-100',
      )}
      onClick={onToggle}
      disabled={isDisabled}
    >
      {columnsLength} columns
      <FontAwesomeIcon
        icon={show ? 'chevron-up' : 'chevron-down'}
        fixedWidth
        className="tw-text-neutral-400 group-hover/columnsToggle:tw-text-secondary-600"
      />
    </Button>
  );

  return isDisabled ? (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="You cannot manipulate with node as you have column selected"
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default ToggleNodeButton;
