import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock } from '@keboola/design';

import Select from '@/react/common/Select';

const columnsFromOptions = [
  {
    label: 'Set the header manually',
    value: 'manual',
  },
  {
    label: 'Read the header from the file(s) header',
    value: 'header',
  },
  {
    label: 'Create the header automatically',
    value: 'auto',
  },
];

class HeaderPrimaryKey extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Header &amp; Primary Key</h3>
        </div>
        <div className="box-content">
          <div className="form-group">
            <label className="col-xs-4 control-label">Read Header</label>
            <div className="col-xs-8">
              <Select
                name="columnsFrom"
                value={this.props.columnsFrom}
                searchable={false}
                clearable={false}
                options={columnsFromOptions}
                disabled={this.props.disabled}
                onChange={(value) => {
                  let diff = {
                    columnsFrom: value,
                  };
                  if (value !== 'manual') {
                    diff.columns = [];
                  }
                  this.props.onChange(diff);
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">Set Header</label>
            <div className="col-xs-8">
              <Select
                name="columns"
                value={this.props.columns}
                multi
                allowCreate
                placeholder="Add a column"
                onChange={(value) => {
                  this.props.onChange({ columns: value });
                }}
                disabled={this.props.columnsFrom !== 'manual' || this.props.disabled}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">Primary Key</label>
            <div className="col-xs-8">
              <Select
                name="primaryKey"
                value={this.props.primaryKey}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primaryKey: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                If a primary key is set, updates can be done on the table by selecting{' '}
                <strong>incremental loads</strong>. The primary key can consist of multiple columns.
                The primary key of an existing table cannot be changed.
              </HelpBlock>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderPrimaryKey.propTypes = {
  columnsFrom: PropTypes.oneOf(['manual', 'header', 'auto']).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  primaryKey: PropTypes.array.isRequired,
};

export default HeaderPrimaryKey;
