import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { routeNames as componentRouteNames } from '@/modules/components-directory/constants';
import LinkMenuItem from '@/react/admin/project/LinkMenuItem';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import ActivateFlowSwitch from './ActivateFlowSwitch';

const FlowActions = (props: {
  flow: Map<string, any>;
  config: Map<string, any>;
  component: Map<string, any>;
  otherFlows: Map<string, any>;
  readOnly: boolean;
}) => {
  return (
    <RowActionDropdown toggleClassName="in-navigation">
      <ReadOnlyTooltip readOnly={props.readOnly} className="tw-block">
        <ActivateFlowSwitch isDisabled={props.readOnly} flow={props.flow} />
      </ReadOnlyTooltip>
      <RowActionMenuItem divider />
      {!props.readOnly && (
        <CopyButton hasFlows configuration={props.config} component={props.component} />
      )}
      <LinkMenuItem
        to={componentRouteNames.GENERIC_CONFIG_RAW}
        params={{ component: KEBOOLA_ORCHESTRATOR, config: props.config.get('id') }}
      >
        <FontAwesomeIcon icon="bug" className="icon-addon-right" fixedWidth />
        Debug mode
      </LinkMenuItem>
      {!props.readOnly && (
        <>
          <RowActionMenuItem divider />
          <DeleteConfigurationButton
            forceTransition
            mode="menuitem"
            componentId={KEBOOLA_ORCHESTRATOR}
            flows={props.otherFlows}
            config={props.flow}
          />
        </>
      )}
    </RowActionDropdown>
  );
};

export default FlowActions;
