export const isValidJsonConfig = (value: string) => {
  try {
    const json = JSON.parse(value);
    return Boolean(json && typeof json === 'object');
  } catch {
    return false;
  }
};

export const isValidUrl = (value: string) => {
  if (!value) {
    return false;
  }

  try {
    return Boolean(new URL(value));
  } catch {
    return false;
  }
};
