import { strRightBack } from 'underscore.string';

import type { Runtime } from '@/api/routes/dataScienceService';

const getRuntimeShortLabel = (runtime?: Runtime, imageKey?: string) => {
  if (!runtime) {
    return imageKey;
  }

  if (!runtime.imageTag && runtime.description) {
    const version = strRightBack(runtime.description, ' ');
    return /^[.\d]+$/.test(version) ? version : imageKey;
  }

  return runtime.imageTag;
};

const prepareRuntimeLabel = (runtime?: Runtime) => {
  if (!runtime) {
    return '';
  }

  const description = `${runtime.description}${runtime.isTypeDefault ? ' (default)' : ''}`;

  if (!runtime.imageTag) {
    return description;
  }

  return `${runtime.imageTag} - ${description}`;
};

const prepareRuntimeValue = (runtime: Runtime, imageKey: keyof Runtime) => {
  return runtime.isTypeDefault ? '' : runtime[imageKey];
};

const prepareRuntimes = (runtimes: Runtime[], imageKey: keyof Runtime) => {
  return runtimes
    .sort((a, b) => prepareRuntimeLabel(b).localeCompare(prepareRuntimeLabel(a)))
    .map((runtime: Runtime) => {
      return {
        label: prepareRuntimeLabel(runtime),
        value: prepareRuntimeValue(runtime, imageKey),
      };
    });
};

const prepareRuntimesForTransformation = (runtimes: Runtime[]) => {
  return prepareRuntimes(runtimes, 'transformationComponentTag');
};

const prepareRuntimesForSandboxes = (runtimes: Runtime[]) => {
  return prepareRuntimes(runtimes, 'sandboxImageTag');
};

export {
  getRuntimeShortLabel,
  prepareRuntimeLabel,
  prepareRuntimesForTransformation,
  prepareRuntimesForSandboxes,
};
