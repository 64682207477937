import React from 'react';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import type { Map } from 'immutable';

import { getDefaultBucketName } from '@/modules/components/helpers';
import { nameWarning } from '@/modules/storage/constants';
import { validateTableName } from '@/modules/storage/helpers';
import actions from '@/modules/stream/actions';
import { INITIAL_SINK } from '@/modules/stream/constants';
import type { StoreSource } from '@/modules/stream/store';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import string from '@/utils/string';

const createBucketId = (sourceName: string) =>
  `in.${getDefaultBucketName(`data-stream-${sourceName}`)}`;

const createTableName = (tableName: string, sourceName: string) =>
  tableName || string.sanitizeKbcTableIdString(sourceName);

const NewStreamModal = (props: {
  show: boolean;
  onHide: () => void;
  tables: Map<string, any>;
  onCreated: (sourceName: string, sourceId: string, tableId: string) => void;
  sources?: StoreSource[];
}) => {
  const [sourceName, setSourceName] = React.useState('');
  const [tableName, setTableName] = React.useState('');
  const [pending, setPending] = React.useState(false);

  const tablesInCurrentBucket = props.tables.filter(
    (table) => table.getIn(['bucket', 'id']) === createBucketId(sourceName),
  ) as Map<string, any>;

  const isSourceNameDuplicate = props.sources?.some(
    (source) => string.webalize(source.name) === string.webalize(sourceName),
  );

  const tableNameWarning =
    sourceName && !isSourceNameDuplicate
      ? validateTableName(createTableName(tableName, sourceName), tablesInCurrentBucket)
      : null;

  // prevent showing error when source is already created, this component rerenders, but modal is not closed, because sink is creating
  const showSourceNameDuplicateError = isSourceNameDuplicate && !pending;

  return (
    <Modal
      backdrop="static"
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        setSourceName('');
        setTableName('');
      }}
    >
      <Form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          setPending(true);

          const tableNameSanitized = createTableName(tableName, sourceName);
          const tableId = `${createBucketId(sourceName)}.${tableNameSanitized}`;

          return actions
            .createSource(sourceName, {
              name: tableNameSanitized,
              type: 'table',
              table: { ...INITIAL_SINK.table, tableId },
            })
            .then((sourceId) => {
              if (!sourceId) {
                return;
              }

              return props.onCreated(sourceName, sourceId, tableId);
            })
            .finally(() => setPending(false));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Data Stream</Modal.Title>
          <ModalIcon icon={['fas', 'webhook']} color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup validationState={showSourceNameDuplicateError ? 'error' : null}>
            <ControlLabel>Data Stream Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={sourceName}
              placeholder="Stream name"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setSourceName(target.value)
              }
            />
            {showSourceNameDuplicateError && (
              <HelpBlock variant="danger">
                Source &quot;{sourceName}&quot; already exists in the project.
              </HelpBlock>
            )}
          </FormGroup>
          <FormGroup validationState={tableNameWarning ? 'error' : null}>
            <ControlLabel>Destination Table Name </ControlLabel>
            <InfoTooltip tooltip="Table where data stream will be persisted" />
            <FormControl
              type="text"
              value={createTableName(tableName, sourceName)}
              placeholder="Destination Table name"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setTableName(string.sanitizeKbcTableIdString(target.value))
              }
            />
            <HelpBlock variant={tableNameWarning ? 'danger' : 'default'}>
              {tableNameWarning || nameWarning}
            </HelpBlock>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            isSaving={pending}
            isDisabled={
              !sourceName.trim() || isSourceNameDuplicate || !!tableNameWarning || pending
            }
            saveLabel={pending ? 'Creating Data Stream...' : 'Create Data Stream'}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewStreamModal;
