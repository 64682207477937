import React from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpBlock, Tooltip } from '@keboola/design';
import { List, Map } from 'immutable';

import { prepareMapping } from '@/modules/ex-generic/actions';
import { prepareRealJobPath } from '@/modules/ex-generic/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';

const DEFAULT_NESTING_LEVEL = 2;

const InferMapping = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  endpointPath: number[];
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  allTables: Map<string, any>;
}) => {
  const [formData, setFormData] = React.useState(Map());
  const [isOpen, setOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  if (props.readOnly) {
    return null;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setOpen(false);
    setIsProcessing(true);
    return prepareMapping(
      props.parameters,
      props.endpointPath,
      formData.get('primaryKey', List()) as List<string>,
      (formData.get('nestingLevel') || DEFAULT_NESTING_LEVEL) as number,
    )
      .then((result) => {
        const mapping = JSON.stringify(result, null, '  ');

        props.setEditing(props.editing.set('mapping', mapping));
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip="Mapping is inferred from sample data. It can be further refined."
        forceHide={isProcessing}
      >
        <Button
          bsStyle="link"
          className="btn-link-inline tw-mb-1"
          onClick={() => !isProcessing && setOpen(true)}
        >
          {isProcessing ? (
            <>
              <Loader className="btn-icon" />
              Processing...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="brain-circuit" className="btn-icon color-cyan" />
              Infer mapping
            </>
          )}
        </Button>
      </Tooltip>
      <Modal
        show={isOpen}
        onHide={() => setOpen(false)}
        onEnter={() => {
          const bucketId = ApplicationStore.hasDisableLegacyBucketPrefix()
            ? `in.${props.parameters.getIn(['config', 'outputBucket'], '')}`
            : `in.c-${props.parameters.getIn(['config', 'outputBucket'], '')}`;
          const tableName = props.parameters.getIn(
            ['config', 'jobs', ...prepareRealJobPath(props.endpointPath), 'dataType'],
            '',
          );
          const table = props.allTables.get(`${bucketId}.${tableName}`, Map());

          setFormData(Map({ primaryKey: table.get('primaryKey', List()) }));
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Infer Mapping</Modal.Title>
            <ModalIcon icon="brain-circuit" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Primay Key Columns</ControlLabel>
              <Select
                multi
                autoFocus
                allowCreate
                trimMultiCreatedValues
                placeholder="Add primary key"
                value={formData.get('primaryKey', List())}
                onChange={(value) => setFormData(formData.set('primaryKey', value))}
                promptTextCreator={(label) => `Add primary key "${label}"`}
              />
              <HelpBlock>
                List of JSON Path column names that will be used as primary keys. E.g.,{' '}
                <code>id, address.street</code>
              </HelpBlock>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Nesting Level</ControlLabel>
              <FormControl
                min={1}
                type="number"
                value={formData.get('nestingLevel', DEFAULT_NESTING_LEVEL)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const nestingLebel = parseInt(e.target.value, 10) || 1;

                  setFormData(formData.set('nestingLevel', nestingLebel));
                }}
              />
              <HelpBlock>
                The level at which nested JSON response properties are flattened. For example, a
                depth of 1 transforms{' '}
                <code>{`{"address": {"street": "Main", "details": {"postcode": "170 00"}}}`}</code>{' '}
                into two columns: <code>address_street</code> and <code>address_details</code>.
              </HelpBlock>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              showCancel={false}
              isSaving={isProcessing}
              saveButtonType="submit"
              saveLabel="Infer Mapping"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default InferMapping;
