import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import Loader from './Loader';
import RollbackVersionModal from './RollbackVersionModal';

type Props = {
  version: Map<string, any>;
  onRollback: () => void;
  componentId: string;
  isPending: boolean;
  isDisabled: boolean;
};

const RollbackVersionButton = ({
  version,
  onRollback,
  componentId,
  isPending,
  isDisabled,
}: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleRollback = () => {
    onRollback();
    setShowModal(false);
  };

  return (
    <>
      <Tooltip tooltip="Restore this version" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={() => setShowModal(true)}
          disabled={isDisabled || isPending}
        >
          {isPending ? <Loader /> : <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />}
        </Button>
      </Tooltip>
      <RollbackVersionModal
        version={version}
        show={showModal}
        onClose={() => setShowModal(false)}
        onRollback={handleRollback}
        componentId={componentId}
      />
    </>
  );
};

export default RollbackVersionButton;
