import React from 'react';
import { Alert } from '@keboola/design';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import DataAppsStore from './DataAppsStore';
import { getAppState } from './helpers';

const RedeployInfoAlert = () => {
  const { config, app } = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const config = InstalledComponentsStore.getConfig(KEBOOLA_DATA_APPS, configId);
      const app = DataAppsStore.getDataApp(config.getIn(['configuration', 'parameters', 'id']));

      return { config, app };
    },
    [],
    [RoutesStore, InstalledComponentsStore, DataAppsStore],
  );

  if (
    !app ||
    ['created', 'starting'].includes(getAppState(app)) ||
    !config.hasIn(['configuration', 'parameters', 'script']) ||
    String(config.get('version')) === String(app.configVersion)
  ) {
    return null;
  }

  return (
    <div className="container tw-mt-5">
      <Alert variant="warning">
        To ensure all your recent changes are applied, please redeploy your data app.
      </Alert>
    </div>
  );
};

export default RedeployInfoAlert;
