import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@keboola/design';
import { Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import DefaultForm from '@/modules/components/react/pages/new-component-form/DefaultForm';
import { createOrchestration } from '@/modules/orchestrations-v2/actions';

class NewOrchestrationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isSaving: false,
      configuration: Map(),
    };

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button className={NEW_ENTITY_BUTTON} onClick={() => this.setState({ showModal: true })}>
          <FontAwesomeIcon icon="plus" />
          New Orchestration
        </Button>
        <Modal show={this.state.showModal} onHide={this.onHide}>
          <DefaultForm
            component={this.props.component}
            configuration={this.state.configuration}
            isValid={!!this.state.configuration.get('name', '').trim()}
            isSaving={this.state.isSaving}
            onChange={(configuration) => this.setState({ configuration })}
            onCancel={this.onHide}
            onSave={this.handleSubmit}
          />
        </Modal>
      </>
    );
  }

  handleSubmit() {
    this.setState({ isSaving: true });
    return createOrchestration(
      this.state.configuration.get('name'),
      this.state.configuration.get('description'),
    ).catch((error) => {
      this.setState({ isSaving: false });
      throw error;
    });
  }

  onHide() {
    this.setState({ showModal: false, configuration: Map() });
  }
}

NewOrchestrationButton.propTypes = {
  component: PropTypes.instanceOf(Map).isRequired,
};

export default NewOrchestrationButton;
