import { Promise } from 'bluebird';
import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import { canActivateSchedule } from '@/modules/admin/privileges';
import { ActionTypes } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ApplicationStore from '@/stores/ApplicationStore';
import schedulerApi from './api';
import { SCHEDULE_STATE } from './constants';

export const applySchedulerChanges = (configurationId: string) => {
  if (!canActivateSchedule(ApplicationStore.getSapiToken())) {
    return null;
  }

  return schedulerApi.applySchedulerChanges(configurationId);
};

export const createAndActivateScheduler = (
  configurationId: string,
  { cronTab, timezone = 'UTC' }: { cronTab: string; timezone: string },
) => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_SCHEDULER, {
    name: `Scheduler for ${configurationId}`,
    configuration: JSON.stringify({
      schedule: { cronTab, timezone, state: SCHEDULE_STATE.ENABLED },
      target: { componentId: KEBOOLA_ORCHESTRATOR, configurationId, mode: 'run' },
    }),
  }).then((response) => applySchedulerChanges(response.id));
};

export const updateScheduler = (configurationId: string, configuration: Map<string, any>) => {
  // update configuration early to see changed schedule state immediately
  Dispatcher.handleViewAction({
    type: ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS,
    componentId: KEBOOLA_SCHEDULER,
    configurationId,
    data: configuration,
  });
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_SCHEDULER,
    configurationId,
    { configuration: JSON.stringify(configuration.get('configuration')) },
    'Update scheduler',
  )
    .catch((error) => {
      // reset original configuration if update fail
      InstalledComponentsActionCreators.loadComponentConfigDataForce(
        KEBOOLA_SCHEDULER,
        configurationId,
      );

      throw error;
    })
    .then((response) => applySchedulerChanges(response.id));
};

export const removeSchedulers = (schedulers: Map<string, any>) => {
  return Promise.map(
    schedulers.toArray(),
    (schedule: Map<string, any>) => removeScheduler(schedule.get('id')),
    { concurrency: 3 },
  ) as Promise<any>;
};

export const removeScheduler = (configurationId: string) => {
  return Promise.resolve()
    .then(() => {
      return canActivateSchedule(ApplicationStore.getSapiToken())
        ? schedulerApi.removeSchedule(configurationId)
        : Promise.resolve();
    })
    .then(() => {
      return InstalledComponentsActionCreators.deleteConfiguration(
        KEBOOLA_SCHEDULER,
        configurationId,
        { transition: false, notification: false },
      );
    });
};
