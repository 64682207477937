import React from 'react';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../../utils';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type ButtonLinkProps = {
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

export const ButtonLink = ({
  children,
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  className,
}: CommonButtonProps & ButtonLinkProps) => {
  const custom = cn(className, 'hover:tw-text-inherit tw-no-underline');
  const styles = buttonStyles({ variant, size, disabled, className: custom });
  return (
    <Slot data-disabled={disabled} onClick={onClick} className={styles}>
      {children}
    </Slot>
  );
};
