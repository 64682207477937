import React, { useState } from 'react';

import actionsProvisioning from '@/modules/ex-facebook/actionsProvisioning';
import storeProvisioning, { storeMixins } from '@/modules/ex-facebook/storeProvisioning';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  componentId: string;
  configId: string;
  queryId: string;
};

const QueryNameEdit = ({ componentId, configId, queryId }: Props) => {
  const state = useStores(
    () => {
      const store = storeProvisioning(componentId, configId);
      const actions = actionsProvisioning(componentId, configId);
      const query = store.findQuery(queryId);

      return {
        actions,
        query,
        queryName: query.get('name'),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [componentId, configId, queryId],
    [...storeMixins, ApplicationStore],
  );

  const [value, setValue] = useState(state.queryName);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    const newQuery = state.query.set('name', value);

    setIsSaving(true);
    return state.actions.saveQuery(newQuery).finally(() => {
      setIsSaving(false);
      setIsEditing(false);
    });
  };

  if (state.readOnly) {
    return <span>{value}</span>;
  }

  return (
    <InlineEditTextInput
      text={value}
      isChanged={state.queryName !== value}
      placeholder="Query name..."
      isSaving={isSaving}
      isEditing={isEditing}
      onEditStart={() => setIsEditing(true)}
      onEditCancel={() => {
        setIsEditing(false);
        setValue(state.queryName);
      }}
      onEditChange={setValue}
      onEditSubmit={handleSave}
    />
  );
};

export default QueryNameEdit;
