import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';

import InputValidation from '@/react/common/InputValidation';

const TargetDatasetSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      dataset: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className="form-horizontal">
        <InputValidation
          value={value.dataset}
          customValidation={(value) => {
            if (/^[a-zA-Z0-9_]*$/.test(value)) {
              return null;
            }

            return 'Dataset name can contain only letters, numbers, and underscores.';
          }}
        >
          {(inputState, message) => (
            <FormGroup validationState={inputState}>
              <div className="col-xs-4">
                <ControlLabel>Dataset</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ dataset: e.target.value })}
                  value={value.dataset}
                  placeholder="my_dataset"
                />
                <HelpBlock variant={inputState === 'error' ? 'danger' : 'default'}>
                  {message || 'Google BigQuery dataset name.'}
                </HelpBlock>
              </div>
            </FormGroup>
          )}
        </InputValidation>
      </div>
    );
  },
});

export default TargetDatasetSection;
