import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@keboola/design';
import { List } from 'immutable';
import numeral from 'numeral';

import { INVOICE_STATUS } from '@/modules/billing/constants';
import { convertCreditsToMinutes } from '@/modules/billing/helpers';
import CreatedData from '@/react/common/CreatedDate';
import LazyList from '@/react/common/LazyList';

class LatestPurchases extends React.Component {
  render() {
    if (!this.props.purchases.count()) {
      return null;
    }

    return (
      <div className="box latest-purchases">
        <div className="box-header above-table">
          <h2 className="box-title">Latest Purchases</h2>
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Date</th>
              <th className="w-175 text-right">Minutes</th>
              <th className="w-175 text-right">Amount (ext. VAT)</th>
              <th className="w-100 text-center">Invoice</th>
              <th className="w-100 text-right">Status</th>
            </tr>
          </thead>
          <LazyList
            limit={10}
            rootElement="tbody"
            className="letter-spacing-narrower"
            items={this.props.purchases}
            render={this.renderBody}
          />
        </Table>
      </div>
    );
  }

  renderBody = (purchases) => {
    return purchases
      .map((purchase) => (
        <tr key={purchase.get('id')}>
          <td>{this.renderDate(purchase)}</td>
          <td className="w-175 text-right font-medium text-muted">
            {this.renderMinutes(purchase)}
          </td>
          <td className="w-175 text-right font-medium text-muted">{this.renderAmount(purchase)}</td>
          <td className="w-100 text-center">{this.renderInvoice(purchase)}</td>
          <td className="w-100 text-right font-medium">{this.renderStatus(purchase)}</td>
        </tr>
      ))
      .toArray();
  };

  renderDate = (purchase) => {
    return (
      <>
        <CreatedData createdTime={purchase.get('created')} />
        {!purchase.get('moneyAmount') && (
          <>
            {' '}
            <strong className="text-success">{purchase.get('description')}</strong>
          </>
        )}
      </>
    );
  };

  renderMinutes = (purchase) => {
    return convertCreditsToMinutes(purchase.get('creditsAmount'));
  };

  renderAmount = (purchase) => {
    const amount = purchase.get('moneyAmount') ? purchase.get('moneyAmount') / 100 : 0;

    return numeral(amount).format('$0,0.00');
  };

  renderInvoice = (purchase) => {
    if (!purchase.get('urlStripeInvoice')) {
      return null;
    }

    return (
      <Link
        href={purchase.get('urlStripeInvoice')}
        className="text-muted font-medium no-underline no-wrap"
      >
        <FontAwesomeIcon icon={['far', 'arrow-down-to-line']} className="icon-addon-left" />
      </Link>
    );
  };

  renderStatus = (purchase) => {
    switch (purchase.get('stripeInvoiceStatus')) {
      case null:
      case INVOICE_STATUS.PAID:
        return <span className="text-success">Paid</span>;

      case INVOICE_STATUS.OPEN:
        return <span className="text-warning">Open</span>;

      case INVOICE_STATUS.UNCOLLECTIBLE:
        return <span className="text-danger">Fail</span>;
    }

    return <span className="text-muted">N/A</span>;
  };
}

LatestPurchases.propTypes = {
  purchases: PropTypes.instanceOf(List).isRequired,
};

export default LatestPurchases;
