import { Promise } from 'bluebird';
import { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import Api from '@/api';
import type { ExistingApp } from '@/api/routes/dataScienceService';
import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import JobsActionCreators from '@/modules/queue/actions';
import { SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import { ActionTypes } from './constants';
import DataAppsStore from './DataAppsStore';

const DATA_APPS_LIMIT = 500;

const loadDataApps = () => {
  const dataApps: ExistingApp[] = [];

  const loadApps = (offset = 0): Promise<void> => {
    return Api.dataScienceService.listApps({ limit: DATA_APPS_LIMIT, offset }).then((apps) => {
      dataApps.push(...apps);

      if (apps.length === DATA_APPS_LIMIT) {
        return loadApps(offset + DATA_APPS_LIMIT);
      }
    });
  };

  return loadApps().then(() => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APPS_LOAD_SUCCESS,
      dataApps,
    });

    return null;
  });
};

const loadDataApp = (id: string) => {
  if (!!DataAppsStore.getDataApp(id)) {
    loadDataAppForce(id);
    return Promise.resolve();
  }

  return loadDataAppForce(id);
};

const loadDataAppForce = (id: string) => {
  return Api.dataScienceService.getApp({ appId: id }).then((dataApp) => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_LOAD_SUCCESS,
      dataApp,
    });

    return null;
  });
};

const createApp = (name: string, description: string, config: Record<string, any>) => {
  return Api.dataScienceService
    .createApp({
      branchId: DevBranchesStore.isDevModeActive()
        ? DevBranchesStore.getCurrentId()!.toString()
        : null,
      type: SANDBOX_TYPE.STREAMLIT,
      name,
      description,
      config,
    })
    .tap((dataApp) => {
      dispatcher.handleViewAction({
        type: ActionTypes.DATA_APPS_CREATE_SUCCESS,
        dataApp,
      });

      return InstalledComponentsActionCreators.loadComponentConfigData(
        KEBOOLA_DATA_APPS,
        dataApp.configId,
      );
    })
    .then((dataApp) => dataApp.configId);
};

const updateApp = (
  app: ExistingApp | undefined,
  config: Map<string, any>,
  desiredState: 'running' | 'stopped',
) => {
  return Promise.all([
    desiredState === 'running' &&
      InstalledComponentsActionCreators.updateComponentConfiguration(
        KEBOOLA_DATA_APPS,
        config.get('id'),
        { configuration: JSON.stringify(config.get('configuration', Map()).toJS()) },
        'Change data app parameters',
      ),
  ])
    .then(() => {
      if (!app) {
        // fallback for old data apps or data apps created by templates
        return InstalledComponentsActionCreators.runComponent({
          component: KEBOOLA_DATA_APPS,
          data: {
            config: config.get('id'),
            configData: {
              parameters: {
                shared: true,
                task: 'create',
                type: SANDBOX_TYPE.STREAMLIT,
                ...config.getIn(['configuration', 'parameters'], Map()).toJS(),
              },
            },
          },
          notify: false,
        });
      }

      return Api.dataScienceService.patchApp(
        { appId: app.id },
        desiredState === 'running'
          ? {
              desiredState,
              restartIfRunning: true,
              configVersion: config.get('version').toString(),
            }
          : { desiredState: 'stopped' },
      );
    })
    .delay(3000) // wait before another update is allowed
    .then(() => (app ? loadDataAppForce(app.id) : loadDataApps()))
    .then(() => {
      JobsActionCreators.loadComponentConfigurationLatestJobs(KEBOOLA_DATA_APPS, config.get('id'));
    });
};

const deleteApp = (id: string) => {
  return Api.dataScienceService.deleteApp({ appId: id }).then(() => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_DELETE_SUCCESS,
      id,
    });

    ApplicationActionCreators.sendNotification({
      type: 'success',
      message: 'Data app has been successfully deleted.',
    });

    return null;
  });
};

const resetPassword = (id: string) => {
  return Api.dataScienceService.resetAppPassword({ appId: id }).then((dataApp) => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_LOAD_SUCCESS,
      dataApp,
    });

    ApplicationActionCreators.sendNotification({
      type: 'success',
      message:
        'Your password has been successfully reset. Please note that this change may take effect within the next minute.',
    });

    return null;
  });
};

export { loadDataApps, loadDataApp, createApp, updateApp, deleteApp, resetPassword };
