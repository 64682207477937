import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import { Map } from 'immutable';

import { nameWarning } from '@/modules/storage/constants';
import string from '@/utils/string';
import FilePicker from './FilePicker';
import FileSearch from './FileSearch';
import LoadWorksheets from './LoadWorksheets';

class Row extends React.Component {
  render() {
    const { value, onChange, disabled, context } = this.props;

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Workbook Configuration</h2>
            <ButtonToolbar>
              <FilePicker
                disabled={disabled}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
              <FileSearch
                disabled={disabled}
                configId={context.get('configurationId')}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
            </ButtonToolbar>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>File Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.workbookName || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ workbookName: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Drive ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.driveId || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ driveId: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>File ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  value={value.fileId || ''}
                  onChange={(e) => onChange({ fileId: e.target.value })}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Worksheet Configuration</h2>
            <LoadWorksheets
              disabled={disabled}
              configId={context.get('configurationId')}
              fileId={value.fileId}
              driveId={value.driveId}
              onSelect={({ name, id }) => {
                onChange({
                  worksheetName: name,
                  name: value.name || string.sanitizeKbcTableIdString(name),
                  id,
                });
              }}
            />
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Worksheet Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  value={value.worksheetName || ''}
                  disabled={disabled}
                  onChange={(e) => onChange({ worksheetName: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Worksheet ID</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  value={value.id || ''}
                  onChange={(e) => onChange({ id: e.target.value })}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Destination</h2>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Storage Table Name</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  value={value.name || ''}
                  onChange={(e) => {
                    onChange({ name: string.sanitizeKbcTableIdString(e.target.value) });
                  }}
                />
                <HelpBlock>
                  Table in Storage where the CSV file will be imported. If the table does not exist,
                  it will be created. {nameWarning}
                </HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
}

Row.propTypes = {
  context: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Row;
