import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

class InlineTooltipWarningIcon extends React.Component<{ message: string }> {
  render() {
    return (
      <Tooltip tooltip={this.props.message} placement="top" type="explanatory">
        <FontAwesomeIcon
          icon="triangle-exclamation"
          className="icon-addon-left inline text-warning"
        />
      </Tooltip>
    );
  }
}

export default InlineTooltipWarningIcon;
