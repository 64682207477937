import React, { useMemo } from 'react';
import type { Map } from 'immutable';

import { getDefaultBucketName } from '@/modules/components/helpers';
import { filterProductionAndCurrentDevBranchBuckets } from '@/modules/dev-branches/helpers';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP, STAGE } from '@/modules/storage/constants';
import { getBucketDisplayNameFromName } from '@/modules/storage/helpers';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import { bucketLabel } from './selectLabels';

type Parts = {
  bucket: string;
  stage: string;
  table: string;
};

type updatePart = (name: string, value: string) => void;

type Entities = Map<string, Map<string, any>>;

const StageSelect = ({
  parts,
  disabled,
  updatePart,
}: {
  parts: Parts;
  disabled: boolean;
  updatePart: updatePart;
}) => (
  <Select
    clearable={false}
    searchable={false}
    value={parts.stage}
    disabled={disabled}
    onChange={(stage: string) => updatePart('stage', stage)}
    options={Object.values(STAGE).map((stage) => ({
      value: stage,
      label: stage.toUpperCase(),
    }))}
  />
);

const BucketSelect = ({
  disabled,
  parts,
  updatePart,
  allBuckets,
  defaultBucketName,
}: {
  disabled: boolean;
  parts: Parts;
  updatePart: updatePart;
  allBuckets: Entities;
  defaultBucketName?: string;
}) => {
  const prepareBucketsOptions = useMemo(() => {
    const bucketName = parts.bucket;
    const buckets = filterProductionAndCurrentDevBranchBuckets(allBuckets)
      .filter((bucket: Map<string, any>) => {
        return bucket.get('stage') === parts.stage && !bucket.has('sourceBucket');
      })
      .map((bucket: Map<string, any>) => ({
        label: bucketLabel(bucket, { hideStage: true }),
        name: bucket.get('displayName'),
        value: bucket.get('name'),
        isDisabled: bucket.get('hasExternalSchema'),
        disabledReason: EXTERNAL_DATASET_DISABLE_TOOLTIP.OUTPUT_MAPPING,
      }))
      .toList();

    if (!!bucketName && !buckets.find((b: { value: string }) => b.value === bucketName)) {
      return buckets.push({
        label: `New bucket ${getBucketDisplayNameFromName(bucketName)}`,
        name: bucketName,
        value: bucketName,
      });
    }

    return buckets;
  }, [allBuckets, parts.bucket, parts.stage]);

  const selectBucket = (bucket: string) => {
    if (
      !!bucket &&
      !bucket.startsWith('c-') &&
      !ApplicationStore.hasDisableLegacyBucketPrefix() &&
      !prepareBucketsOptions.find((b: { value: string }) => b.value === bucket)
    ) {
      updatePart('bucket', 'c-' + bucket);
      return;
    }
    if (!bucket && defaultBucketName) {
      updatePart('bucket', defaultBucketName);
      return;
    }
    updatePart('bucket', bucket);
  };

  const isDefaultBucketNameUsed =
    parts.bucket === defaultBucketName || parts.bucket === `c-${defaultBucketName}`;

  const isBucketNameValid = (inputValue: string) => {
    if (inputValue.length === 0) {
      return false;
    }

    const buckets = allBuckets.filter((bucket) => {
      return bucket?.get('stage') === parts.stage;
    });

    return (
      !buckets.has(`${parts.stage}.${getDefaultBucketName(inputValue)}`) &&
      !buckets.some((bucket) => bucket?.get('displayName') === inputValue)
    );
  };

  const selectBucketOptionCreator = (option: string) => {
    return {
      label: `Create new bucket "${option}"`,
      value: option,
    };
  };

  return (
    <Select
      allowCreate
      promptTextCreator={(label) => label}
      disabled={disabled}
      placeholder="Select a bucket"
      value={parts.bucket}
      clearable={!isDefaultBucketNameUsed}
      onChange={selectBucket}
      options={prepareBucketsOptions.toJS()}
      newOptionCreator={selectBucketOptionCreator}
      inputValueSanitizer={string.sanitizeKbcTableIdString}
      isValidNewOption={isBucketNameValid}
    />
  );
};

const TableSelect = ({
  disabled,
  parts,
  updatePart,
  currentSource,
  tableName,
  allTables,
}: {
  disabled: boolean;
  parts: Parts;
  updatePart: updatePart;
  currentSource?: string;
  tableName?: string;
  allTables: Entities;
}) => {
  const prepareTablesOptions = useMemo(() => {
    const bucketId = parts.stage + '.' + parts.bucket;
    const webalizedSource = string.webalize(currentSource, { caseSensitive: true });
    const defaultTableName = webalizedSource || tableName;

    const tables = allTables
      .filter((table) => table?.getIn(['bucket', 'id']) === bucketId)
      .map((table) => ({
        label: `${table?.get('displayName')}${table?.get('isAlias', false) ? ' (alias)' : ''}`,
        value: table?.get('name'),
        name: table?.get('displayName'),
        isDisabled: table?.get('isAlias', false),
      }))
      .toList();

    if (!!defaultTableName && !tables.find((table) => table?.value === defaultTableName)) {
      return tables
        .insert(0, {
          label: `New table ${defaultTableName}`,
          value: defaultTableName,
          name: defaultTableName,
          isDisabled: false,
        })
        .toJS();
    }

    return tables.toJS();
  }, [allTables, currentSource, parts.bucket, parts.stage, tableName]);

  const isTableNameValid = (inputValue: string) => {
    if (inputValue.length === 0) {
      return false;
    }

    const bucketId = parts.stage + '.' + parts.bucket;
    const tables = allTables.filter((table) => {
      return table?.getIn(['bucket', 'id']) === bucketId;
    });

    return (
      !tables.has(inputValue) && !tables.some((table) => table?.get('displayName') === inputValue)
    );
  };

  const selectTableOptionCreator = (inputValue: string) => {
    const option = string.sanitizeKbcTableIdString(inputValue);

    return {
      label: `Create new table "${option}"`,
      value: option,
    };
  };

  return (
    <Select
      allowCreate
      promptTextCreator={(label) => label}
      disabled={disabled}
      placeholder="Select a table or create a new one"
      value={parts.table}
      onChange={(value: string) => updatePart('table', value)}
      options={prepareTablesOptions}
      newOptionCreator={selectTableOptionCreator}
      inputValueSanitizer={string.sanitizeKbcTableIdString}
      isValidNewOption={isTableNameValid}
    />
  );
};

type Props = {
  tables: Entities;
  buckets: Entities;
  parts: Parts;
  updatePart: updatePart;
  currentSource?: string;
  defaultBucketName?: string;
  defaultTableName?: string;
  disabled?: boolean;
};

const DestinationTableSelector = ({
  tables,
  buckets,
  parts,
  updatePart,
  currentSource = '',
  defaultBucketName,
  defaultTableName,
  disabled = false,
}: Props) => (
  <div className="destination-table-selector">
    <StageSelect parts={parts} disabled={disabled} updatePart={updatePart} />
    <BucketSelect
      disabled={disabled}
      parts={parts}
      updatePart={updatePart}
      allBuckets={buckets}
      defaultBucketName={defaultBucketName}
    />
    <TableSelect
      disabled={disabled}
      parts={parts}
      updatePart={updatePart}
      allTables={tables}
      tableName={defaultTableName}
      currentSource={currentSource}
    />
  </div>
);

export default DestinationTableSelector;
