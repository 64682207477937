import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';
import createReactClass from 'create-react-class';

import { KEBOOLA_EX_GMAIL } from '@/constants/componentIds';
import { isGoogleService } from '@/modules/oauth-v2/helpers';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';

const CustomAuthorizationFields = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    onChangeFn: PropTypes.func,
    disabled: PropTypes.bool,
  },

  render() {
    let fields = { appKey: 'Client ID', appSecret: 'Client secret' };

    return (
      <div>
        {isGoogleService(this.props.componentId) && (
          <p>
            Provide your own OAuth 2.0 credentials from{' '}
            <Link href="https://console.developers.google.com">Google API Console</Link>
            .
            <br />
            Follow these <Link href={this.getInstructionsUrl()}>instructions</Link> to set up an
            application and obtain a pair of credentials.
          </p>
        )}
        <div className="form-group">
          <label className="control-label col-sm-3">
            Name <OptionalFormLabel />
          </label>
          <div className="col-sm-9">
            <FormControl
              value={this.props.values.authorizedFor || ''}
              onChange={(e) => this.props.onChangeFn('authorizedFor', e.target.value)}
              autoFocus
              disabled={this.props.disabled}
            />
            <p className="help-block">Describe this authorization, e.g., by the account name.</p>
          </div>
        </div>
        {Object.keys(fields).map((key) => this.renderInput(key, fields[key]))}
      </div>
    );
  },

  renderInput(key, name) {
    return (
      <div className="form-group" key={key}>
        <label className="control-label col-sm-3">{name}</label>
        <div className="col-sm-9">
          <FormControl
            name={key}
            value={this.props.values[key] || ''}
            onChange={(e) => this.props.onChangeFn(key, e.target.value)}
            disabled={this.props.disabled}
          />
          <p className="help-block">{name} of your app</p>
        </div>
      </div>
    );
  },

  getInstructionsUrl() {
    if (this.props.componentId === KEBOOLA_EX_GMAIL) {
      return `${URLS.USER_DOCUMENTATION}/components/extractors/communication/gmail/#authorization`;
    }

    return `${URLS.USER_DOCUMENTATION}/components/extractors/marketing-sales/google-analytics/#custom-oauth-credentials`;
  },
});

export default CustomAuthorizationFields;
