import React from 'react';
import { Tooltip } from '@keboola/design';

const MultiSortTooltip = (props: { active: boolean; children: React.ReactNode }) => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip={
        <>
          Hold the <b>Shift</b> key down
          <br />
          for multi-column sorting.
        </>
      }
      forceHide={!props.active}
    >
      {props.children}
    </Tooltip>
  );
};

export default MultiSortTooltip;
