import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@keboola/design';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames as projectSettingsRouteNames } from '@/modules/settings/constants';
import TokensActions from '@/modules/tokens/actionCreators';
import TokenEditor from '@/modules/tokens/react/components/tokenEditor/TokenEditor';
import TokenString from '@/modules/tokens/react/components/TokenString';
import SendTokenModal from '@/modules/tokens/react/modals/SendTokenModal';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Link from '@/react/common/RouterLink';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const New = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TokensStore, BucketsStore, ComponentsStore)],

  getStateFromStores() {
    const localState = TokensStore.localState();

    return {
      localState,
      allBuckets: BucketsStore.getAll(),
      allComponents: ComponentsStore.getAll(),
      token: localState.get('newToken', Map()),
      isSendingToken: TokensStore.isSendingToken,
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
    };
  },

  getInitialState() {
    return {
      createdToken: Map(),
      sendModal: false,
      isSaving: false,
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          {this.state.createdToken.count() ? (
            this.renderTokenCreated()
          ) : (
            <div className="form form-horizontal">
              <div className="save-buttons">
                <ConfirmButtons
                  saveButtonType="submit"
                  isSaving={this.state.isSaving}
                  isDisabled={!this.isValid()}
                  onSave={this.handleSave}
                  saveLabel="Create"
                  showCancel={false}
                />
              </div>
              <TokenEditor
                isEditing={false}
                disabled={!!this.state.isSaving}
                token={this.state.token}
                sapiToken={this.state.sapiToken}
                allBuckets={this.state.allBuckets}
                allComponents={this.state.allComponents}
                updateToken={this.updateToken}
                hasNewQueue={this.state.hasNewQueue}
              />
            </div>
          )}
        </div>
      </div>
    );
  },

  renderTokenCreated() {
    const creatorLink = (
      <Link
        to={projectSettingsRouteNames.TOKEN_DETAIL}
        params={{ tokenId: this.state.createdToken.get('id') }}
      >
        {this.state.createdToken.get('description')}
      </Link>
    );

    return (
      <div className="text-center">
        {this.renderSendModal()}
        <Alert variant="success" className="tw-mb-5">
          Token {creatorLink} has been created.
        </Alert>
        <TokenString token={this.state.createdToken} sendTokenComponent={this.renderSendButton()} />
      </div>
    );
  },

  renderSendButton() {
    return (
      <Button onClick={this.openSendModal} className="mt-1">
        <FontAwesomeIcon icon="share" className="icon-addon-right" />
        Send token via email
      </Button>
    );
  },

  renderSendModal() {
    const isSending = this.state.isSendingToken(this.state.createdToken.get('id'));

    return (
      <SendTokenModal
        token={this.state.createdToken}
        show={!!this.state.sendModal}
        onSendFn={this.sendToken}
        onHideFn={this.closeSendModal}
        isSending={!!isSending}
      />
    );
  },

  sendToken(params) {
    return TokensActions.sendToken(this.state.createdToken.get('id'), params).then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              Token <b>{this.state.createdToken.get('description')}</b> sent to{' '}
              <b>{params.email}</b>.
            </>
          );
        },
      });

      this.closeSendModal();
    });
  },

  isValid() {
    return !!(this.state.token.get('description') && this.state.token.get('expiresIn') !== 0);
  },

  handleSave(e) {
    e.preventDefault();

    let token = this.state.token;

    if (!token.get('expiresIn')) {
      token = token.delete('expiresIn');
    }

    if (this.state.sapiToken.getIn(['admin', 'role']) === ADMIN_ROLES.PRODUCTION_MANAGER) {
      token = token.set('canCreateJobs', true);
    }

    this.saving(true);
    return TokensActions.createToken(token.toJS())
      .then((createdToken) => {
        this.setState({
          createdToken,
        });
        this.resetToken();
      })
      .finally(() => {
        this.saving(false);
      });
  },

  updateToken(newToken) {
    const updatedLocalState = this.state.localState.set('newToken', newToken);
    TokensActions.updateLocalState(updatedLocalState);
  },

  resetToken() {
    const updatedLocalState = this.state.localState.delete('newToken');
    TokensActions.updateLocalState(updatedLocalState);
  },

  openSendModal() {
    this.setState({
      sendModal: true,
    });
  },

  closeSendModal() {
    this.setState({
      sendModal: false,
    });
  },

  saving(value) {
    this.setState({
      isSaving: value,
    });
  },
});

export default New;
