import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import callDockerAction from '@/modules/components/DockerActionsApi';
import Loader from '@/react/common/Loader';

const GenericSyncActionsButton = (props: {
  componentId: string;
  configId: string;
  schema: Map<string, any>;
}) => {
  const [isProcessing, setProcessing] = React.useState(false);

  const buttonProps = props.schema.get('properties').first().getIn(['options', 'async'], Map());

  const onClick = () => {
    setProcessing(true);
    return callDockerAction(props.componentId, buttonProps.get('action'), {
      configData: { configId: props.configId },
    })
      .then((response) => {
        if (!!response.message) {
          ApplicationActionCreators.sendNotification({
            type: response?.status === 'error' ? 'error' : 'info',
            message: response.message,
          });
        }
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Tooltip
      type="auto"
      placement="top"
      tooltip={buttonProps.get('tooltip')}
      forceHide={!buttonProps.has('tooltip')}
    >
      <Button
        bsStyle="link"
        className="btn-link-inline btn-block"
        onClick={onClick}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <Loader />
        ) : (
          <FontAwesomeIcon icon={buttonProps.get('icon', 'message-lines')} />
        )}
        {buttonProps.get('label')}
      </Button>
    </Tooltip>
  );
};

export default GenericSyncActionsButton;
