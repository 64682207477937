import React from 'react';
import classnames from 'classnames';
import { Map } from 'immutable';

import { routeNames, tableTabs } from '@/modules/storage/constants';
import { routeNames as tableBrowserRouteNames } from '@/modules/table-browser/constants';
import { SEARCH_TYPES } from '@/react/common/constants';
import MarkedText from '@/react/common/MarkedText';
import Link from '@/react/common/RouterLink';
import Truncated from '@/react/common/Truncated';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  table: Map<string, any>;
  isBucketBrowser: boolean;
  columnName?: string;
  searchQuery: string;
};

const TableLink = ({ table, isBucketBrowser, columnName, searchQuery }: Props) => {
  const className = classnames('link-inherit', {
    'dotted-underline': table.get('isAlias', false),
  });
  const name = columnName || table.get('displayName');

  if (isBucketBrowser) {
    const location = RoutesStore.getRouterState().get('location', Map());

    return (
      <Link
        to={tableBrowserRouteNames.ROOT}
        params={{
          tableId: table.get('id'),
          tableTab: columnName ? tableTabs.DATA_SAMPLE : tableTabs.OVERVIEW,
        }}
        query={{
          context: location.getIn(['query', 'context']),
          ...(columnName && { q: columnName, qt: SEARCH_TYPES.KEY }),
        }}
        state={location.get('state')}
        className={className}
      >
        <Truncated tooltip={name} text={<MarkedText source={name} mark={searchQuery} />} />
      </Link>
    );
  }

  return (
    <Link
      to={routeNames.TABLE}
      params={{
        bucketId: table.getIn(['bucket', 'id']),
        tableName: table.get('name'),
        tableTab: columnName ? tableTabs.DATA_SAMPLE : tableTabs.OVERVIEW,
      }}
      className={className}
      {...(columnName && { query: { q: columnName, qt: SEARCH_TYPES.KEY } })}
    >
      <Truncated tooltip={name} text={<MarkedText source={name} mark={searchQuery} />} />
    </Link>
  );
};

export default TableLink;
