import React from 'react';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

import {
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
} from '@/constants/componentIds';

const queryEditorPlaceholder = {
  default: 'e.g., SELECT "id", "name" FROM "myTable"',
  [KEBOOLA_EX_DB_MYSQL]: 'e.g., SELECT `id`, `name` FROM `myTable`',
};

export function getQueryEditorPlaceholder(componentId) {
  return queryEditorPlaceholder[componentId]
    ? queryEditorPlaceholder[componentId]
    : queryEditorPlaceholder.default;
}

const queryEditorHelp = {
  [KEBOOLA_EX_DB_ORACLE]: 'Please do not put semicolons at the end of the query.',
  [KEBOOLA_EX_DB_MSSQL]: (
    <div>
      From Oct 1, 2018, DATETIME fields will be exported with milliseconds.
      <br />
      If you&apos;d like to export without milliseconds, please cast your column as{' '}
      <code>CONVERT(DATETIME2(0), my_column)</code>
      <br />
      See the{' '}
      <Link
        href={`${URLS.USER_DOCUMENTATION}/components/extractors/database/sqldb/#ms-sql-server-advanced-mode`}
      >
        documentation
      </Link>
      &nbsp;for further help.
    </div>
  ),
  [KEBOOLA_EX_DB_PGSQL]: (
    <span>
      Please use only block comments <code>&#47;* *&#47;</code>. The copy command will fail on{' '}
      <code>--</code> inline comments
    </span>
  ),
};

export function getQueryEditorHelpText(componentId) {
  return queryEditorHelp[componentId] ? queryEditorHelp[componentId] : null;
}
