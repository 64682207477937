import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@keboola/design';
import { Map } from 'immutable';

import ModelsApi from '@/modules/model-services/api';
import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import { isValidJsonConfig } from '@/utils/validation';

const INITIAL_STATE = {
  isTesting: false,
  showModal: false,
  result: null,
  error: null,
  testJson: '',
};

class TestModelButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.hideModal = this.hideModal.bind(this);
    this.submitTestButton = this.submitTestButton.bind(this);
  }

  render() {
    if (!this.props.model.hasIn(['deployment', 'url'])) {
      return null;
    }

    return (
      <>
        <RowActionMenuItem onSelect={() => this.setState({ showModal: true })}>
          <FontAwesomeIcon icon="circle-check" fixedWidth />
          Test model
        </RowActionMenuItem>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Test Model</Modal.Title>
            <ModalIcon icon="circle-check" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              Enter JSON-serialized DataFrame to test{' '}
              <strong>{this.props.model.get('name')}</strong> model. If empty, we will try to use
              the <code>input_example.json</code> file from model artifacts.
            </p>
            <FormGroup>
              <CodeEditor
                value={this.state.testJson}
                onChange={(value) => {
                  this.setState({ testJson: value, result: null });
                }}
                options={{
                  placeholder: '-- Your JSON-serialized DataFrame goes here...',
                }}
              />
            </FormGroup>
            {this.state.result && (
              <>
                <b>Test results</b>
                <pre>{JSON.stringify(this.state.result, null, 2)}</pre>
              </>
            )}
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveStyle="primary"
              saveLabel={this.state.isTesting ? 'Testing model...' : 'Test model'}
              isSaving={this.state.isTesting}
              onSave={this.submitTestButton}
              isDisabled={!this.isJsonValid()}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  isJsonValid() {
    return !this.state.testJson || isValidJsonConfig(this.state.testJson);
  }

  submitTestButton() {
    this.setState({ isTesting: true, result: null, error: null });
    ModelsApi.testModel(this.props.model.getIn(['deployment', 'id']), this.state.testJson)
      .then((result) => this.setState({ result }))
      .catch(({ message }) =>
        this.setState({ error: message || 'An error occurred while testing model.' }),
      )
      .finally(() => this.setState({ isTesting: false }));
  }

  hideModal() {
    this.setState(INITIAL_STATE);
  }
}

TestModelButton.propTypes = {
  model: PropTypes.instanceOf(Map).isRequired,
};

export default TestModelButton;
