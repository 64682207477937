import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { ioType } from '@/modules/components/Constants';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import actionCreators from '@/modules/transformations/ActionCreators';
import { backends } from '@/modules/transformations/Constants';
import InputMappingModal from '@/modules/transformations/react/modals/InputMapping';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import NullablePrimaryKeyWarning from '@/react/common/NullablePrimaryKeyWarning';
import SourceSearchInPanel from '@/react/common/SourceSearch/SourceSearchInPanel';

const InputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    inputMapping: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    transformation: PropTypes.object.isRequired,
    bucket: PropTypes.object.isRequired,
    editingId: PropTypes.string.isRequired,
    mappingIndex: PropTypes.string.isRequired,
    otherDestinations: PropTypes.object.isRequired,
    editingInputMapping: PropTypes.object,
    pendingActions: PropTypes.object,
    definition: PropTypes.object,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      definition: Map(),
      disabled: false,
    };
  },

  render() {
    return (
      <span className="tr row-sort hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    if (!this.props.definition.has('label')) {
      return (
        <>
          <span className="td row-sort-handle col-xs-5">
            <div className="flex-container flex-start">
              {this.renderDragHandle()}
              {this.renderSource()}
              {this.renderNullablePrimaryKeyWarning()}
            </div>
          </span>
          <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
          <span className="td col-xs-5">
            <InputOutputTypeIcon
              type={
                this.props.transformation.get('backend') === 'docker' ? ioType.FILE : ioType.TABLE
              }
            />
            {this.props.transformation.get('backend') === 'docker'
              ? `in/tables/${this.props.inputMapping.get('destination')}`
              : this.props.inputMapping.get('destination')}
          </span>
        </>
      );
    }

    return (
      <>
        <span className="td col-xs-5">{this.props.definition.get('label')}</span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderSource()}</span>
      </>
    );
  },

  renderNullablePrimaryKeyWarning() {
    if (
      this.props.transformation.get('backend') === backends.SNOWFLAKE &&
      this.props.inputMapping.get('source', '') &&
      this.props.inputMapping.get('loadType', 'copy') === 'copy' &&
      this.props.inputMapping.get('datatypes', Map()).some((datatype) => {
        if (!Map.isMap(datatype)) {
          return false;
        }
        return (
          datatype.get('convertEmptyValuesToNull', false) &&
          this.props.tables
            .get(this.props.inputMapping.get('source'), Map())
            .get('primaryKey', List())
            .includes(datatype.get('column'))
        );
      })
    ) {
      return <NullablePrimaryKeyWarning />;
    }
    return null;
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <InputMappingModal
          mode="edit"
          tables={this.props.tables}
          buckets={this.props.buckets}
          backend={this.props.transformation.get('backend')}
          type={this.props.transformation.get('type')}
          mapping={this.props.editingInputMapping}
          otherDestinations={this.props.otherDestinations}
          onChange={this._handleChange}
          onCancel={this._handleCancel}
          onSave={this._handleSave}
          definition={this.props.definition}
          disabled={this.props.disabled}
        />
        {this.props.inputMapping.get('source') !== '' && (
          <Tooltip tooltip="Delete Input" placement="top">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={this._handleDelete}
              disabled={
                this.props.disabled ||
                this.props.pendingActions.get(`delete-input-${this.props.mappingIndex}`)
              }
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
        )}
      </span>
    );
  },

  renderDragHandle() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <FontAwesomeIcon
        className={classnames({
          'dragable drag-handle': this.props.pendingActions.count() === 0,
          'not-allowed': this.props.pendingActions.count() > 0,
        })}
        icon="bars"
      />
    );
  },

  renderSource() {
    if (this.props.inputMapping.has('source_search')) {
      return <SourceSearchInPanel />;
    }

    if (!this.props.inputMapping.get('source')) {
      return 'Not set';
    }

    return <StorageApiTableLinkEx tableId={this.props.inputMapping.get('source')} />;
  },

  renderRightArrow() {
    const mapping = this.props.inputMapping;

    if (mapping.get('loadType') === 'clone') {
      return (
        <Tooltip placement="top" tooltip="CLONE">
          <FontAwesomeIcon icon={['far', 'chevrons-right']} className="text-muted" />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <>
            COPY
            {(mapping.get('whereColumn') || mapping.get('columns', List()).count() > 0) && (
              <> (Filtered)</>
            )}
            {mapping.get('changedSince') && <div>Incremental</div>}
          </>
        }
      >
        <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />
      </Tooltip>
    );
  },

  _handleChange(newMapping) {
    return actionCreators.updateTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      this.props.editingId,
      newMapping,
    );
  },

  _handleCancel() {
    return actionCreators.cancelTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      this.props.editingId,
    );
  },

  _handleSave() {
    return actionCreators.saveTransformationMapping(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'input',
      this.props.editingId,
      this.props.mappingIndex,
    );
  },

  _handleDelete() {
    return actionCreators.deleteTransformationMapping(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'input',
      this.props.mappingIndex,
    );
  },
});

export default InputMappingRow;
