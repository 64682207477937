import React from 'react';

import useStores from '@/react/hooks/useStores';
import Automations from './Automations';
import NewAutomation from './NewAutomation';
import AutomationsStore from './store';

const Index = () => {
  const store = useStores(
    () => {
      return {
        automations: AutomationsStore.getStore().automations,
      };
    },
    [],
    [AutomationsStore],
  );

  return (
    <>
      <NewAutomation />
      <Automations automations={store.automations} />
    </>
  );
};

export default Index;
