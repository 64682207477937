import { STACKS } from '@keboola/constants';

import { getCampaignTarget, isValidCampaignTarget } from '@/modules/home/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import { getJiraWidgetIframeDoc } from './jiraWidget';

export const getProjectCode = () => {
  if (ApplicationStore.isDemoPreview()) {
    return 'dJwWgtEVmEraUlro';
  }
  if (ApplicationStore.hasPayAsYouGo()) {
    return 'V5x3GnLccUmxjejR';
  }

  switch (ApplicationStore.getCurrentStackId()) {
    case STACKS.US_EAST_1_AWS.id:
      return '0t8RZuvgvuyzXRPN';
    case STACKS.EU_CENTRAL_1_AWS.id:
      return 'Tg0NCPVOAZZxlEWw';
    case STACKS.NORTH_EUROPE_AZURE.id:
      return 'Nr5zikdYkUBDWHIX';
    case STACKS.EUROPE_WEST2_GCP.id:
    case STACKS.EUROPE_WEST3_GCP.id:
    case STACKS.US_EAST_4_GCP.id:
      return '8mS7eWNhuDRJz3mI';
    case STACKS.CSAS_PROD.id:
    case STACKS.CSAS_PROD_CS.id:
    case STACKS.CSAS_TEST.id:
      return 'u5IhJPP2XvAJVL0N';
    case STACKS.INNOGY.id:
      return 'W8ISIGrLISE7wyam';
    default:
      return 'Tg0NCPVOAZZxlEWw';
  }
};

export const getCustomData = (location) => {
  const project = ApplicationStore.getCurrentProject();

  return {
    projectId: project.get('id'),
    backend: project.get('defaultBackend'),
    ...(isValidCampaignTarget(location.query.target) && {
      landing_page: getCampaignTarget(location.query.target),
      campaign: location.query.campaign,
    }),
  };
};

export const showButton = () => {
  if (window.productFruits?.button?.config?.isActive) {
    window.productFruits.api.button.showButton?.();
  }
};

export const hideButton = () => {
  if (!window.productFruits) return;

  if (
    window.productFruits.button?.buttonEl?.classList.contains('productfruits--lifering-clicked')
  ) {
    window.productFruits.button.buttonEl.click();
  }

  window.productFruits.api?.button?.hideButton();
};

export const injectButtonStyles = (styles = {}) => {
  if (!window.productFruits.button?.buttonEl) return;

  Object.entries(styles).forEach(([key, value]) => {
    window.productFruits.button.buttonEl.style[key] = value;
  });
};

export const notifyProductFruitsWhenRouteChanges = () => {
  // This is to let ProductFruits know that the page has changed (since `disableLocationChangeDetection` is on), but not show the button if Jira support widget is opened.
  window.productFruits?.pageChanged?.();

  if (getJiraWidgetIframeDoc()?.querySelector('.form-container')) {
    hideButton();
  }
};
