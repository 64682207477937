import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Well } from 'react-bootstrap';
import { Alert } from '@keboola/design';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';

const INITIAL_STATE = {
  forceDelete: false,
  error: null,
};

const DeleteColumnsModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    tableAliases: PropTypes.array.isRequired,
    tableLinks: PropTypes.array.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Columns</Modal.Title>
            <ModalIcon.Trash />
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <p>Are you sure you want to delete the selected columns?</p>
            <DevBranchStorageWarning
              message="The columns will also be deleted from the table in production."
              hasProductionEntity={!isCreatedInDevBranch(this.props.table.get('bucket'))}
            />
            <Well>
              <p className="text-muted small">These columns will be deleted:</p>
              <ul className="list-unstyled mb-0">
                {this.props.columns.map((columnName) => (
                  <li key={columnName}>{columnName}</li>
                ))}
              </ul>
            </Well>
            {this.hasAliasesOrLinks() && (
              <div>
                <Checkbox checked={this.state.forceDelete} onChange={this.toggleForceDelete}>
                  Delete columns from table and alias tables
                </Checkbox>
                <TableAliasesAndLinks
                  sapiToken={this.props.sapiToken}
                  urlTemplates={this.props.urlTemplates}
                  tableAliases={this.props.tableAliases}
                  tableLinks={this.props.tableLinks}
                  onLinkClick={this.props.onHide}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={this.isDisabled()}
              isSaving={this.props.isDeleting}
              saveLabel={this.props.isDeleting ? 'Deleting...' : 'Delete'}
              saveStyle="danger"
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  toggleForceDelete() {
    this.setState({ forceDelete: !this.state.forceDelete });
  },

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  },

  handleSubmit(event) {
    event.preventDefault();
    this.props
      .onConfirm(this.state.forceDelete)
      .then(this.onHide)
      .catch((error) => {
        this.setState({ error });
      });
  },

  isDisabled() {
    if (this.hasAliasesOrLinks() && !this.state.forceDelete) {
      return true;
    }

    return false;
  },

  hasAliasesOrLinks() {
    return this.props.tableAliases.length > 0 || this.props.tableLinks.length > 0;
  },
});

export default DeleteColumnsModal;
