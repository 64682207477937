import React from 'react';
import { Button, ControlLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, HelpBlock, Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import { performFunction } from '@/modules/ex-generic/actions';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import { isValidJsonConfig } from '@/utils/validation';
import DocumentationLink from './DocumentationLink';

const TEMPLATES_OPTIONS = [
  {
    label: 'Date & Time',
    options: [
      {
        label: 'X - Days Ago in Y-m-d format',
        value: {
          function: 'date',
          args: ['Y-m-d', { function: 'strtotime', args: ['-2 day', { time: 'currentStart' }] }],
        },
      },
      {
        label: 'X - Days Ago in Y-m-d H:i:s format',
        value: {
          function: 'date',
          args: [
            'Y-m-d H:i:s',
            { function: 'strtotime', args: ['-2 day', { time: 'currentStart' }] },
          ],
        },
      },
      {
        label: 'Relative Timestamp in ISO 8601 format',
        value: {
          function: 'date',
          args: [
            'Y-m-d\\TH:i:sP',
            { function: 'strtotime', args: ['-2 day', { time: 'currentStart' }] },
          ],
        },
      },
      {
        label: 'Relative Date in Y-m-dT00:00:00.000Z format',
        value: {
          function: 'concat',
          args: [
            {
              function: 'date',
              args: [
                'Y-m-d',
                { function: 'strtotime', args: ['-1 day', { time: 'currentStart' }] },
              ],
            },
            'T00:00:00.000Z',
          ],
        },
      },
      {
        label: 'Current Time in Y-m-d H:i:s format',
        value: { function: 'date', args: ['Y-m-d H:i:s', { time: 'currentStart' }] },
      },
      {
        label: 'Date in Y-m-d H:i:s format',
        value: { function: 'date', args: ['Y-m-d H:i:s', 1490000000] },
      },
      {
        label: 'Previous Start in Y-m-d H:i:s format',
        value: { function: 'date', args: ['Y-m-d H:i:s', { time: 'previousStart' }] },
      },
      {
        label: 'Previous Start in Unix Timestamp (Epoch)',
        value: { time: 'previousStart' },
      },
      {
        label: 'Current Time in Unix Timestamp (Epoch)',
        value: { function: 'time' },
      },
      {
        label: 'String to Unix Timestamp (Epoch)',
        value: { function: 'strtotime', args: ['-7 days', { time: 'currentStart' }] },
      },
    ],
  },
  {
    label: 'Concat',
    options: [
      {
        label: 'With separator',
        value: { function: 'implode', args: [',', ['apples', 'oranges', 'plums']] },
      },
      {
        label: 'Without separator',
        value: { function: 'concat', args: ['Hen', 'Or', 'Egg'] },
      },
      {
        label: 'updatedAt >= relative date',
        value: {
          function: 'concat',
          args: [
            '=updatedAt',
            '>=',
            {
              function: 'date',
              args: [
                'd-m-Y',
                { function: 'strtotime', args: ['-3 day', { time: 'previousStart' }] },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    label: 'Hash',
    options: [
      {
        label: 'MD5 Hash',
        value: { function: 'md5', args: ['NotSoSecret'] },
      },
      {
        label: 'SHA1 Hash',
        value: { function: 'sha1', args: ['NotSoSecret'] },
      },
      {
        label: 'Base64 Encode',
        value: { function: 'base64_encode', args: ['TeaPot'] },
      },
      {
        label: 'HMAC Hash',
        value: { function: 'hash_hmac', args: ['sha256', '12345abcd5678efgh90ijk', 'TeaPot'] },
      },
    ],
  },
  {
    label: 'Others',
    options: [
      {
        label: 'Sprintf',
        value: { function: 'sprintf', args: ['Three %s are %.2f %s.', 'apples', 0.5, 'plums'] },
      },
      {
        label: 'If empty',
        value: { function: 'ifempty', args: ['', 'Banzai'] },
      },
    ],
  },
];

const FunctionControl = (props: {
  autoFocus: boolean;
  value: string;
  onChange: (value: string) => void;
  parameters: Map<string, any>;
}) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<Record<string, any>>();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [result, setResult] = React.useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const isValidFunction = isValidJsonConfig(props.value);

  const testFunction = () => {
    setResult(null);
    setIsProcessing(true);
    return performFunction(props.parameters, JSON.parse(props.value))
      .then((data: { result?: string; error?: string }) => {
        setResult({
          type: 'result' in data ? 'success' : 'error',
          message: data?.result || data?.error || 'An error occurred while testing function.',
        });
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-gap-6">
        <ControlLabel>Function</ControlLabel>
        <Tooltip placement="top" type="explanatory" tooltip="Test your function to see the result.">
          <Button
            bsStyle="link"
            className="btn-link-inline tw-mb-1"
            onClick={testFunction}
            disabled={isProcessing || !isValidFunction}
          >
            {isProcessing ? (
              <>
                <Loader className="btn-icon" />
                Processing...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="brain-circuit" className="btn-icon color-cyan" />
                Test your function
              </>
            )}
          </Button>
        </Tooltip>
      </div>
      {result && (
        <Alert
          variant={result.type}
          title={result.type === 'success' ? 'Function result' : 'Error'}
          className="tw-mb-2"
        >
          {result.message}
        </Alert>
      )}
      <Select
        className="tw-mb-2"
        clearable={false}
        placeholder="Select from predefined function templates"
        value={selectedTemplate}
        options={TEMPLATES_OPTIONS}
        onChange={(value: Record<string, any>) => {
          setSelectedTemplate(value);
          props.onChange(JSON.stringify(value, null, 2));
        }}
      />
      <CodeEditor autoFocus={props.autoFocus} value={props.value} onChange={props.onChange} />
      <HelpBlock>
        Functions are simple pre-defined functions that allow you to add extra flexibility when
        needed. <DocumentationLink format="text" path="functions/" />
      </HelpBlock>
    </>
  );
};

export default FunctionControl;
