import React from 'react';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

const ReadOnlyDocumentationLink = () => {
  return (
    <Link href={`${URLS.USER_DOCUMENTATION}/transformations/mappings/#read-only-input-mapping`}>
      Learn More
    </Link>
  );
};

export default ReadOnlyDocumentationLink;
