import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Modal } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { Alert, HelpBlock, Link } from '@keboola/design';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import { isValidJsonConfig } from '@/utils/validation';

const SERVICE_ACCOUNT_REQUIRED_PROPS = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
];

const ServiceAccountModal = createReactClass({
  propTypes: {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    documentationLink: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      value: '',
      isJsonValid: false,
      errors: List(),
    };
  },

  onChangeValue(e) {
    const jsonString = e.target.value;
    this.setState({
      errors: this.getErrors(jsonString),
      isJsonValid: isValidJsonConfig(jsonString),
      value: jsonString,
    });
  },

  getErrors(jsonString) {
    if (!isValidJsonConfig(jsonString)) {
      return List();
    }
    let errors = List();
    const serviceAccountData = fromJS(JSON.parse(jsonString));
    SERVICE_ACCOUNT_REQUIRED_PROPS.forEach((propertyName) => {
      if (!serviceAccountData.has(propertyName)) {
        errors = errors.push(propertyName);
      }
    });
    return errors;
  },

  isValid() {
    return this.state.isJsonValid && this.state.errors.count() === 0;
  },

  resetState() {
    this.setState(this.getInitialState());
  },

  onSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(fromJS(JSON.parse(this.state.value)));
    this.resetState();
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  renderErrors() {
    if (this.isValid() || this.state.value === '') {
      return null;
    }
    if (!this.state.isJsonValid) {
      return (
        <Alert variant="error" className="tw-mb-5">
          Provided Service Account Key is not a valid JSON object.
        </Alert>
      );
    }
    return (
      <Alert variant="error" className="tw-mb-5">
        <p>Provided Service Account Key does not contain all required properties.</p>
        <p>
          Missing properties:{' '}
          {this.state.errors
            .map((missingProp, index) => {
              return (
                <span key={missingProp}>
                  <strong>{missingProp}</strong>
                  {index + 1 < this.state.errors.count() && ', '}
                </span>
              );
            })
            .toArray()}
          .
        </p>
      </Alert>
    );
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Google Service Account Key</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Textarea
                label="service_account"
                value={this.state.value}
                onChange={this.onChangeValue}
                className="form-control"
                minRows={15}
                maxRows={20}
                placeholder="{}"
              />
              <HelpBlock>
                Copy &amp; paste the whole JSON of the Google service account key file. For
                instructions on how to create the service account, see the{' '}
                <Link href={this.props.documentationLink}>documentation</Link>.
              </HelpBlock>
            </FormGroup>
            {this.renderErrors()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel="Submit"
              saveButtonType="submit"
              isDisabled={!this.isValid()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },
});

export default ServiceAccountModal;
