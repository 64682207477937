import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type { Map } from 'immutable';

import StorageApi from '@/modules/components/StorageApi';
import { isMergeRequestApproved, isMergeRequestInReview } from '@/modules/dev-branches/helpers';
import Loader from '@/react/common/Loader';
import string from '@/utils/string';

const ChangelogDownloadButton = (props: { mergeRequest: Map<string, any> }) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleDownload = () => {
    setLoading(true);
    return StorageApi.loadMergeRequest(props.mergeRequest.get('id'))
      .then((mergeRequest) => {
        const link = document.createElement('a');
        link.href = `data:text/json;charset=utf-8,${JSON.stringify(mergeRequest.changeLog)}`;
        link.download = `${string.webalize(props.mergeRequest.get('title'))}-changelog.json`;
        link.click();
      })
      .finally(() => setLoading(false));
  };

  if (!isMergeRequestInReview(props.mergeRequest) && !isMergeRequestApproved(props.mergeRequest)) {
    return null;
  }

  return (
    <Tooltip tooltip="Download Changelog" placement="top">
      <Button onClick={handleDownload} disabled={isLoading}>
        {isLoading ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon className="icon-addon-right" icon={['far', 'arrow-down-to-line']} />
        )}
        Changelog
      </Button>
    </Tooltip>
  );
};

export default ChangelogDownloadButton;
