import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@keboola/design';
import classNames from 'classnames';

const MarketplacePortalLink = (props: { href: string; className?: string }) => {
  return (
    <Link href={props.href} className={classNames('btn btn-success btn-block', props.className)}>
      Manage in Azure Portal
      <FontAwesomeIcon icon="arrow-up-right-from-square" className="f-14 icon-addon-left" />
    </Link>
  );
};

export default MarketplacePortalLink;
