import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';

const SourceSearchInEditor = ({ inputSearch }) => {
  return (
    <>
      <InputGroup>
        <InputGroup.Addon>
          <small>{inputSearch.get('key', '')}</small>
        </InputGroup.Addon>
        <FormControl value={inputSearch.get('value', '')} disabled />
      </InputGroup>
      <HelpBlock>Table will be searched by its metadata</HelpBlock>
    </>
  );
};

SourceSearchInEditor.propTypes = {
  inputSearch: PropTypes.object.isRequired,
};

export default SourceSearchInEditor;
