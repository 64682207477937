import React from 'react';
import { Tooltip } from '@keboola/design';

import { SqlSyntaxItem } from '@/modules/components/react/components/generic/SnowflakeSqlTableExplainTooltip/SqlSyntaxItem';
import { SqlTableCard } from '@/modules/components/react/components/generic/SnowflakeSqlTableExplainTooltip/SqlTableCard';

export const SnowflakeSqlTableExplainTooltip = () => {
  return (
    <Tooltip
      type="explanatory"
      className="tw-max-w-none"
      triggerClassName="tw-text-secondary-600 tw-underline tw-underline-offset-2 tw-decoration-dashed tw-cursor-default"
      tooltip={
        <div className="tw-f tw-grid tw-grid-cols-2 tw-gap-12 tw-font-sans">
          <SqlTableCard title="Uppercase table name" tableName="EXTRA-FAST">
            <SqlSyntaxItem type="valid" text="EXTRA-FAST" />
            <SqlSyntaxItem type="valid" text='"EXTRA-FAST"' />
            <SqlSyntaxItem type="valid" text="extra-fast" />
            <SqlSyntaxItem type="invalid" text='"extra-fast"' />
          </SqlTableCard>

          <SqlTableCard title="Non-Uppercase table name" tableName="extra-fast">
            <SqlSyntaxItem type="invalid" text="EXTRA-FAST" />
            <SqlSyntaxItem type="invalid" text='"EXTRA-FAST"' />
            <SqlSyntaxItem type="invalid" text="extra-fast" />
            <SqlSyntaxItem type="valid" text='"extra-fast"' />
          </SqlTableCard>
        </div>
      }
      placement="bottom"
    >
      How to reference table in SQL
    </Tooltip>
  );
};
