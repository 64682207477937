import { fromJS, List, Map } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import RoutesStore from '@/stores/RoutesStore';

const getCacheKey = (
  componentId: string,
  action: string,
  configData: Map<string, any>,
  autoload?: string[],
) => {
  let partialData = Map();

  if (Array.isArray(autoload)) {
    partialData = autoload.reduce((acc, path) => {
      return acc.setIn(path.split('.'), configData.getIn(path.split('.')));
    }, Map());
  }

  return fromJS({ componentId, action, partialData }).hashCode();
};

const shouldAutoload = (autoload: string[], configData: Map<string, any>) => {
  if (!Array.isArray(autoload)) {
    return false;
  }

  return autoload.every((path) => {
    const value = configData.getIn(path.split('.'));

    return List.isList(value) ? !value.isEmpty() : !!value;
  });
};

const prepareConfigData = (componentId: string, configId: string, rowId?: string) => {
  const configData: Map<string, any> = InstalledComponentsStore.getConfigData(
    componentId,
    configId,
  );
  const editingParameters: string = InstalledComponentsStore.getEditingRawConfigDataParameters(
    componentId,
    configId,
    configData.get('parameters', Map()),
  );

  const rowConfigData = ConfigurationRowsStore.getConfiguration(
    componentId,
    configId,
    rowId,
  ) as Map<string, any>;
  const editingRow = ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId);

  return configData
    .set('parameters', fromJS(JSON.parse(editingParameters)))
    .mergeDeep(rowConfigData.set('parameters', editingRow.get('parameters', Map())));
};

const getCurrentConfigData = () => {
  return prepareConfigData(
    RoutesStore.getCurrentRouteComponentId(),
    RoutesStore.getCurrentRouteParam('configId'),
    RoutesStore.getCurrentRouteParam('row'),
  );
};

const isValidNewOption = (input: string, regexString: string) => {
  try {
    return new RegExp(regexString).test(input);
  } catch {
    return false;
  }
};

export { getCacheKey, shouldAutoload, prepareConfigData, getCurrentConfigData, isValidNewOption };
