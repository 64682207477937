import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';
import createReactClass from 'create-react-class';

import Select from '@/react/common/Select';

const SavedFiles = createReactClass({
  propTypes: {
    tags: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Stored Files</h2>
        </div>
        <div className="box-content">
          <div className="form-group">
            <Select
              multi
              allowCreate
              trimMultiCreatedValues
              name="tags"
              placeholder="Add tags..."
              promptTextCreator={(label) => `Add file tag "${label}"`}
              value={this.props.tags}
              disabled={this.props.isSaving || this.props.disabled}
              onChange={this.props.onEditChange}
              isLoading={this.props.isSaving}
            />
            <span className="help-block">
              The latest file with a given tag will be saved to <code>in/user/&#123;tag&#125;</code>
              . For writing files back to Storage, see{' '}
              <Link
                href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/common-interface/manifest-files/#dataoutfiles-manifests`}
              >
                documentation
              </Link>
              .
            </span>
          </div>
        </div>
      </div>
    );
  },
});

export default SavedFiles;
