import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal, Nav, NavItem, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';
import type { Map } from 'immutable';

import { canManageVariables } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isMergeRequestApproved, isMergeRequestInReview } from '@/modules/dev-branches/helpers';
import OAuthActions from '@/modules/oauth-v2/ActionCreators';
import OAuthStore from '@/modules/oauth-v2/Store';
import FullScreenModal from '@/react/common/FullScreenModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import isActiveInputElement from '@/utils/isActiveInputElement';
import OauthVariablesTable from './components/OauthVariablesTable';
import VariablesTable from './components/VariablesTable';
import { loadVariables } from './actions';
import {
  filterProductionVariables,
  filterVariablesAvailableInCurrentScope,
  separateOauthVariables,
} from './helpers';
import VariablesStore from './store';

const NAV_TABS = { variables: 'Variables', authorizations: 'Authorizations' } as const;

const Vault = () => {
  const store = useStores(
    () => {
      const isDevModeActive = DevBranchesStore.isDevModeActive();
      const allVariables = VariablesStore.getStore().variables;
      const availableVariables = filterVariablesAvailableInCurrentScope(allVariables).concat(
        isDevModeActive ? filterProductionVariables(allVariables) : [],
      );
      const { variables, oauthVariables } = separateOauthVariables(availableVariables);
      const sapiToken = ApplicationStore.getSapiToken();

      return {
        sapiToken,
        allVariables,
        variables,
        oauthVariables,
        isDevModeActive,
        canManageVariables: canManageVariables(sapiToken),
        hasCredentialsInVariables: ApplicationStore.hasCredentialsInVariables(),
        mergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
        currentDevBranchId: DevBranchesStore.getCurrentId() as unknown as number | null,
        defaultDevBranchId: DevBranchesStore.getDefaultBranchId() as unknown as number,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allConfigurations: InstalledComponentsStore.getAll(),
        allOauthCredentials: OAuthStore.getAllCredentials() as Map<string, any>,
      };
    },
    [],
    [
      VariablesStore,
      ApplicationStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore,
      OAuthStore,
    ],
  );

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<keyof typeof NAV_TABS>('variables');
  const [showForm, setShowForm] = useState<null | 'folder' | 'variable'>(null);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'v' && !isActiveInputElement()) {
        e.preventDefault();
        setOpen(true);
      }
    };

    document.addEventListener('keypress', down);
    return () => {
      document.removeEventListener('keypress', down);
    };
  }, [setOpen]);

  const renderVariablesTable = () => {
    return (
      <VariablesTable
        sapiToken={store.sapiToken}
        variables={store.variables}
        allVariables={store.allVariables}
        isDevModeActive={store.isDevModeActive}
        canManageVariables={store.canManageVariables}
        currentDevBranchId={store.currentDevBranchId}
        defaultDevBranchId={store.defaultDevBranchId}
        showForm={showForm}
        setShowForm={(show: boolean) => setShowForm(show ? 'variable' : null)}
      />
    );
  };

  const renderAuthorizationsTable = () => {
    return (
      <OauthVariablesTable
        sapiToken={store.sapiToken}
        variables={store.oauthVariables}
        isDevModeActive={store.isDevModeActive}
        canManageVariables={store.canManageVariables}
        currentDevBranchId={store.currentDevBranchId}
        defaultDevBranchId={store.defaultDevBranchId}
        allComponents={store.allComponents}
        allConfigurations={store.allConfigurations}
        allOauthCredentials={store.allOauthCredentials}
      />
    );
  };

  const renderBody = () => {
    if (!store.hasCredentialsInVariables) {
      return renderVariablesTable();
    }

    return (
      <Tab.Container
        activeKey={activeTab}
        id="vault-nav"
        onSelect={(activeKey: keyof typeof NAV_TABS) => {
          setShowForm(null);
          setActiveTab(activeKey);
        }}
      >
        <>
          <div className="flex-container flex-nowrap">
            <Nav bsStyle="tabs" role="navigation">
              {Object.entries(NAV_TABS).map(([key, label]) => (
                <NavItem key={key} eventKey={key}>
                  {label}
                </NavItem>
              ))}
            </Nav>
          </div>
          <Tab.Content animation={false}>
            <Tab.Pane eventKey="variables">{renderVariablesTable()}</Tab.Pane>
            <Tab.Pane eventKey="authorizations">{renderAuthorizationsTable()}</Tab.Pane>
          </Tab.Content>
        </>
      </Tab.Container>
    );
  };

  return (
    <>
      <Tooltip tooltip="Vault" placement="bottom">
        <Button
          bsStyle="link"
          onClick={() => {
            setOpen(true);
            loadVariables();
            OAuthActions.loadAllCredentials();
          }}
        >
          <FontAwesomeIcon icon="key" className="font-size-inherit" />
        </Button>
      </Tooltip>
      {open && (
        <FullScreenModal className="full-screen-vault-modal" onHide={() => setOpen(false)}>
          <Modal.Header>
            <div className="flex-container">
              <Modal.Title className="flex-container flex-start">
                <VaultIcon
                  mergeRequest={store.mergeRequest}
                  isDevModeActive={store.isDevModeActive}
                />
                Vault
              </Modal.Title>
              <ButtonToolbar>
                {activeTab === 'variables' && store.canManageVariables && (
                  <>
                    <Button
                      onClick={() => setShowForm('folder')}
                      disabled={!store.variables.length || !!showForm}
                    >
                      <FontAwesomeIcon icon="plus" className="icon-addon-right" />
                      Create Folder
                    </Button>
                    <Button
                      bsStyle="success"
                      onClick={() => setShowForm('variable')}
                      disabled={!!showForm}
                    >
                      <FontAwesomeIcon icon="plus" className="icon-addon-right" />
                      Create Variable
                    </Button>
                    <span className="btn-separator" />
                  </>
                )}
                <Button onClick={() => setOpen(false)}>
                  <FontAwesomeIcon icon="xmark" />
                </Button>
              </ButtonToolbar>
            </div>
          </Modal.Header>
          <Modal.Body>{renderBody()}</Modal.Body>
        </FullScreenModal>
      )}
    </>
  );
};

const VaultIcon = (props: { isDevModeActive: boolean; mergeRequest: Map<string, any> }) => {
  const inReview = isMergeRequestInReview(props.mergeRequest);
  const isApproved = isMergeRequestApproved(props.mergeRequest);

  return (
    <i className="fa-stack mrp-3">
      <FontAwesomeIcon
        icon="square"
        className={classNames('fa-stack-2x', {
          'text-muted': !props.isDevModeActive,
          'color-warning': props.isDevModeActive && !inReview && !isApproved,
          'color-purple': inReview,
          'color-success': isApproved,
        })}
      />
      <FontAwesomeIcon icon="key" className="fa-stack-1x f-20" inverse />
    </i>
  );
};

export default Vault;
