import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Tooltip } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { ioType } from '@/modules/components/Constants';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import Loader from '@/react/common/Loader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import FileOutputMappingModal from './FileOutputMappingModal';

const FileOutputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    definedSources: PropTypes.instanceOf(List).isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      isDeleting: false,
    };
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <div className="tw-flex tw-h-full tw-items-center">
              <MultiActionsSelectCheckbox
                isChecked={this.props.isSelected}
                isDisabled={this.state.isDeleting}
                onToggle={this.props.toggleSelection}
                entity="mapping"
              />
            </div>
          </span>
        )}
        <span className="td col-xs-5">
          <div className="tw-flex tw-items-center">
            <InputOutputTypeIcon type={ioType.FILE} />
            out/files/
            {this.props.value.get('source')}
          </div>
        </span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderTags()}</span>
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <FileOutputMappingModal
          onSave={this.props.onSave}
          mapping={this.props.value}
          definedSources={this.props.definedSources}
        />
        <Tooltip placement="top" tooltip="Delete Output">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={() => {
              this.setState({ isDeleting: true });
              this.props.onDelete().finally(() => this.setState({ isDeleting: false }));
            }}
            disabled={this.state.isDeleting}
          >
            {this.state.isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          </Button>
        </Tooltip>
      </span>
    );
  },

  renderTags() {
    const tags = this.props.value.get('tags', List());

    if (tags.isEmpty()) {
      return 'No tags will be assigned.';
    }

    return tags.map((tag) => <Badge key={tag} text={tag} asTag placement="right" />).toArray();
  },

  renderRightArrow() {
    if (!this.props.value.get('is_public') && !this.props.value.get('is_permanent')) {
      return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <>
            {this.props.value.get('is_public') && <div>Public</div>}
            {this.props.value.get('is_permanent') && <div>Permanent</div>}
          </>
        }
      >
        <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />
      </Tooltip>
    );
  },
});

export default FileOutputMappingRow;
