import type { List } from 'immutable';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import {
  extractPlaceholders,
  prepareEndpointPath,
  prepareRealJobPath,
} from '@/modules/ex-generic/helpers';

const KEY = 'general';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const parent =
    jobPath.length > 2
      ? parameters.getIn(['config', 'jobs', ...jobPath.slice(0, -2), 'endpoint'])
      : void 0;

  return fromJS({
    [KEY]: {
      method: job.get('method', 'GET'),
      endpoint: job.get('endpoint', ''),
      parent,
      childJob: !!parent,
    },
  });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  return job
    .set('method', editing.getIn([KEY, 'method'], 'GET'))
    .set('endpoint', editing.getIn([KEY, 'endpoint']));
};

const mapToParameters = (
  parameters: Map<string, any>,
  editing: Map<string, any>,
  jobPath: (string | number)[],
) => {
  const newJobPath = prepareRealJobPath(prepareEndpointPath(parameters, editing, jobPath));
  const job = parameters
    .getIn(['config', 'jobs', ...jobPath], Map())
    .update((job: Map<string, any>) => {
      if (newJobPath.length === 1) {
        return job;
      }

      return job.update('placeholders', Map(), (placeholders: Map<string, any>) => {
        extractPlaceholders(job.get('endpoint', '')).forEach((placeholder: string) => {
          if (!placeholders.has(placeholder)) {
            placeholders = placeholders.set(placeholder, '');
          }
        });

        return placeholders;
      });
    });

  return parameters
    .setIn(['config', 'jobs', ...newJobPath], job)
    .updateIn(['config', 'jobs', ...newJobPath.slice(0, -1)], (jobs) => jobs.toList())
    .updateIn(['config', 'jobs'], (jobs: List<any>) => {
      if (_.isEqual(newJobPath, jobPath)) {
        return jobs;
      }

      return jobs.deleteIn(jobPath);
    });
};

export { KEY, mapToState, mapToJob, mapToParameters };
