import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Radio } from 'react-bootstrap';
import { HelpBlock, PanelWithDetails } from '@keboola/design';
import createReactClass from 'create-react-class';

import Checkbox from '@/react/common/Checkbox';

const NewDimensionForm = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
  },

  handleChange(newDiff) {
    this.props.onChange({ ...this.props.value, ...newDiff });
  },

  render() {
    const { disabled, value } = this.props;

    return (
      <Form horizontal>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Name</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl
              type="text"
              disabled={disabled}
              onChange={(e) => this.handleChange({ name: e.target.value })}
              value={value.name}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">
            <Checkbox
              checked={!!value.includeTime}
              onChange={() => this.handleChange({ includeTime: !value.includeTime })}
            >
              Include Time
            </Checkbox>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Template</ControlLabel>
          </div>
          <div className="col-xs-9">
            {this.renderRadio(
              'GoodData',
              'gooddata',
              'Default date dimension provided by GoodData',
            )}
            {this.renderRadio(
              'Keboola',
              'keboola',
              'Default date dimension provided by Keboola. Added all week setups: Mon-Sun, Tue-Mon, Wed-Tue, Thu-Wed, Fri-Thu, Sat-Fri, Sun-Sat + Boolean value whether its weekend or working day',
            )}
            {this.renderRadio(
              'Custom',
              'custom',
              'Provide your own template. You can generate the csv file containing all necessary details and provide it to GoodData.',
            )}
          </div>
        </FormGroup>
        {value.template !== 'keboola' && value.template !== 'gooddata' && (
          <FormGroup>
            <div className="col-xs-3">
              <ControlLabel>Template ID</ControlLabel>
            </div>
            <div className="col-xs-9">
              <FormControl
                type="text"
                disabled={disabled}
                onChange={(e) => this.handleChange({ templateId: e.target.value })}
                value={value.templateId}
              />
            </div>
          </FormGroup>
        )}
        <PanelWithDetails labelCollapse="Hide Advanced Options" labelOpen="Show Advanced Options">
          <FormGroup>
            <div className="col-xs-3">
              <ControlLabel>Identifier</ControlLabel>
            </div>
            <div className="col-xs-9">
              <FormControl
                type="text"
                placeholder="optional"
                disabled={disabled}
                onChange={(e) => this.handleChange({ identifier: e.target.value })}
                value={value.identifier}
              />
            </div>
          </FormGroup>
        </PanelWithDetails>
      </Form>
    );
  },

  renderRadio(label, value, helpText) {
    return (
      <div className="tw-mb-2.5">
        <Radio
          disabled={this.props.disabled}
          checked={this.props.value.template === value}
          onChange={() => this.handleChange({ template: value })}
          value={value}
          name="template"
        >
          {label}
        </Radio>
        {helpText && <HelpBlock>{helpText}</HelpBlock>}
      </div>
    );
  },
});

export default NewDimensionForm;
