import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import type Promise from 'bluebird';
import classNames from 'classnames';

import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';

type Props = {
  title?: string;
  text?: React.ReactNode;
  isPending: boolean;
  disabledReason?: string;
  onConfirm: () => Promise<any>;
};

const RestoreConfigurationButton = ({
  title = 'Restore Configuration',
  text = 'Are you sure you want to restore the configuration?',
  disabledReason,
  isPending,
  onConfirm,
}: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Tooltip tooltip={disabledReason || title} placement="top" type="explanatory">
        <Button
          bsStyle="link"
          className={classNames('text-muted', { disabled: isPending || !!disabledReason })}
          onClick={() => !isPending && !disabledReason && setModalVisible(true)}
        >
          {isPending ? <Loader /> : <FontAwesomeIcon icon="reply" fixedWidth />}
        </Button>
      </Tooltip>
      <ConfirmModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        icon="reply"
        buttonLabel="Restore"
        title={title}
        buttonType="success"
        text={text}
        onConfirm={onConfirm}
        isDisabled={isPending}
      />
    </>
  );
};

export default RestoreConfigurationButton;
