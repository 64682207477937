import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import { Clipboard } from '@keboola/design';
import createReactClass from 'create-react-class';

import Input from './JsonConfigurationInput';

const JsonConfiguration = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    isChanged: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      value: '',
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">
            Configuration <Clipboard text={this.props.value} />
          </h2>
        </div>
        <div className="box-content">
          <Input
            value={this.props.value}
            disabled={this.props.isSaving || this.props.readOnly}
            onChange={this.props.onEditChange}
          />
        </div>
      </div>
    );
  },
});

export default JsonConfiguration;
