import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import type { Map } from 'immutable';

import { filterProductionAndCurrentDevBranchBuckets } from '@/modules/dev-branches/helpers';
import { getOutputBucketValue, prepareOutputBucket } from '@/modules/ex-generic/helpers';
import { nameWarning, STAGE } from '@/modules/storage/constants';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import string from '@/utils/string';
import DocumentationLink from './DocumentationLink';
import SwitchControl from './SwitchControl';

const Destination = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  allBuckets: Map<string, any>;
}) => {
  const inBuckets = props.allBuckets.filter((bucket: Map<string, any>) => {
    return bucket.get('stage') === STAGE.IN;
  });
  const bucket = getOutputBucketValue(props.allBuckets, props.editing.get('outputBucket'));
  const options = filterProductionAndCurrentDevBranchBuckets(inBuckets)
    .sortBy((bucket: Map<string, any>) => bucket.get('displayName'))
    .map((bucket: Map<string, any>) => ({
      value: bucket.get('id'),
      label: bucketLabel(bucket),
      name: bucket.get('displayName'),
    }))
    .toArray();

  return (
    <div className="box">
      <div className="box-header big-padding">
        <h2 className="box-title">
          Destination
          <DocumentationLink path="configuration/config/#output-bucket" />
        </h2>
      </div>
      <div className="box-content pt-0">
        <FormGroup>
          <ControlLabel>Output Bucket</ControlLabel>
          <Select
            allowCreate
            clearable={false}
            menuPlacement="top"
            disabled={props.readOnly}
            placeholder="Select or create a bucket"
            value={bucket}
            onChange={(bucket: string) => {
              const outputBucket = prepareOutputBucket(props.allBuckets, bucket);

              props.setEditing(props.editing.set('outputBucket', outputBucket));
            }}
            options={options}
            singleValueRenderer={(props) => {
              return (
                <div
                  className="flex-container flex-start"
                  style={props.getStyles?.('singleValue', props) ?? {}}
                >
                  {typeof props.data.label === 'string' ? (
                    <>
                      <BucketStageLabel placement="left" stage="in" />
                      {props.data.label}
                    </>
                  ) : (
                    props.data.label(props.selectProps.inputValue)
                  )}
                </div>
              );
            }}
            promptTextCreator={(label) => label}
            newOptionCreator={(option) => {
              return { label: `Create new bucket "${option}"`, value: option };
            }}
            inputValueSanitizer={string.sanitizeKbcTableIdString}
          />
          <HelpBlock>{nameWarning}</HelpBlock>
        </FormGroup>
        <SwitchControl
          label="Incremental Output"
          readOnly={props.readOnly}
          checked={props.editing.get('incrementalOutput')}
          onChange={(checked: boolean) =>
            props.setEditing(props.editing.set('incrementalOutput', checked))
          }
        />
      </div>
    </div>
  );
};

export default Destination;
