import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import createReactClass from 'create-react-class';

import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import RoutesStore from '@/stores/RoutesStore';

const QueryDeleteButton = createReactClass({
  propTypes: {
    query: PropTypes.object.isRequired,
    configurationId: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired,
    tooltipPlacement: PropTypes.string,
    componentId: PropTypes.string,
    actionsProvisioning: PropTypes.object.isRequired,
    mode: PropTypes.string,
    onKeyDown: PropTypes.func,
  },

  getDefaultProps() {
    return {
      tooltipPlacement: 'top',
    };
  },

  deleteQuery(e) {
    e.preventDefault();
    e.stopPropagation();
    RoutesStore.getRouter().transitionTo(this.props.componentId, {
      config: this.props.configurationId,
    });
    const ExDbActionCreators = this.props.actionsProvisioning.createActions(this.props.componentId);
    setTimeout(
      ExDbActionCreators.deleteQuery,
      null,
      this.props.configurationId,
      this.props.query.get('id'),
    );
  },

  render() {
    if (this.props.mode === 'menuitem') {
      return (
        <RowActionMenuItem
          onSelect={this.deleteQuery}
          disabled={this.props.isPending}
          onKeyDown={this.props.onKeyDown}
        >
          {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          Delete export
        </RowActionMenuItem>
      );
    }

    if (this.props.isPending) {
      return (
        <span className="btn btn-link">
          <Loader />
        </span>
      );
    }

    return (
      <Tooltip tooltip="Delete Export" placement={this.props.tooltipPlacement}>
        <Button bsStyle="link" onClick={this.deleteQuery}>
          <FontAwesomeIcon icon="trash" fixedWidth />
        </Button>
      </Tooltip>
    );
  },
});

export default QueryDeleteButton;
