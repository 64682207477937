import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import { List, Map } from 'immutable';

import type { ioType } from '@/modules/components/Constants';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Editor from './TableInputMappingEditor';

const TableInputMappingModal = (props: {
  onSave: (mapping: Map<string, any>) => Promise<any>;
  componentId: string;
  tables: Map<string, any>;
  buckets: Map<string, any>;
  otherMappings: Map<string, any>;
  destinationType: (typeof ioType)[keyof typeof ioType];
  mapping?: Map<string, any>;
  savedMapping?: Map<string, any>;
  replacementComponentId?: string;
  hasSourceTable?: boolean;
  generateSources?: boolean;
}) => {
  const [show, setShow] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [editing, setEditing] = React.useState<Map<string, any>>(Map());
  const isEdit = !!props.mapping;
  const isDbtComponent = DBT_COMPONENTS.includes(props.componentId);
  const editingNonExistentTable = isEdit && !props.hasSourceTable;

  const handleShow = () => {
    setShow(true);
    setEditing(props.mapping || Map());
  };

  const handleSave = () => {
    setIsSaving(true);
    props
      .onSave(editing)
      .then(() => setShow(false))
      .finally(() => setIsSaving(false));
  };

  const isDestinationDuplicate = () => {
    const otherDestinations = props.otherMappings.map((mapping) =>
      mapping.get('destination', '').toLowerCase(),
    );

    if (editing.has('tables')) {
      return editing.get('tables').some((table: Map<string, any>) => {
        if (
          editing.get('tables').some((tableB: Map<string, any>) => {
            return (
              table.get('source') !== tableB.get('source') &&
              table.get('destination') === tableB.get('destination')
            );
          })
        ) {
          return true;
        }

        return otherDestinations.contains(table.get('destination', '').toLowerCase());
      });
    }

    return otherDestinations.contains(editing.get('destination', '').toLowerCase());
  };

  const isValid = () => {
    return (
      (List.isList(editing.get('source'))
        ? !editing.get('source').isEmpty()
        : !!editing.get('source')) &&
      (editing.has('tables') || !!editing.get('destination')) &&
      (editing.has('tables') || !isDestinationDuplicate()) &&
      (editing.get('where_column')
        ? !editing.get('where_values', List()).isEmpty()
        : editing.get('where_values', List()).isEmpty())
    );
  };

  const renderOpenButton = () => {
    if (isEdit && isDbtComponent) {
      return null;
    }

    if (isEdit) {
      return (
        <Tooltip tooltip={editingNonExistentTable ? 'Open Input' : 'Edit Input'} placement="top">
          <Button bsStyle="link" className="text-muted" onClick={handleShow}>
            {editingNonExistentTable ? (
              <FontAwesomeIcon icon="eye" fixedWidth />
            ) : (
              <FontAwesomeIcon icon="pen" fixedWidth />
            )}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-success"
        onClick={handleShow}
        disabled={isDbtComponent && !props.generateSources}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        {isDbtComponent ? 'Add New Source' : 'New Table Input'}
      </Button>
    );
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      {renderOpenButton()}
      <Modal bsSize="large" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isDbtComponent ? 'Storage Source' : 'Input Mapping'}</Modal.Title>
          {isEdit ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>
        <Modal.Body>
          <Editor
            mode={isEdit ? 'edit' : 'create'}
            value={editing}
            onChange={setEditing}
            tables={props.tables}
            buckets={props.buckets}
            savedValue={props.savedMapping}
            componentId={props.componentId}
            replacementComponentId={props.replacementComponentId}
            disabled={isSaving || editingNonExistentTable}
            isDestinationDuplicate={isDestinationDuplicate()}
            editingNonExistentTable={editingNonExistentTable}
            destinationType={props.destinationType}
            otherMappings={props.otherMappings}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={isEdit ? 'Save Input' : `Add ${isDbtComponent ? 'Source' : 'Input'}`}
            onSave={handleSave}
            isSaving={isSaving}
            isDisabled={editingNonExistentTable || !isValid()}
          />
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default TableInputMappingModal;
