import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import { fromJS, List, Map } from 'immutable';

import CircleIcon from '@/react/common/CircleIcon';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Select from '@/react/common/Select';

class SisenseRelations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      relations: this.props.relations,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  render() {
    const hasChanges = !this.props.relations.equals(this.state.relations);

    return (
      <>
        <CircleIcon className="box-icon" icon="network-wired" color="blue" bold />
        <div className="box pt-1">
          <div className="box-content pt-0 pb-0 flex-container">
            <h2>Relationships</h2>
            {!this.props.readOnly && (
              <ConfirmButtons
                saveStyle="primary"
                cancelLabel="Reset"
                showCancel={hasChanges}
                isSaving={this.state.isSaving}
                isDisabled={!hasChanges || this.state.isSaving}
                onCancel={() => this.setState({ relations: this.props.relations })}
                onSave={this.handleSave}
              />
            )}
          </div>
          <Table className="compact">
            <thead>
              <tr>
                <th className="w-250">Column</th>
                <th className="text-left">Relations</th>
              </tr>
            </thead>
            <tbody>
              {this.props.columns.map((column) => {
                const options = this.getOptions(column);
                const hasInvalidOption = options.find(
                  (option) => option.className === 'option-danger',
                );

                return (
                  <tr key={column.get('name')}>
                    <td>{column.get('name')}</td>
                    <td className="text-left">
                      <Select
                        multi
                        placeholder="Select relations..."
                        onChange={(selected) => {
                          const columnRelations = selected.map((relation) =>
                            fromJS(JSON.parse(relation)),
                          );
                          const relations = this.state.relations
                            .filter((relation) => relation.get('column') !== column.get('name'))
                            .concat(columnRelations);
                          this.setState({ relations });
                        }}
                        value={this.getValues(column)}
                        options={options}
                        disabled={this.props.readOnly}
                      />
                      {hasInvalidOption && (
                        <HelpBlock variant="danger">
                          Some selected columns do not match name or type. Connected columns has to
                          have same type.
                        </HelpBlock>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  getValues(column) {
    return this.state.relations
      .filter((relation) => relation.get('column') === column.get('name'))
      .map((relation) => JSON.stringify(relation));
  }

  getOptions(column) {
    let options = this.props.otherTables
      .map((table) => {
        return table
          .get('items')
          .filter((item) => item.get('type') === column.get('type'))
          .filter((item) => {
            const directRelationExist = this.state.relations.find((relation) => {
              return (
                relation.get('column') === column.get('dbName') &&
                relation.getIn(['target', 'table']) === table.get('dbName') &&
                relation.getIn(['target', 'column']) === item.get('dbName')
              );
            });

            if (directRelationExist) {
              return true;
            }

            const columnHasRelationToTable = this.state.relations.find((relation) => {
              return (
                (relation.get('column') === column.get('dbName') &&
                  relation.getIn(['target', 'table']) === table.get('dbName')) ||
                (relation.getIn(['target', 'table']) === table.get('dbName') &&
                  relation.getIn(['target', 'column']) === item.get('dbName'))
              );
            });

            return !columnHasRelationToTable;
          })
          .map((item) => {
            const value = JSON.stringify({
              column: column.get('name'),
              target: {
                table: table.get('dbName'),
                column: item.get('dbName'),
              },
            });

            return { label: `${table.get('dbName')} - ${item.get('dbName')}`, value };
          });
      })
      .toList()
      .flatten(1);

    this.state.relations
      .filter((relation) => relation.get('column') === column.get('name'))
      .forEach((relation) => {
        const value = JSON.stringify(relation);
        const optionExist = options.find((option) => option.value === value);

        if (!optionExist) {
          const targetTable = relation.getIn(['target', 'table']);
          const targetColumn = relation.getIn(['target', 'column']);
          options = options.push({
            label: `${targetTable} - ${targetColumn}`,
            value,
            className: 'option-danger',
          });
        }
      });

    return options.toJS();
  }

  handleSave() {
    this.setState({ isSaving: true });
    return this.props.onSave(this.state.relations).finally(() => {
      this.setState({ isSaving: false });
    });
  }
}

SisenseRelations.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  columns: PropTypes.instanceOf(List).isRequired,
  relations: PropTypes.instanceOf(List).isRequired,
  otherTables: PropTypes.instanceOf(Map).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SisenseRelations;
