import React from 'react';
import { Badge, cn } from '@keboola/design';

import type { ExistingApp } from '@/api/routes/dataScienceService';
import { getAppState } from '@/modules/data-apps/helpers';

const states = {
  created: { variant: 'gray', text: 'Not Deployed' },
  running: { variant: 'green', text: 'Active' },
  sleeping: { variant: 'purple', text: 'Sleeping' },
  starting: { variant: 'orange', text: 'Starting' },
  restarting: { variant: 'orange', text: 'Starting' },
  stopping: { variant: 'orange', text: 'Stopping' },
  stopped: { variant: 'orange', text: 'Stopped' },
  deleted: { variant: 'red', text: 'Deleted' },
  deleting: { variant: 'orange', text: 'Deleting' },
} as const;

const StatusLabel = ({ app, className }: { app?: ExistingApp; className?: string }) => {
  const state = states[getAppState(app)];

  if (!state) {
    return null;
  }

  return (
    <Badge
      text={state.text}
      variant={state.variant}
      className={cn('tw-min-w-16 tw-rounded tw-py-1.5', className)}
    />
  );
};

export default StatusLabel;
