import React from 'react';
import { cn } from '@keboola/design';

const TextDivider = ({
  className,
  textClassName,
  label = 'OR',
}: {
  label?: string;
  className?: string;
  textClassName?: string;
}) => (
  <div
    className={cn(
      'tw-relative tw-flex tw-items-center tw-text-neutral-200',
      'before:tw-mr-3 before:tw-h-px before:tw-grow before:tw-bg-[currentColor] before:tw-content-[""]',
      'after:tw-ml-3 after:tw-h-px after:tw-grow after:tw-bg-[currentColor] after:tw-content-[""]',
      className,
    )}
  >
    <span className={cn('tw-text-xs tw-font-medium tw-text-neutral-400', textClassName)}>
      {label}
    </span>
  </div>
);

export default TextDivider;
