import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import { HelpBlock } from '@keboola/design';
import Promise from 'bluebird';
import { Map } from 'immutable';
import { rtrim } from 'underscore.string';

import ConfigurationRowsActionCreators, {
  createSimpleRow,
} from '@/modules/configurations/ConfigurationRowsActionCreators';
import { createConfiguration } from '@/modules/ex-aws-s3/adapters/row';
import { runComponent } from '@/modules/simplified-ui/actions';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import nextTick from '@/utils/nextTick';
import Help from './Help';

class AwsSourceSetting extends React.Component {
  static propTypes = {
    configRows: PropTypes.instanceOf(Map),
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
  };

  state = {
    data: this.prepareData(),
    isSaving: false,
  };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          <Form onSubmit={this.handleSaveAndRun} horizontal>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>S3 Bucket</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="text"
                  value={this.state.data.get('bucket')}
                  onChange={(e) =>
                    this.setState({ data: this.state.data.set('bucket', e.target.value) })
                  }
                  placeholder="mybucket"
                  disabled={this.isDisabled()}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Search Key</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="text"
                  value={this.state.data.get('key')}
                  onChange={(e) =>
                    this.setState({ data: this.state.data.set('key', e.target.value) })
                  }
                  placeholder="myfolder/myfile.csv"
                  disabled={this.isDisabled()}
                />
                <HelpBlock>
                  Search key including folders or a prefix. Do not type <code>*</code> or{' '}
                  <code>%</code> wildcards, it will be added automatically. All files will be
                  imported into a single Storage table <b>data.csv</b>.
                </HelpBlock>
              </div>
            </FormGroup>
            {this.renderConfirmButton()}
          </Form>
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderHelp() {
    if (!this.state.isSaving && this.isSubmitDisabled()) {
      return (
        <Help
          title="Enter your source setting"
          text="If you need more options (CSV setting, destination, or processing options), switch to an advanced mode, where you will have more options for how to set up your configuration."
          documentation={`${URLS.USER_DOCUMENTATION}/components/extractors/storage/aws-s3#source`}
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="We will start loading your data. It may take a few minutes for large amounts of data. In the meantime, you can load data from other sources."
      />
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveButtonType="submit"
        saveLabel="Save and Run configuration"
        isSaving={this.state.isSaving}
        isDisabled={this.isSubmitDisabled()}
      />
    );
  }

  isDisabled() {
    return this.props.readOnly || this.state.isSaving;
  }

  isSubmitDisabled() {
    return (
      this.isDisabled() ||
      !this.state.data.get('bucket').trim() ||
      !this.state.data.get('key').trim() ||
      this.state.data.equals(this.prepareData())
    );
  }

  prepareData() {
    if (this.props.configRows.isEmpty()) {
      return Map({ bucket: '', key: '' });
    }

    const rowConfigData = this.props.configRows.first().get('configuration', Map());

    return Map({
      bucket: rowConfigData.getIn(['parameters', 'bucket'], ''),
      key: rtrim(rowConfigData.getIn(['parameters', 'key'], ''), '*'),
    });
  }

  handleSaveAndRun = (e) => {
    e.preventDefault();

    this.setState({ isSaving: true });
    return Promise.resolve()
      .then(() => {
        const configuration = JSON.stringify(
          createConfiguration(
            Map({
              bucket: this.state.data.get('bucket').trim(),
              key: this.state.data.get('key').trim(),
              name: 'data',
              wildcard: true,
            }),
          ).toJS(),
        );

        if (this.props.configRows.isEmpty()) {
          return createSimpleRow(
            this.props.componentId,
            this.props.configId,
            { name: 'Data', configuration },
            'Create source setting',
          );
        }

        return ConfigurationRowsActionCreators.updateSimple(
          this.props.componentId,
          this.props.configId,
          this.props.configRows.first().get('id'),
          { configuration },
          'Update source setting',
        );
      })
      .then(() => runComponent(this.props.componentId, this.props.configId, this.props.hasNewQueue))
      .then(() => nextTick(() => this.setState({ data: this.prepareData() })))
      .finally(() => this.setState({ isSaving: false }));
  };
}

export default AwsSourceSetting;
