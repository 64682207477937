import React from 'react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { getDisabledRestoreReason } from '@/modules/trash/utils';
import Checkbox from '@/react/common/Checkbox';
import TimeAndUser from '@/react/common/TimeAndUser';
import Truncated from '@/react/common/Truncated';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import DeleteConfigurationButton from './DeleteConfigurationButton';
import RestoreConfigurationButton from './RestoreConfigurationButton';

type Props = {
  readOnly: boolean;
  config: Map<string, any>;
  component: Map<string, any>;
  admins: Map<string, any>;
  isDeleteEnabled: boolean;
  isSelected: boolean;
  isRestoreEnabled: boolean;
  onSelect: (isChecked: boolean) => void;
};

const DeletedConfigurationRow = ({
  readOnly,
  config,
  admins,
  component,
  isDeleteEnabled,
  isRestoreEnabled,
  isSelected,
  onSelect,
}: Props) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isRestoring, setIsRestoring] = React.useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    return InstalledComponentsActionCreators.deleteConfigurationPermanently(
      component.get('id'),
      config.get('id'),
    ).finally(() => setIsDeleting(false));
  };

  const handleRestore = () => {
    setIsRestoring(true);
    return InstalledComponentsActionCreators.restoreConfiguration(component, config).finally(() => {
      return setIsRestoring(false);
    });
  };

  const renderActions = () => {
    if (readOnly) {
      return null;
    }

    return (
      <>
        <RestoreConfigurationButton
          text={
            <>
              Are you sure you want to restore the configuration{' '}
              <strong>{config.get('name')}</strong>?
            </>
          }
          disabledReason={getDisabledRestoreReason(component.get('id'))}
          onConfirm={handleRestore}
          isPending={isRestoring}
        />
        {isDeleteEnabled && (
          <DeleteConfigurationButton
            config={config}
            onConfirm={handleDelete}
            isPending={isDeleting}
          />
        )}
      </>
    );
  };

  return (
    <span
      className={classNames('tr hoverable-actions-with-replacement', { 'bg-selected': isSelected })}
    >
      <span className={classNames('td', isSelected && 'bg-selected')}>
        <div className="tw-flex tw-items-center tw-gap-3">
          {!readOnly && isRestoreEnabled && (
            <Checkbox
              className="tw-mb-0.5"
              tooltip={`${isSelected ? 'Deselect' : 'Select'} configuration`}
              checked={isSelected}
              onChange={onSelect}
            />
          )}
          <div>
            <Truncated text={config.get('name', '---')} />
            {config.get('description') && (
              <div>
                <span className="f-13 text-muted">
                  {descriptionExcerpt(config.get('description'))}
                </span>
              </div>
            )}
          </div>
        </div>
      </span>
      <span className={classNames('td w-200 text-right', isSelected && 'bg-selected')}>
        <div className="actions-container">
          <div className="not-actions">
            <TimeAndUser
              admin={admins.get(config.getIn(['currentVersion', 'creatorToken', 'description']))}
              time={config.getIn(['currentVersion', 'created'])}
              fallbackName={config.getIn(['currentVersion', 'creatorToken', 'description'])}
            />
          </div>
          <div className="actions">
            <span className="no-wrap">{renderActions()}</span>
          </div>
        </div>
      </span>
    </span>
  );
};

export default DeletedConfigurationRow;
