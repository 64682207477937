import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';
import Promise from 'bluebird';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import tokensActions from '@/modules/tokens/actionCreators';
import NewTokenHeaderButton from '@/modules/tokens/react/components/NewTokenHeaderButton';
import TokenHeaderButtons from '@/modules/tokens/react/components/TokenHeaderButtons';
import DetailPage from '@/modules/tokens/react/pages/Detail/Detail';
import NewPage from '@/modules/tokens/react/pages/New/New';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import { DEFAULT_INTERVAL } from '@/utils/genericPolls';
import InviteUserHeaderButton from './components/InviteUserHeaderButton';
import {
  generateSessionToken,
  loadFeatures,
  loadOrganizations,
  loadOrganizationsViaMaintainer,
  loadProject,
  loadUsers,
} from './actions';
import CliSync from './CliSync';
import { routeNames } from './constants';
import { catchSessionTokenError } from './helpers';
import Project from './Project';
import Tokens from './Tokens';
import Users from './Users';

export const sessionTokenPoller = {
  interval: DEFAULT_INTERVAL,
  action: () => generateSessionToken().catch((error) => catchSessionTokenError(error, true)),
  skipFirst: true,
};

const PRODUCT_SETTING_TITLE = 'Project Settings';

export default [
  {
    name: routeNames.PROJECT,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Project,
    requireData: [
      () => {
        return generateSessionToken()
          .then(() =>
            Promise.all([
              loadProject(),
              loadOrganizationsViaMaintainer(),
              loadOrganizations(),
              loadFeatures(),
            ]),
          )
          .catch(catchSessionTokenError);
      },
    ],
    poll: sessionTokenPoller,
  },
  {
    name: routeNames.USERS,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Users,
    headerButtonsHandler: InviteUserHeaderButton,
    requireData: [() => generateSessionToken().then(loadUsers).catch(catchSessionTokenError)],
    poll: sessionTokenPoller,
  },
  {
    name: routeNames.TOKENS,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Tokens,
    headerButtonsHandler: NewTokenHeaderButton,
    requireData: [tokensActions.loadTokens],
    childRoutes: [
      {
        name: routeNames.NEW_TOKEN,
        path: 'new-token',
        title: 'New Token',
        defaultRouteHandler: NewPage,
        requireData: [() => StorageActionCreators.loadBucketsAndTables()],
      },
      {
        name: routeNames.TOKEN_DETAIL,
        path: ':tokenId',
        defaultRouteHandler: DetailPage,
        headerButtonsHandler: TokenHeaderButtons,
        title: (routerState) => {
          const tokenId = routerState.getIn(['params', 'tokenId']);
          const token = TokensStore.getAll().find((token) => token.get('id') === tokenId);

          if (!token) {
            return `Unknown token ${tokenId}`;
          }

          return `${token.get('description')} (${token.get('id')})`;
        },
        requireData: [() => StorageActionCreators.loadBucketsAndTables()],
      },
    ],
  },
  {
    name: routeNames.CLI,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: CliSync,
    headerButtonsHandler: () => (
      <Link className="btn btn-default" href={`${URLS.DEVELOPERS_DOCUMENTATION}/cli`}>
        <FontAwesomeIcon icon="book-blank" className="icon-addon-right" />
        Documentation
      </Link>
    ),
  },
];
