import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/orchestrations/Constants';
import OrchestrationActiveButton from '@/modules/orchestrations/react/components/OrchestrationActiveButton';
import OrchestrationDeleteButton from '@/modules/orchestrations/react/components/OrchestrationDeleteButton';
import OrchestrationRunButton from '@/modules/orchestrations/react/components/OrchestrationRunButton';
import CronRecord from '@/modules/scheduler/components/CronRecord';
import CreatedDate from '@/react/common/CreatedDate';
import JobDuration from '@/react/common/JobDuration';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import Link from '@/react/common/RouterLink';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import Truncated from '@/react/common/Truncated';

const OrchestrationRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    hasTrigger: PropTypes.bool.isRequired,
    isOrchestrationActiveButtonDisabled: PropTypes.bool.isRequired,
    orchestration: PropTypes.object,
    pendingActions: PropTypes.object,
    tasks: PropTypes.object,
  },

  render() {
    const lastExecutedJob = this.props.orchestration.get('lastExecutedJob');
    const isOrchestrationActive = this.props.orchestration.get('active');

    return (
      <Link
        className={classnames('tr', 'color-inherit hoverable-actions-with-replacement', {
          'row-disabled': !isOrchestrationActive,
        })}
        to={routeNames.ORCHESTRATION}
        params={{ orchestrationId: this.props.orchestration.get('id') }}
      >
        <span className="td" title="Last job status">
          <JobStatusIcon status={lastExecutedJob ? lastExecutedJob.get('status') : 'default'} />
        </span>
        <span className="td">
          <Truncated text={this.props.orchestration.get('name')} />
        </span>
        <span className="td">
          {this.props.hasTrigger ? (
            <span>Event trigger</span>
          ) : (
            <CronRecord
              crontabRecord={this.props.orchestration.get('crontabRecord')}
              crontabTimezone={this.props.orchestration.get('crontabTimezone')}
            />
          )}
        </span>
        <span className="td text-right">{this.renderDuration(lastExecutedJob)}</span>
        {this.props.readOnly ? (
          <span className="td text-right">{this.renderLastRun(lastExecutedJob)}</span>
        ) : (
          <span className="td no-wrap">
            <div className="actions-container">
              <div className="not-actions">{this.renderLastRun(lastExecutedJob)}</div>
              <div className="actions">
                <RowActionDropdown>
                  {isOrchestrationActive && (
                    <>
                      <OrchestrationRunButton
                        tasks={this.props.tasks}
                        orchestration={this.props.orchestration}
                      />
                      <RowActionMenuItem divider />
                    </>
                  )}
                  <OrchestrationActiveButton
                    buttonDisabled={this.props.isOrchestrationActiveButtonDisabled}
                    orchestration={this.props.orchestration}
                    isPending={this.props.pendingActions.get('active', false)}
                  />
                  <RowActionMenuItem divider />
                  <OrchestrationDeleteButton
                    orchestration={this.props.orchestration}
                    isPending={this.props.pendingActions.get('delete', false)}
                  />
                </RowActionDropdown>
              </div>
            </div>
          </span>
        )}
      </Link>
    );
  },

  renderLastRun(lastExecutedJob) {
    return <CreatedDate createdTime={lastExecutedJob?.get('startTime')} fallback="No run yet" />;
  },

  renderDuration(lastExecutedJob) {
    if (!lastExecutedJob || !lastExecutedJob.get('startTime')) {
      return 'No run yet';
    }

    return (
      <JobDuration
        status={lastExecutedJob.get('status')}
        startTime={lastExecutedJob.get('startTime')}
        endTime={lastExecutedJob.get('endTime')}
      />
    );
  },
});

export default OrchestrationRow;
