import React, { useState } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Button, cn } from '@keboola/design';
import type { Map } from 'immutable';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import Checkbox from '@/react/common/Checkbox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import useStores from '@/react/hooks/useStores';

const ComponentSelection = ({
  componentsIds,
  onSubmit,
}: {
  componentsIds?: string[];
  onSubmit: (componentIds: string[]) => void;
}) => {
  const { allComponents } = useStores(
    () => ({ allComponents: ComponentsStore.getAll() as Map<string, any> }),
    [],
    [ComponentsStore],
  );
  const [selectedComponents, setSelectedComponents] = useState<string[]>([]);

  if (!componentsIds?.length) return null;

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {componentsIds.map((id) => {
        const component = allComponents.get(id);

        if (!component) return null;

        return (
          <Button
            key={id}
            variant="outline"
            className={cn('tw-max-h-none tw-gap-2 tw-text-left hover:!tw-border-secondary-500', {
              'tw-border-secondary-500': selectedComponents.includes(id),
            })}
            onClick={() =>
              setSelectedComponents((previous) => {
                if (previous.includes(id)) {
                  return previous.filter((componentId) => componentId !== id);
                }

                return [...previous, id];
              })
            }
          >
            <Checkbox
              checked={selectedComponents.includes(id)}
              className="tw-pointer-events-none"
            />
            <ComponentIcon component={component} size="20" />
            <ComponentName component={component} />
          </Button>
        );
      })}
      <div className="tw-flex tw-gap-2">
        <Button
          onClick={() => onSubmit(selectedComponents)}
          disabled={selectedComponents.length === 0}
        >
          Submit Selection
        </Button>
        <BootstrapButton bsStyle="link" className="btn-link-inline" onClick={() => onSubmit([])}>
          Not Relevant
        </BootstrapButton>
      </div>
    </div>
  );
};

export default ComponentSelection;
