import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';
import { HelpBlock, Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';

class LastFetchedValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }
  render() {
    if (!this.props.value) {
      return (
        <FormControl.Static>
          There is no value stored.
          <HelpBlock>Either the component has not yet run, or it has been reset.</HelpBlock>
        </FormControl.Static>
      );
    }

    return (
      <FormControl.Static>
        {this.state.isLoading ? (
          <>
            <Loader /> Clearing...
          </>
        ) : (
          <strong>{this.props.value}</strong>
        )}
        {!this.props.readOnly && (
          <HelpBlock>
            To start from the beginning of the table, you can{' '}
            <Tooltip
              tooltip={
                'Resetting means that the next run will start from the lowest value of ' +
                this.props.incrementalFetchingColumn
              }
              placement="top"
              type="explanatory"
            >
              <Button
                bsStyle="link"
                className="btn-link-inline"
                onClick={() => {
                  this.setState({
                    isLoading: true,
                  });
                  this.props.onReset().finally(() => {
                    this.setState({
                      isLoading: false,
                    });
                  });
                }}
              >
                clear the stored value
              </Button>
            </Tooltip>
            .
          </HelpBlock>
        )}
      </FormControl.Static>
    );
  }
}

LastFetchedValue.propTypes = {
  onReset: PropTypes.func.isRequired,
  value: PropTypes.node,
  incrementalFetchingColumn: PropTypes.string,
  readOnly: PropTypes.bool,
};

LastFetchedValue.defaultProps = {
  readOnly: false,
};

export default LastFetchedValue;
