import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { saveFolderToMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import ConfirmModal from '@/react/common/ConfirmModal';
import Select from '@/react/common/Select';
import string from '@/utils/string';
import { getRealComponentId, getUsedFolders } from './helpers';

type SelectedRows = { config: Map<string, any>; component: Map<string, any> }[];

type Props = {
  show: boolean;
  onHide: () => void;
  isDevModeActive: boolean;
  rows: SelectedRows;
  component: Map<string, any>;
  availableConfigurations: Map<string, any>;
  componentsMetadata: Map<string, any>;
};

const MoveConfigurationsModal = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState('');

  React.useEffect(() => {
    if (props.show) {
      setSelectedFolder('');
    }
  }, [props.show]);

  const getTitle = () => {
    if (props.rows.length === 1) {
      return `Move ${props.rows[0].config.get('name')}`;
    }

    return 'Move configurations';
  };

  const usedFolders = getUsedFolders(
    props.availableConfigurations,
    props.componentsMetadata,
    props.component,
  );

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      isDisabled={props.isDevModeActive && !!!selectedFolder}
      onHide={props.onHide}
      icon="folder-arrow-up"
      buttonType="success"
      buttonLabel="Move"
      title={getTitle()}
      text={
        <FormGroup>
          <ControlLabel>Folder</ControlLabel>
          <Select
            autoFocus
            allowCreate
            value={selectedFolder}
            placeholder="Select existing folder or create new one"
            promptTextCreator={(label) => `Create new folder "${label}"`}
            onChange={(selected: string) => setSelectedFolder(selected)}
            options={usedFolders
              .map((folder) => ({ label: folder, value: folder }))
              .sort()
              .toArray()}
          />
          {!props.isDevModeActive && (
            <HelpBlock>
              Leave empty for moving selected {string.pluralize(props.rows, 'configuration')} out of
              folders.
            </HelpBlock>
          )}
        </FormGroup>
      }
      isLoading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        return Promise.map(
          props.rows,
          (row: { config: Map<string, any>; component: Map<string, any> }) => {
            const componentId = getRealComponentId(row.config, row.component);

            if (selectedFolder) {
              return saveFolderToMetadata(componentId, row.config.get('id'), selectedFolder);
            }

            const folderMetadata = props.componentsMetadata
              .getIn([componentId, row.config.get('id')], List())
              .find((row: Map<string, any>) => {
                return row.get('key') === MetadataKeys.CONFIGURATION_FOLDER;
              });

            if (!folderMetadata) {
              return Promise.resolve();
            }

            return InstalledComponentsActionCreators.deleteConfigurationMetadata(
              componentId,
              row.config.get('id'),
              folderMetadata.get('id'),
              { skipEmitChanges: true },
            ).catch((error: any) => {
              if (error.response.status === 404) {
                return Promise.resolve();
              }

              throw error;
            });
          },
          { concurrency: 3 },
        )
          .then(() => InstalledComponentsActionCreators.loadComponentsMetadataForce())
          .then(() => {
            props.onHide();

            if (selectedFolder && !usedFolders.has(selectedFolder)) {
              ApplicationActionCreators.sendNotification({
                type: 'success',
                message: 'Folder created.',
              });
            }
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default MoveConfigurationsModal;
