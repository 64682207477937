import React from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Modal } from 'react-bootstrap';
import { HelpBlock } from '@keboola/design';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const MoveTasksModal = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    show: PropTypes.bool.isRequired,
    phases: PropTypes.object.isRequired,
    onMoveTasks: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      value: null,
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Move Selected Tasks to Phase</Modal.Title>
          <ModalIcon icon="right-left" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <Select
            allowCreate
            clearable={false}
            value={this.state.value}
            promptTextCreator={(label) => `Create phase "${label}"`}
            placeholder="Select a phase or type a new..."
            onChange={(input) => this.setState({ value: input })}
            onInputChange={(inputValue) => capitalize(inputValue)}
            options={this.getPhasesOptions()}
          />
          <HelpBlock>Select an existing phase name or type a new phase name.</HelpBlock>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Move"
            isDisabled={!this.isValid()}
            onSave={this.handleSave}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  isValid() {
    return !!this.state.value;
  },

  getPhasesOptions() {
    return this.props.phases.map((key) => ({ label: key, value: key })).toArray();
  },

  closeModal() {
    this.props.onHide();
  },

  handleSave() {
    this.props.onMoveTasks(this.state.value);
  },
});

export default MoveTasksModal;
