import React from 'react';
import { Tooltip } from '@keboola/design';
import type { Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Promise from 'bluebird';
import classnames from 'classnames';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import dayjs from '@/date';
import JobsActionCreators from '@/modules/queue/actions';
import ContinueOnFailureIcon from '@/modules/queue/components/ContinueOnFailureIcon';
import ScheduledJob from '@/modules/queue/components/ScheduledJob';
import TriggeredJob from '@/modules/queue/components/TriggeredJob';
import {
  BEHAVIOR_TYPES,
  JOB_RUNNING_STATUSES,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_STATUS,
  JOBS_TYPES,
  routeNames,
} from '@/modules/queue/constants';
import { isScheduledJob, isTriggeredJob, prepareSortedChildJobs } from '@/modules/queue/helpers';
import {
  getComponentByJob,
  getConfigurationName,
  getOrchestratorPhaseName,
} from '@/modules/queue/jobResolver';
import BlockButton from '@/react/common/BlockButton';
import CircleIcon from '@/react/common/CircleIcon';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import CreatedDate from '@/react/common/CreatedDate';
import Gravatar from '@/react/common/Gravatar';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import JobTerminateButton from '@/react/common/JobTerminateButton';
import Loader from '@/react/common/Loader';
import RouterLink from '@/react/common/RouterLink';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import Truncated from '@/react/common/Truncated';
import User from '@/react/common/User';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import Timer from '@/utils/Timer';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

type DataRow = Row<{ job: Map<string, any> }>;

const JobsTable = (props: {
  configId: string;
  jobs: Map<string, any>;
  allJobs: Map<string, any>;
  admins: Map<string, any>;
  sapiToken: Map<string, any>;
  terminatingPendingActions: Map<string, boolean>;
  isLoading: boolean;
}) => {
  const [loadingChildJobs, setLoadingChildJobs] = React.useState(Map());

  const columns = React.useMemo(
    () => [
      {
        header: 'All runs',
        cell: ({ row }: { row: DataRow }) => {
          const job = row.original.job;
          const component = getComponentByJob(job);
          const isFlow = component.get('id') === KEBOOLA_ORCHESTRATOR;

          if (row.depth === 0) {
            const tokenDescription = job.getIn(
              ['token', 'description'],
              job.getIn(['initiatorToken', 'description']),
            );
            const admin = props.admins.get(tokenDescription);
            const isLoading = Boolean(loadingChildJobs.get(job.get('id'), false));
            const isRunnedByScheduler = isScheduledJob(job);

            return (
              <span className={classnames('flex-container flex-start', { 'with-expand': isFlow })}>
                <>
                  {(row.getCanExpand() || isFlow) && !!job.get('startTime') && (
                    <TableCollapseButton entity="job" isCollapsed={!row.getIsExpanded()} />
                  )}
                  {isRunnedByScheduler ? (
                    <CircleIcon
                      bold
                      icon="clock"
                      color="blue"
                      className="job-icon icon-addon-right"
                    />
                  ) : (
                    <span className="icon-addon-right line-height-1">
                      <Gravatar user={admin} size={28} fallback={tokenDescription} />
                    </span>
                  )}
                  <RouterLink
                    to={routeNames.JOB_DETAIL}
                    params={{ jobId: job.get('id') }}
                    className="link-inherit"
                  >
                    <div className="flex-container flex-start">
                      {admin ? (
                        <User user={admin} avatar={false} />
                      ) : isRunnedByScheduler ? (
                        <ScheduledJob noIcon />
                      ) : isTriggeredJob(job) ? (
                        <TriggeredJob />
                      ) : (
                        tokenDescription
                      )}
                    </div>
                  </RouterLink>
                  {isLoading && (
                    <Tooltip tooltip="Loading child jobs" placement="top">
                      <Loader className="icon-addon-left text-muted-light" />
                    </Tooltip>
                  )}
                </>
              </span>
            );
          }

          if (row.depth === 1) {
            return (
              <span
                className={classnames('flex-container flex-start !tw-ml-14', {
                  'with-expand': isFlow,
                })}
              >
                {row.getCanExpand() && (
                  <TableCollapseButton entity="job" isCollapsed={!row.getIsExpanded()} />
                )}
                <ComponentIcon component={component} isPhase size="28" className="mr-1" />
                <RouterLink
                  to={routeNames.JOB_DETAIL}
                  params={{ jobId: job.get('id') }}
                  className="link-inherit mr-1"
                >
                  <div className="flex-container flex-start">
                    <Truncated text={getOrchestratorPhaseName(job)} />
                  </div>
                </RouterLink>
                {job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </span>
            );
          }

          return (
            <div
              className={classnames('flex-container flex-start !tw-ml-20', {
                'with-expand': isFlow,
              })}
            >
              <ComponentIcon component={component} size="28" />
              <div className="ml-1 mr-1">
                <div className="line-height-18">
                  <Truncated text={getConfigurationName(job)} />
                </div>
                <div className="f-11 line-height-1 text-muted">
                  <ComponentName component={component} capitalize showType>
                    {(nameAndType: string) => nameAndType}
                  </ComponentName>
                </div>
              </div>
              {job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                <ContinueOnFailureIcon />
              )}
            </div>
          );
        },
        accessorKey: 'job',
      },
      {
        header: 'Duration',
        cell: ({ row }: { row: DataRow }) => {
          const job = row.original.job;

          return (
            <JobDuration
              status={job.get('status')}
              startTime={job.get('startTime')}
              endTime={job.get('endTime')}
            />
          );
        },
        accessorKey: 'duration',
      },
      {
        header: 'Created',
        cell: ({ row }: { row: DataRow }) => {
          return <CreatedDate createdTime={row.original.job.get('createdTime')} absolute />;
        },
        accessorKey: 'created',
      },
      {
        header: 'Status',
        cell: ({ row }: { row: DataRow }) => {
          const job = row.original.job;
          const jobId = job.get('id');
          const jobStatus = job.get('status');

          if (props.terminatingPendingActions.get(jobId, false)) {
            return <JobStatusLabel status={JOBS_STATUS.TERMINATING} />;
          }

          if (!JOB_RUNNING_STATUSES.includes(jobStatus) || jobStatus === JOBS_STATUS.TERMINATING) {
            return <JobStatusLabel status={jobStatus} />;
          }

          return (
            <div className="actions-container with-inline-buttons">
              <div className="not-actions">
                <JobStatusLabel status={jobStatus} />
              </div>
              <div className="actions">
                <JobTerminateButton
                  simple
                  isFlow
                  isTerminating={false}
                  job={job}
                  sapiToken={props.sapiToken}
                  onTerminate={() => JobsActionCreators.terminateJob(jobId)}
                />
              </div>
            </div>
          );
        },
        accessorKey: 'status',
      },
    ],
    [props.admins, props.terminatingPendingActions, props.sapiToken, loadingChildJobs],
  );

  const data = React.useMemo(() => {
    return props.jobs
      .map((orchestratorJob: Map<string, any>) => {
        return {
          job: orchestratorJob,
          subRows: prepareSortedChildJobs(props.allJobs, orchestratorJob)
            .map((phaseJob: Map<string, any>) => {
              return {
                job: phaseJob,
                subRows: prepareSortedChildJobs(props.allJobs, phaseJob)
                  .map((taskJob: Map<string, any>) => ({ job: taskJob }))
                  .toArray(),
              };
            })
            .toArray(),
        };
      })
      .toArray();
  }, [props.allJobs, props.jobs]);

  const tableInstance = useReactTable({
    columns,
    data,
    autoResetExpanded: false,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  React.useEffect(() => {
    const checkRunningJobs = () => {
      const openedNotFinishedJobs = tableInstance
        .getRowModel()
        .rows.filter((row) => {
          return (
            row.getIsExpanded() &&
            row.original.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER &&
            (!row.original.job.get('isFinished') ||
              dayjs().diff(row.original.job.get('endTime'), 'minutes') < 1)
          );
        })
        .map((row) => row.original.job);

      return Promise.map(openedNotFinishedJobs, JobsActionCreators.loadChildJobsForce, {
        concurrency: 3,
      }).then(() =>
        JobsActionCreators.loadComponentConfigurationLatestJobs(
          KEBOOLA_ORCHESTRATOR,
          props.configId,
        ),
      );
    };

    Timer.poll(checkRunningJobs, { interval: 10 });
    return () => {
      Timer.stop(checkRunningJobs);
    };
  }, [tableInstance, props.configId]);

  return (
    <div className="box mb-2">
      <div className="table table-hover react-table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => {
            return (
              <div key={headerGroup.id} className="tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div
                      key={header.id}
                      className={classnames('th', {
                        'w-150 text-right': header.column.id === 'duration',
                        'w-250 text-right': header.column.id === 'created',
                        'w-125': header.column.id === 'status',
                      })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="tbody">
          {props.jobs.isEmpty() && (
            <div className="tr no-hover">
              <div className="td text-muted">
                {props.isLoading ? (
                  <>
                    <Loader className="icon-addon-right" />
                    Loading jobs...
                  </>
                ) : (
                  'No flow jobs found'
                )}
              </div>
              <div className="td" />
              <div className="td" />
              <div className="td" />
            </div>
          )}
          {tableInstance.getRowModel().rows.map((row) => {
            const job = row.original.job;
            const rowAction = (e?: React.MouseEvent | React.KeyboardEvent) => {
              if (hasSelections()) {
                return;
              }

              if (shouldUseNewWindow(e)) {
                const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
                  jobId: job.get('id'),
                });

                return windowOpen(href);
              }

              if (
                row.depth < 2 &&
                [JOBS_TYPES.ORCHESTRATION_CONTAINER, JOBS_TYPES.PHASE_CONTAINER].includes(
                  job.get('type'),
                )
              ) {
                if (!row.getIsExpanded() && row.subRows.length === 0 && !!job.get('startTime')) {
                  setLoadingChildJobs(loadingChildJobs.set(job.get('id'), true));
                  return JobsActionCreators.loadChildJobsForce(job).then(() => {
                    setLoadingChildJobs(loadingChildJobs.delete(job.get('id')));
                    row.toggleExpanded(true);
                  });
                }

                return row.toggleExpanded(row.getCanExpand() && !row.getIsExpanded());
              }

              return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, {
                jobId: job.get('id'),
              });
            };
            const isClickable = row.getCanExpand() || row.depth === 2 || !!job.get('startTime');

            return (
              <div
                key={row.id}
                className={classnames('tr hoverable-actions-with-replacement', {
                  clickable: isClickable,
                })}
                {...(isClickable && {
                  tabIndex: 0,
                  role: 'button',
                  onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                  onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                  onClick: rowAction,
                  onKeyDown: onTableRowKeyDown(rowAction),
                })}
              >
                {row.getAllCells().map((cell) => {
                  return (
                    <div
                      key={cell.id}
                      className={classnames('td', {
                        'text-right': ['duration', 'created'].includes(cell.column.id),
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {props.jobs.count() >= JOBS_LIMIT_FOR_GRAPH && (
        <BlockButton
          label="Show All Jobs"
          onClick={() => {
            return RoutesStore.getRouter().transitionTo(
              routeNames.JOBS,
              {},
              { component: KEBOOLA_ORCHESTRATOR, config: props.configId },
            );
          }}
        />
      )}
    </div>
  );
};

export default JobsTable;
