import React from 'react';
import type { ReactNode } from 'react';

type ChosenColumn = { nodeId: string; column: string } | null;

export type ColumnsHighlightingContextType = {
  chosenColumn: ChosenColumn;
  setChosenColumn: (column: ChosenColumn) => void;
  highlightedColumns: { column: string; nodeId: string }[];
  setHighlightedColumns: (highlightedColumns: { column: string; nodeId: string }[]) => void;
  highlightedEdges: string[];
  setHighlightedEdges: (highlightedEdges: string[]) => void;
  shouldResetColumnsHighlight: boolean;
  setShouldResetColumnsHighlight: (shouldResortColumns: boolean) => void;
  nodeIdManipulating: string | null;
  setNodeIdManipulating: (nodeId: string | null) => void;
};

export const ColumnsHighlightingContext = React.createContext<ColumnsHighlightingContextType>(
  null!,
);

const ColumnsHighlightingContextProvider = (props: { children: ReactNode }) => {
  const [shouldResetColumnsHighlight, setShouldResetColumnsHighlight] = React.useState(false);
  const [chosenColumn, setChosenColumn] = React.useState<ChosenColumn>(null);
  const [highlightedColumns, setHighlightedColumns] = React.useState<
    { column: string; nodeId: string }[]
  >([]);
  const [highlightedEdges, setHighlightedEdges] = React.useState<string[]>([]);
  const [nodeIdManipulating, setNodeIdManipulating] = React.useState<string | null>(null);

  const contextValue = React.useMemo(() => {
    return {
      chosenColumn,
      setChosenColumn,
      highlightedColumns,
      setHighlightedColumns,
      highlightedEdges,
      setHighlightedEdges,
      shouldResetColumnsHighlight,
      setShouldResetColumnsHighlight,
      nodeIdManipulating,
      setNodeIdManipulating,
    };
  }, [
    chosenColumn,
    highlightedColumns,
    highlightedEdges,
    nodeIdManipulating,
    shouldResetColumnsHighlight,
  ]);

  return (
    <ColumnsHighlightingContext.Provider value={contextValue}>
      {props.children}
    </ColumnsHighlightingContext.Provider>
  );
};

export default ColumnsHighlightingContextProvider;
