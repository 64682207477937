import Promise from 'bluebird';

import Dispatcher from '@/Dispatcher';
import api from './api';
import { actionTypes } from './constants';
import NotificationsStore from './store';

const loadNotifications = () => {
  if (!NotificationsStore.getAll().isEmpty()) {
    loadNotificationsForce();
    return Promise.resolve();
  }

  loadNotificationsForce();
};

const loadNotificationsForce = () => {
  return api.loadAll().then((notifications) => {
    return Dispatcher.handleViewAction({
      type: actionTypes.LOAD_NOTIFICATIONS_SUCCESS,
      notifications,
    });
  });
};

const addNotification = (notification: Record<string, unknown>) => {
  return api.addNotification(notification);
};

const removeNotification = (id: string | number) => {
  return api.removeNotification(id);
};

export { loadNotifications, loadNotificationsForce, addNotification, removeNotification };
