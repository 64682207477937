import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Modal, Well } from 'react-bootstrap';
import { Alert } from '@keboola/design';
import { List, Map } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import UsedByProjectsList from './UsedByProjectsList';

const UNLINK_TYPE_ALL = 'all';
const UNLINK_TYPE_SELECTED = 'selected';

const UNLINK_TYPES = [
  { value: UNLINK_TYPE_ALL, label: 'Unlink from all projects' },
  { value: UNLINK_TYPE_SELECTED, label: 'Unlink from selected projects' },
];

const INITIAL_STATE = {
  showModal: false,
  unlinkFromProjects: Map(),
  unlinkType: UNLINK_TYPE_SELECTED,
  isLoading: false,
};

class ForceUnlinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static defaultProps = {
    disableAnimation: false,
    showUnsharedInfo: false,
    allowCancel: false,
  };

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <Modal
        animation={!this.props.disableAnimation}
        show={this.props.show}
        onHide={this.closeModal}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <ModalIcon icon="trash" color="red" bold />
            <Modal.Title>Unlink Bucket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            {this.props.showUnsharedInfo ? (
              <Alert variant="success" className="tw-mb-5">
                You have successfully updated sharing of{' '}
                <strong className="tw-font-medium">{this.props.bucket.get('displayName')}</strong>.
                You have unshared the bucket from one or more projects where the bucket is still
                linked (project&apos;s users still see it). Below you may choose projects from which
                you want to unlink it.
              </Alert>
            ) : (
              <p>
                You are about to unlink the bucket{' '}
                <strong className="tw-font-medium">{this.props.bucket.get('displayName')}</strong>{' '}
                from the projects bellow. Reading from the bucket will no longer work in these
                projects.
              </p>
            )}
            <FormGroup>
              <Select
                clearable={false}
                value={this.state.unlinkType}
                onChange={(type) => this.setState({ unlinkType: type })}
                options={UNLINK_TYPES}
              />
            </FormGroup>
            {this.getLinkedProjects().count() !== 0 && (
              <Well>
                <p className="text-muted small">
                  The bucket will be unlinked from{' '}
                  {this.state.unlinkType === UNLINK_TYPE_ALL ? 'these' : 'the following selected'}{' '}
                  projects:
                </p>
                {this.renderProjects()}
              </Well>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="tw-flex tw-items-center tw-gap-2">
              {this.props.allowCancel && (
                <Button
                  bsStyle="default"
                  className="!tw-m-0 tw-w-1/2 tw-grow"
                  onClick={this.closeModal}
                >
                  Keep linked
                </Button>
              )}
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveStyle="success"
                saveLabel={
                  this.state.isLoading
                    ? 'Unlinking...'
                    : this.state.unlinkType === UNLINK_TYPE_ALL
                      ? 'Unlink from all projects'
                      : 'Unlink from selected projects'
                }
                saveButtonClass={'tw-grow !tw-m-0 tw-w-1/2'}
                isSaving={this.state.isLoading}
                isDisabled={
                  this.getLinkedProjects().count() === 0 ||
                  (this.state.unlinkType === UNLINK_TYPE_SELECTED &&
                    this.state.unlinkFromProjects.count() === 0)
                }
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  renderProjects() {
    if (this.state.unlinkType === UNLINK_TYPE_ALL) {
      return (
        <UsedByProjectsList
          urlTemplates={this.props.urlTemplates}
          links={this.getLinkedProjects()}
        />
      );
    }

    return this.getLinkedProjects()
      .map((link) => {
        return (
          <div key={link.getIn(['project', 'id'])}>
            <Checkbox
              className="mb-0 mt-0"
              checked={this.state.unlinkFromProjects.has(link.getIn(['project', 'id']))}
              onChange={(checked) => {
                this.setState({
                  unlinkFromProjects: checked
                    ? this.state.unlinkFromProjects.set(link.getIn(['project', 'id']), true)
                    : this.state.unlinkFromProjects.delete(link.getIn(['project', 'id'])),
                });
              }}
            >
              {link.getIn(['project', 'name'])}
            </Checkbox>
          </div>
        );
      })
      .toArray();
  }

  closeModal() {
    this.setState(INITIAL_STATE, () => {
      this.props.closeModalFn();
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const projectIds =
      this.state.unlinkType === UNLINK_TYPE_ALL
        ? this.getLinkedProjects()
            .map((link) => link.getIn(['project', 'id']))
            .toArray()
        : this.state.unlinkFromProjects.keySeq().toArray();

    this.setState({ isLoading: true });
    this.cancellablePromise = StorageActionCreators.forceUnlinkBucketFromProjects(
      this.props.bucket,
      projectIds,
    )
      .then(this.closeModal)
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  getLinkedProjects() {
    return this.props.bucket.get('linkedBy', List());
  }
}

ForceUnlinkModal.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
};

export default ForceUnlinkModal;
