import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';

type Props = {
  isCollapsed: boolean;
  entity?: string;
  isDisabled?: boolean;
};

const TableCollapseButton = ({ isCollapsed, entity, isDisabled }: Props) => {
  let tooltip = isCollapsed ? 'More Details' : 'Less Details';

  if (entity) {
    tooltip = `${isCollapsed ? 'Expand' : 'Collapse'} ${entity}`;
  }

  return (
    <Tooltip placement="top" tooltip={tooltip} triggerClassName="table-collapse-wrapper">
      <Button
        bsStyle="link"
        className="table-collapse-button btn-link-inline no-underline"
        disabled={isDisabled}
        onClick={() => (document.activeElement as HTMLElement | null)?.blur()}
      >
        <FontAwesomeIcon icon={['far', isCollapsed ? 'angle-right' : 'angle-down']} />
      </Button>
    </Tooltip>
  );
};

export default TableCollapseButton;
