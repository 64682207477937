import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactFlowProvider } from '@xyflow/react';
import classNames from 'classnames';
import { Map } from 'immutable';

import { canAccessProject } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { getProjectsLineage, getTablesLineage } from '@/modules/lineage/api';
import Breadcrumbs from '@/modules/lineage/Breadcrumbs';
import ActionsPanel from '@/modules/lineage/components/ActionsPanel';
import { UPPER_PANEL_HEIGHT } from '@/modules/lineage/components/constants';
import NoAccessIcon from '@/modules/lineage/components/NoAccessIcon';
import NoGraphWrapper from '@/modules/lineage/components/NoGraphWrapper';
import { routeNames } from '@/modules/lineage/constants';
import GraphContextProvider from '@/modules/lineage/contexts/GraphContext';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import { onProjectOpenInNewWindow } from '@/modules/lineage/helpers';
import type { OnSelectProject } from '@/modules/lineage/rfTypes';
import TeasingContent from '@/modules/lineage/TeasingContent';
import type { ProjectsLineage, TablesLineage } from '@/modules/lineage/types';
import FullScreenModal from '@/react/common/FullScreenModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { parseLocationContext } from '@/utils/modalRoutes';
import OrganizationDetailLineage from './OrganizationDetailLineage';
import ProjectDetailLineage from './ProjectDetailLineage';

const CombinedLineage = () => {
  const state = useStores(
    () => {
      return {
        hasAiLineage: ApplicationStore.hasAiLineage(),
        components: ComponentsStore.getAll() as Map<string, any>,
        currentProject: ApplicationStore.getCurrentProject(),
        currentOrganization: ApplicationStore.getCurrentOrganization(),
        projectId: RoutesStore.getCurrentRouteParam('projectId') || null,
        projectName: RoutesStore.getCurrentRouteParam('projectName') || null,
      };
    },
    [],
    [ComponentsStore, ApplicationStore, RoutesStore],
  );

  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [projectsData, setProjectsData] = React.useState<ProjectsLineage | null>(null);
  const [tablesData, setTablesData] = React.useState<TablesLineage | null>(null);

  const [showTablesOnly, setShowTablesOnly] = React.useState(false);

  const fetchProjectsData = useCallback(() => {
    setIsLoading(true);
    getProjectsLineage()
      .then((data) => {
        setProjectsData(data.graph);
        setIsError(Object.keys(data.graph.projects).length === 0);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchTablesData = useCallback((params: { project?: string } = {}) => {
    setIsLoading(true);
    getTablesLineage({ ...params, columns: false })
      .then((data) => {
        setTablesData(data.graph);
        setIsError(
          Object.keys(data.graph.tables).length === 0 &&
            Object.keys(data.graph.configurations).length === 0,
        );
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (state.projectId && state.projectName) {
      fetchTablesData({
        project: constructFQID({ type: FQID_TYPES.project, data: { projectId: state.projectId } }),
      });
      return;
    }
    fetchProjectsData();
  }, [fetchProjectsData, fetchTablesData, state.projectId, state.projectName]);

  const handleSelectProject: OnSelectProject = useCallback((name, projectId, nodeId) => {
    setIsLoading(true);
    const context = new URLSearchParams(window.location.search).get('context') || null;
    RoutesStore.getRouter().transitionTo(
      routeNames.LINEAGE,
      { projectId: projectId, projectName: name },
      { ...(context && { context }), ...(nodeId && { node: nodeId }) },
    );
  }, []);

  const handleOrganizationClick = useCallback(() => {
    const context = new URLSearchParams(window.location.search).get('context') || null;
    RoutesStore.getRouter().transitionTo(routeNames.LINEAGE, null, context ? { context } : null);
  }, []);

  const handleCloseModal = () => {
    setShowTablesOnly(false);

    const { pathname, query, hash, routeState } = parseLocationContext(
      RoutesStore.getRouterState().get('location', Map()),
    );

    RoutesStore.getRouter().transitionTo(pathname, null, query, hash, routeState);
  };

  const handleShowTablesOnly = () => {
    setShowTablesOnly((prevState) => !prevState);
  };

  return (
    <FullScreenModal className="lineage-modal" onHide={handleCloseModal}>
      <GraphContextProvider zoomOnHover>
        <ReactFlowProvider>
          <ActionsPanel
            showActions={state.hasAiLineage && !isError && !isLoading}
            inModal
            onClose={handleCloseModal}
            showTablesOnly={showTablesOnly}
            showTablesToggleAction={!!state.projectId && !!state.projectName}
            onShowTablesToggle={handleShowTablesOnly}
            left={
              <Breadcrumbs
                onOrganizationClick={
                  !state.hasAiLineage || (!state.projectId && !state.projectName)
                    ? null
                    : handleOrganizationClick
                }
                organizationName={state.currentOrganization.get('name')}
                projectName={state.projectName}
                additionalTitleContent={
                  !state.projectId || canAccessProject(state.projectId) ? null : (
                    <NoAccessIcon classNames="tw-ml-3" />
                  )
                }
              />
            }
          />
          <Modal.Body
            className={classNames('tw-content-center !tw-p-0', {
              'grid-background': state.hasAiLineage,
            })}
            style={{ height: `calc(100vh - ${UPPER_PANEL_HEIGHT}px)` }}
          >
            {!state.hasAiLineage ? (
              <TeasingContent />
            ) : (
              <NoGraphWrapper isLoading={isLoading} isError={isError}>
                {!state.projectId && !state.projectName
                  ? projectsData && (
                      <OrganizationDetailLineage
                        data={projectsData}
                        onSelectProject={handleSelectProject}
                      />
                    )
                  : tablesData && (
                      <ProjectDetailLineage
                        allComponents={state.components}
                        isLocked={!state.hasAiLineage}
                        isError={isError}
                        isLoading={isLoading}
                        data={tablesData}
                        showTablesOnly={showTablesOnly}
                        projectId={state.projectId}
                        onSelectProject={onProjectOpenInNewWindow}
                      />
                    )}
              </NoGraphWrapper>
            )}
          </Modal.Body>
        </ReactFlowProvider>
      </GraphContextProvider>
    </FullScreenModal>
  );
};

export default CombinedLineage;
