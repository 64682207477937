import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@keboola/design';
import classNames from 'classnames';
import { capitalize } from 'underscore.string';

import { BACKEND_SIZES } from '@/modules/components/Constants';
import { JOBS_STATUS } from '@/modules/queue/constants';

const CLASS_MAP = {
  [JOBS_STATUS.CREATED]: 'color-primary',
  [JOBS_STATUS.WAITING]: 'text-muted',
  [JOBS_STATUS.PROCESSING]: 'color-primary',
  [JOBS_STATUS.SUCCESS]: 'text-success',
  [JOBS_STATUS.ERROR]: 'text-danger',
  warn: 'color-warning',
  [JOBS_STATUS.WARNING]: 'color-warning',
  canceled: 'color-dark-muted',
  [JOBS_STATUS.CANCELLED]: 'color-dark-muted',
  [JOBS_STATUS.TERMINATED]: 'color-dark',
  [JOBS_STATUS.TERMINATING]: 'color-dark',
} as const;

const JobStatusLabel = ({
  status,
  backendType,
}: {
  status: keyof typeof CLASS_MAP;
  backendType?: (typeof BACKEND_SIZES)[keyof typeof BACKEND_SIZES];
}) => {
  if (!CLASS_MAP[status]) {
    return null;
  }

  return (
    <span className={classNames('job-status-label', CLASS_MAP[status])}>
      {capitalize(status)}
      {JOBS_STATUS.CREATED === status &&
        backendType &&
        [BACKEND_SIZES.MEDIUM, BACKEND_SIZES.LARGE].includes(backendType) && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="It might take a bit longer to process jobs with larger backend sizes."
          >
            <FontAwesomeIcon icon="circle-info" className="status-icon" />
          </Tooltip>
        )}
    </span>
  );
};

export default JobStatusLabel;
