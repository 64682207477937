import React from 'react';
import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

import CircleIcon from '@/react/common/CircleIcon';

const AccountPlanBox = () => {
  return (
    <div className="account-plan-box box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Your Plan</h2>
        <CircleIcon icon="flag" color="blue" bold />
      </div>
      <div className="box-panel-content flex-container fill-space flex-column align-top">
        <h2 className="f-32">Pay As You Go (Free Plan)</h2>
        <p className="text-muted pr-2">
          You are on a Pay As You Go Keboola plan with dozens of free minutes per month. All your
          jobs consume minutes.
        </p>
        <p className="font-medium text-muted">
          <Link href={`${URLS.USER_DOCUMENTATION}/management/payg-project/`}>Learn more</Link>
        </p>
      </div>
    </div>
  );
};

export default AccountPlanBox;
