import React, { useContext } from 'react';

import { GraphContext } from '@/modules/lineage/contexts/GraphContext';
import LoadingPlaceholder from './LoadingPlaceholder';

const NodesLoadingPlaceholder = (props: { children: React.ReactNode }) => {
  const { isLoading, setIsLoading, graphId } = useContext(GraphContext);
  const observer = React.useRef<MutationObserver | null>(null);

  const disconnectObserver = React.useCallback(() => {
    observer.current?.disconnect();
  }, []);

  React.useLayoutEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  React.useEffect(() => {
    const reactFlow = document.getElementById(graphId);

    if (!reactFlow) {
      setIsLoading(false);
      return;
    }

    observer.current = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode instanceof Element && addedNode.classList.contains('react-flow__node')) {
            setIsLoading(false);
            disconnectObserver();
            return;
          }
        });
      });
    });

    observer.current.observe(reactFlow, { childList: true, subtree: true });

    return () => {
      disconnectObserver();
    };
  }, [setIsLoading, disconnectObserver, graphId]);

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  return props.children;
};

export default NodesLoadingPlaceholder;
