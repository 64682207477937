import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@keboola/design';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_WR_LOOKER_V2 } from '@/constants/componentIds';
import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import TablesByBucketsPanel from '@/modules/components/react/components/TablesByBucketsPanel';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { removeDevBranchReferenceFromTable } from '@/modules/dev-branches/helpers';
import { STAGE } from '@/modules/storage/constants';
import WrDbActions from '@/modules/wr-db/actionCreators';
import { getStorageInputTablesWithSourceSearch, supportConfigRows } from '@/modules/wr-db/helpers';
import { registerToLooker, saveCredentials } from '@/modules/wr-db/lookerActions';
import AddNewTableModal from '@/modules/wr-db/react/components/AddNewTableModal';
import LookerCredentialsModal from '@/modules/wr-db/react/components/LookerCredentialsModal';
import MigrateToRowsButton from '@/modules/wr-db/react/components/MigrateToRowsButton';
import ProvisioningCredentialsModal from '@/modules/wr-db/react/pages/credentials/ProvisioningCredentialsModal';
import WrDbStore from '@/modules/wr-db/store';
import hasValidCredentials from '@/modules/wr-db/templates/hasValidCredentials';
import V2Actions from '@/modules/wr-db/v2-actions';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import CredentialsSetupBox from '@/react/common/CredentialsSetupBox';
import FilterPanel from '@/react/common/FilterPanel';
import Link from '@/react/common/RouterLink';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ConfigRowTables from './ConfigRowTables';
import TableRow from './TableRow';

const Index = (componentId, driver, isProvisioning) => {
  return createReactClass({
    displayName: 'wrdbIndex',

    mixins: [
      createStoreMixin(
        ApplicationStore,
        ComponentsStore,
        StorageTablesStore,
        StorageBucketsStore,
        InstalledComponentsStore,
        ConfigurationRowsStore,
        DevBranchesStore,
        WrDbStore,
      ),
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const localState = InstalledComponentsStore.getLocalState(componentId, configId);
      const tables = WrDbStore.getTables(componentId, configId);
      const credentials = WrDbStore.getCredentials(componentId, configId);
      const lookerCredentials = WrDbStore.getLookerCredentials(componentId, configId);
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const configRows = ConfigurationRowsStore.getRows(componentId, configId);
      const component = ComponentsStore.getComponent(componentId);
      const configData = config.get('configuration', Map());
      const globalDbConfig = component.getIn(
        ['data', 'image_parameters', 'global_config', 'db'],
        Map(),
      );

      return {
        config,
        configData,
        configRows,
        component,
        componentId,
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        updatingTables: WrDbStore.getUpdatingTables(componentId, configId),
        allTables: StorageTablesStore.getAll(),
        allBuckets: StorageBucketsStore.getAll(),
        tables,
        credentials,
        lookerCredentials,
        configId,
        localState,
        bucketToggles: localState.get('bucketToggles', Map()),
        deletingTables: WrDbStore.getDeletingTables(componentId, configId),
        v2Actions: V2Actions(configId, componentId),
        hasValidCredentials: hasValidCredentials(
          componentId,
          globalDbConfig.mergeDeep(credentials),
        ),
        hasValidLookerCredentials:
          lookerCredentials.get('credentialsId') &&
          lookerCredentials.get('#token') &&
          lookerCredentials.get('host'),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        tablesWithSourceSearchInputMapping: getStorageInputTablesWithSourceSearch(configData),
        sapiToken: ApplicationStore.getSapiToken(),
        readOnly: ApplicationStore.isReadOnly(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        allComponents: ComponentsStore.getAll(),
      };
    },

    getInitialState() {
      return {
        showLookerCredentialsModal: false,
        showProvisioningCredentialsModal: false,
      };
    },

    render() {
      return (
        <>
          <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            allComponents={this.state.allComponents}
            config={this.state.config}
            flows={this.state.flows}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            {this.renderMainContent()}
            {this.renderSideBar()}
          </div>
        </>
      );
    },

    _hasTablesToExport() {
      if (this._hasActiveConfigRows()) {
        return !!this.state.configRows.find((row) => row.get('isDisabled') === false);
      }

      return !!this.state.tables.find((table) => table.get('export') === true);
    },

    _renderAddNewTable() {
      if (this.state.readOnly || (supportConfigRows(componentId) && !this._hasActiveConfigRows())) {
        return null;
      }

      const data = this.state.localState.get('newTable', Map());
      const selectedTableId = data.get('tableId');
      const inputTables = this.state.tables.toMap().mapKeys((key, c) => c.get('id'));
      const isAllConfigured =
        this.state.allTables
          .filter((table) => {
            return (
              Object.values(STAGE).includes(table.getIn(['bucket', 'stage'])) &&
              !inputTables.has(table.get('id'))
            );
          })
          .count() === 0;

      const updateStateFn = (path, newData) => {
        return this._updateLocalState(['newTable'].concat(path), newData);
      };

      return (
        <>
          <Button
            bsSize="sm"
            bsStyle="success"
            disabled={isAllConfigured}
            onClick={() => updateStateFn(['show'], true)}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Add Table
          </Button>
          <AddNewTableModal
            show={data.get('show', false)}
            buckets={this.state.allBuckets}
            tables={this.state.allTables}
            onHideFn={() => this._updateLocalState([], Map())}
            selectedTableId={selectedTableId}
            onSetTableIdFn={(tableId) => updateStateFn(['tableId'], tableId)}
            configuredTables={inputTables}
            onSaveFn={(tableId) => {
              const selectedTable = this.state.allTables.find((t) => t.get('id') === tableId);
              return WrDbActions.addTableToConfig(
                componentId,
                this.state.configId,
                tableId,
                selectedTable,
              ).then(() => {
                return RoutesStore.getRouter().transitionTo(`${componentId}-table`, {
                  tableId: removeDevBranchReferenceFromTable(
                    tableId,
                    DevBranchesStore.getCurrentId(),
                  ),
                  config: this.state.configId,
                });
              });
            }}
            isSaving={this._isPendingTable(selectedTableId)}
          />
        </>
      );
    },

    renderMainContent() {
      return (
        <div className="col-sm-9">
          <ComponentDescription componentId={componentId} configId={this.state.configId} />
          {this.renderMigrationToRowsButton()}
          {this.renderMissingDatabaseCredentials()}
          {this.renderTables()}
        </div>
      );
    },

    renderTables() {
      if (
        !this.state.hasValidCredentials ||
        (componentId === KEBOOLA_WR_LOOKER_V2 && !this.state.hasValidLookerCredentials)
      ) {
        return null;
      }

      if (this._hasActiveConfigRows()) {
        return (
          <ConfigRowTables
            readOnly={this.state.readOnly}
            component={this.state.component}
            config={this.state.config}
            rows={this.state.configRows}
            buckets={this.state.allBuckets}
            tables={this.state.allTables}
          />
        );
      }

      return this.renderTablesByBuckets();
    },

    renderMigrationToRowsButton() {
      if (
        this.state.readOnly ||
        !supportConfigRows(componentId) ||
        !this.state.configData.hasIn(['parameters', 'tables'])
      ) {
        return null;
      }

      return (
        <div className="box">
          <div className="box-content text-center">
            {this.state.config.get('rows', List()).isEmpty() ? (
              <p>
                Please migrate the configuration to the newest format to unlock the latest features.
              </p>
            ) : (
              <Alert variant="warning" className="tw-mb-5">
                The migration was not fully completed. Please run it again.
              </Alert>
            )}
            <MigrateToRowsButton
              onMigrate={() => this.state.v2Actions.migrateConfig(this.state.configId)}
            />
          </div>
        </div>
      );
    },

    renderTablesByBuckets() {
      if (this.state.tables.isEmpty()) {
        return (
          <div className="box-separator">
            <h2 className="tw-m-0 tw-mb-4 tw-text-base">Tables</h2>
            <div className="box">
              <div className="box-content text-center">
                <p>No tables assigned yet.</p>
                {this._renderAddNewTable()}
              </div>
            </div>
          </div>
        );
      }

      const configuredIds = this.state.tables.map((table) => table.get('id'));
      const configuredTables = configuredIds ? configuredIds.toJS() : [];
      const hasAnyRowWithChangedSinceSet = this._hasAnyRowWithChangedSinceSet();

      return (
        <div className="box-separator">
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base">Tables</h2>
            {this._renderAddNewTable()}
          </div>
          <FilterPanel
            query={this.state.localState.get('searchQuery') || ''}
            onChange={this._handleSearchQueryChange}
          />
          <div className="box tables-by-buckets">
            <div className="box-content">
              <TablesByBucketsPanel
                tables={this.state.allTables}
                buckets={this.state.allBuckets}
                renderTableRowFn={(table) =>
                  this._renderTableRow(table, hasAnyRowWithChangedSinceSet, true)
                }
                renderDeletedTableRowFn={(table) =>
                  this._renderTableRow(table, hasAnyRowWithChangedSinceSet, false)
                }
                searchQuery={this.state.localState.get('searchQuery')}
                isExportedFn={this._isTableExported}
                isShownFn={this._isTableInConfig}
                onToggleBucketFn={this._handleToggleBucket}
                isBucketToggledFn={this._isBucketToggled}
                configuredTables={configuredTables}
                tablesWithSourceSearchInputMapping={this.state.tablesWithSourceSearchInputMapping}
              />
            </div>
          </div>
        </div>
      );
    },

    renderMissingDatabaseCredentials() {
      if (!this.state.hasValidCredentials) {
        return (
          <>
            <CredentialsSetupBox
              show
              readOnly={this.state.readOnly}
              onClick={this.handleSetUpCredentials}
            />
            {isProvisioning && this.state.showProvisioningCredentialsModal && (
              <ProvisioningCredentialsModal
                show
                driver={driver}
                componentId={componentId}
                configId={this.state.configId}
                sapiToken={this.state.sapiToken}
                onHide={() => this.setState({ showProvisioningCredentialsModal: false })}
              />
            )}
          </>
        );
      }

      if (componentId === KEBOOLA_WR_LOOKER_V2) {
        return (
          <div className="box">
            <div className="box-content">
              {this.state.hasValidLookerCredentials ? (
                <>
                  Registered Looker credentials with Client ID:{' '}
                  <strong>{this.state.lookerCredentials.get('credentialsId')}</strong>
                  {!this.state.readOnly && (
                    <Button
                      bsSize="sm"
                      bsStyle="link"
                      onClick={() => this.setState({ showLookerCredentialsModal: true })}
                    >
                      Change
                    </Button>
                  )}
                </>
              ) : (
                <div className="text-center">
                  <p>No Looker credentials set.</p>
                  {!this.state.readOnly && (
                    <Button
                      bsSize="sm"
                      bsStyle="success"
                      onClick={() => this.setState({ showLookerCredentialsModal: true })}
                    >
                      Connect your Looker account
                    </Button>
                  )}
                </div>
              )}
              <LookerCredentialsModal
                show={this.state.showLookerCredentialsModal}
                onHide={() => {
                  this.setState({ showLookerCredentialsModal: false });
                }}
                registerToLookerFn={registerToLooker}
                saveCredentialsFn={saveCredentials}
                configId={this.state.configId}
                lookerComponent={ComponentsStore.getComponent(KEBOOLA_WR_LOOKER_V2)}
              />
            </div>
          </div>
        );
      }

      return null;
    },

    _disabledToRun() {
      if (!this.state.hasValidCredentials) {
        return 'No database credentials provided';
      }
      if (!this._hasTablesToExport()) {
        return 'No tables selected to export';
      }
      return '';
    },

    renderSideBar() {
      return (
        <div className="col-sm-3">
          <Sidebar
            componentId={componentId}
            configId={this.state.configId}
            run={{
              disabled: this._disabledToRun(),
              text: 'You are about to run an upload of selected tables.',
            }}
            additionalButtons={
              this.state.hasValidCredentials && (
                <Link to={`${componentId}-credentials`} params={{ config: this.state.configId }}>
                  <FontAwesomeIcon icon="user" fixedWidth />
                  Database credentials
                </Link>
              )
            }
          />
        </div>
      );
    },

    _renderTableRow(table, hasAnyRowWithChangedSinceSet, tableExists = true) {
      const tableMapping = this.state.v2Actions.getTableMapping(table.get('id'));
      const configTable = this.state.v2Actions.configTables.find(
        (t) => t.get('tableId') === table.get('id'),
      );

      return (
        <TableRow
          key={table.get('id')}
          readOnly={this.state.readOnly}
          tableExists={!!(tableExists && tableMapping)}
          configId={this.state.configId}
          tableDbName={this._getConfigTable(table.get('id')).get('dbName')}
          isIncremental={!!(configTable && configTable.get('incremental'))}
          isTableExported={this._isTableExported(table.get('id'))}
          isPending={this._isPendingTable(table.get('id'))}
          isUpdating={this._isUpdating()}
          componentId={componentId}
          onExportChangeFn={() => {
            return this._handleExportChange(table.get('id'));
          }}
          table={table}
          prepareSingleUploadDataFn={this._prepareTableUploadData}
          deleteTableFn={(tableId) => {
            return WrDbActions.deleteTable(componentId, this.state.configId, tableId);
          }}
          isDeleting={!!this.state.deletingTables.get(table.get('id'))}
          showIncrementalResetWarning={hasAnyRowWithChangedSinceSet}
          tablesWithSourceSearchInputMapping={this.state.tablesWithSourceSearchInputMapping}
        />
      );
    },

    _handleExportChange(tableId) {
      const isExported = this._isTableExported(tableId);
      const newExportedValue = !isExported;
      const table = this._getConfigTable(tableId);
      let dbName = tableId;
      if (table && table.get('name')) {
        dbName = table.get('name');
      }
      return WrDbActions.setTableToExport(
        componentId,
        this.state.configId,
        tableId,
        dbName,
        newExportedValue,
      );
    },

    _hasActiveConfigRows() {
      return (
        supportConfigRows(componentId) &&
        this.state.configData.has('parameters') &&
        !this.state.configData.hasIn(['parameters', 'tables'])
      );
    },

    _isPendingTable(tableId) {
      return this.state.updatingTables.has(tableId);
    },

    _isUpdating() {
      return !!(this.state.updatingTables.count() || this.state.deletingTables.count());
    },

    _prepareTableUploadData() {
      return [];
    },

    _isTableInConfig(tableId) {
      return this.state.tables.find((t) => t.get('id') === tableId);
    },

    _isTableExported(tableId) {
      const table = this._getConfigTable(tableId);
      return table && table.get('export') === true;
    },

    _handleToggleBucket(bucketName) {
      const newValue = !this._isBucketToggled(bucketName);
      const newToggles = this.state.bucketToggles.set(bucketName, newValue);
      return this._updateLocalState(['bucketToggles'], newToggles);
    },

    _isBucketToggled(bucketName) {
      return !!this.state.bucketToggles.get(bucketName);
    },

    _handleSearchQueryChange(newQuery) {
      return this._updateLocalState(['searchQuery'], newQuery);
    },

    _updateLocalState(path, data) {
      const newLocalState = this.state.localState.setIn(path, data);
      return InstalledComponentsActions.updateLocalState(
        componentId,
        this.state.configId,
        newLocalState,
        path,
      );
    },

    _getConfigTable(tableId) {
      return this.state.tables.find((table) => tableId === table.get('id'));
    },

    _hasAnyRowWithChangedSinceSet() {
      return (
        this.state.tables
          .find(
            (table) => {
              const tableMapping = this.state.v2Actions.getTableMapping(table.get('id'));
              return !!tableMapping && !!tableMapping.get('changed_since', '');
            },
            null,
            Map(),
          )
          .count() > 0
      );
    },

    handleSetUpCredentials() {
      if (
        !isProvisioning ||
        this.state.isDevModeActive ||
        (driver === 'redshift' && !this.state.sapiToken.getIn(['owner', 'hasRedshift'], false))
      ) {
        return RoutesStore.getRouter().transitionTo(`${componentId}-credentials`, {
          config: this.state.configId,
        });
      }

      this.setState({ showProvisioningCredentialsModal: true });
    },
  });
};

export default Index;
